import React, { useLayoutEffect } from "react";
import { Link } from 'react-router-dom';
import SessionWarning from "../reusableComponent/sessionWarning";
import { resize, hideSidebar } from "../../settings/resize";
import menuData from "../../data/menu.json";


const Logs = () => {

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    })

    useLayoutEffect(() => {
        resize()
        hideSidebar();
    }, [])

    const sortedLogs = [...menuData.logs].sort((a, b) => a.reportName.localeCompare(b.reportName));

    return (
        <>
            <div className="container p-2">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="row">
                            <h5>Most popular Logs</h5>
                        </div>
                    </div>
                    <div className="col-12 pt-2 pb-3">
                        <div className="row justify-center">
                            {
                                sortedLogs.map((log, index) => (
                                    localStorage.permissions.includes(log.permissions) && (
                                        <Link to={log.linkTo} aria-expanded="false" key={index}>
                                            <div className="flex-item">
                                                <div className="flex-item-head">{log.reportName}</div>
                                                <br />
                                                <div className="flex-item-body">{log.description}</div>
                                            </div>
                                        </Link>
                                    )
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default Logs;