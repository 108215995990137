import React, { FC } from "react";

type dropItem={
  label:string,
  value:number|string
}

interface Props{
    listOptions:any
    onListClick:(item:dropItem)=>void,
    activeIndex:number
    onHoverIndex:(index:number)=>void
    selectedValue:string
}



export const SuggestionsList:FC<Props>=({listOptions,onListClick,activeIndex,onHoverIndex,selectedValue})=>{

  const onclick = (selectedItem:dropItem)=>{
      //  if(selectedValue===selectedItem.label) return
       onListClick(selectedItem) 
  }

  // Handle mouse hover to avoid conflicts with keyboard navigation
  const handleMouseEnter = (index: number) => {
    // if(activeIndex===index) return
    onHoverIndex(index); // Update the active index when hovering over an item
  };

    return(
        <>
        {listOptions.map((item:dropItem, index:number)=>{
          return(
            <li key={index} 
            onClick={()=>onclick(item)} 
            onMouseEnter={() => handleMouseEnter(index)} // Highlight item on hover
            className={index === activeIndex ? 'active suggestion-item' : 'suggestion-item'}>
            {item.label}
            </li>
          )
        })}

        </>

    )
}