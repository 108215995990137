import React, { FC, useEffect, useRef, useState } from 'react'
import { SuggestionsList } from './suggestionsList'

type dropItem={
    label:string,
    value:number|string
  }

interface Props{
    placeholder:string
    listOptions:any
    isOpen:boolean
    filterList:(value:string)=>void
    getSelectedItem:(selectedItem:dropItem)=>void
    toggleDropdown:(labelName:any) =>void;
    autoSelectLabel:string
}

export const SearchBox:FC<Props>=({placeholder,listOptions,isOpen,filterList,getSelectedItem,toggleDropdown,autoSelectLabel})=>{

    const[inputValue,setInputValue]=useState<string | number>("")
    const [selectedValue,setSelectedValue] = useState<any>("")
    const [activeIndex, setActiveIndex] = useState<number>(-1); // Track the active list item index
    const listRef = useRef<HTMLUListElement>(null); // Ref to access the list container

    useEffect(()=>{
      if(autoSelectLabel !== undefined && typeof(autoSelectLabel) !=='number'){
        setInputValue(autoSelectLabel)
      }else if(typeof(autoSelectLabel) ==='number'){
        setInputValue("")
      } 
    },[autoSelectLabel])
    
    const onSearch = (event:any)=>{ 
       const eventValue = event.target.value
       setInputValue(eventValue)
       if(eventValue.length > 2 ){
          filterList(eventValue)
       }else if(!eventValue){
          filterList(eventValue)
       }
    }

  const onListClick = (selectedItem:dropItem)=>{
        setInputValue(selectedItem.label)
        getSelectedItem(selectedItem)
        setSelectedValue(selectedItem.label)
  }

    // Handle keyboard navigation
  const handleKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prevIndex) =>
        prevIndex < listOptions.length - 1 ? prevIndex + 1 : prevIndex
      );
    }else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    }else if (e.key === 'Enter' && activeIndex >= 0) {
      e.preventDefault();
      const selectedItem = listOptions[activeIndex]; // Get the selected value
      toggleDropdown(null); // Close the dropdown 
      if (selectedItem && 'label' in selectedItem && 'value' in selectedItem){
            if(selectedItem.label===selectedValue) return
            getSelectedItem(selectedItem)
            setActiveIndex(-1); // Reset the active index after selection
            filterList(selectedItem['label'])
            setInputValue(selectedItem['label'])
      }    
    }
  };

  // Ensure the active item is visible in the list
  useEffect(() => {
    if (listRef.current && activeIndex >= 0) {
      const activeItem = listRef.current.children[activeIndex] as HTMLLIElement;
      activeItem.scrollIntoView({ block: 'nearest' });
    }
  }, [activeIndex]);

  const onHoverIndex=(index:number)=>{
    setActiveIndex(index)
  }

    return(
        <>
        {isOpen && <div className='search-wrapper'>
            <input type="search"  
             value={inputValue}
             placeholder={placeholder} 
             className='filter-input'
             onChange={onSearch}
             onKeyDown={handleKeyDown} // Attach the key down event handler
            />
            <ul className='suggestions-list' ref={listRef}>
            <SuggestionsList
              listOptions={listOptions}
              onListClick={(selectedItem:dropItem)=>onListClick(selectedItem)}
              activeIndex={activeIndex}
              onHoverIndex={(index)=>onHoverIndex(index)}
              selectedValue={selectedValue}
            />
            </ul>
            </div>
        } 
        </>
    )
}