import React,{FC,useState,useEffect} from 'react';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { Modal,Select,ConfigProvider} from 'antd';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Ushift1 from './uShift1';
import Ushift2 from './ushift2';
import Ushift3 from './ushift3';
import * as roles from '../../settings/roles';



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className="mt-3 bg-white"
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  
  const a11yProps = (index: number) => {
    return {
      id: `simple-minitab-${index}`,
      "aria-controls": `simple-minitabpanel-${index}`,
    };
  };

  interface Props{
    isOpen:boolean
    update:()=>void
    items:any
    updatedShedule:()=>void
  }

const UpdateShiftShedules:FC<Props> = ({isOpen,update,items,updatedShedule})=>{
    const [value, setValue] = React.useState(0);
    const [shiftReq,setShiftReq] = useState<any>(items)
    const [disable1,setDisable1] = useState<boolean>(false)
    const [disable2,setDisable2] = useState<boolean>(false)
    const [disable3,setDisable3] = useState<boolean>(false)
    const [callLocations,setCallLocations] = useState<boolean>(true)


    const [orgList,setOrgList] = useState<any[]>([])
    const [companyList,setCompanyList] = useState<any[]>([])
    const [locationList,setLocationList] = useState<any>([])
    const [siteList,setSiteList] = useState<any>([])
    const [orgDisabled,setOrgDisabled] = useState<boolean>(false)
    const [companyDisabled,setCompanyDisabled] = useState<boolean>(false)
    const [locDisabled,setLocDisabled] = useState<boolean>(false)
    const [siteDisabled,setSiteDisabled] = useState<boolean>(false)


    useEffect(()=>{
      setDisable1(shiftReq['shift1Enabled']==="Y"?true:false)
      setDisable2(shiftReq['shift2Enabled']==="Y"?true:false)
      setDisable3(shiftReq['shift3Enabled']==="Y"?true:false)
    },[])

    const getOrgList = async () => {
      let userInfo: any = Utility.getUserInfo();
      let token: string = userInfo.authToken;
      let userId = userInfo.userId
      let payload = {}
      if (roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) {
          payload={orgId:0,divisionId:0,locationId:0,buildingId:0}
      } else {
          payload={userId:userId,divisionId:0,locationId:0,buildingId:0}
      }

      try {
        const response = await service({
        url: constantUrl.api.dashboard.getOrganizations,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data:payload
        });
        setOrgList(response)
        if(callLocations){
          setCallLocations(false)
        }
      } catch (error) {
        console.log(error)
      }
    };

    const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
  
    const handleOk = async()=>{
      let userInfo: any = Utility.getUserInfo();
      let token: string = userInfo.authToken;
      try {
          const response = await service({
          url: constantUrl.api.shiftShedules.putShift+"/"+shiftReq.shiftId,
          method: "PUT",
          headers: {'Content-Type':'application/json', Authorization: "Bearer " + token },
          data:shiftReq
          });
          update()
          updatedShedule()
      } catch (error) {
        console.log(error)
      }
    };

    const selectOrgId = (value:any)=>{
      const selectedOrg = orgList.find((item: any) => item.orgId === value);
      if (selectedOrg) {
        let orgId = selectedOrg['orgId']
        setShiftReq({...shiftReq,orgId,orgName: selectedOrg.orgName,divisionId:-1,divisionName:'All',locationId:-1,locationName:'All',
        buildingId:-1,buildingName:'All'});
        getCompanyList(selectedOrg.orgId)
        getLocations(orgId,-1,-1,-1)
        getSiteList(orgId,-1,-1)
      }
    };

    const getLocations = async (orgId:number,divisionId:number,locationId:number,buildingId:number) => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        let payload = {orgId,divisionId,locationId,buildingId}
        try {
          const response = await service({
          url: constantUrl.api.dashboard.getLocations,
          method: "POST",
          headers: { Authorization: 'Bearer ' + token },
          data:payload
          })
          setLocationList(response)
        } catch (error) {
          console.log(error)
        }
    };

    const handleLocation = (locationName:any)=>{
      let selectedLoc = locationList.find((item:any)=>item.locationName === locationName)
      const {divisionId,orgId} = shiftReq
      if(locationName==='All'){
        setShiftReq({...shiftReq,locationId:-1,locationName:'All',buildingId:-1,buildingName:'All'})
        getSiteList(orgId,divisionId,-1)
      }else{
        let locationId = selectedLoc['locId'] 
        setShiftReq({...shiftReq,locationId,locationName,buildingId:-1,buildingName:'All'})
        getSiteList(orgId,divisionId,locationId)
      };
    };

    const getSiteList = async(orgId:number,divisionId:number,locationId:number)=>{
      let userInfo: any = Utility.getUserInfo();
      let token: string = userInfo.authToken;
      let payload = {orgId,divisionId,locationId}
      try {
        const response = await service({
        url: constantUrl.api.HeaderFilters.getSites,
        method: "POST",
        headers: { Authorization: 'Bearer ' + token },
        data:payload
        })
        setSiteList(response)
      } catch (error) {
        console.log(error)
      }
    };

    const handleBuilding = (buildingName:any)=>{
      let selectedBuilding = siteList.find((item:any)=>item.buildingName === buildingName)
      if(buildingName==='All'){
        setShiftReq({...shiftReq,buildingId:-1,buildingName:'All'})
      }else{
         setShiftReq({...shiftReq,buildingId:selectedBuilding.id,buildingName:selectedBuilding.buildingName})
      }
    };


    const shift1Checkbox = (value:boolean)=>{
      setDisable1(value)
      value?setShiftReq({...shiftReq,shift1Enabled:'Y'}):setShiftReq({...shiftReq,shift1Enabled:'N'})
    };
  
    const onChangeShift1 = (value1:any,value2:string)=>{
        setShiftReq({...shiftReq,[value2]:value1})
    };
 
    const shift1Break = (value1:string,value2:string)=>{
          setShiftReq({...shiftReq,[value1]:null,[value2]:null})
    };


    const shift2Checkbox = (value:boolean)=>{
      setDisable2(value)
      value?setShiftReq({...shiftReq,shift2Enabled:'Y'}):setShiftReq({...shiftReq,shift2Enabled:'N'})
    };

    const onChangeShift2 = (value1:any,value2:string)=>{
          setShiftReq({...shiftReq,[value2]:value1})
    };

    const shift2Break = (value1:string,value2:string)=>{
          setShiftReq({...shiftReq,[value1]:null,[value2]:null})
    };

    const shift3Checkbox = (value:boolean)=>{
        setDisable3(value)
        value?setShiftReq({...shiftReq,shift3Enabled:'Y'}):setShiftReq({...shiftReq,shift3Enabled:'N'})
    };

    const onChangeShift3 = (value1:any,value2:string)=>{
        setShiftReq({...shiftReq,[value2]:value1})
    };

    const shift3Break = (value1:string,value2:string)=>{
            setShiftReq({...shiftReq,[value1]:null,[value2]:null})
    };

    const getCompanyList = async(orgId:number)=>{
      let userInfo: any = Utility.getUserInfo();
      let token: string = userInfo.authToken;
  
      try {
        const response = await service({
        url: constantUrl.api.location.getDivisions,
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + token },
        data:{orgId:orgId}
        });
        setCompanyList(response)
      } catch (error) {
        console.log("getOrg:", error);
      }
    }
  
    const handleCompany = (companyName:string)=>{
          let company = companyList.find((item:any)=>item.divisionName === companyName)
          const {orgId} = shiftReq 
          if(companyName==='All'){
            setShiftReq({...shiftReq,divisionId:-1,divisionName:companyName,locationId:-1,
              locationName:'All',buildingId:-1,buildingName:'All'})
            getLocations(orgId,-1,-1,-1) 
            getSiteList(orgId,-1,-1)
          }else{
            let divisionId = company['divisionId']
            setShiftReq({...shiftReq,divisionId,divisionName:companyName,locationId:-1,
            locationName:'All',buildingId:-1,buildingName:'All'})

            getLocations(orgId,divisionId,-1,-1) 
            getSiteList(orgId,divisionId,-1)
          }
  
    };

    useEffect(()=>{
      let userRole = localStorage?.UserType
      if(roles.PLATFORM_ADMIN===userRole || roles.PLATFORM_MANAGER ===userRole || roles.DEALER_ADMIN===userRole || roles.DEALER_MANAGER===userRole){
        getOrgList()
        items['orgId'] && getLocations(items['orgId'],-1,-1,-1)
        items.buildingId && getSiteList(items['orgId'],-1,-1)
        getCompanyList(items['orgId'])
        return
      }

      if (roles.COMPANY_ADMIN === localStorage?.UserType || roles.COMPANY_MANAGER === localStorage?.UserType) {
        setOrgDisabled(true)
        setCompanyDisabled(true)
        let orgId =      Number(localStorage.getItem('accountId')) 
        let orgName =    localStorage.getItem('accountName')
        let divisionId = Number(localStorage.getItem('divisionId'))  
        let divisionName=localStorage.getItem('divisionName') 
        setShiftReq({...shiftReq,orgId,orgName,divisionId,divisionName})
        getLocations(orgId,divisionId,-1,-1)
        getSiteList(orgId,divisionId,-1,)
        return
      }
      if (roles.LOCATION_ADMIN === localStorage?.UserType || roles.LOCATION_MANAGER === localStorage?.UserType) {
        setOrgDisabled(true)
        setCompanyDisabled(true)
        setLocDisabled(true)
        let orgId =      Number(localStorage.getItem('accountId')) 
        let orgName =    localStorage.getItem('accountName')
        let divisionId = Number(localStorage.getItem('divisionId'))  
        let divisionName=localStorage.getItem('divisionName')
        let locationId = Number(localStorage.getItem('locationId'))
        let locationName = localStorage.getItem('locationName')
        setShiftReq({...shiftReq,orgId,orgName,divisionId,divisionName,locationId,locationName})
        getSiteList(orgId,divisionId,locationId)
        return
      }
      if (roles.SITE_ADMIN === userRole || roles.SITE_MANAGER === userRole) {
        setOrgDisabled(true)
        setCompanyDisabled(true)
        setLocDisabled(true)
        setSiteDisabled(true)
        let orgId =      Number(localStorage.getItem('accountId')) 
        let orgName =    localStorage.getItem('accountName')
        let divisionId = Number(localStorage.getItem('divisionId'))  
        let divisionName=localStorage.getItem('divisionName')
        let locationId = Number(localStorage.getItem('locationId'))
        let locationName = localStorage.getItem('locationName')
        let buildingId = Number(localStorage.getItem('buildingId')) 
        let buildingName = localStorage.getItem('buildingName')
        setShiftReq({...shiftReq,orgId,orgName,divisionId,divisionName,locationId,locationName,buildingId,buildingName})
      }
    },[])

    return(
      <ConfigProvider theme = {{token : {colorPrimary : '#004f9f'}}}>  
      <Modal width={'90%'} centered 
       title="Modify Shift Schedule" open={isOpen} onOk={handleOk} onCancel={()=>update()}>
      <div className='drop-list'>
      <ConfigProvider 
        theme = {{token:{colorPrimary :'#004f9f'}}}>
        <div>
        <label className="pr-2 mb-0">Organization: </label>
        <Select
          disabled={orgDisabled}
          value={shiftReq.orgName}
          size='small'
          style={{width:175}}
          placeholder="Select Organization" onChange={selectOrgId}>
         {orgList?.map((item:any,index:any)=>{
          return <Select.Option key={index} value={item.orgId}>{item.orgName}</Select.Option>
          })}
        </Select>
        </div>  
        <div> 
          <label className="pr-2 mb-0">Division: </label>
          <Select
            disabled={companyDisabled}
            size='small'
            style={{ width: 175 }}
            placeholder="Select Division"
            onChange={handleCompany}
            value={shiftReq?.divisionName}
          >
           <Select.Option key={'key'} value={'All'}>All</Select.Option>
           {companyList?.map((item: any, index: any) => {
              return <Select.Option key={index} value={item.divisionName}>{item.divisionName}</Select.Option>
            })}
          </Select>
          </div> 
        <div>
        <label className="pr-2 mb-0">Location: </label>
        <Select
          disabled={locDisabled}
          value={shiftReq.locationName}
          size='small'
          style={{width:175}}
          placeholder="Select Location"
          onChange={handleLocation}
          >
          <Select.Option key={'key'} value={'All'}>All</Select.Option>
          {locationList?.map((item:any,index:any)=>{
          return <Select.Option key={index+"1"} value={item.locationName}>{item.locationName}</Select.Option>
          })}
        </Select>
        </div>
        <div>
        <label className="pr-2 mb-0">Building: </label>
        <Select
          disabled={siteDisabled}
          value={shiftReq.buildingName}
          size='small'
          style={{width:175}}
          placeholder="Select Building"
          onChange={handleBuilding}
          >
          <Select.Option key={'buildingKey'} value={'All'}>All</Select.Option>
          {siteList?.map((item:any,index:any)=>{
          return <Select.Option key={index+"-1"} value={item.buildingName}>{item.buildingName}</Select.Option>
          })}
        </Select>
        </div>
        </ConfigProvider>
      </div>  
     <Box sx={{ backgroundColor: "white" }}>
        <Tabs
          value={value}
          onChange={handleTabs}
          variant="scrollable"
          scrollButtons={false} 
          aria-label="basic tabs example"
        >
          <Tab label="Shift Schedule 1" {...a11yProps(0)} />
          <Tab label="Shift Schedule 2" {...a11yProps(1)} />
          <Tab label="Shift Schedule 3" {...a11yProps(2)} />
        </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Ushift1 
             shiftReq={shiftReq}
             onChangeShift1={onChangeShift1}
             shift1Checkbox={shift1Checkbox}
             shift1Break={shift1Break}
             disable1={disable1}
             />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <Ushift2 
            shiftReq={shiftReq}
            onChangeShift2={onChangeShift2}
            shift2Checkbox={shift2Checkbox}
            shift2Break={shift2Break}
            disable2={disable2}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
        <Ushift3 
           shiftReq={shiftReq}
           onChangeShift3={onChangeShift3}
           shift3Checkbox={shift3Checkbox}
           shift3Break={shift3Break}
           disable3={disable3}
           />
        </CustomTabPanel>
        </Modal>   
        </ConfigProvider> 
    )
}

export default UpdateShiftShedules;