import service from '../service/api/common'
import constantUrl from '../settings/constants'
import { Utility } from "../helper/index";



    function batterySummary(payload) {
        let userInfo = Utility.getUserInfo();
        var token = userInfo.authToken;
            return service({
            url: constantUrl.api.staeOfCharge.batterySummary,
            method: 'POST',
            headers: {"Content-type": "application/json",Authorization: "Bearer " + token},
            data:payload
        });
    }


    const getBatterySummary = {
        batterySummary
    }

    export default getBatterySummary;
