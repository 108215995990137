import React, { FC, useState, useEffect } from 'react';
import SessionWarning from '../reusableComponent/sessionWarning';
import LoadingSpinner from '../spinner/loading-spinner';
import { ConfigProvider, Modal, Button } from 'antd';
import { format, subDays } from 'date-fns';
import { Utility } from '../../helper';
import service from '../../service/api/common';
import constantUrl from '../../settings/constants';
import { resize , hideSidebar} from '../../settings/resize';
import { Pagination } from '@mui/material';

const userInfo: any = Utility.getUserInfo();
const userId: number = userInfo.userId;
const token: any = userInfo.authToken

type reqBody = {
    orgId: number,
    divisionId: number,
    locationId: number,
    buildingId: number,
    startDate: string,
    endDate: string,
    batteryId: string,
    vehicleId: string,
    serialNumber: string,
    batteryRxSim: string,
    pageOffset: number,
    pageLimit: number,
    sortColumns: string,
    sortDirection: string,
    timeZone: string
}

let Body = {
    orgId: 0,
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    startDate: "",
    endDate: "",
    batteryId: "",
    vehicleId: "",
    serialNumber: "",
    batteryRxSim: "",
    pageOffset: 1,
    pageLimit: 1,
    sortColumns: "",
    sortDirection: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    // refresh: number
}

const BatteryRXTestPage: FC<props> = ({ globalData}) => {
    // const BatteryRXTestPage: FC<props> = ({ globalData, refresh }) => {
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [resetData, setResetData] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0)
    const [current, setCurrent] = useState<number>(1)
    const [version, setVersion] = useState<string>("");
    const [bmidReqBody, setBmidReqBody] = useState<reqBody>(Body)
    const [bmidReport, setBmidReport] = useState<any[]>([])
    const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
    const [tableReport, setTableReport] = useState<any[]>([])
    const [countReqBody, setCountReqBody] = useState<reqBody>(Body)
    const [initial, setInitial] = useState<boolean>(true);
    const boundaryCount: any = 'false';
    const [ipaddress, setipaddress] = useState<any>("");
    const [rebootBmidResponse, setRebootBmidResponse] = useState<any>([]);
    const [resetMessage, setResetMessage] = useState<any>("")
    const [resetPopUp, setResetPopUp] = useState<boolean>(false);


    useEffect(() => {
        getBmidInformation();
    }, [bmidReqBody]);

    useEffect(() => {
        getChargerStatus();
    }, [tableReqBody]);

    useEffect(() => {
        getCount();
    }, [countReqBody]);

    // useEffect(() => {
    //     if (refresh === 0) return
    //     setBmidReqBody({ ...bmidReqBody })
    //     setTableReqBody({ ...tableReqBody })
    // }, [refresh]);

    useEffect(() => {
        setInitial(true)
        window.scrollTo(0, 0)
        resize();
        hideSidebar();
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        setCurrent(1);
        let org = data?.orgId > -1 ? data?.orgId : userId;
        getFirmwareVersion();
        getIpAddress();
        setBmidReqBody({ ...bmidReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
        setTableReqBody({ ...tableReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
        setCountReqBody({ ...countReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
        setInitial(false)
    }, [])

    useEffect(() => {
        if (initial == false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;

            setBmidReqBody({ ...bmidReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
            setTableReqBody({ ...tableReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
            setCountReqBody({ ...countReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId, globalData?.serialId])

    const getFirmwareVersion = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.batteryRxTestPage.firmwareVersion,
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token }
            })
            setVersion(response)
        } catch (error) {
            console.log(error)
        }
    }

    async function getBmidInformation() {
        if (bmidReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = bmidReqBody;

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.batteryRxTestPage.bmidInformation,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setBmidReport(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("bmidInformation:", error);
            setIsShowSpinner(false);
        }
    }

    async function getChargerStatus() {
        if (tableReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = tableReqBody;

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.batteryRxTestPage.chargerStatus,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTableReport(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("chargerStatus:", error);
            setIsShowSpinner(false);
        }
    }

    async function getCount() {
        if (countReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = countReqBody;

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.batteryRxTestPage.chargerStatusCount,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setCount(response);
        } catch (error) {
            console.error("chargerStatusCount:", error);
        }
    }

    const handleChange = (event: any, value: any) => {
        setTableReqBody({ ...tableReqBody, pageOffset: value });
        setCountReqBody({ ...countReqBody, pageOffset: value })
        setCurrent(value);
    };

    const getIpAddress = async () => {
        try {
            fetch('https://api.ipify.org/')
                .then(obj => obj.text())
                .then(res => {
                    setipaddress(res)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const resetBmid = async () => {
        if (tableReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = tableReqBody;

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.batteryRxTestPage.resetBmid,
                method: "DELETE",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setResetData(false)
            if (response) {
                setResetPopUp(true)
                setResetMessage("Data deleted successfully.")
                setBmidReport([])
                setTableReport([])
                setCount(0)
            } else {
                setResetMessage("Data deletion failed.")
                setResetPopUp(true)
            }
        } catch (error) {
            console.error("resetBmid:", error);
        } finally {
            setIsShowSpinner(false)
        }
    }

    const clearLogs = async () => {
        if (tableReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        let userName = localStorage.getItem('userName')
        data = data && JSON.parse(data);

        let { orgId, divisionId, locationId, buildingId, batteryId, vehicleId, serialNumber, timeZone } = tableReqBody

        let payload = {
            orgId,
            divisionId,
            locationId,
            buildingId,
            batteryId,
            vehicleId,
            serialNumber,
            timeZone,
            userName: userName,
            ipAddress: ipaddress,
        }

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.batteryRxTestPage.clearBmid,
                method: "POST",
                data: payload,
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
            });

            if (response.substring(0, 5) === 'Start') {
                setRebootBmidResponse([...rebootBmidResponse, { color: 'green', value: response }]);
            } else if (response.substring(0, 4) === 'Stop') {
                setRebootBmidResponse([...rebootBmidResponse, { color: 'red', value: response }]);
            } else {
                setRebootBmidResponse([...rebootBmidResponse, { color: 'blue', value: response }]);
            }

        } catch (error) {
            console.error("clearBmid:", error);
        } finally {
            setIsShowSpinner(false)
        }
    }

    const rebootBmid = async () => {
        if (tableReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        let userName = localStorage.getItem('userName')
        data = data && JSON.parse(data);

        let { orgId, divisionId, locationId, buildingId, batteryId, vehicleId, serialNumber, timeZone } = tableReqBody

        let payload = {
            orgId,
            divisionId,
            locationId,
            buildingId,
            batteryId,
            vehicleId,
            serialNumber,
            timeZone,
            userName: userName,
            ipAddress: ipaddress,
        }

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.batteryRxTestPage.rebootBmid,
                method: "POST",
                data: payload,
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
            });

            if (response.substring(0, 5) === 'Start') {
                setRebootBmidResponse([...rebootBmidResponse, { color: 'green', value: response }]);
            } else if (response.substring(0, 4) === 'Stop') {
                setRebootBmidResponse([...rebootBmidResponse, { color: 'red', value: response }]);
            } else {
                setRebootBmidResponse([...rebootBmidResponse, { color: 'blue', value: response }]);
            }

        } catch (error) {
            console.error("rebootBmid:", error);
        } finally {
            setIsShowSpinner(false)
        }
    }

    return <>
        <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
        <div className="container-scroller">
            <div className="container-fluid page-battery-body-wrapper">
                <div className='col-md-12 col-lg-12 col-xl-12 mt-4 colBackGroundColor bgWhite'>
                    <div className="container-fluid">
                        <div className='ml-auto mr-auto firmradio pt-3'>{version}</div>
                        {/* bmid information */}
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="bmid-text">
                                    <table className='bmid-table'>
                                        <tbody>
                                            <tr>
                                                <th colSpan={2}><div className='bmidTableTitle'>BMID Information</div></th>
                                            </tr>
                                            <tr>
                                                <th>Last Updated :</th>
                                                <td>{(bmidReport[0]?.formatDateUpdated || bmidReport[0]?.formatDateUpdated == 0) ? (bmidReport[0]?.formatDateUpdated) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Protocol Version :</th>
                                                <td>{(bmidReport[0]?.protocolVer || bmidReport[0]?.protocolVer == 0) ? (bmidReport[0]?.protocolVer) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Boot Kernel Version :</th>
                                                <td>{(bmidReport[0]?.bootKerVer || bmidReport[0]?.bootKerVer == 0) ? (bmidReport[0]?.bootKerVer) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Application Version :</th>
                                                <td>{(bmidReport[0]?.appVersion || bmidReport[0]?.appVersion == 0) ? (bmidReport[0]?.appVersion) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Part Number :</th>
                                                <td>{(bmidReport[0]?.batteryRxSim || bmidReport[0]?.batteryRxSim == 0) ? (bmidReport[0]?.batteryRxSim) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Serial Number :</th>
                                                <td>{(bmidReport[0]?.batteryRxSerialNumber || bmidReport[0]?.batteryRxSerialNumber == 0) ? (bmidReport[0]?.batteryRxSerialNumber) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Manufacture Date :</th>
                                                <td>{(bmidReport[0]?.formatManufDate || bmidReport[0]?.formatManufDate == 0) ? (bmidReport[0]?.formatManufDate) : "(not available)"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='col-md-6 reset-btn-div'>
                                <button onClick={() => { setResetData(true) }} type="button" className="bmid-btn bmid-btn-size reset-bmid-btn" style={{color:"#fa1d17"}}>Reset BMID Data in the Back Office</button>
                            </div>
                        </div>
                        {/* charger status */}
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="bmid-text">
                                    <table className='bmid-table'>
                                        <tbody>
                                            <tr>
                                                <th colSpan={2}><div className='bmidTableTitle'>Charger Status</div></th>
                                            </tr>
                                            <tr>
                                                <th>Last Updated :</th>
                                                <td>{(tableReport[0]?.formatPollingDate || tableReport[0]?.formatPollingDate == 0) ? (tableReport[0]?.formatPollingDate) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Charger ID :</th>
                                                <td>{(tableReport[0]?.chargerId || tableReport[0]?.chargerId == 0) ? (tableReport[0]?.chargerId) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Charger Port :</th>
                                                <td>{(tableReport[0]?.chargerPort || tableReport[0]?.chargerPort == 0) ? (tableReport[0]?.chargerPort) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Start Time :</th>
                                                <td>{(tableReport[0]?.formatStartDate || tableReport[0]?.formatStartDate == 0) ? (tableReport[0]?.formatStartDate) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>End Time :</th>
                                                <td>{(tableReport[0]?.formatEndDate || tableReport[0]?.formatEndDate == 0) ? (tableReport[0]?.formatEndDate) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Charge Duration :</th>
                                                <td>{(tableReport[0]?.durationMinutes || tableReport[0]?.durationMinutes == 0) ? (tableReport[0]?.durationMinutes) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Charge Ah :</th>
                                                <td>{(tableReport[0]?.chargeAh || tableReport[0]?.chargeAh == 0) ? (tableReport[0]?.chargeAh) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Charge KWh :</th>
                                                <td>{(tableReport[0]?.chargeKwh || tableReport[0]?.chargeKwh == 0) ? (tableReport[0]?.chargeKwh) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Start Temperature :</th>
                                                <td>{(tableReport[0]?.startTemp || tableReport[0]?.startTemp == 0) ? (tableReport[0]?.startTemp) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>End Temperature :</th>
                                                <td>{(tableReport[0]?.endTemp || tableReport[0]?.endTemp == 0) ? (tableReport[0]?.endTemp) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Start SOC :</th>
                                                <td>{(tableReport[0]?.startSoc || tableReport[0]?.startSoc == 0) ? (tableReport[0]?.startSoc) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>End SOC :</th>
                                                <td>{(tableReport[0]?.endSoc || tableReport[0]?.endSoc == 0) ? (tableReport[0]?.endSoc) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Start Volts :</th>
                                                <td>{(tableReport[0]?.startVolts || tableReport[0]?.startVolts == 0) ? (tableReport[0]?.startVolts) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>End Volts :</th>
                                                <td>{(tableReport[0]?.endVolts || tableReport[0]?.endVolts == 0) ? (tableReport[0]?.endVolts) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Start Current :</th>
                                                <td>{(tableReport[0]?.startCurrent || tableReport[0]?.startCurrent == 0) ? (tableReport[0]?.startCurrent) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>End Current :</th>
                                                <td>{(tableReport[0]?.endCurrent || tableReport[0]?.endCurrent == 0) ? (tableReport[0]?.endCurrent) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Charge Type :</th>
                                                <td>{(tableReport[0]?.chargeType || tableReport[0]?.chargeType == 0) ? (tableReport[0]?.chargeType) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Start Code :</th>
                                                <td>{(tableReport[0]?.startCode || tableReport[0]?.startCode == 0) ? (tableReport[0]?.startCode) : "(not available)"}</td>
                                            </tr>
                                            <tr>
                                                <th>Termination Code :</th>
                                                <td>{(tableReport[0]?.terminationCode || tableReport[0]?.terminationCode == 0) ? (tableReport[0]?.terminationCode) : "(not available)"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                            <div className='col-12 pr-0'>
                                <div className="bmid-text">
                                    <table className='bmid-table'>
                                        <tbody>
                                            <tr className="pagination-tr">
                                                <th>
                                                    <div className='bmidTableTitle'>{count > 0 ? `Record ${current} of ${count}` : "No Records Found"}</div>
                                                </th>
                                                <td>
                                                    <Pagination
                                                        count={count}
                                                        showFirstButton
                                                        showLastButton
                                                        page={current}
                                                        onChange={handleChange}
                                                        boundaryCount={boundaryCount}
                                                        sx={{
                                                            '& .MuiPaginationItem-root': {
                                                                margin: 0
                                                            },
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                            </div>
                            <div className='col-md-6 logs-rebopt-btn'>
                                {/* <div className='py-3'>
                                    <div className="pr-2 pt-5" style={{ position: "relative", top: "15px" }}> */}
                                        <button onClick={clearLogs} type="button" className="bmid-btn bmid-btn-size clear-bmid-btn" style={{color:"#09851b"}}>Clear BMID Logs</button>
                                    {/* </div>
                                    <div className="pr-2 pt-3" style={{ position: "relative", top: "15px" }}> */}
                                        <button onClick={rebootBmid} type="button" className="bmid-btn bmid-btn-size reboot-bmid-btn" style={{color:"#2e3ad1"}}>Reboot BMID</button>
                                    {/* </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                        {/* pagination */}
                        {/* <div className='row'>
                            <div className='col-md-6 pr-0'>
                                <div className="bmid-text">
                                    <table className='bmid-table'>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <div className='bmidTableTitle'>{count > 0 ? `Record ${current} of ${count}` : "No Records Found"}</div>
                                                </th>
                                                <td>
                                                    <Pagination
                                                        count={count}
                                                        showFirstButton
                                                        showLastButton
                                                        page={current}
                                                        onChange={handleChange}
                                                        boundaryCount={boundaryCount}
                                                        sx={{
                                                            '& .MuiPaginationItem-root': {
                                                                margin: 0
                                                            },
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                        {/* bmid command history */}
                        <div className='py-3'>
                            <div className='firmheader'>BMID Command History</div>
                            <div className='bmidcommandhistory'>
                                <div>{rebootBmidResponse.map((item: any, index: any) => {
                                    return <div key={index} style={{ color: item['color'] }}>{item['value']}</div>
                                })}</div>
                            </div>
                        </div>
                        {/* reset BMID Pop up */}
                        <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                            <Modal
                                centered
                                footer={[
                                    <Button key="back" onClick={() => { setResetData(false) }}>Cancel</Button>,
                                    <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={resetBmid}>Ok</Button>
                                ]}
                                onCancel={() => { setResetData(false) }}
                                open={resetData}
                            >
                                <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                    <div>Are you sure you want to delete all data in the Back Office for this BMID?</div>
                                </div>
                            </Modal>
                            <Modal
                                centered
                                footer={[
                                    <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={() => setResetPopUp(false)}>Ok</Button>
                                ]}
                                open={resetPopUp}
                            >
                                <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                    <div>{resetMessage}</div>
                                </div>
                            </Modal>
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        </div>
        <SessionWarning />
    </>
}

export default BatteryRXTestPage;