/**
 * @author Raghav Prabhu
 * @description Generic API client module
 */

import axios from 'axios'
import {Utility } from "../../../helper/index"


/**
 * Generic Axios client with default base URL
 */

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL
});


    let userInfo= Utility.getUserInfo()
    var token = userInfo.authToken


var header = { 'Content-Type': 'application/json', "Authorization":"Bearer " + token} 

// instance.defaults.headers.post['Content-Type'] = "application/json";
// instance.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem(ACCESS_TOKEN);
/**
 * API Wrapper with default success/error actions
 */
const service = function(options) {
   if(options.headers === undefined)
    options.headers = header;

  //  console.log(JSON.stringify(options))

    
  const onErrorCallBack = options.onError
  if (onErrorCallBack) {
    delete options.onError
  }
  
  const onSuccess = function(response) {
    // console.debug('Request Successful!', response);
    return response.data;
  }
  
  const onError = function(error) {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // console.error('Status:',  error.response.status);
      if(error.response.status === 401) { //Token expires or unauthorized access
        // localStorage.clear();
        // sessionStorage.clear();
        // window.location.reload();
        // window.location.href = "/login"
      }
      if(error.response.status === 403) { //Unauthorized access
        // window.location.reload();
        // window.location.href ="/login"
      }
      console.error('Data:',    error.response.data);
      console.error('Headers:', error.response.headers);

    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  }
  //returns the default request
  return instance(options)
  .then(onSuccess)
  .catch(onErrorCallBack? onErrorCallBack : onError);
}

export default service;