import React, { useEffect, useState,useLayoutEffect } from 'react';
import LoadingSpinner from '../spinner/loading-spinner'
import { ConfigProvider } from 'antd'
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import AlertSettings from './alertSettings';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { Input, Switch, Button, Modal } from 'antd';
import SessionWarning from '../reusableComponent/sessionWarning';
import { resize, hideSidebar } from '../../settings/resize';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface stateType {
    userOrganization: string
    userDivison: string
    userLocation: string
    userBuilding: string
    userName: string
    userAccountType: string
    firstName: string,
    lastName: string,
    userEmail: string
    operationsStatusReportPrimaryEmailActive: boolean
    alternativeEmail: string
    operationsStatusReportAlternativeEmailActive: boolean
}

const state = {
    userOrganization: "",
    userDivison: "",
    userLocation: "",
    userBuilding: "",
    userName: "",
    userAccountType: "",
    firstName: "",
    lastName: "",
    userEmail: "",
    operationsStatusReportPrimaryEmailActive: false,
    alternativeEmail: "",
    operationsStatusReportAlternativeEmailActive: false
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className='mt-3 bg-white'
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const a11yProps = (index: number) => {
    return {
        id: `simple-minitab-${index}`,
        'aria-controls': `simple-minitabpanel-${index}`,
    };
}
const AccountManagement = () => {
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [value, setValue] = React.useState(0);
    const [states, setStates] = useState<stateType>(state)
    const [validEmail, setValidEmail] = useState<string>("")
    const [validAlternateEmail, setValidAlternateEmail] = useState<string>("")
    const [firstNameValidation, setFirstNameValidation] = useState<string>("")
    const [lastNameValidation, setLastNameValidation] = useState<string>("")
    const [open, setOpen] = useState<boolean>(false)


    useLayoutEffect(()=>{
        let timer:any
        const debouncedHandleResize = ()=>{
         clearTimeout(timer)
         timer=setTimeout(()=>{
          resize()
         },500)
        }
        window.addEventListener("resize",debouncedHandleResize)
        return()=>{
         window.removeEventListener("resize",debouncedHandleResize)       }
     });

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('Account_Profile_W')) window.history.back()
        }

        resize();
        hideSidebar();
        getAccountProfile()
    }, [])


    const getAccountProfile = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.accountProfile.getGeneral,
                method: "GET",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setStates({ ...response, alternativeEmail: response?.userAlternativeEmail })
            setIsShowSpinner(false)
        } catch (error) {
            console.log(error)
            setIsShowSpinner(false)

        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const applyChanges = async () => {
        if (validateEmail(states.userEmail) === false) return
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        const { firstName, lastName, userEmail, operationsStatusReportPrimaryEmailActive, alternativeEmail, operationsStatusReportAlternativeEmailActive } = states
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.accountProfile.putGeneral,
                method: "PUT",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: { firstName, lastName, userEmail, operationsStatusReportPrimaryEmailActive, alternativeEmail, operationsStatusReportAlternativeEmailActive }
            });
            const data = await response
            if (data.status === "FAILURE" && data.errorCode === 3122 && data.errorMessage === "Invalid firstName") {
                setFirstNameValidation(data.errorMessage)
            } else if (data.status === "FAILURE" && data.errorCode === 3124 && data.errorMessage === "Invalid lastName") {
                setLastNameValidation(data.errorMessage)
            } else if (data.status === "SUCCESS") {
                setOpen(true)
                setFirstNameValidation("")
                setLastNameValidation("")
            }

        } catch (error: any) {
            const { status, data } = error
            const { firstName, lastName, userEmail, alternativeEmail } = data
            if (status === 400) {
                setFirstNameValidation(firstName)
                setLastNameValidation(lastName)
                setValidEmail(userEmail)
                setValidAlternateEmail(alternativeEmail)
            }

            console.log("error")

        } finally {
            setIsShowSpinner(false)

        }
    };

    const emailOnchange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (validateEmail(e.target.value)) {
            setStates({ ...states, userEmail: e.target.value })
            setValidEmail("")
        }
        else {
            setStates({ ...states, userEmail: e.target.value })
            setValidEmail("Please Enter Valid Email ID")
        }
    };

    const alternateEmailOnchange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setStates({ ...states, alternativeEmail: e.target.value })
        if (validateEmail(e.target.value)) {
            setValidAlternateEmail("")
        }
        else if(validateEmail(e.target.value)===false && e.target.value){
            setValidAlternateEmail("Please Enter Valid Alternate Email ID")
        }else if(!e.target.value){
            setValidAlternateEmail("")
        }
    };

    const validateEmail = (email: any) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email)
    };

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div>
                <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                    <div className="ChartPanel">
                        <Box sx={{ backgroundColor: 'white' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Account Profile"  {...a11yProps(0)} />
                                <Tab label="Account Settings" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-10 col-lg-6 padd'>
                                        <div className='grid-body1'>
                                            <div className='font-color'>Organization</div>
                                            <div className='font2'>{states.userOrganization}</div>
                                            <div className='font-color'>Division</div>
                                            <div className='font2'>{states?.userDivison}</div>
                                            <div className='font-color'>Location</div>
                                            <div className='font2'>{states?.userLocation}</div>
                                            <div className='font-color'>Site</div>
                                            <div className='font2'>{states?.userBuilding}</div>
                                            <div className='font-color'>User Name</div>
                                            <div className='font2'>{states.userName}</div>
                                            <div className='font-color'>Account Type</div>
                                            <div className='font2'>{states.userAccountType}</div>
                                            <div className='font-color'>First Name</div>
                                            <div>
                                                <Input onChange={(event) => {
                                                    setStates({ ...states, firstName: event.target.value })
                                                    setFirstNameValidation("")
                                                }}
                                                    size="small" placeholder="Enter First Name" value={states.firstName} />
                                                <span className='errorColor'>{firstNameValidation}</span>
                                            </div>
                                            <div className='font-color'>Last Name</div>
                                            <div>
                                                <Input onChange={(event) => {
                                                    setStates({ ...states, lastName: event.target.value })
                                                    setLastNameValidation("")
                                                }}
                                                    size="small" placeholder="Enter Last Name" value={states.lastName} />
                                                <span className='errorColor'>{lastNameValidation}</span>
                                            </div>
                                            <div className='font-color'>Primary Email Address</div>
                                            <div>
                                                <Input size="small" placeholder="Enter Email"
                                                    value={states.userEmail}
                                                    onChange={emailOnchange}
                                                />
                                                {validEmail ? <span className='errorColor'>{validEmail}</span> : ""}
                                            </div>

                                        </div>
                                        <div className='grid-body2'>
                                            <div className='font-color'>Receive Weekly Operations Status Report to Primary Email</div>
                                            <div>
                                                <Switch checked={states.operationsStatusReportPrimaryEmailActive}
                                                    onChange={(checked: boolean) => setStates({ ...states, operationsStatusReportPrimaryEmailActive: checked })}
                                                />
                                            </div>
                                        </div>
                                        <div className='grid-body1'>
                                            <div className='font-color'>Alternate Email Address</div>
                                            <div>
                                                <Input size="small" placeholder="Enter Alternate Email"
                                                    value={states.alternativeEmail}
                                                    onChange={alternateEmailOnchange}
                                                />
                                                {validAlternateEmail ? <span className='errorColor'>{validAlternateEmail}</span> : ""}
                                            </div>
                                        </div>
                                        <div className='grid-body2'>
                                            <div className='font-color'>Receive Weekly Operations Status Report to Alternate Email</div>
                                            <div>
                                                <Switch checked={states.operationsStatusReportAlternativeEmailActive}
                                                    onChange={(checked: boolean) => setStates({ ...states, operationsStatusReportAlternativeEmailActive: checked })}
                                                />
                                            </div>
                                        </div>
                                        <Modal open={open} closable={false} centered title="Profile updated successfully"
                                            footer={[
                                                <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setOpen(false)}>OK</Button>
                                            ]}>
                                        </Modal>
                                        <div className='pb-2'>
                                            <Button
                                                onClick={applyChanges}
                                                style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", }}>
                                                APPLY CHANGES
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <AlertSettings />
                        </CustomTabPanel>
                    </div>
                </ConfigProvider>
            </div>

            <SessionWarning />
        </>
    )
}
export default AccountManagement;