import React from 'react'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const Apply = () => {

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));


    return (
        <>
            <LightTooltip title="Apply" placement="right-start">
                <TaskAltRoundedIcon
                    className='apply-filter'
                />
            </LightTooltip>
        </>
    )
}

export default Apply;