import React, { FC, useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";
import { Modal, Form, Input, Button, Select, Tooltip, ConfigProvider, Checkbox, AutoComplete, Upload } from "antd";
import { PlusCircleOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import service from "../../service/api/common";
import constants from "../../settings/constants";
import { Utility } from "../../helper/index";
import LoadingSpinner from '../spinner/loading-spinner'
import CountryList from "../reusableComponent/country";
import SessionWarning from '../reusableComponent/sessionWarning';
import { useLocation } from "react-router-dom";
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import * as roles from '../../settings/roles';
import { resize, hideSidebar } from '../../settings/resize';
import { SearchOutlined } from '@ant-design/icons';

const req = {
  pageOffset: 1,
  pageLimit: 10,
  adminDealer: false,
  orgName: "",
  dealerOnly: false,
  allOrgs: true,
  orgId: 0,
  unMapPrarentOrg: [],
  sortColumns: "org_Name",
  sortDirection: "ASC",
}

type Props = {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  }
  refresh: number
}

const OrganizationMaintenance: FC<Props> = ({ globalData, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [reqBody, setReqBody] = useState<any>(req)
  const [countReq, setCountReq] = useState<any>(req)
  const [show, setShow] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [toggleDelete, setToggleDelete] = useState(false)
  const [successAddOrg, setSuccessAddOrg] = useState<boolean>(false)
  const [parentOrgToggle, setParentOrgToggle] = useState<boolean>(true)
  const [url, setUrl] = useState<string>("")
  const [Title, setTitle] = useState<string>("")
  const [orgIdToDelete, setOrgIdToDelete] = useState<number | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [initialValue2, setInitialValue] = useState({ org_Name: "", org_Address: "", org_Country: "", contact_Name: "", contact_Email: "", contact_Telephone: "", customer_Code: "", account_Locked: "", account_Locked_Date: "", id: null, order_Id: null, parentOrgYN: '' });
  const [addMap, setAddMap] = useState<boolean>(false)
  const [dealerId, setDealerId] = useState<number | null>(null)
  const [orgId, setOgrId] = useState<number | null>(null)
  const [isOrgUpdated, setIsOrgUpdated] = useState<boolean>(false)
  const [isOrgDeleted, setIsOrgDeleted] = useState<boolean>(false)
  const [isMapping, setIsMapping] = useState<boolean>(false)
  const [orgMapTitle, setOrgMapTitle] = useState<string>("Organization Mapping Updated Successfully")
  const [sort, setSort] = useState<any>({ organizationName: false, dealername: false, orgAddress: false, country: false, contactName: false, email: false, telephone: false, locked: false, dealer: false })
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)
  const [callByOrgName, setCallByOrgName] = useState<boolean>(false)
  const [orgQuery, setOrgQuery] = useState<string>("")
  const [orgEmpty, setOrgEmpty] = useState<boolean | string>(false);
  const [parentOrgList, setParentOrgList] = useState<any[]>([])
  const [parentOrgName, setParentOrgName] = useState<any>("")
  const [initial, setInitial] = useState(true)
  const [image, setImage] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [fileKey, setFileKey] = useState(Date.now());


  const tabelRef = useRef<HTMLDivElement>(null)
  const location = useLocation();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm()
  const [mappingForm] = Form.useForm()

  const blueColor = "#004f9f"
  const grayColor = "#666666"
  const tooltipColor = "#7a8185"

  const showAddUser = () => {
    setShow(!show);
    form.resetFields();
    setImage(null);
    setFileName('');
    setFileError('');
  }

  const showUpdateOrg = useCallback(
    function useCallback1(item: any) {
      setIsModalOpen(true);
      const { org_Name, org_Address, org_Country, contact_Name, contact_Email, contact_Telephone, customer_Code, account_Locked, account_Locked_Date, id, order_Id, parentOrgYN, image } = item;
      setInitialValue({ ...initialValue2, org_Name: org_Name, org_Address: org_Address, org_Country: org_Country, contact_Name: contact_Name, contact_Email: contact_Email, contact_Telephone: contact_Telephone, customer_Code: customer_Code, account_Locked: account_Locked, account_Locked_Date: account_Locked_Date, id: id, order_Id: order_Id, parentOrgYN: parentOrgYN });
      updateForm.setFieldsValue({
        u_orgName: org_Name,
        u_address: org_Address,
        u_countryName: org_Country,
        u_contactName: contact_Name,
        email: contact_Email,
        u_telephone: contact_Telephone
      })
    },
    [initialValue2]
  );

  async function handleUpdate(value: any) {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    const { u_orgName, u_address, u_countryName, u_contactName, email, u_telephone } = value
    const { id } = initialValue2
    let body = {
      orgId: id,
      orgName: u_orgName,
      orgAddress: u_address,
      orgCountry: u_countryName,
      contactName: u_contactName,
      contactEmail: email,
      contactTelephone: u_telephone,
      unMapPrarentOrg: []
    }
    var url
    if (initialValue2.parentOrgYN === "N") url = constants.api.organization.putOrganization

    if (initialValue2.parentOrgYN === "Y") url = constants.api.organization.putClientOrg
    try {
      const response = await service({
        url: url,
        method: "PUT",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: body
      });
      setIsModalOpen(false);
      setIsOrgUpdated(true)
      image && onAddImage(response.orgId)
      setTitle('Organization Updated Successfully')
    } catch (error) {
      console.log("UpdateOrg:", error);
    }
  }

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem('permissions')
    if (permission !== null) {
      if (!permission.includes('organizationMaintenanceR_W')) window.history.back()
    }

    let userInfo: any = Utility.getUserInfo();
    var loginDealerId = userInfo.userId;

    setInitial(true)
    setUrl(constants.api.organization.postOrganization)
    resize();
    hideSidebar();

    if (roles.PLATFORM_ADMIN === localStorage?.UserType) {
      getParentOrgList(0);
    } else {
      getParentOrgList(loginDealerId);
    }

    var data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    setReqBody({ ...reqBody, orgId: data?.orgId, adminDealer: true, dealerOnly: true, pageOffset: 1 })
    getOrganizationData({ ...reqBody, orgId: data?.orgId, adminDealer: true, dealerOnly: true, pageOffset: 1 })
    getCount({ ...reqBody, orgId: data?.orgId, adminDealer: true, dealerOnly: true, pageOffset: 1 })
    setInitial(false)
  }, []);

  useEffect(() => {
    if (initial == false) {
      setOrgQuery("")
      setReqBody({ ...reqBody, orgId: globalData?.orgId > 0 ? globalData?.orgId : userId, adminDealer: true, dealerOnly: true, pageOffset: 1, orgName: "" })
      getOrganizationData({ ...reqBody, orgId: globalData?.orgId > 0 ? globalData?.orgId : userId, adminDealer: true, dealerOnly: true, pageOffset: 1, orgName: "" })
      getCount({ ...reqBody, orgId: globalData?.orgId > 0 ? globalData?.orgId : userId, adminDealer: true, dealerOnly: true, pageOffset: 1, orgName: "" })
    }
  }, [globalData?.orgId])

  useEffect(() => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    if (refresh === 0) return
    setReqBody({ ...reqBody, orgId: data?.orgId, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
    getOrganizationData({ ...reqBody, orgId: data?.orgId, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
    getCount({ ...reqBody, orgId: data?.orgId, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })

  }, [refresh])

  async function getOrganizationData(payload: any) {
    var data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    setIsShowSpinner(true)

    var url = "";
    url = constants.api.dashboard.getOrgs;
    // if (localStorage.getItem('UserType') === "PLATFORM_ADMIN") {
    //   url = constants.api.organization.getAllOrganizations;
    // } else {
    //   url = constants.api.organization.getOrganization;
    // }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setTableData(response);
      setIsShowSpinner(false);
      if (response && response.length === 0 && payload.orgName) {
        setOrgEmpty("Please Enter Valid Organization Name");
      } else {
        setOrgEmpty("");
      }
    } catch (error) {
      console.log("getOrg:", error);
      setIsShowSpinner(false)
    }
  };

  const getCount = async (payload: any) => {
    var data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    var url = constants.api.dashboard.getOrgsCount;
    // if (localStorage.getItem('UserType') === "PLATFORM_ADMIN") {
    //   url = constants.api.organization.count;
    // } else {
    //   url = constants.api.organization.getCount;
    // }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setTotalCount(response)
    } catch (error) {
      console.log(error);
    }
  };

  const validateMessages = {
    required: "Please Enter Email Address",
    types: { email: "Email is invalid" },
  };

  const updateValidateMessages = {
    required: "Please Enter Email Address",
    types: { email: "Email is invalid" }
  };

  async function onAddFinish(value: any) {
    delete value.image

    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", "Content-Type": "application/json", Authorization: "Bearer " + token },
        data: value,
      });
      const data = await response;
      if (!data.order_Id && data.errorCode) {
        if (data.errorMessage) {
          form.setFields([{ name: 'orgName', errors: [data.errorMessage] }])
        }
      } else if (data.order_Id > 0) {
        let userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;
        if (roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) {
          getParentOrgList(0);
        } else {
          getParentOrgList(loginDealerId);
        }

        image && onAddImage(data.order_Id)
        setTitle('Organization Created Successfully')
        setShow(false)
        setIsOrgUpdated(true)
      }

      // if (data.status === "FAILURE") {
      //   form.setFields([{ name: 'orgName', errors: [data.errorMessage] }])
      //   setTitle(data.errorMessage)
      // }

      // setIsOrgUpdated(true)

    } catch (error: any) {
      if (error.status === 400) {
        const { data } = error
        if (data.contactName) {
          form.setFields([{ name: 'contactName', errors: [data.contactName] }])
        }
        if (data.orgName) {
          form.setFields([{ name: 'orgName', errors: [data.orgName] }])
        }
        if (data.contactTelephone) {
          form.setFields([{ name: 'contactTelephone', errors: [data.contactTelephone] }])
        }
        if (data.contactEmail) {
          form.setFields([{ name: 'contactEmail', errors: [data.contactEmail] }])
        }
      }
      console.warn(error);
    }
  }

  async function onAddImage(value: any) {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    const formData = new FormData();

    if (image) {
      formData.append('file', image);
    }

    try {
      const response = await service({
        url: constants.api.organization.image + "/" + value,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: formData,
      });
      const data = await response;
      if (data.status === "SUCCESS" || data) {
        setImage(null)
        setShow(false)
      }

      if (data.status === "FAILURE") {
        form.setFields([{ name: 'orgName', errors: [data.errorMessage] }])
        setTitle(data.errorMessage)
      }

      setIsOrgUpdated(true)

    } catch (error: any) {
      console.warn(error);
    }
  }

  async function deleteOrgList(id: number) {
    setToggleDelete(!toggleDelete)
    setOrgIdToDelete(id)
  }

  async function deleteToggle() {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    try {
      const response = await service({
        url: constants.api.organization.deleteDealerById + orgIdToDelete,
        method: "DELETE",
        headers: { accept: "*/*", "content-type": "application/json", Authorization: "Bearer " + token },
      });
      const data = await response;
      if (data.message === "SUCCESS") {
        let userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;
        if (roles.PLATFORM_ADMIN === localStorage?.UserType) {
          getParentOrgList(0);
        } else {
          getParentOrgList(loginDealerId);
        }
        setTitle("Organization Deactivated Successfully")
        setIsOpen(true)
        setIsOrgDeleted(true)
      }
      if (data.status === false && data.errorCode === 1127) {
        setTitle(data.errorMessage)
        setIsOpen(true)
      }
    } catch (error) {
      console.warn("dealerDelete:", error)
    }
    setToggleDelete(!toggleDelete);
  }
  const CancelDeleteOrg = () => setToggleDelete(!toggleDelete);

  const submitOrgOk = () => {
    setSuccessAddOrg(false);
    window.location.reload()
  }

  const submitorgCancel = () => setSuccessAddOrg(false);

  const toggleParentOrgInput = (event: any) => {
    const { checked } = event.target
    if (checked) {
      setParentOrgToggle(!parentOrgToggle)
      setUrl(constants.api.organization.postOrganization)
    } else {
      setParentOrgToggle(!parentOrgToggle)
      setUrl(constants.api.organization.postOrganization)
    }
  }
  const deleteResponseOk = () => {
    setIsOpen(!isOpen)
    window.location.reload()
  }
  const deleteResponseCancel = () => setIsOpen(!isOpen)

  const getDealerId = (id: number) => {
    setDealerId(id)
    let parentOrg = parentOrgList?.find((item: any) => item.orgId === id)
    setParentOrgName(parentOrg['orgName'])
  }

  const okaddMap = async () => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    try {
      const response = await service({
        url: constants.api.organization.dealerMapping + dealerId + "/" + orgId,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });
      const data = await response;
      if (data.status === "SUCCESS") {
        setIsMapping(true)
      }
      if (data.status === "FAILURE") {
        setOrgMapTitle("This organisation is already assigned to another dealer")
        setIsMapping(true)

      }
      // console.log("data:",data)
    } catch (error) {
      console.log("getOrg:", error);
    }
    setAddMap(false)
  }

  const cancelMap = () => {
    setAddMap(false)
    mappingForm.resetFields()
  }

  const addMapping = (orgName: any) => {
    setParentOrgName(orgName)
    let parentOrg = parentOrgList?.find((item: any) => item.orgName === orgName)
    setOgrId(parentOrg['orgId'])
    setDealerId(parentOrg['orgId'])
    setAddMap(true)
  }

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    setCallByOrgName(!callByOrgName)
    switch (id) {
      case "organizationName":
        if (sort.organizationName) {
          setReqBody({ ...reqBody, sortColumns: "org_Name", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "org_Name", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "org_Name", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "org_Name", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "org_Name", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "org_Name", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dealername":
        if (sort.dealername) {
          setReqBody({ ...reqBody, sortColumns: "dealer_Name", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "dealer_Name", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "dealer_Name", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "dealer_Name", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "dealer_Name", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "dealer_Name", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "orgAddress":
        if (sort.orgAddress) {
          setReqBody({ ...reqBody, sortColumns: "org_Address", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "org_Address", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "org_Address", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "org_Address", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "org_Address", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "org_Address", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "country":
        if (sort.country) {
          setReqBody({ ...reqBody, sortColumns: "org_Country", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "org_Country", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "org_Country", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "org_Country", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "org_Country", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "org_Country", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "contactName":
        if (sort.contactName) {
          setReqBody({ ...reqBody, sortColumns: "contact_Name", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "contact_Name", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "contact_Name", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contact_Name", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "contact_Name", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "contact_Name", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "email":
        if (sort.email) {
          setReqBody({ ...reqBody, sortColumns: "contact_Email", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "contact_Email", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "contact_Email", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contact_Email", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "contact_Email", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "contact_Email", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "telephone":
        if (sort.telephone) {
          setReqBody({ ...reqBody, sortColumns: "contact_Telephone", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "contact_Telephone", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "contact_Telephone", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contact_Telephone", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "contact_Telephone", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "contact_Telephone", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "locked":
        if (sort.locked) {
          setReqBody({ ...reqBody, sortColumns: "account_Locked", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "account_Locked", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "account_Locked", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "account_Locked", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "account_Locked", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "account_Locked", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dealer":
        if (sort.dealer) {
          setReqBody({ ...reqBody, sortColumns: "parentOrgYN", sortDirection: "ASC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "parentOrgYN", sortDirection: "ASC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "parentOrgYN", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "parentOrgYN", sortDirection: "DESC", pageOffset: 1 })
          getOrganizationData({ ...reqBody, sortColumns: "parentOrgYN", sortDirection: "DESC", pageOffset: 1 })
          getCount({ ...reqBody, sortColumns: "parentOrgYN", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
    }
  };

  const handlePagination = (pageNumber: number) => {
    setCallByOrgName(!callByOrgName)
    setReqBody({ ...reqBody, pageOffset: pageNumber });
    getOrganizationData({ ...reqBody, pageOffset: pageNumber })
    getCount({ ...reqBody, pageOffset: pageNumber })
    setCurrent(pageNumber);
  }

  const onSelect = (getPageSize: string) => {
    const numPageSize = Number(getPageSize)
    setPageSize(numPageSize)
    setCurrent(1);
    setReqBody({ ...reqBody, pageLimit: getPageSize, pageOffset: 1 })
    getOrganizationData({ ...reqBody, pageLimit: getPageSize, pageOffset: 1 })
    getCount({ ...reqBody, pageLimit: getPageSize, pageOffset: 1 })
  }

  const handleSearch = (value: string) => {
    setOrgQuery(value)
    setOrgEmpty(false)
    setTimeout(() => {
      let trimmedValue = value ? value?.trim() : "";
      if (trimmedValue === "") {
        setCountReq({ ...countReq, orgName: "", pageOffset: 1 })
        setReqBody({ ...reqBody, orgName: "", pageOffset: 1 })
        getOrganizationData({ ...reqBody, orgName: "", pageOffset: 1 })
        getCount({ ...reqBody, orgName: "", pageOffset: 1 })
        setCurrent(1)
      } else {
        setReqBody({ ...reqBody, orgName: trimmedValue, pageOffset: 1 })
        setCountReq({ ...countReq, orgName: trimmedValue, pageOffset: 1 })
        getOrganizationData({ ...reqBody, orgName: trimmedValue, pageOffset: 1 })
        getCount({ ...reqBody, orgName: trimmedValue, pageOffset: 1 })
        setCurrent(1)
      }
    }, 1000)
  };

  const searchOnSelect = (value: string) => {
    setCallByOrgName(!callByOrgName)
    setReqBody({ ...reqBody, orgName: value, pageOffset: 1 })
    setCountReq({ ...countReq, orgName: value, pageOffset: 1 })
    getOrganizationData({ ...reqBody, orgName: value, pageOffset: 1 })
    getCount({ ...reqBody, orgName: value, pageOffset: 1 })
    setCurrent(1);
  };

  const onClearGetCall = () => { };

  const onCallOrgApi = () => {
    if (!orgQuery) {
      setOrgEmpty(true)
    } else {
      setOrgEmpty(false)
    }
    if (!orgQuery) return
    setCallByOrgName(!callByOrgName)
    setCountReq({ ...countReq, orgName: orgQuery, pageOffset: 1 })
    setReqBody({ ...reqBody, orgName: orgQuery, pageOffset: 1 })
    getOrganizationData({ ...reqBody, orgName: orgQuery, pageOffset: 1 })
    getCount({ ...reqBody, orgName: orgQuery, pageOffset: 1 })
  };

  const hideModal = () => {
    setIsOrgUpdated(false)
    Title === 'Organization Updated Successfully' && getOrganizationData({ ...reqBody });
    window.location.reload();
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getParentOrgList = async (orgid: any) => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    let payload = {
      "orgId": orgid,
      "divisionId": 0,
      "locationId": 0,
      "buildingId": 0
    }

    try {
      const response = await service({
        url: constants.api.dashboard.getOrganizations,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setParentOrgList(response)
      // using this modifoedRes to display dealer list in header filter
      let modifoedRes = []
      for (let orgItem of response) {
        if ((orgItem.orgId && orgItem.orgName) !== null) {
          modifoedRes.push({ label: orgItem.orgName, value: orgItem.orgId })
        }
      }
      sessionStorage.setItem('orgList', JSON.stringify(modifoedRes))
    } catch (error) {
      console.log("getOrg:", error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setFileError('Please Select a Valid Image File');
        setImage(null);
        setFileName('');
      } else {
        setFileError('');
        setImage(file);
        setFileName(file.name);
      }
    }
  };

  const handleCancel = () => {
    setImage(null);
    setFileName('');
    setFileError('');
    setIsModalOpen(false);
    setFileKey(Date.now());
  };

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className="container-fluid px-0 ">
        {localStorage.getItem('permissions')?.includes("PlatformAdminDealerOrganisation_W" && "PlatformAdminClientOrganisation_W") ? <>
          <div className=" row nav-filter align-items-center">
            <div className="col-12  tab_content-battery">
              <span onClick={showAddUser} className="d-flex align-items-center  ">
                {show ? <MinusCircleOutlined /> : <PlusCircleOutlined />}{" "}
                <span className="pl-2">Add New Organization</span>{" "}
              </span>
            </div>
          </div>
        </> : <></>}
        {show ? (
          <>
            <div
              className={show ? "show-user  add-user" : "hide-user add-user"}>
              <ConfigProvider
                theme={{
                  token: { colorPrimary: "#004f9f", },
                }}>
                <Form
                  form={form}
                  onFinish={onAddFinish}
                  layout="vertical"
                  validateMessages={validateMessages}
                >
                  <Form.Item
                    style={{ marginBottom: "5px" }}
                    label="Organization Name"
                    name="orgName"
                    rules={[{ required: true, message: "Please Enter Organization Name" }]}>
                    <Input placeholder="Enter Organization Name" />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: "5px" }}
                    label="Address"
                    name="orgAddress">
                    <Input placeholder="Enter Your Address" />
                  </Form.Item>
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label" htmlFor=""></label>
                      <Form.Item
                        style={{ marginBottom: "5px" }}
                        label="Country"
                        name="orgCountry"
                        rules={[{ required: true, message: 'Please Select Country' }]}
                      >
                        <Select
                          placeholder="Select Country"
                          showSearch
                          dropdownStyle={{ zIndex: 900 }}
                        >
                          {CountryList?.map((item: any, index: any) => {
                            return <Select.Option name={item} key={index} value={item}>{item}</Select.Option>
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-6">
                      <label className="form-label" htmlFor=""></label>
                      <Form.Item
                        style={{ marginBottom: "5px" }}
                        label="Contact Name"
                        name="contactName"
                        rules={[{ required: true, message: "Please Enter Contact Name" }]}>
                        <Input placeholder="Enter Contact Name" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label" htmlFor=""></label>
                      <Form.Item
                        style={{ marginBottom: "5px" }}
                        label="Email Address"
                        rules={[{ type: 'email', required: true, message: 'Please Enter Email Address' }]}
                        name="contactEmail"
                        required
                      >
                        <Input placeholder="Enter Email" />
                      </Form.Item>
                    </div>
                    <div className="col-6">
                      <label className="form-label" htmlFor=""></label>
                      <Form.Item
                        style={{ marginBottom: "5px" }}
                        label="Telephone Number	"
                        name="contactTelephone"
                        rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                      >
                        <Input type="tel" placeholder="Enter Telephone Number" maxLength={10} />
                      </Form.Item>
                    </div>
                  </div>
                  {parentOrgToggle ? <>
                    <Form.Item
                      style={{ marginBottom: "5px" }}
                      label="Dealer"
                      name="parentOrgId"
                    >
                      <Select
                        showSearch
                        dropdownStyle={{ zIndex: 900 }}
                        placeholder="Select Dealer"
                        filterOption={filterOption}
                        options={parentOrgList?.map((item: any) => { return { value: item.orgId, label: item.orgName } })}
                      >
                        {/* {tableData && tableData.map((item: any, index: any) => {
                          return <Select.Option key={index} value={item.id}>{item.org_Name}</Select.Option>
                        })} */}
                      </Select>
                    </Form.Item>
                  </> : <></>}

                  {
                    (roles.PLATFORM_ADMIN === localStorage?.UserType) &&
                    <div className="row">
                      <div className="col-6">
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          // name="parentOrgYN"
                          valuePropName="checked"
                        >
                          <Checkbox onChange={toggleParentOrgInput}>Is Dealer</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                  }

                  <Form.Item style={{ marginBottom: "25px" }}
                    label="Logo"
                    name='image'
                    rules={[{ message: 'Please Select Logo' }]}>
                    <input type="file" accept="image/*" onChange={handleFileChange} />
                    {fileError && <div style={{ color: 'red' }}>{fileError}</div>}
                  </Form.Item>

                  <div className="d-flex">
                    <div>
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          onClick={showAddUser}
                          style={{
                            background: "#004f9f",
                            borderBlockColor: "#004f9f",
                            color: "white",
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="pl-3">
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white" }}
                          htmlType="submit">
                          Add
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </ConfigProvider>
            </div>
            {/* </Modal> */}
          </>
        ) : (
          <></>
        )}
      </div>
      <ConfigProvider theme={{ token: { colorPrimary: "#004f9f" } }}>
        <Modal
          className="update-users"
          title="Modify Organization Information"
          centered
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            layout="vertical"
            form={updateForm}
            onFinish={handleUpdate}
            validateMessages={updateValidateMessages}
          >
            <Form.Item
              style={{ marginBottom: "5px" }}
              label="Organization Name"
              name='u_orgName'
              rules={[{ required: true, message: "Please Enter Organization Name" }]}
            >
              <Input
                disabled
                placeholder="Enter Organization Name"
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "5px" }}
              label="Address"
              name='u_address'
            >
              <Input
                placeholder="Enter Your Address"
              />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Country"
                  name='u_countryName'
                  rules={[{ required: true, message: 'Please Select Country' }]}
                >
                  <Select
                    showSearch
                    placeholder="Select Country">
                    {CountryList?.map((item: any, index: any) => {
                      return <Select.Option name={item} key={index} value={item}>{item}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Contact Name"
                  name="u_contactName"
                  rules={[{ required: true, message: "Please Enter Contact Name" }]}
                >
                  <Input
                    placeholder="Enter Contact Name"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Email Address"
                  name='email'
                  rules={[{ type: 'email', required: true }]}
                >
                  <Input
                    placeholder="Enter Email"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  label="Telephone Number"
                  name="u_telephone"
                  rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}

                >
                  <Input
                    type="tel"
                    placeholder="Enter Telephone Number"
                    maxLength={10}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item style={{ marginBottom: "5px" }}
                  label="Logo"
                  name="u_image"
                  rules={[{ message: 'Please Select Logo' }]}>
                  <input key={fileKey} type="file" accept="image/*" onChange={handleFileChange} />
                  {fileError && <div style={{ color: 'red' }}>{fileError}</div>}
                </Form.Item>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex justify-content-end">
                  <div>
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button
                        onClick={handleCancel}
                        style={{ color: "#004f9f", }}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                  <div className="pl-3">
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button
                        style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white" }}
                        htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

          </Form>
        </Modal>
      </ConfigProvider>

      <ConfigProvider
        theme={{
          token: { colorPrimary: '#004f9f' }
        }}
      >
        <Modal open={successAddOrg} onOk={submitOrgOk} onCancel={submitorgCancel} centered title="Organization Created Successfully"></Modal>
        <Modal open={isOrgUpdated} closable={false} centered title={Title}
          footer={[
            <Button style={{ background: '#004f9f', color: "white" }} onClick={hideModal}>OK</Button>
          ]}
        ></Modal>
        <Modal okButtonProps={{ color: "#004f9f" }} centered title={"Are You Sure Want To Deactivate This Organization?"} open={toggleDelete} onOk={deleteToggle} onCancel={CancelDeleteOrg} ></Modal>
        {/* <Modal open={isOrgDeleted} closable={false} centered title="Organization Deactivated Successfully"
          footer={[
            <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setIsOrgDeleted(false)}>OK</Button>
          ]}
        ></Modal> */}

        <Modal open={isOpen} closable={false} centered title={Title}
          footer={[
            <Button style={{ background: '#004f9f', color: "white" }} onClick={deleteResponseOk}>OK</Button>
          ]}
        ></Modal>

        <Modal title="Dealer Organization Mapping" centered open={addMap} onOk={okaddMap} onCancel={cancelMap} maskClosable={false}>
          <Form layout="vertical" form={mappingForm}>
            <Form.Item
              style={{ marginBottom: "5px" }}
              label="Parent Organizaton"
            >
              <Select
                showSearch
                dropdownStyle={{ zIndex: 900 }}
                value={parentOrgName}
                placeholder="Select Parent Org"
                onChange={(value) => getDealerId(value)}>
                {parentOrgList?.map((item: any, index: any) => {
                  return <Select.Option key={index} value={item.orgId}>{item.orgName}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal open={isMapping} closable={false} centered title={orgMapTitle}
          footer={[
            <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setIsMapping(false)}>OK</Button>
          ]}
        ></Modal>
      </ConfigProvider>
      <div className="sms-report-search auto_complete pt-2">
        <AutoComplete
          size='middle'
          className="sms-search"
          onSelect={searchOnSelect}
          onSearch={handleSearch}
          allowClear
          onClear={onClearGetCall}
          placeholder="Search Organization Name"
          value={orgQuery}
        >
        </AutoComplete>
        <span onClick={onCallOrgApi} className="search_icon"><SearchOutlined style={{ color: 'white' }} /></span>
      </div>
      {
        orgEmpty && (
          <div className="errorColor" style={{ paddingInline: "15px", display: "block" }}>
            {orgEmpty === "Please Enter Valid Organization Name" ? "Please Enter Valid Organization Name" : "Please Enter Organization Name"}
          </div>
        )
      }
      <div id="reporting_chart5" className="container-fluid pr-0 pl-0">
        <div className="row">
          <div className="col-12 ">
            <div className="container-daily-uses mt-2">
              <div className="bgWhite">
                <div className="chartTitle py-2 d-flex align-items-center">
                  <span className='ml-auto mr-auto'>Organizations</span>
                  <div className='show-entries'>
                    <ShowEntries getPageSize={onSelect} />
                  </div>
                </div>
                <div ref={tabelRef} className="tableWrapper-org overflow-auto customScroll">
                  <table className="billable" style={{ width: "100%" }}>
                    <thead className='tableHeader'>
                      <tr className="billable-head">
                        <th>
                          <div onClick={() => sortBy('organizationName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Organization Name</span>
                            {sort.organizationName ? <CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : <CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>Dealer Name</th>
                        <th>
                          <div onClick={() => sortBy('orgAddress')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Organization Address</span>
                            {sort.orgAddress ? <CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : <CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('country')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Country</span>
                            {sort.country ? <CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : <CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('contactName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Contact Name</span>
                            {sort.contactName ? <CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : <CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('email')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Email Address</span>
                            {sort.email ? <CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : <CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('telephone')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Telephone No</span>
                            {sort.telephone ? <CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : <CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('locked')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Account locked</span>
                            {sort.locked ? <CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : <CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        {/* <th>
                          <div onClick={() => sortBy('dealer')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Dealer</span>
                            {sort.dealer ? <CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : <CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th> */}
                        {(localStorage.getItem('permissions')?.includes('DealerAdminClientOrganisation_U') || localStorage.getItem('permissions')?.includes('PlatformAdminClientOrganisation_U')) &&
                          <th className="px-5 py-3">Action</th>
                        }
                      </tr>
                    </thead>

                    <tbody>
                      {
                        tableData?.map((item: any, index: any) => {
                          const { id, org_Name, org_Address, org_Country, contact_Name, contact_Email, contact_Telephone, account_Locked, parentOrgYN, dealer_Name, status } = item;
                          return (
                            <tr key={`${index}+1`}>
                              <td>{org_Name} </td>
                              <td>{dealer_Name}</td>
                              <td>{org_Address}</td>
                              <td>{org_Country} </td>
                              <td>{contact_Name}</td>
                              <td>{contact_Email}</td>
                              <td>{contact_Telephone}</td>
                              <td>{account_Locked}</td>
                              {/* <td>{parentOrgYN}</td> */}
                              <td className="p-0">
                                {localStorage.getItem('permissions')?.includes("DealerAdminClientOrganisation_U" && "PlatformAdminClientOrganisation_U" && "PlatformAdminDealerOrganisation_U") &&
                                  <Tooltip
                                    title={status === false ? "" : "Update"}
                                    color={tooltipColor}
                                  >
                                    <EditOutlined
                                      onClick={() => {
                                        if (status !== false) {
                                          showUpdateOrg(item);
                                        }
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: status === false ? grayColor : blueColor,
                                        paddingRight: "0px",
                                        cursor: status === false ? "auto" : "pointer",
                                        marginRight: 10
                                      }}
                                    />
                                  </Tooltip>}
                                {localStorage.getItem('permissions')?.includes("PlatformAdminDealerOrganisation_D") &&
                                  <Tooltip
                                    title={status === false ? "" : "Deactivate"}
                                    color={tooltipColor}
                                  >
                                    <DeleteOutlined
                                      onClick={() => {
                                        if (status !== false) {
                                          deleteOrgList(id);
                                        }
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: status === false ? grayColor : "red",
                                        paddingRight: "0px",
                                        cursor: status === false ? "auto" : "pointer",
                                        marginRight: 10
                                      }}
                                    />
                                  </Tooltip>}
                                {/* {localStorage.getItem('permissions')?.includes("PlatformAdminDealerOrganisationMap_W") &&
                                  <Tooltip title="Map Org" color="#7a8185">
                                    <KeyboardReturnIcon onClick={() => addMapping(org_Name)} />
                                  </Tooltip>} */}
                              </td>
                            </tr>
                          );
                        })
                      }
                      {
                        tableData?.length == 0 && <tr><td colSpan={10} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className='text-center pb-2 pt-1'>
                  <PaginationComponent
                    handlePagination={handlePagination}
                    totalLength={totalCount}
                    pageSize={pageSize}
                    current={current}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <SessionWarning />
    </>
  );
};
export default OrganizationMaintenance;