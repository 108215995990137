import React,{FC,useState} from 'react';
import TimeFields from './timeFields';
import {Checkbox,Modal,Button} from "antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimeField } from "@mui/x-date-pickers/TimeField";
interface Props{
  shiftReq:any
  onChangeShift3:(value1:any,value2:string)=>void
  shift3Checkbox:(value:boolean)=>void
  shift3Break:(value1:string,value2:string)=>void
  disable3:boolean
}

const startTime = new Date()
startTime.setHours(8)
startTime.setMinutes(0)

const endTime  = new Date()
endTime.setHours(17)
endTime.setMinutes(0);


const Shift3:FC<Props> = ({shiftReq,shift3Checkbox,onChangeShift3,shift3Break,disable3})=>{
  const [disable,setDisable] = useState<boolean>(false);

  
  const onChange = (e:CheckboxChangeEvent)=>{
    setDisable(e.target.checked)
  };

  const onChangecheckBox = (e:CheckboxChangeEvent)=>{
    shift3Checkbox(e.target.checked)
  };

  
  const onchange = (newValue:any,newValue1:string)=>{
    onChangeShift3(newValue,newValue1)
  }


 const cancel = ()=>{}

    return(
      <div>
      <div className="col-lg-8 flex4 mb-2">
        <h6 className='mb-0'>Shift Schedule 3</h6>
        <Checkbox
          checked={disable3}
          className="font-color align-items-center"
          onChange={onChangecheckBox}
        >
          Use this Schedule
        </Checkbox>
      </div>
      <div className="col-lg-8">
        <h6 className="bgColor">Shift Times </h6>
        <div className="flex2">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable3?false:true}
                  defaultValue={startTime}
                  value={disable3?shiftReq.shiftStartTimer3:null}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'shiftStartTimer3')}
                  />
              </DemoContainer>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable3?false:true}
                  defaultValue={endTime}
                  value={disable3?shiftReq.shiftEndTimer3:null}
                  size="small"
                  // onChange={(newValue)=>{setShiftReq({...shiftReq,shiftEndTimer1:newValue})}}
                  onChange={(newValue)=>onchange(newValue,'shiftEndTimer3')}
                  />
              </DemoContainer>
     </LocalizationProvider>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <h6 className="bgColor">Break Times </h6>
        <div>
          <div className="grid2">
            <label htmlFor="">Break 1</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break1StartTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break1StartTimer3')}
                 />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break1EndTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break1EndTimer3')}
                  />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button
             onClick={()=>shift3Break('break1StartTimer3','break1EndTimer3')}
             type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <div>
          <div className="grid2">
            <label htmlFor="">Break 2</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break2StartTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break2StartTimer3')}
                 />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break2EndTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break2EndTimer3')}
                  />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button 
            onClick={()=>shift3Break('break2StartTimer3','break2EndTimer3')}
            type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <div>
          <div className="grid2">
            <label htmlFor="">Break 3</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break3StartTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break3StartTimer3')}
                  />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break3EndTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break3EndTimer3')}
                 />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button 
            onClick={()=>shift3Break('break3StartTimer3','break3EndTimer3')}
            type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <div>
          <div className="grid2">
            <label htmlFor="">Break 4</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break4StartTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break4StartTimer3')}
                 />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break4EndTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break4EndTimer3')}
                  />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button 
             onClick={()=>shift3Break('break4StartTimer3','break4EndTimer3')}
             type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <div>
          <div className="grid2">
            <label htmlFor="">Shift Change</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break5StartTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break5StartTimer3')}
                  />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable3?false:true}
                  value={shiftReq?.break5EndTimer3}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break5EndTimer3')}
                  />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button 
            onClick={()=>shift3Break('break5StartTimer3','break5EndTimer3')}
            type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div> 
    </div>
    )
}

export default Shift3;