export const PLATFORM_ADMIN = "PLATFORM_ADMIN";
export const PLATFORM_MANAGER = "PLATFORM_MANAGER";
export const DEALER_ADMIN = "DEALER_ADMIN";
export const DEALER_MANAGER = "DEALER_MANAGER";
export const COMPANY_ADMIN = "COMPANY_ADMIN";
export const COMPANY_MANAGER = "COMPANY_MANAGER";
export const LOCATION_ADMIN = "LOCATION_ADMIN";
export const LOCATION_MANAGER = "LOCATION_MANAGER";
export const SITE_ADMIN = "SITE_ADMIN";
export const SITE_MANAGER = "SITE_MANAGER";
export const GENERAL_USER = "GENERAL_USER";
export const TENANT_ADMIN = "TENANT_ADMIN";