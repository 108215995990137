// PrivateRoute.js
import React from 'react';
import { Utility } from '../helper/index'
import {Navigate,useNavigate} from 'react-router-dom';
import * as roles from '../settings/roles';


const PrivateRoute = ({ element,accessCode}) => {
  const navigate = useNavigate(); 

  if(localStorage.getItem('permissions')?.includes(accessCode)){
    return  element 
  }else{
    let userInfo = Utility.getUserInfo();
    var token = userInfo.authToken;
    if(token){
      let UserType  = localStorage.getItem('UserType')
      let to
      switch(UserType){
        case roles.PLATFORM_ADMIN:
          to='/admindashboard'
          break
        case roles.PLATFORM_MANAGER:
          to='/admindashboard'
            break  
        case roles.DEALER_ADMIN:
          to='/dealerdashboard'  
          break 
        case roles.DEALER_MANAGER:
          to='/dealerdashboard'    
          break  
        case roles.COMPANY_ADMIN:
          to='/companydashboard' 
          break 
        case roles.COMPANY_MANAGER:
          to='/companydashboard' 
          break 
        case roles.LOCATION_ADMIN:
          to='/locationdashboard'  
          break  
        case roles.LOCATION_MANAGER:
          to='/locationdashboard'     
          break 
        case roles.SITE_ADMIN:
          to='/sitedashboard'
          break 
        case roles.SITE_MANAGER:
          to='/sitedashboard'
        break 
        default:
          to='/login'  
      }
      return <Navigate to={to} />
    }else{
      return <Navigate to="/login" replace state={{ from: window.location.pathname }} />
    }
 };
 }

export default PrivateRoute;
