import React, { useState,useRef } from 'react';
import backgroundImg from '../../images/PosiLink-Warehouse.png';
import log from '../../images/PosiChargeLogo.svg';
import Ampure from '../../images/Ampure234x50.jpg'
import loginIcon from '../../images/Login Icon.svg';
import { TextField,Button } from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import service from "../../service/api/common";
import constantUrl from "../../settings/constants";
import { Modal } from 'antd';



const ForgotPassword = ()=>{
    const [email, setEmail] = useState("")
    const [isOpen,setIsOpen] = useState(false)
    const [titleMsg,setTitleMsg] = useState("")


    const emailValidation = useRef(null)
    const navigate = useNavigate()


   const getEmail = (event) => {
      const { value } = event.target
      setEmail(value)
      value === "" ? emailValidation.current.innerText = "Please Enter Your Email Id"  : emailValidation.current.innerText = ""
   }

   const onFinish = async()=>{
      let payload = {
         emailAddress: email
      }
      if(email===""){
         emailValidation.current.innerText = "Please Enter Your Email Id"
          return
      }else if(email){
         try {
            const response = await service({
               url: constantUrl.api.resetPwd.reset,
               method: 'POST',
               data: payload
            })
            emailValidation.current.innerText = response?.errorMessage
            if(response.status==="SUCCESS"){
              setTitleMsg(response.data)
              setIsOpen(true)
            }
         } catch (error) {
            console.warn(error)
         }

      }    
    };
    
    const onClick = ()=>{
      setIsOpen(false)
      navigate('/login',{ replace: true })
    };

    return(
        <>
         <div className='login-main-div' style={{
            backgroundImage: `url(${backgroundImg})`,
            height: "100vh",
            backgroundRepeat: "no-repeat"
         }}>
            <div className='login-div  p-4'>
               <div className='text-center'>
                  <img src={Ampure} alt="img" />
               </div>
               <h4 className='sign-text pt-4'>Forgot Password</h4>
               <div className='login-input row align-items-center no-gutters mt-4'>
                  <div className='col-1'>
                     <img className='posi_user_icon mt-4' src={loginIcon} alt="" />
                  </div>
                  <div className='col-11 posi_user_padding'>
                     <TextField
                        onChange={getEmail}
                        id="input-with-sx" label="Please Enter Your Email Id" variant="standard"
                        className='textField'
                        InputLabelProps={{ className: "input_label" }}
                        style={{ width: "100%" }} />
                     <div ref={emailValidation} style={{ color: "#ff4d4f" }}></div>
                  </div>
               </div>
               <div className='text-center py-4'>
                  <Button onClick={onFinish} variant="contained" style={{ background: "#004f9f", paddingInline: "2.4rem", paddingBlock: ".8rem" }}>SEND</Button>
               </div>
               <Link to='/login' style={{ color: "#017BFF", fontWeight: "400", fontSize: 18, textAlign: "center", marginBottom: "0px", display: "block" }}>
                Sign In
               </Link>
            </div>
            <Modal open={isOpen} closable={false} centered title={titleMsg}
            footer={[
              <Button style={{ background: '#004f9f', color: "white" }} onClick={onClick}>OK</Button>
            ]}
            ></Modal>
         </div>
        </>
    )
}

export default ForgotPassword;