import React,{FC,useState} from 'react';
import TimeFields from './timeFields';
import {Checkbox,Modal,Button} from "antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimeField } from "@mui/x-date-pickers/TimeField";

interface Props{
  shiftReq:any
  onChangeShift2:(value1:any,value2:string)=>void
  shift2Checkbox:(value:boolean)=>void
  shift2Break:(value1:string,value2:string)=>void
  disable2:boolean
}

const startTime = new Date()
startTime.setHours(8)
startTime.setMinutes(0)

const endTime  = new Date()
endTime.setHours(17)
endTime.setMinutes(0);

const Shift2:FC<Props> = ({shiftReq,shift2Checkbox,onChangeShift2,shift2Break,disable2})=>{
  const [disable,setDisable] = useState<boolean>(false)
  // const [shiftStart,setShiftStart] = useState<any>(startTime)
  // const [shiftEnd,setShiftEnd] = useState<any>(endTime)

  
  const onChange = (e:CheckboxChangeEvent)=>{
    setDisable(e.target.checked)
 }

 
  const onChangecheckBox = (e:CheckboxChangeEvent)=>{
    shift2Checkbox(e.target.checked)
  }

  const cancel = ()=>{}

  const onchange = (newValue:any,newValue1:string)=>{
    onChangeShift2(newValue,newValue1)
  }


    return(
      <div>
      <div className="col-lg-8 flex4 mb-2">
        <h6 className='mb-0'>Shift Schedule 2</h6>
        <Checkbox
          checked={disable2}
          className="font-color align-items-center"
          onChange={onChangecheckBox}
        >
          Use this Schedule
        </Checkbox>
      </div>
      <div className="col-lg-8">
        <h6 className="bgColor">Shift Times </h6>
        <div className="flex2">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable2?false:true}
                  defaultValue={startTime}
                  value={disable2?shiftReq.shiftStartTimer2:null}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'shiftStartTimer2')}
                  />
              </DemoContainer>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable2?false:true}
                  defaultValue={endTime}
                  value={disable2?shiftReq.shiftEndTimer2:null}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'shiftEndTimer2')}
                  />
              </DemoContainer>
     </LocalizationProvider>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <h6 className="bgColor">Break Times </h6>
        <div>
          <div className="grid2">
            <label htmlFor="">Break 1</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break1StartTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break1StartTimer2')}
                 />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break1EndTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break1EndTimer2')}
                  />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button
             onClick={()=>shift2Break('break1StartTimer2','break1EndTimer2')}
             type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <div>
          <div className="grid2">
            <label htmlFor="">Break 2</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break2StartTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break2StartTimer2')}
                 />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break2EndTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break2EndTimer2')}
                  />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button 
            onClick={()=>shift2Break('break2StartTimer2','break2EndTimer2')}
            type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <div>
          <div className="grid2">
            <label htmlFor="">Break 3</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break3StartTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break3StartTimer2')}
                  />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break3EndTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break3EndTimer2')}
                 />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button 
            onClick={()=>shift2Break('break3StartTimer2','break3EndTimer2')}
            type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <div>
          <div className="grid2">
            <label htmlFor="">Break 4</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break4StartTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break4StartTimer2')}
                 />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break4EndTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break4EndTimer2')}
                  />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button 
            onClick={()=>shift2Break('break4StartTimer2','break4EndTimer2')}
             type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 mt-2">
        <div>
          <div className="grid2">
            <label htmlFor="">Shift Change</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="Start Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break5StartTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break5StartTimer2')}
                  />
              </DemoContainer>
     </LocalizationProvider>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["TimeField"]}>
                <TimeField
                  label="End Time"
                  disabled={disable2?false:true}
                  value={shiftReq?.break5EndTimer2}
                  size="small"
                  onChange={(newValue)=>onchange(newValue,'break5EndTimer2')}
                  />
              </DemoContainer>
     </LocalizationProvider>
            <div>
            <button 
            onClick={()=>shift2Break('break5StartTimer2','break5EndTimer2')}
            type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
            </div>
          </div>
        </div>
      </div> 
    </div>
    )
}

export default Shift2;