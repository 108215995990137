import React, { FC, useState } from 'react';
import { Space, Form, Input, Button, ConfigProvider, Modal, Progress } from 'antd';
import logo from '../../images/PosiChargeLogo.svg'
import Ampure from '../../images/Ampure234x50.jpg'
import { Utility } from "../../helper/index"
import constant from '../../settings/constants';
import service from "../../service/api/common";
import { useNavigate } from 'react-router-dom';




const UpdatePassword: FC = () => {
  const [opentModal, setOpentModal] = useState(false)
  const [pwdStrengthWeak, usePwdStrengthWeak] = useState<string>('')
  const [pwdStrengthMedium, usePwdStrengthMedium] = useState<string>('')
  const [pwdStrengthStrong, usePwdStrengthStrong] = useState<string>('')

  const [pwdStrengthStatus, usePwdStrengtStatus] = useState<string>('weak')
  const [pwdValue, setPwdValue] = useState<number>(100)

  let white = 'white'
  let green = 'green'
  let red = 'red'
  let yellow = '#DAA520'
  let weak = 'weak'
  let medium = 'medium'
  let strong = 'strong'

  const navigate = useNavigate();
  const [form] = Form.useForm();

  async function verifyPassword(values: any) {
    let { newPassword, oldPassword } = values;
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken

    try {
      const response = await service({
        url: constant.api.updatePassword.updatepwdLink,
        method: 'POST',
        headers: { "Content-type": "application/json", "Authorization": "Bearer " + token },
        data: { newPassword, oldPassword }
      })
      const data = await response
      if (data.status === "SUCCESS" && data.errorMessage === null && data.errorCode === null) {
        setOpentModal(true)
      }
      if (data.status === "FAILURE" && data.errorCode === 4108) {
        form.setFields([{ name: 'newPassword', errors: [data.errorMessage] }]);
      }
    } catch (error: any) {
      console.warn(error)
      const { status, errorCode, errorMessage } = error.data
      if (status === "FAILURE" && errorCode === 4105) {
        form.setFields([{ name: 'oldPassword', errors: [errorMessage] }]);
      } else if (status === "FAILURE" && (errorCode === 4113 || errorCode === 4108)) {
        form.setFields([{ name: 'newPassword', errors: [errorMessage] }]);
      } else {
        form.setFields([{ name: 'newPassword', errors: [('The password entered does not meet the required criteria')] }]);
      }
    }
  }

  const checkPwd = (event: any) => {
    const passwordValue = event.target.value;

    const weakRegExp = /^(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    const mediumRegExp = /^(?=.*?[0-9]).{8,}$/;
    const strongRegExp = /^(?=.*?[#!@$%^&*+=]).{8,}$/;

    const poorPassword = weakRegExp.test(passwordValue);
    const mediumPassword = mediumRegExp.test(passwordValue);
    const strongPassword = strongRegExp.test(passwordValue);

    if ((poorPassword && !mediumPassword && !strongPassword) || (!poorPassword && mediumPassword && !strongPassword) || (!poorPassword && !mediumPassword && strongPassword) || (!poorPassword && !mediumPassword && !strongPassword)) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthWeak(red)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthMedium(white)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthStrong(white)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengtStatus(weak)
    } else if ((poorPassword && mediumPassword && !strongPassword) || (!poorPassword && mediumPassword && strongPassword) || (poorPassword && !mediumPassword && strongPassword)) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthWeak(yellow)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthMedium(yellow)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthStrong(white)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengtStatus(medium)
    } else if (poorPassword && mediumPassword && strongPassword) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthWeak(green)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthMedium(green)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengthStrong(green)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePwdStrengtStatus(strong)
    }
  }

  const redirectToLogin = () => { navigate("/login", { replace: true }); localStorage.clear(); sessionStorage.clear(); }

  const btnStyle = { background: '#004f9f', color: "white", display: 'block', marginInline: "auto" }
  const btnDisable = { display: 'block', marginInline: "auto" }

  const validatePassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error('Please enter your new password'));
    }
    if (pwdStrengthStatus !== strong) {
      return Promise.reject(new Error('The password entered does not meet the required criteria'));
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className='update-main ' >
        <ConfigProvider
          theme={{ token: { colorPrimary: '#004f9f' } }}>
          <Modal open={opentModal} footer={null} closable={false} centered title="Password Updated Successfully"  >
            <Form onFinish={redirectToLogin}>
              <Form.Item>
                <Button style={{ background: '#004f9f', color: "white", position: "absolute", right: "0px" }} htmlType='submit'>OK</Button>
              </Form.Item>
            </Form>

          </Modal>

        </ConfigProvider>
        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "inherit" }}>
          <Space direction='vertical' className='posi_user_update'>
            <div className="update-body">
              <img src={Ampure} alt="" style={{ display: 'block', marginInline: 'auto' }} />
              <h2 className='my-4' style={{ color: "#7a8185", textAlign: 'center' }}>Update Password</h2>
              <Form onFinish={verifyPassword} form={form}>
                <Form.Item
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter your old password',
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder='Enter Old Password' />
                </Form.Item>

                <Form.Item
                  name="newPassword"
                  rules={[
                    { validator: validatePassword },
                  ]}
                >
                  <Input onChange={checkPwd} type="password" placeholder='New Password' />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  style={{ marginBottom: 0 }}
                  dependencies={['newPassword']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('New & Confirm password do not match'));
                      },
                    }),
                    // { validator: validatePasswordStrength },
                  ]}
                >
                  <Input.Password type="password" placeholder='Confirm New Password' />
                </Form.Item>
                <p className='validate-msg'>Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 special character (!@#$%^&*+=)</p>
                {pwdStrengthWeak || pwdStrengthMedium || pwdStrengthStrong
                  ? <div className='pwd-main'>
                    <div className='pwd-grid'>
                      <div>
                        <Progress strokeColor={pwdStrengthWeak} strokeLinecap="square" percent={pwdValue} size={["default", 2]} showInfo={false} />
                      </div>
                      <Progress strokeColor={pwdStrengthMedium} strokeLinecap="square" percent={pwdValue} size={["default", 2]} showInfo={false} />
                      <Progress strokeColor={pwdStrengthStrong} strokeLinecap="square" percent={pwdValue} size={["default", 2]} showInfo={false} />
                    </div>
                    <span className='pwd-status'>{pwdStrengthStatus}</span>
                  </div> : <></>
                }

                <Form.Item style={{ marginBlock: "10px" }}>
                  <Button disabled={(pwdStrengthStatus === strong) ? false : true} htmlType='submit' style={pwdStrengthStatus === strong ? btnStyle : btnDisable}>Update</Button>
                </Form.Item>
              </Form>
            </div>
          </Space>
        </div>
      </div>
    </>
  )
}
export default UpdatePassword;
