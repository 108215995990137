import React, { FC } from 'react'
import { Link } from 'react-router-dom';

interface Props {
    heading: string,
    summaryData: { companiesCount?: number, dealerCount?: number, locationsCount: number, usersCount: number, siteCount?: number, divicesCount?: number }
    orgID?: any
    SiteIcon?: string
    batteryIcon?: string
    DealerIcon?: string
    CompaniesIcon?: string
    LocationIcon: string
    UserIcon: any
    locationLabel: string
    userLabel: string
    siteLable?: any
    deviceLabel?: any
    dealerLabel?: any
    comapniesLabel?: any

}
const Summary: FC<Props> = ({ heading, summaryData, orgID, DealerIcon, CompaniesIcon, SiteIcon, batteryIcon, LocationIcon, UserIcon, locationLabel, userLabel, comapniesLabel, dealerLabel, siteLable, deviceLabel }) => {
    return (
        <div className="row system-summary-body" id="system-summary-body">
            <div className="col-md-12 mt-2 mb-2 px-2">
                <div id="summaryCardsTitle" className="col-12  chartTitle">{heading}</div>
            </div>
            <div className=" col-md-6 pr-10 pl-2">
                {
                    (DealerIcon) ?
                        <>
                            {
                                (localStorage.getItem('UserType') === "LOCATION_MANAGER" || localStorage.getItem('UserType') === "LOCATION_ADMIN" || localStorage.getItem('UserType') === "COMPANY_ADMIN" || localStorage.getItem('UserType') === "COMPANY_MANAGER") ? <div className="system-summary-card">
                                    <div>
                                        <img src={DealerIcon} className="img_size" alt='img' />
                                    </div>
                                    <div>
                                        <div className="system-count">{summaryData.dealerCount}</div>
                                        <div className="system-name">{dealerLabel}</div>
                                    </div>
                                </div> : <Link state={{ adminDealer: true, allOrgs: false, dealerOnly: true }} to="/organizationmaintenance">
                                    <div className="system-summary-card">
                                        <div>
                                            <img src={DealerIcon} className="img_size" alt='img' />
                                        </div>
                                        <div>
                                            <div className="system-count">{summaryData.dealerCount}</div>
                                            <div className="system-name">{dealerLabel}</div>
                                        </div>
                                    </div>
                                </Link>
                            }
                        </>

                        :
                        <Link state={{ orgID }} to="/locationmaintenance">
                            <div className="system-summary-card">
                                <div>
                                    <img src={LocationIcon} className="img_size" alt='img' />
                                </div>
                                <div>
                                    <div className="system-count">{summaryData.locationsCount}</div>
                                    <div className="system-name">{locationLabel}</div>
                                </div>
                            </div>
                        </Link>
                }

            </div>
            <div className=" col-md-6 pl-10 pr-2">
                {
                    (CompaniesIcon) ?
                        <>
                            {
                                (localStorage.getItem('UserType') === "LOCATION_MANAGER" || localStorage.getItem('UserType') === "LOCATION_ADMIN") ? <div>
                                    <div className="system-summary-card">
                                        <div>
                                            <img src={(CompaniesIcon)} className="img_size" alt='img' />
                                        </div>
                                        <div>
                                            <div className="system-count">{summaryData.companiesCount}</div>
                                            <div className="system-name">{comapniesLabel}</div>
                                        </div>
                                    </div>
                                </div> : <Link state={{ adminDealer: false, allOrgs: false, dealerOnly: false }} to="/organizationmaintenance">
                                    <div>
                                        <div className="system-summary-card">
                                            <div>
                                                <img src={(CompaniesIcon)} className="img_size" alt='img' />
                                            </div>
                                            <div>
                                                <div className="system-count">{summaryData.companiesCount}</div>
                                                <div className="system-name">{comapniesLabel}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            }
                        </>

                        :
                        <Link state={{ orgID }} to="/usermaintenance">
                            <div>
                                <div className="system-summary-card">
                                    <div>
                                        <img src={UserIcon} className="img_size" alt='img' />
                                    </div>
                                    <div>
                                        <div className="system-count">{summaryData.usersCount}</div>
                                        <div className="system-name">{userLabel}</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                }
            </div>

            <div className="col-md-6 pr-10 pl-2 mt-2">
                {
                    (SiteIcon) ?
                        <Link state={{ orgID }} to="/sitemaintenance">
                            <div className="system-summary-card">
                                <div>
                                    <img src={SiteIcon} className="img_size" alt='img' />
                                </div>
                                <div>
                                    <div className="system-count">{summaryData.siteCount}</div>
                                    <div className="system-name">{siteLable}</div>
                                </div>
                            </div>
                        </Link>
                        :
                        <Link state={{ orgID }} to="/locationmaintenance">
                            <div className="system-summary-card">
                                <div>
                                    <img src={LocationIcon} className="img_size" alt='img' />
                                </div>
                                <div>
                                    <div className="system-count">{summaryData.locationsCount}</div>
                                    <div className="system-name">{locationLabel}</div>
                                </div>
                            </div>
                        </Link>
                }

            </div>

            <div className="col-md-6 mt-2 pl-10 pr-2">
                {
                    (batteryIcon) ?
                        <Link to="/bmidInventory" state={{MenuType:'BMID'}}>
                            <div className="system-summary-card">
                                <div>
                                    <img src={batteryIcon} className="img_size" alt='img' />
                                </div>
                                <div>
                                    <div className="system-count">{summaryData.divicesCount}</div>
                                    <div className="system-name">{deviceLabel}</div>
                                </div>
                            </div>
                        </Link>
                        :
                        <Link state={{ orgID }} to="/usermaintenance">
                            <div className="system-summary-card">
                                <div>
                                    <img src={UserIcon} className="img_size" alt='img' />
                                </div>
                                <div>
                                    <div className="system-count">{summaryData.usersCount}</div>
                                    <div className="system-name">{userLabel}</div>
                                </div>
                            </div>
                        </Link>
                }

            </div>
        </div>
    )
}
export default Summary;