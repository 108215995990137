import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import LoadingSpinner from '../spinner/loading-spinner';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { resize, hideSidebar } from '../../settings/resize';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface Props {
  globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
  action: string
  // refresh:number
}

const ConsumptionByAhrsChart: FC<Props> = ({ globalData, action}) => {
  // const ConsumptionByAhrsChart: FC<Props> = ({ globalData, action,refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [reqBody, setReqBody] = useState({ orgId: 0, batteryId: "", vehicleId: "", divisionId: -1, locationId: -1, buildingId: -1 })
  const [chartData, setChartData] = useState<any>({})
  const [batteryIds, setBatteryIds] = useState<any[]>([])
  const [batteryMayReplacement, setBatteryMayReplacement] = useState<any[]>([])
  const [elapsedAhrs, setElapsedAhrs] = useState<any[]>([])
  const [estimateRemainingAhrs, setEstimateRemainingAhrs] = useState<any[]>([])
  const [sort, setSort] = useState<boolean>(false)
  const [sortAZ, setSortAZ] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [chartHeight, setChartHeight] = useState<any>(window.innerHeight * 0.72);
  const [callChartApi, setCallChartApi] = useState<boolean>(false)
  const [initial, setInitial] = useState<boolean>(true);
  
  const sortBtnRef = useRef<HTMLButtonElement>(null)
  const dimensionRef = useRef<HTMLDivElement>(null)
  const mainRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)

  // useEffect(()=>{
  //   if (refresh !== 0)setReqBody({ ...reqBody})
  // },[refresh])

  useEffect(() => {
    if (reqBody.orgId === 0) return
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    setIsShowSpinner(true);
    (async () => {
      try {
        const response = await service({
          url: constantUrl.api.consumptionAhrs.consumptionAhrsReport,
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
          data: reqBody
        })
        setChartData(response)
        getChartData(response)
        setDisabled(false)
        setIsShowSpinner(false)
      } catch (error) {
        setIsShowSpinner(false)
      }
    })();
    // }
    //setTimeout(() => setIsShowSpinner(false), 4000)
    // }
  }, [reqBody])

  const getChartData = (response: any) => {
    let batteryIds: any[] = []
    let batteryMayReplacement: any[] = []
    let elapsedAhrs: any[] = []
    let estimateRemainingAhrs: any[] = []

    response.forEach((item: any, index: any) => {
      if (item?.confBatteryId) {
        batteryIds.push({ label: item?.confBatteryId })
      }
      batteryMayReplacement.push({ value: item.batteryMayReplacement, displayValue: item.batteryMayReplacement })
      elapsedAhrs.push({ value: item.elapsedAhrs, displayValue: item.elapsedAhrs })
      estimateRemainingAhrs.push({ value: item.estimateRemainingAhrs, displayValue: item.estimateRemainingAhrs })
    })
    setBatteryIds(batteryIds)
    setBatteryMayReplacement(batteryMayReplacement)
    setElapsedAhrs(elapsedAhrs)
    setEstimateRemainingAhrs(estimateRemainingAhrs)
    setIsShowSpinner(false)
  }

  const dataSource: any = {
    chart: {
      theme: "fusion",
      numvisibleplot: "6",
      yAxisname: "Ahrs",
      xAxisName: "Batteries (by Battery ID)",
      yAxisNameFontColor: "#4f82b6",
      yAxisNameFontBold: "1",
      xAxisNameFontColor: "#4f82b6",
      xAxisNameFontBold: "1",
      useRoundEdges: "1",
      formatNumberScale: "0",
      thousandSeparatorPosition: "3",
      formatNumber: "1",
      decimals: "0",
      plotSpacePercent: "40",
      //yaxisminvalue: "0",
      //yaxismaxvalue: "2500",          
      numbersuffix: " Ahrs",
      //"numberPrefix": "$",
      paletteColors: "#e86868, #4f82b6, #e8e368",
      //bgColor: "#ffffff",
      borderAlpha: "20",
      showCanvasBorder: "1",
      usePlotGradientColor: "0",
      plotBorderAlpha: "10",
      legendBorderAlpha: "0",
      legendShadow: "0",
      //"adjustDiv": "0",
      numDivLines: "10",
      divlinethickness: "2",
      divlinealpha: "50",
      divlinedashed: "1",
      divlinedashgap: "3",
      divLineIsDashed: "1",
      showAlternateVGridColor: "1",
      showHoverEffect: "1",
      showSum: "1",
      legendposition: "top",
      legendPadding: "30",
      drawCustomLegendIcon: "2",
      legendIconSides: "0",
      // exportEnabled:"1",
      // exportfilename:"Consumption_by_Ahrs",
      plottooltext: "<b>$dataValue</b> $seriesName {br}<b>$percentValue</b> of total visits in the month",
    },
    categories: [{ category: batteryIds }],
    dataset: [
      { seriesname: "Battery May Need Replacement", data: batteryMayReplacement },
      { seriesname: "Elapsed Ahrs", data: elapsedAhrs },
      { seriesname: "Estimated Remaining Ahrs", data: estimateRemainingAhrs }
    ]
  };

  const chartConfigs: any = {
    type: "scrollstackedbar2d",
    renderAt: "chart-container",
    width: "100%",
    valuefontcolor: "#FFFFFF",
    height: chartHeight,
    dataFormat: "json",
    dataSource: dataSource
  };

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(window.innerHeight * 0.72);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartHeight]);

  useEffect(() => {
   // check the page permission
    let permission:any =  localStorage.getItem('permissions')
    if(!permission.includes('consumption_ahrs_W'))window.history.back() 

    setInitial(true);
    resize();
    hideSidebar();
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    setCallChartApi(true)
    let org = data?.orgId > -1 ? data?.orgId : userId;
    setReqBody({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setInitial(false);
  }, [])

  useEffect(() => {
    if(initial == false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setReqBody({ ...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  const sortBy = () => {
    if (sort) {
      let data = chartData?.sort((a: any, b: any) => (a.elapsedAhrs > b.elapsedAhrs) ? -1 : 1)
      setSort(false)
      setChartData(data)
      getChartData(data)
      if (sortBtnRef.current && chartData.length > 1) { sortBtnRef.current.style.backgroundColor = '#ffffff' }
    } else {
      let data = chartData?.sort((a: any, b: any) => (a.elapsedAhrs < b.elapsedAhrs) ? -1 : 1)
      setSort(true)
      setChartData(data)
      getChartData(data)
      if (sortBtnRef.current && chartData.length > 1) { sortBtnRef.current.style.backgroundColor = 'rgb(201 198 208 / 46%)' }
    }
  }

  const sortByAZ = () => {
    if (sortAZ) {
      let data = chartData.sort((a: any, b: any) => (a.confBatteryId > b.confBatteryId) ? -1 : 1)
      setChartData(data)
      getChartData(data)
      setSortAZ(false)
    } else {
      let data = chartData.sort((a: any, b: any) => (a.confBatteryId < b.confBatteryId) ? -1 : 1)
      setChartData(data)
      getChartData(data)
      setSortAZ(true)
    }
  }

  useEffect(() => {
    if (action !== "") {
      onButtonClick(action)
    }
  }, [action])

  const onButtonClick = useCallback((value: string) => {
    if (ref.current === null) {
      return
    }
    setIsShowSpinner(true);
    if (value === 'png') {
      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'Consumption_by_Ahrs.png'
          link.href = dataUrl
          link.click()
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    } else {
      html2canvas(ref.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a')
          link.download = 'my-image-name.png';
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'PNG', 5, 20, 200, 100);
          pdf.save("Consumption_by_Ahrs.pdf");
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    }
  }, [ref])


  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div id="chart1" className=" charts">
        <div className="ChartPanel pr-3">
          <div >
            <div className="row mb-2">
              <div>
                <button disabled={disabled} ref={sortBtnRef} onClick={sortBy} type="button" className="btn btn-light sort-btn mx-3">Sort by Elapsed Ahrs</button>
              </div>
              <button onClick={sortByAZ} type="button" className="btn btn-light sort-btn">Sort by:A-Z</button>
            </div>
          </div>
        </div>
        <div className='position-relative' ref={ref}>
          <div ref={mainRef} className="bgWhite">
            <div className="chartTitle p-2 text-center "><label className='ml-auto mr-auto'>Consumption by Ahrs</label></div>
            <div ref={dimensionRef}>
              <ReactFC {...chartConfigs} />
            </div>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default ConsumptionByAhrsChart;