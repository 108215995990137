import CryptoJS from "crypto-js";

export default class Algorithm {
    static key:any = process.env.REACT_APP_ENCRYPTION_KEY;
    static encryptData = (msg:any) => {
        const data = CryptoJS.AES.encrypt(
            msg,
            Algorithm.key
        ).toString();
    
       return data;
    };

    static decryptData = (msg:any) => {
        
        const bytes = CryptoJS.AES.decrypt(msg,  Algorithm.key);
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return data;
    };

    static getHexDecryptionKey = (encryptedHex: any) => {
        var aesjs = require("aes-js");
        // An example 128-bit key
        var key = [49, 50, 51, 52, 53, 54, 55, 56, 57, 49, 49, 50, 51, 52, 53, 54];
    
        // The initialization vector (must be 16 bytes)
        var iv = [48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48];
    
        var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
    
        // The cipher-block chaining mode of operation maintains internal
        // state, so to decrypt a new instance must be instantiated.
        var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
        var decryptedBytes = aesCbc.decrypt(encryptedBytes);
    
        // Convert our bytes back into text
        var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    
        return decryptedText;
      };


}