import React, { useState, useEffect, useRef } from 'react'
import log from '../../images/PosiChargeLogo.svg'
import Ampure from '../../images/Ampure234x50.jpg'
import backgroundImg from '../../images/PosiLink-Warehouse.png'
import loginIcon from '../../images/Login Icon.svg'
import pwdIcon from '../../images/PW Icon.svg'
import captchaBg from '../../images/captchaBackground.png'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link, useNavigate } from 'react-router-dom'
import { constants, Algorithm } from '../../helper/index'
import service from "../../service/api/common";
import constantUrl from "../../settings/constants";
import * as roles from "../../settings/roles";
import FusionCharts from 'fusioncharts';


const UserLogin = () => {
   const [email, setEmail] = useState("")
   const [password, setPassword] = useState("")
   const [captcha, SetCaptcha] = useState("")
   const [compareCaptcha, setCompareCaptcha] = useState('')

   const emailValidation = useRef(null)
   const pwdValidation = useRef(null)
   const captchaValidation = useRef(null)
   const emailFieldRef = useRef(null)
   const onEnterRef = useRef(null)


   const navigate = useNavigate();

   useEffect(() => {
      generateCaptcha(6)
      emailFieldRef.current.focus()
   }, [])

   function generateCaptcha(length) {
      setCompareCaptcha('')
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return SetCaptcha(result);
   }

   const validateCaptcha = (event) => {
      setCompareCaptcha(event.target.value)
      captchaValidation.current.innerText = ""
   }

   const getEmail = (event) => {
      const { value } = event.target
      setEmail(value)
      emailValidation.current.innerText = value === "" ? "Please Enter your User id" : ""
   }

   const getPassword = (event) => {
      const { value } = event.target
      setPassword(value)
      pwdValidation.current.innerText = value === "" ? "Please Enter your Password" : ""
   }

   const onFinish = async () => {
      if (email === "") {
         emailValidation.current.innerText = "Please Enter your Email Id"
      }

      if (password === "") {
         pwdValidation.current.innerText = "Please Enter your Password"
      }

      if (compareCaptcha === "") {
         captchaValidation.current.innerText = "Please Enter Captcha"
      } else if (compareCaptcha !== captcha) {
         captchaValidation.current.innerText = "Invalid Captcha"
         generateCaptcha(6)
      }

      if (email && password && compareCaptcha === captcha) {
         try {
            const response = await service({
               url: constantUrl.api.login.getLogin,
               method: 'POST',
               data: { email: email, password: password }
            })
            const data = await response
            
            if (data.status === "SUCCESS") {
               getRoleAccess(data.token)
               let getUser = data.accountsDTOS[0]
               sessionStorage.setItem(constants.sessionDetails.userInfo, Algorithm.encryptData(JSON.stringify({ userName: getUser.accountName, userId: getUser.accountID, authToken: data.token })))
               userMe(data.token)
               return;
            } else if (data.status === "FAILURE" && data.errorMessage === "You must change your password." && data.errorCode === 3117) {
               let getUser = data.accountsDTOS[0]
               sessionStorage.setItem(constants.sessionDetails.userInfo, Algorithm.encryptData(JSON.stringify({ userName: getUser.accountName, userId: getUser.accountID, authToken: data.token })))
               localStorage.setItem("tempToken", data.token);
               navigate('/updatepassword', { replace: true });
               return;
            } else if (data.status === "FAILURE" && data.errorMessage === "Invalid email id or password") {
               pwdValidation.current.innerText = data.errorMessage
               generateCaptcha(6)
               return;
            } else if (data.status === "FAILURE" && data.errorCode === 4103 && data.errorMessage === "Invalid Username or Password") {
               pwdValidation.current.innerText = data.errorMessage
               generateCaptcha(6)

            } else if (data.status === "FAILURE" && data.errorCode === 1104) {
               pwdValidation.current.innerText = data.errorMessage
               generateCaptcha(6)
            } else if (data.status === "FAILURE" && data.errorCode === 4111) {
               pwdValidation.current.innerText = data.errorMessage
               generateCaptcha(6)

            }
         } catch (error) {
            console.warn(error)
         }
      }
   }

   const getRoleAccess = async (token) => {
      try {
         const response = await service({
            url: constantUrl.api.userRole.getRoleAccess,
            method: "GET",
            headers: { accept: "*/*", Authorization: "Bearer " + token },
         });
         let permissionData = response.roleAccess;
         localStorage.setItem("permissions", response?.roleAccess)
      } catch (error) {
         console.log(error);
      }
   };

   async function userMe(token) {
      try {
         const response = await service({
            url: constantUrl.api.user.accountId,
            method: "GET",
            headers: { accept: "*/*", Authorization: "Bearer " + token }
         });

         if (response) {
            let divId, divName, locId, locName, siteId, siteName;
            localStorage.setItem("accountId", response?.data?.accountId);
            localStorage.setItem("userId", response?.data?.userId);
            localStorage.setItem("firstName", response?.data?.firstName);
            localStorage.setItem("lastName", response?.data?.lastName);
            localStorage.setItem("accountName", response?.data?.accountName);
            localStorage.setItem("email", response?.data?.email);
            localStorage.setItem("userName", response?.data?.userName);
            //@ts-ignore
            FusionCharts.options['license']({key:Algorithm.getHexDecryptionKey(response?.data?.chartKey),creditLabel: false});
            localStorage.setItem("chartKey", response?.data?.chartKey);
            if (response?.data?.weeklyStartDay !== null) {
               localStorage.setItem("weeklyStartDay", response?.data?.weeklyStartDay);
            } else {
               localStorage.setItem("weeklyStartDay", "MON");
            }
            if (response?.data?.sendOpsReport !== null) {
               localStorage.setItem("sendOpsReport", response?.data?.sendOpsReport);
            } else {
               localStorage.setItem("sendOpsReport", "N");
            }
            if (response?.data?.reportEmail !== null) {
               localStorage.setItem("reportEmail", response?.data?.reportEmail);
            } else {
               localStorage.setItem("reportEmail", "");
            }
            if (response?.data?.role === roles.PLATFORM_ADMIN ||
               response?.data?.role === roles.PLATFORM_MANAGER ||
               response?.data?.role === roles.DEALER_ADMIN ||
               response?.data?.role === roles.DEALER_MANAGER
            ) {
               localStorage.setItem("divisionId", -1);
               localStorage.setItem("divisionName", "All");
               localStorage.setItem("locationId", -1);
               localStorage.setItem("locationName", "All");
               localStorage.setItem("buildingId", -1);
               localStorage.setItem("buildingName", "All");
               divId = -1;
               divName = "All";
               locId = -1;
               locName = "All";
               siteId = -1;
               siteName = "All";
            } else {
               localStorage.setItem("divisionId", response?.data?.divisionId);
               localStorage.setItem("divisionName", response?.data?.divisionName);
               localStorage.setItem("locationId", response?.data?.locationId);
               localStorage.setItem("locationName", response?.data?.locationName);
               localStorage.setItem("buildingId", response?.data?.buildingId);
               localStorage.setItem("buildingName", response?.data?.buildingName);
               divId = response?.data?.divisionId;
               divName = response?.data?.divisionName;
               locId = response?.data?.locationId;
               locName = response?.data?.locationName;
               siteId = response?.data?.buildingId;
               siteName = response?.data?.buildingName;
            }

            localStorage.setItem("UserType", response?.data?.role);

            let orgid = response?.data?.accountId;
            let orgname = response?.data?.accountName;

            if (response?.data?.role === roles.PLATFORM_ADMIN || response?.data?.role === roles.PLATFORM_MANAGER) {
               orgid = -1;
               orgname = "";
            }

            let global = {
               orgId: orgid,
               orgName: orgname,
               divId: divId,
               divName: divName,
               locationId: locId,
               locationName: locName,
               buildingId: siteId,
               buildingName: siteName,
               batteryId: "",
               batteryName: "",
               vehicleId: "",
               vehicleName: "",
               serialId: "",
               serialName: ""
            };

            sessionStorage.setItem("globalData", JSON.stringify(global));

            
            response?.data?.role === roles.PLATFORM_ADMIN && navigate('/admindashboard', { replace: true });
            response?.data?.role === roles.PLATFORM_MANAGER && navigate('/admindashboard', { replace: true });
            response?.data?.role === roles.DEALER_ADMIN && navigate('/dealerdashboard', { replace: true });
            response?.data?.role === roles.DEALER_MANAGER && navigate('/dealerdashboard', { replace: true });
            response?.data?.role === roles.COMPANY_ADMIN && navigate('/companydashboard', { replace: true });
            response?.data?.role === roles.COMPANY_MANAGER && navigate('/companydashboard', { replace: true });
            response?.data?.role === roles.LOCATION_ADMIN && navigate('/locationdashboard', { replace: true });
            response?.data?.role === roles.LOCATION_MANAGER && navigate('/locationdashboard', { replace: true });
            response?.data?.role === roles.SITE_ADMIN && navigate('/sitedashboard', { replace: true });
            response?.data?.role === roles.SITE_MANAGER && navigate('/sitedashboard', { replace: true });
            response?.data?.role === roles.TENANT_ADMIN && navigate('/locationdashboard', { replace: true });
            response?.data?.role === roles.GENERAL_USER && navigate('/locationdashboard', { replace: true });
            
            // window.location.reload();
         }
      }
      catch (error) {
         console.log("User Details:", error)
      }
   }

   // const stopCopy = (e) => {
   //    if (e.ctrlKey && (e.key === 'c' || e.key === 'C')) e.preventDefault();
   // };

   // const disableClick = (event) => event.preventDefault();

   // const disableDragAndDrop = (event) => event.preventDefault();

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         onFinish()
      }
   }

   return (
      <>
         <div className='login-main-div' tabIndex="0"
            ref={onEnterRef}
            style={{
               backgroundImage: `url(${backgroundImg})`,
               height: "100vh",
               backgroundRepeat: "no-repeat"
            }}>
            <div className='login-div p-4'>
               <div className='text-center'>
                  <img src={Ampure} alt="img" />
               </div>
               <h4 className='sign-text pt-3'>Sign In</h4>
               <div className='login-input row align-items-center no-gutters'>
                  <div className='col-1'>
                     <img className='posi_user_icon' src={loginIcon} alt="" />
                  </div>
                  <div className='col-11 posi_user_padding'>
                     <TextField
                        onKeyDown={handleKeyDown}
                        inputRef={emailFieldRef}
                        onChange={getEmail}
                        id="input-with-sx"
                        label="E-mail"
                        variant="standard"
                        className='textField'
                        InputLabelProps={{ className: "input_label" }}
                        style={{ width: "100%" }}
                     />
                     <div ref={emailValidation} style={{ color: "#ff4d4f" }} className="posi_user_validation"></div>
                  </div>
               </div>
               <div className='pwd-input pt-2 row align-items-center no-gutters'>
                  <div className='col-1'>
                     <img className='posi_user_icon' src={pwdIcon} alt="" />
                  </div>
                  <div className='col-11 posi_user_padding'>
                     <TextField
                        onKeyDown={handleKeyDown}
                        onChange={getPassword}
                        id="input-with-s"
                        label="Password"
                        variant="standard"
                        style={{ width: "100%" }}
                        type={'password'}
                     />
                     <div ref={pwdValidation} style={{ color: "#ff4d4f" }} className="posi_user_validation"></div>
                  </div>
               </div>
               <div className='captcha'>Captcha</div>
               <div className='captcha-main pt-3'>
                  <div className='d-flex align-items-center position-relative'>
                     <input className='captcha-value' type="text" value={captcha} readOnly
                        // onKeyDown={stopCopy}
                        // onContextMenu={disableClick}
                        // onDragStart={disableDragAndDrop}
                        style={{
                           backgroundImage: `url(${captchaBg})`,
                           height: "inherit",
                           outline: "none"
                        }}
                     />
                     <RefreshIcon onClick={() => generateCaptcha(6)} className="posi_user_captcha_refresh" />
                  </div>
                  <div className='position-relative'>
                     <input onKeyDown={handleKeyDown} value={compareCaptcha} onChange={validateCaptcha} type="text" className='captcha-input' placeholder='Enter the Captcha' />
                  </div>
               </div>
               <div ref={captchaValidation} style={{ color: "#ff4d4f", textAlign: "center", paddingLeft: "10rem" }}></div>

               <div className='text-center py-4'>
                  <Button onClick={onFinish} variant="contained" style={{ background: "#004f9f", paddingInline: "2.4rem", paddingBlock: ".8rem" }}>SIGN IN</Button>
               </div>
               <Link to='/forgotpassword' style={{ color: "#017BFF", fontWeight: "400", fontSize: 18, textAlign: "center", marginBottom: "0px", display: "block" }}>
                  Forgot password?
               </Link>
            </div>
         </div>
      </>
   )
}

export default UserLogin;