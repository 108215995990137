import React, { FC, useState, useEffect, useDeferredValue } from 'react'
import SelectFilter from '../reusableComponent/selectFilter'
import { Input, AutoComplete } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { SearchOutlined } from '@ant-design/icons';

interface Props {
  searchBy: (partialSearcBy: string, value: string) => void
  callOnClick: (partialSearcBy: string, value: string) => void
  SearchOnOptionSelect: (partialSearcBy: string, value: string) => void
  searchResult: any[]
  setEmailEmpty: (value: any) => void,
  setFirstEmpty: (value: any) => void,
  setLastEmpty: (value: any) => void
  clearSearchValue:number
  onChangeFilter:(val:string)=>void
}

const SelectToSearch: FC<Props> = ({searchBy,onChangeFilter, callOnClick, searchResult, SearchOnOptionSelect, setEmailEmpty, setFirstEmpty, setLastEmpty,clearSearchValue }) => {
  const [placeholder, setPlaceholder] = useState("Search By Email Address")
  const [getaccountId, setGetaccountId] = useState<number>(0)
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);
  const [firsNametoptions, setFirstNameOptions] = useState<SelectProps<object>['options']>([]);
  const [lastNameOptions, setLastNameOptions] = useState<SelectProps<object>['options']>([]);
  const [emailOptions, setEmailOptions] = useState<SelectProps<object>['options']>([]);
  const [firstSearchValue, setFirstSearchValue] = useState<string>("");
  const [lastSearchValue, setLastSearchValue] = useState<string>("");
  const [emailSearchValue, setEmailSearchValue] = useState<string>("");
  const [searchValue,setSearchValue] = useState<string>("")


  const DropList = [{ label: "Search By Email Address" },{ label: "Search By First Name" }, { label: "Search By Last Name" }]

  const changeSearchBy = (value: string) => {
    onChangeFilter(searchValue)
    setSearchValue("")
    setPlaceholder(value)
    setEmailEmpty(false)
    setFirstEmpty(false)
    setLastEmpty(false)
  }

  useEffect(() => {
    setGetaccountId(Number(localStorage.getItem('accountId')))
  }, [])

  useEffect(()=>{
    setSearchValue("")
  },[clearSearchValue])

  const onSearch = (value: string)=>{
     switch(placeholder){
        case "Search By Email Address":
          setSearchValue(value)
          searchBy('email', value)
          break;
        case "Search By First Name":
          setSearchValue(value)
          searchBy('firstName', value)
          break;
        case "Search By Last Name":
          setSearchValue(value)
          searchBy('lastName', value)
     }  
  }



  const callonApi = () => {
    if (placeholder === "Search By First Name") {
      callOnClick('firstName', searchValue)
    } else if (placeholder === "Search By Email Address") {
      callOnClick('email', searchValue)
    } else {
      callOnClick('lastName', searchValue)
    }
  }
  useEffect(() => {
    if (searchResult.length > 0) {
      searchOptions()
    } else {
      setOptions([])
    }
  }, [searchResult]);

  const searchOptions = () => {
    const data1 = searchResult?.map((item: any, index: any) => {
      return (
        {
          value: emailSearchValue ? item?.email : searchValue ? item?.firstName : item?.lastName,
          label: (
            <span key={index}>{emailSearchValue ? item?.email : searchValue ? item?.firstName : item?.lastName}</span>
          )
        }
      )
    })
    setOptions(data1)
  };

  const searchOnSelect = (value: string) => {
    if (firstSearchValue) {
      SearchOnOptionSelect('firstName', value)
    } else if (lastSearchValue) {
      SearchOnOptionSelect('lastName', value)
    } else if (emailSearchValue) {
      SearchOnOptionSelect('email', value)
    }
  };


  return (
    <>
      <SelectFilter
        placeholder={placeholder}
        dropDownList={DropList}
        filterById={changeSearchBy}
        allowClear={false}
      />
      <div className='auto_complete position-relative'>
        <AutoComplete
          style={{ width: 300 }}
          options={options}
          onSelect={searchOnSelect}
          onSearch={onSearch}
          allowClear
          placeholder={placeholder}
          value={searchValue}
        >
          {/* <Input.Search size="middle"  placeholder={placeholder} enterButton className="input-searcOrg" /> */}
        </AutoComplete>
      <span onClick={callonApi} className="user-search_icon position-absolute"><SearchOutlined style={{ color: 'white' }} /></span>
      </div>

    </>
  )
}

export default SelectToSearch;