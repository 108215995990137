import React,{FC} from 'react';
import {Checkbox} from "antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { parseISO } from 'date-fns';



const startTime = new Date()
startTime.setHours(8)
startTime.setMinutes(0)

const endTime  = new Date()
endTime.setHours(17)
endTime.setMinutes(0);


interface Props{
  shiftReq:any
  onChangeShift1:(value1:any,value2:string)=>void
  shift1Checkbox:(value:boolean)=>void
  shift1Break:(value1:string,value2:string)=>void
  disable1:boolean
}


const Shift1:FC<Props> = ({shiftReq,shift1Checkbox,onChangeShift1,shift1Break,disable1})=>{
    const onChangecheckBox = (e:CheckboxChangeEvent)=>{
      shift1Checkbox(e.target.checked)
    };

    const onchange = (newValue:any,newValue1:string)=>{
          onChangeShift1(newValue,newValue1)
    };
    
    return(
        <div>
          <div className="col-lg-8 flex4 mb-2">
            <h6 className='mb-0'>Shift Schedule 1</h6>
            <Checkbox
              checked={disable1}
              className="font-color align-items-center"
              onChange={onChangecheckBox}
            >
              Use this Schedule
            </Checkbox>
          </div>
          <div className="col-lg-8">
            <h6 className="bgColor">Shift Times </h6>
            <div className="flex2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="Start Time"
                      disabled={disable1?false:true}
                      defaultValue={startTime}
                      value={shiftReq?.shiftStartTimer1}
                      size="small"
                      onChange={(newValue)=>onchange(newValue,"shiftStartTimer1")}
                      />
                  </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="End Time"
                      disabled={disable1?false:true}
                      defaultValue={endTime}
                      value={shiftReq?.shiftEndTimer1?shiftReq?.shiftEndTimer1:endTime}
                      size="small"
                      onChange={(newValue)=>onchange(newValue,"shiftEndTimer1")}
                      />
                  </DemoContainer>
         </LocalizationProvider>
            </div>
          </div>
          <div className="col-lg-8 mt-2">
            <h6 className="bgColor">Break Times </h6>
            <div>
              <div className="grid2">
                <label htmlFor="">Break 1</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="Start Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break1StartTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break1StartTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break1StartTimer1')}
                     />
                  </DemoContainer>
         </LocalizationProvider>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="End Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break1EndTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break1EndTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break1EndTimer1')}
                      />
                  </DemoContainer>
         </LocalizationProvider>
                <div>
                <button
                //onClick={()=> setShiftReq({...shiftReq,break1StartTimer1:null,break1EndTimer1:null})}
                 onClick={()=>shift1Break('break1StartTimer1','break1EndTimer1')}
                 type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 mt-2">
            <div>
              <div className="grid2">
                <label htmlFor="">Break 2</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="Start Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break2StartTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break2StartTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break2StartTimer1')}
                     />
                  </DemoContainer>
         </LocalizationProvider>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="End Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break2EndTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break2EndTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break2EndTimer1')}
                      />
                  </DemoContainer>
         </LocalizationProvider>
                <div>
                <button 
                // onClick={()=>setShiftReq({...shiftReq,break2StartTimer1:null,break2EndTimer1:null})}
                onClick={()=>shift1Break('break2StartTimer1','break2EndTimer1')}
                type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 mt-2">
            <div>
              <div className="grid2">
                <label htmlFor="">Break 3</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="Start Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break3StartTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break3StartTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break3StartTimer1')}
                      />
                  </DemoContainer>
         </LocalizationProvider>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="End Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break3EndTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break3EndTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break3EndTimer1')}
                     />
                  </DemoContainer>
         </LocalizationProvider>
                <div>
                <button 
                // onClick={()=>setShiftReq({...shiftReq,break3StartTimer1:null,break3EndTimer1:null})}
                onClick={()=>shift1Break('break3StartTimer1','break3EndTimer1')}
                type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 mt-2">
            <div>
              <div className="grid2">
                <label htmlFor="">Break 4</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="Start Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break4StartTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break4StartTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break4StartTimer1')}
                     />
                  </DemoContainer>
         </LocalizationProvider>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="End Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break4EndTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break4EndTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break4EndTimer1')}
                      />
                  </DemoContainer>
         </LocalizationProvider>
                <div>
                <button 
                //  onClick={()=>setShiftReq({...shiftReq,break4StartTimer1:null,break4EndTimer1:null})}
                onClick={()=>shift1Break('break4StartTimer1','break4EndTimer1')}
                 type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 mt-2">
            <div>
              <div className="grid2">
                <label htmlFor="">Shift Change</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="Start Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break5StartTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break5StartTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break5StartTimer1')}
                      />
                  </DemoContainer>
         </LocalizationProvider>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer components={["TimeField"]}>
                    <TimeField
                      label="End Time"
                      disabled={disable1?false:true}
                      value={shiftReq?.break5EndTimer1}
                      size="small"
                      // onChange={(newValue)=>{setShiftReq({...shiftReq,break5EndTimer1:newValue})}}
                      onChange={(newValue)=>onchange(newValue,'break5EndTimer1')}
                      />
                  </DemoContainer>
         </LocalizationProvider>
                <div>
                <button 
                // onClick={()=>setShiftReq({...shiftReq,break5StartTimer1:null,break5EndTimer1:null})}
                onClick={()=>shift1Break('break5StartTimer1','break5EndTimer1')}
                type="button" className="btn btn-outline-secondary btn-sm">No Break</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Shift1;