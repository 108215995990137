import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import LoadingSpinner from '../spinner/loading-spinner';
import BatteryDetails from '../reusableComponent/batteryDetalis';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, startOfMonth, subDays } from 'date-fns';
import SelectDays from '../reusableComponent/selectDays';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import getBatterySummary from '../../api-call/battery-summary';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {resize, hideSidebar} from '../../settings/resize';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const startOfCurrentMonth: any = format(subDays(new Date(), 7), 'dd-MMM-yyyy')
const today: any = format(new Date(), 'dd-MMM-yyyy');

type reqType = {
  startDate: string,
  endDate: string,
  orgId: number,
  batteryId: string,
  vehicleId: string,
  divisionId: number,
  locationId: number,
  buildingId: number
}
const req = {
  "startDate": startOfCurrentMonth,
  "endDate": today,
  "orgId": 0,
  "batteryId": "",
  "vehicleId": "",
  "divisionId": -1,
  "locationId": -1,
  "buildingId": -1
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string,
  // refresh: number
}


const BatterySocReport: FC<Props> = ({ globalData , action }) => {
  // const BatterySocReport: FC<Props> = ({ globalData , action,refresh }) => {
  let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
  const [reqBody, setReqBody] = useState({ startDate: startOfCurrentMonth, endDate: today, orgId: 0, batteryId: "", vehicleId: "", divisionId: -1, locationId: -1, buildingId: -1 })
  const [batteryIdReq, setBatteryIdReq] = useState({ startDate: startOfCurrentMonth, endDate: today, orgId: 0, vehicleId: "", divisionId: -1, locationId: -1, buildingId: -1 })
  const [battryIds, setBatteryIds] = useState<string[]>([])
  const [filterData, setFilterData] = useState<any[]>([]);
  const [dateLabel, setDateLabel] = useState<{}[]>([])
  const [avgVolt, setAvgVolt] = useState<number | string>();
  const [lowestVoltage, setLowestVoltage] = useState<number | string>();
  const [higestVoltage, sethigestVoltage] = useState<number | string>();
  const [callBatteryId, setCallBatteryId] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [summaryBatteryReq, setSummaryBatteryReq] = useState<reqType>(req)
  const [summaryBattery, setSummaryBattery] = useState<any>([])
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [value, onChange] = useState<any>([subDays(new Date(), 7), new Date()]);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [showSummary, setShowSummary] = useState<boolean>(false)
  const [initial, setInitial] = useState<boolean>(true);


  // useEffect(()=>{
  //   if (refresh === 0) return
  //   let globalData:any = sessionStorage.getItem('globalData')
  //   let parseData = JSON.parse(globalData)
  //   if(parseData.batteryId || parseData.vehicleId)setSummaryBatteryReq({...summaryBatteryReq})
  //   setReqBody({ ...reqBody})
  // },[refresh])

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if(date1 && date2) {
    daysFilter(date1 ?? startOfCurrentMonth, date2 ?? today);
    }
  }, [value])

  useEffect(() => {
    batterySummary()
  }, [summaryBatteryReq])

  const batterySummary = () => {
    if (summaryBatteryReq.orgId === 0 || (!summaryBatteryReq?.batteryId  && !summaryBatteryReq?.vehicleId) ) return
    getBatterySummary.batterySummary(summaryBatteryReq)
      .then((response) => {
        setSummaryBattery(response)
        setShowSummary(true)
      }).catch((error) => {
        console.log('batterySummary:', error)
      })
  }

  useEffect(() => {
    if (reqBody.orgId === 0) return;
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    setIsShowSpinner(true);
    (async () => {
      try {
        const response = await service({
          url: constantUrl.api.staeOfCharge.staeOfCharge,
          method: "POST",
          headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
          data: reqBody
        })
        if (response.length) {
          frameChart(response)
        }
        else {
          setDateLabel([{ label: "" }])
          setFilterData([{ value: "0" }])
          setAvgVolt("")
          setLowestVoltage("")
          sethigestVoltage("")
        }
        setIsShowSpinner(false)
      } catch (error) {
        console.error("dailyUsesError:", error)
        setIsShowSpinner(false)
      }
    })();
  }, [reqBody])

  const frameChart = (data: any[]) => {
    if (!data.length) return
    let labels = data.flatMap((item: any) => { return [{ label: format(new Date(item.startDate), 'dd-MMM-yyyy') }, { label: format(new Date(item.endDate), 'dd-MMM-yyyy') }] })
    let record = data.flatMap((item: any) => { return [{ value: item.startSoc }, { value: item.endSoc }] })
    
    let newRecord = []
    for (let i = 0; i < record.length; i++) {
      let data1 = 0
      data1 = Number(record[i]['value'])
      newRecord.push({ value: data1 }) 
    }

    let newRecord1: any = []
    let prev_vlaue = 0
    for (let i = 0; i < newRecord.length; i++) {
      let currValue = Number(newRecord[i]['value'])
      if (prev_vlaue > data[0]['avgSoc'] && currValue < data[0]['avgSoc']) {
        newRecord1.push({ value: data[0]['avgSoc'], color: '#e20a15' }, { value: currValue })
      } else {
        newRecord1.push({ value: currValue })
      }
      prev_vlaue = Number(newRecord[i]['value'])
    }

    let filterdata: any = newRecord1.map((obj: any, index: any) => {
      if(obj.value >= data[0]['avgSoc'] && newRecord1[index+1]?.value >= data[0]['avgSoc']) {
        return obj.color ? { ...obj } : { ...obj, color: '#004f9f' }
      } else {
        return obj.color ? { obj } : { ...obj, color: '#e20a15' }
      }
    })
    setFilterData(filterdata)
    setDateLabel(labels)
    setAvgVolt(Math.round(data[0]['avgSoc']))

  }

  const chartConfigs_line = {
    type: 'scrollline2d',
    dataFormat: 'json',
    width: '100%',
    height: '98%',
    dataSource: {
      "chart": {
        yaxisname: "SOC %",
        yaxisminvalue: "0",
        yaxismaxvalue: "100",
        //subcaption: "[2005-2016]",
        //numbersuffix: " mph",
        xAxisName: "DATE",
        xAxisNameFontColor: "#4f82b6",
        xAxisNameFontBold: "1",
        yAxisNameFontColor: "#4f82b6",
        yAxisNameFontBold: "1",
        //rotatelabels: "0",
        lineThickness: "3",
        setadaptiveymin: "0",
        showToolTip: '1',
        drawAnchors: '0',
        labelFontSize: "12",
        labelDisplay: "none",
        //legendPadding: "400",
        chartTopMargin: "70",
        // useEllipsesWhenOverflow: "0",
        //flatScrollBars: "1",
        //// scrollheight: "10",
        isTrendZone: "1",
        "labelStep": "1",
        // exportEnabled:"1",
        // exportfilename:"State_of_Charge",
        plotToolText: "<div class='__head'>SOC %</div><div class='__body'>$dataValue</div><div class='__footer'>$label</div>",
        theme: "fusion"
      },
      trendlines: [{
        line: [{
          startvalue: avgVolt ? avgVolt : 0,
          color: "#e20a15",
          valueOnRight: "1",
          // displayvalue: "<b>20% <br>Low Threshold</b> ",
          displayvalue: `<b>${avgVolt ? avgVolt : 0}% <br>Low Threshold</b>`,
          thickness: "3",
          dashed: "1",
          dashLen: "4",
          dashGap: "2",
          labelFontSize: "3"
        }]
      }],
      "categories": [{
        "category": dateLabel.length ? dateLabel : [{ label: "" }]
        //dateLabel
        //[{label: "2022-08-01"}]
      }],
      "dataset": [{
        "data": filterData.length ? filterData : [{ value: "0" }]
        // filterData 
        //[{value: "16"}]
      }]
    }
  }

  useEffect(()=>{
    setInitial(true);
    resize();
    hideSidebar();
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;

    (data?.batteryId === "" && data?.vehicleId==="") ? setShowSummary(false):setShowSummary(true)

    setSummaryBatteryReq({ ...summaryBatteryReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId });
    setReqBody({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId });

    setInitial(false);
  }, [])

  useEffect(()=>{
    if(initial === false) {
      let data: any = sessionStorage.getItem('globalData');
      data = data && JSON.parse(data);

      (data?.batteryId === "" && data?.vehicleId==="") ? setShowSummary(false):setShowSummary(true)
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;

      setSummaryBatteryReq({ ...summaryBatteryReq, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId });
      setReqBody({ ...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId });
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  const daysFilter = (startDate: string, endDate: string) => {
    setReqBody({ ...reqBody, startDate, endDate })
  }

  useEffect(() => {
    if(action !== "") {
      onButtonClick(action)
    }
  }, [action])

  const onButtonClick = useCallback((value: string) => {
    if (ref.current === null) return
    setIsShowSpinner(true);
    if (value === 'png') {
      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'State_Of_Charge.png'
          link.href = dataUrl
          link.click()
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    } else {
      html2canvas(ref.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a')
          link.download = 'my-image-name.png';
          const pdf = new jsPDF();
          // pdf.addImage(imgData, 'PNG', 2, 20, 0, 99);
          pdf.addImage(imgData, 'PNG',5,20,200,100);
          pdf.save("State_Of_Charge.pdf");
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    }
    
  }, [ref])

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div>
        { showSummary && <BatteryDetails batteryData={summaryBattery[0]} /> }

        <div className="container-battery-voltage" ref={ref}>
          <div className="chartTitle p-2 mr-auto ml-auto position-relative">
            BATTERY STATE OF CHARGE
          </div>
          <span className="export">
            {showDateFilter && <span><DateRangePicker onChange={onChange} value={value} maxDate={new Date()} /></span>}
            <SelectDays daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} />
          </span>
          <div className='chart-tooltip'>
            <div className='battey-voltage-chart' id="battey-voltage-chart">
              <ReactFC {...chartConfigs_line} />
            </div>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default BatterySocReport;

