import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import { Utility } from "../../helper/index";
import LoadingSpinner from '../spinner/loading-spinner'
import PosinetHeader from '../../layout/header';
import HeaderFilter from '../reusableComponent/headerFilter';
import BatteryDetails from '../reusableComponent/batteryDetalis';
import {format,startOfMonth,subDays} from 'date-fns';
import SelectDays from '../reusableComponent/selectDays';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import getBatterySummary from '../../api-call/battery-summary';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DownloadIcon from '../../images/Download.png';
import {resize} from '../../settings/resize';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const batteryData={batteryId :"",vehicleId : "",serialNumber : "",mfgdate :"",inServiceDate :"",daysInOperation :0,batteryType :0,batteryVoltage :0,capacity :0}

const startOfCurrentMonth:any=format(subDays(new Date(),7),'dd-MMM-yyyy')
const today:any = format(new Date(),'dd-MMM-yyyy');

type reqType={
  startDate:string,
  endDate:string,
  orgId:number,
  batteryId:string,
  vehicleId:string,
  divisionId:number,
  locationId:number,
  buildingId:number  
}
const req={
  "startDate":startOfCurrentMonth,
  "endDate":today,
  "orgId":0,
  "batteryId": "",
  "vehicleId": "",
  "divisionId":0,
  "locationId": -1,
  "buildingId": 0
}

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    }
        ,
    action: string
  }


const ChargeCurrentOverTime: FC<Props> = ({globalData , action }) => {
        let userInfo: any = Utility.getUserInfo();
        let userId: number = userInfo.userId;
     
      const [reqBody,setReqBody] = useState({
      startDate:startOfCurrentMonth,
      endDate:today,
      orgId:0,
      parentOrgId:0,
      batteryId:"",
      vehicleId:"",
      divisionId: -1,
      locationId: -1,
      buildingId: -1
      })
      const [batteryIdReq,setBatteryIdReq] = useState({startDate:startOfCurrentMonth,endDate:today,orgId:0,parentOrgId:0,vehicleId: "",divisionId: -1,locationId:-1,buildingId: -1})
      const [callBatteryId,setCallBatteryId] = useState<boolean>(false) 
      const ref = useRef<HTMLDivElement>(null)
      const [summaryBatteryReq,setSummaryBatteryReq] = useState<reqType>(req)      
      const [summaryBattery,setSummaryBattery] = useState<any>([batteryData])
      const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
      const [value, onChange] = useState<any>([subDays(new Date(),7), new Date()]);
      const [showSummary,setShowSummary] = useState<boolean>(false);
  
      useEffect(() => {
        let date1: any = value && format(value[0], 'dd-MMM-yyyy');
        let date2: any = value && format(value[1], 'dd-MMM-yyyy');
        if(date1 && date2) {
        daysFilter(date1 ?? startOfCurrentMonth, date2 ?? today);
        }
    }, [value])
      
    useEffect(()=>{
        batterySummary()
    },[reqBody])
      
    const batterySummary = ()=>{
        if(summaryBatteryReq.orgId===0) return
        if(summaryBatteryReq?.batteryId !==""){

            getBatterySummary.batterySummary(summaryBatteryReq) 
            .then((response)=>{
             const {batteryId,vehicleId,serialNumber,mfgdate,inServiceDate,daysInOperation,batteryType,batteryVoltage,capacity} = response 
             setSummaryBattery({...summaryBattery,batteryId,vehicleId,serialNumber,mfgdate,inServiceDate,daysInOperation,batteryType,batteryVoltage,capacity}) 
             setShowSummary(true)
             }).catch((error)=>{
             console.log('batterySummary:',error)
            })
        }
    }
 
      
    const  chartConfigs_line={
        type: 'stackedcolumn2d',
        dataFormat: 'json',
        width: '100%',
        height: '98%',
        dataSource: {
            chart: {
                xAxisname: "DATE",
                yAxisName: "Currnet (Amp)",
                formatNumberScale: "0",
                formatNumber: "0",
                decimals: "0",
                yaxisminvalue: "0",
                yaxismaxvalue: "1000",
                labelDisplay: "none",
                labelStep: "5",
                yAxisNameFontColor: "#4f82b6",
                yAxisNameFontBold: "1",
                xAxisNameFontColor: "#4f82b6",
                xAxisNameFontBold: "1",
                chartTopMargin: "50",
                legendposition:"Top", 
                showlegend: "1",
                numDivLines: "10",
                //useRoundEdges: "1",
                thickness: "5",
                showvalues: "0",
                plotSpacePercent: "60",
                // exportEnabled:"1",
                // exportfilename:"Charge_Current_Over_Time",
                paletteColors: "#e8e368, #5a8dc1, #10ff35, #ffef9f, #a246c4, #1fd284, #ffcc41, #30436e",
                theme: "fusion"
            },
            categories: [{
                category: [{
                    label: "1-Aug 22"
                },
                {
                    label: "2-Aug 22"
                },
                {
                    label: "3-Aug 22"
                },
                {
                    label: "4-Aug 22"
                },
                {
                    label: "5-Aug 22"
                },
                {
                    label: "6-Aug 22"
                },
                {
                    label: "7-Aug 22"
                },
                {
                    label: "8-Aug 22"
                },
                {
                    label: "9-Aug 22"
                },
                {
                    label: "10-Aug 22"
                },
                {
                    label: "11-Aug 22"
                },
                {
                    label: "12-Aug 22"
                },
                {
                    label: "13-Aug 22"
                },
                {
                    label: "14-Aug 22"
                },
                {
                    label: "15-Aug 22"
                },
                {
                    label: "16-Aug 22"
                },
                {
                    label: "17-Aug 22"
                },
                {
                    label: "18-Aug 22"
                },
                {
                    label: "19-Aug 22"
                },
                {
                    label: "20-Aug 22"
                },
                {
                    label: "21-Aug 22"
                },
                {
                    label: "22-Aug 22"
                },
                {
                    label: "23-Aug 22"
                },
                {
                    label: "24-Aug 22"
                },
                {
                    label: "25-Aug 22"
                },
                {
                    label: "26-Aug 22"
                },
                {
                    label: "27-Aug 22"
                },
                {
                    label: "28-Aug 22"
                },
                {
                    label: "29-Aug 22"
                },
                {
                    label: "30-Aug 22"
                },
                {
                    label: "31-Aug 22"
                }
                ]
            }],
            dataset: [{
                seriesname: "seriesname 1",
                data: [{
                    label: "1-Aug 22",
                    value: "100"
                },
                {
                    label: "2-Aug 22",
                    value: "50"
                },
                {
                    label: "3-Aug 22",
                    value: "200"
                },
                {
                    label: "4-Aug 22",
                    value: "50"
                },
                {
                    label: "5-Aug 22",
                    value: "250"
                },
                {
                    label: "6-Aug 22",
                    value: "50"
                },
                {
                    label: "7-Aug 22",
                    value: "200"
                },
                {
                    label: "8-Aug 22",
                    value: "100"
                },
                {
                    label: "9-Aug 22",
                    value: "50"
                },
                {
                    label: "10-Aug 22",
                    value: "200"
                },
                {
                    label: "11-Aug 22",
                    value: "50"
                },
                {
                    label: "12-Aug 22",
                    value: "250"
                },
                {
                    label: "13-Aug 22",
                    value: "50"
                },
                {
                    label: "14-Aug 22",
                    value: "200"
                },
                {
                    label: "15-Aug 22",
                    value: "100"
                },
                {
                    label: "16-Aug 22",
                    value: "50"
                },
                {
                    label: "17-Aug 22",
                    value: "200"
                },
                {
                    label: "18-Aug 22",
                    value: "50"
                },
                {
                    label: "19-Aug 22",
                    value: "250"
                },
                {
                    label: "20-Aug 22",
                    value: "50"
                },
                {
                    label: "21-Aug 22",
                    value: "100"
                },
                {
                    label: "22-Aug 22",
                    value: "50"
                },
                {
                    label: "23-Aug 22",
                    value: "200"
                },
                {
                    label: "24-Aug 22",
                    value: "50"
                },
                {
                    label: "25-Aug 22",
                    value: "250"
                },
                {
                    label: "26-Aug 22",
                    value: "50"
                },
                {
                    label: "27-Aug 22",
                    value: "50"
                },
                {
                    label: "28-Aug 22",
                    value: "200"
                },
                {
                    label: "29-Aug 22",
                    value: "100"
                },
                {
                    label: "30-Aug 22",
                    value: "50"
                },
                {
                    label: "31-Aug 22",
                    value: "200"
                }
                ]
            },
            {
                seriesname: "seriesname 2",
                data: [{
                    label: "1-Aug 22",
                    value: "220"
                },
                {
                    label: "2-Aug 22",
                    value: "50"
                },
                {
                    label: "3-Aug 22",
                    value: "120"
                },
                {
                    label: "4-Aug 22",
                    value: "50"
                },
                {
                    label: "5-Aug 22",
                    value: "120"
                },
                {
                    label: "6-Aug 22",
                    value: "50"
                },
                {
                    label: "7-Aug 22",
                    value: "200"
                },
                {
                    label: "8-Aug 22",
                    value: "200"
                },
                {
                    label: "9-Aug 22",
                    value: "50"
                },
                {
                    label: "10-Aug 22",
                    value: "120"
                },
                {
                    label: "11-Aug 22",
                    value: "50"
                },
                {
                    label: "12-Aug 22",
                    value: "130"
                },
                {
                    label: "13-Aug 22",
                    value: "50"
                },
                {
                    label: "14-Aug 22",
                    value: "200"
                },
                {
                    label: "15-Aug 22",
                    value: "220"
                },
                {
                    label: "16-Aug 22",
                    value: "50"
                },
                {
                    label: "17-Aug 22",
                    value: "120"
                },
                {
                    label: "18-Aug 22",
                    value: "50"
                },
                {
                    label: "19-Aug 22",
                    value: "130"
                },
                {
                    label: "20-Aug 22",
                    value: "50"
                },
                {
                    label: "21-Aug 22",
                    value: "200"
                },
                {
                    label: "22-Aug 22",
                    value: "250"
                },
                {
                    label: "23-Aug 22",
                    value: "50"
                },
                {
                    label: "24-Aug 22",
                    value: "120"
                },
                {
                    label: "25-Aug 22",
                    value: "50"
                },
                {
                    label: "26-Aug 22",
                    value: "140"
                },
                {
                    label: "27-Aug 22",
                    value: "50"
                },
                {
                    label: "28-Aug 22",
                    value: "200"
                },
                {
                    label: "29-Aug 22",
                    value: "250"
                },
                {
                    label: "30-Aug 22",
                    value: "50"
                },
                {
                    label: "31-Aug 22",
                    value: "120"
                }
                ]
            },
            {
                seriesname: "seriesname 3",
                data: [{
                    label: "1-Aug 22",
                    value: ""
                },
                {
                    label: "2-Aug 22",
                    value: "60"
                },
                {
                    label: "3-Aug 22",
                    value: "260"
                },
                {
                    label: "4-Aug 22",
                    value: "50"
                },
                {
                    label: "5-Aug 22",
                    value: "300"
                },
                {
                    label: "6-Aug 22",
                    value: "40"
                },
                {
                    label: "7-Aug 22",
                    value: "30"
                },
                {
                    label: "8-Aug 22",
                    value: ""
                },
                {
                    label: "9-Aug 22",
                    value: "40"
                },
                {
                    label: "10-Aug 22",
                    value: "250"
                },
                {
                    label: "11-Aug 22",
                    value: "50"
                },
                {
                    label: "12-Aug 22",
                    value: "250"
                },
                {
                    label: "13-Aug 22",
                    value: "40"
                },
                {
                    label: "14-Aug 22",
                    value: "30"
                },
                {
                    label: "15-Aug 22",
                    value: ""
                },
                {
                    label: "16-Aug 22",
                    value: "40"
                },
                {
                    label: "17-Aug 22",
                    value: "250"
                },
                {
                    label: "18-Aug 22",
                    value: "40"
                },
                {
                    label: "19-Aug 22",
                    value: "250"
                },
                {
                    label: "20-Aug 22",
                    value: "40"
                },
                {
                    label: "21-Aug 22",
                    value: "30"
                },
                {
                    label: "22-Aug 22",
                    value: ""
                },
                {
                    label: "23-Aug 22",
                    value: "40"
                },
                {
                    label: "24-Aug 22",
                    value: "250"
                },
                {
                    label: "25-Aug 22",
                    value: "40"
                },
                {
                    label: "26-Aug 22",
                    value: "250"
                },
                {
                    label: "27-Aug 22",
                    value: "40"
                },
                {
                    label: "28-Aug 22",
                    value: "30"
                },
                {
                    label: "29-Aug 22",
                    value: ""
                },
                {
                    label: "30-Aug 22",
                    value: "40"
                },
                {
                    label: "31-Aug 22",
                    value: "250"
                }
                ]
            },
            {
                seriesname: "seriesname 4",
                data: [{
                    label: "1-Aug 22",
                    value: ""
                },
                {
                    label: "2-Aug 22",
                    value: ""
                },
                {
                    label: "3-Aug 22",
                    value: "40"
                },
                {
                    label: "4-Aug 22",
                    value: "220"
                },
                {
                    label: "5-Aug 22",
                    value: "50"
                },
                {
                    label: "6-Aug 22",
                    value: "40"
                },
                {
                    label: "7-Aug 22",
                    value: ""
                },
                {
                    label: "8-Aug 22",
                    value: ""
                },
                {
                    label: "9-Aug 22",
                    value: ""
                },
                {
                    label: "10-Aug 22",
                    value: "40"
                },
                {
                    label: "11-Aug 22",
                    value: "250"
                },
                {
                    label: "12-Aug 22",
                    value: "40"
                },
                {
                    label: "13-Aug 22",
                    value: "40"
                },
                {
                    label: "14-Aug 22",
                    value: ""
                },
                {
                    label: "15-Aug 22",
                    value: ""
                },
                {
                    label: "16-Aug 22",
                    value: ""
                },
                {
                    label: "17-Aug 22",
                    value: "40"
                },
                {
                    label: "18-Aug 22",
                    value: "250"
                },
                {
                    label: "19-Aug 22",
                    value: "40"
                },
                {
                    label: "20-Aug 22",
                    value: "40"
                },
                {
                    label: "21-Aug 22",
                    value: ""
                },
                {
                    label: "22-Aug 22",
                    value: ""
                },
                {
                    label: "23-Aug 22",
                    value: ""
                },
                {
                    label: "24-Aug 22",
                    value: "40"
                },
                {
                    label: "25-Aug 22",
                    value: "250"
                },
                {
                    label: "26-Aug 22",
                    value: "40"
                },
                {
                    label: "27-Aug 22",
                    value: "40"
                },
                {
                    label: "28-Aug 22",
                    value: ""
                },
                {
                    label: "29-Aug 22",
                    value: ""
                },
                {
                    label: "30-Aug 22",
                    value: ""
                },
                {
                    label: "31-Aug 22",
                    value: "40"
                }
                ]
            },
            {
                seriesname: "seriesname 5",
                data: [{
                    label: "1-Aug 22",
                    value: ""
                },
                {
                    label: "2-Aug 22",
                    value: ""
                },
                {
                    label: "3-Aug 22",
                    value: ""
                },
                {
                    label: "4-Aug 22",
                    value: "50"
                },
                {
                    label: "5-Aug 22",
                    value: ""
                },
                {
                    label: "6-Aug 22",
                    value: "50"
                },
                {
                    label: "7-Aug 22",
                    value: ""
                },
                {
                    label: "8-Aug 22",
                    value: ""
                },
                {
                    label: "9-Aug 22",
                    value: ""
                },
                {
                    label: "10-Aug 22",
                    value: ""
                },
                {
                    label: "11-Aug 22",
                    value: "50"
                },
                {
                    label: "12-Aug 22",
                    value: ""
                },
                {
                    label: "13-Aug 22",
                    value: "50"
                },
                {
                    label: "14-Aug 22",
                    value: ""
                },
                {
                    label: "15-Aug 22",
                    value: ""
                },
                {
                    label: "16-Aug 22",
                    value: ""
                },
                {
                    label: "17-Aug 22",
                    value: ""
                },
                {
                    label: "18-Aug 22",
                    value: "50"
                },
                {
                    label: "19-Aug 22",
                    value: ""
                },
                {
                    label: "20-Aug 22",
                    value: "50"
                },
                {
                    label: "21-Aug 22",
                    value: ""
                },
                {
                    label: "22-Aug 22",
                    value: ""
                },
                {
                    label: "23-Aug 22",
                    value: ""
                },
                {
                    label: "24-Aug 22",
                    value: ""
                },
                {
                    label: "25-Aug 22",
                    value: "50"
                },
                {
                    label: "26-Aug 22",
                    value: ""
                },
                {
                    label: "27-Aug 22",
                    value: "50"
                },
                {
                    label: "28-Aug 22",
                    value: ""
                },
                {
                    label: "29-Aug 22",
                    value: ""
                },
                {
                    label: "30-Aug 22",
                    value: ""
                },
                {
                    label: "31-Aug 22",
                    value: ""
                }
                ]
            },
            {
                seriesname: "seriesname 6",
                data: [{
                    label: "1-Aug 22",
                    value: ""
                },
                {
                    label: "2-Aug 22",
                    value: ""
                },
                {
                    label: "3-Aug 22",
                    value: ""
                },
                {
                    label: "4-Aug 22",
                    value: "250"
                },
                {
                    label: "5-Aug 22",
                    value: ""
                },
                {
                    label: "6-Aug 22",
                    value: "50"
                },
                {
                    label: "7-Aug 22",
                    value: ""
                },
                {
                    label: "8-Aug 22",
                    value: ""
                },
                {
                    label: "9-Aug 22",
                    value: ""
                },
                {
                    label: "10-Aug 22",
                    value: ""
                },
                {
                    label: "11-Aug 22",
                    value: "250"
                },
                {
                    label: "12-Aug 22",
                    value: ""
                },
                {
                    label: "13-Aug 22",
                    value: "50"
                },
                {
                    label: "14-Aug 22",
                    value: ""
                },
                {
                    label: "15-Aug 22",
                    value: ""
                },
                {
                    label: "16-Aug 22",
                    value: ""
                },
                {
                    label: "17-Aug 22",
                    value: ""
                },
                {
                    label: "18-Aug 22",
                    value: "250"
                },
                {
                    label: "19-Aug 22",
                    value: ""
                },
                {
                    label: "20-Aug 22",
                    value: "50"
                },
                {
                    label: "21-Aug 22",
                    value: ""
                },
                {
                    label: "22-Aug 22",
                    value: ""
                },
                {
                    label: "23-Aug 22",
                    value: ""
                },
                {
                    label: "24-Aug 22",
                    value: ""
                },
                {
                    label: "25-Aug 22",
                    value: "250"
                },
                {
                    label: "26-Aug 22",
                    value: ""
                },
                {
                    label: "27-Aug 22",
                    value: "50"
                },
                {
                    label: "28-Aug 22",
                    value: ""
                },
                {
                    label: "29-Aug 22",
                    value: ""
                },
                {
                    label: "30-Aug 22",
                    value: ""
                },
                {
                    label: "31-Aug 22",
                    value: ""
                }
                ]
            },
            {
                seriesname: "seriesname 7",
                data: [{
                    label: "1-Aug 22",
                    value: ""
                },
                {
                    label: "2-Aug 22",
                    value: ""
                },
                {
                    label: "3-Aug 22",
                    value: ""
                },
                {
                    label: "4-Aug 22",
                    value: ""
                },
                {
                    label: "5-Aug 22",
                    value: ""
                },
                {
                    label: "6-Aug 22",
                    value: "70"
                },
                {
                    label: "7-Aug 22",
                    value: ""
                },
                {
                    label: "8-Aug 22",
                    value: ""
                },
                {
                    label: "9-Aug 22",
                    value: ""
                },
                {
                    label: "10-Aug 22",
                    value: ""
                },
                {
                    label: "11-Aug 22",
                    value: ""
                },
                {
                    label: "12-Aug 22",
                    value: ""
                },
                {
                    label: "13-Aug 22",
                    value: "70"
                },
                {
                    label: "14-Aug 22",
                    value: ""
                },
                {
                    label: "15-Aug 22",
                    value: ""
                },
                {
                    label: "16-Aug 22",
                    value: ""
                },
                {
                    label: "17-Aug 22",
                    value: ""
                },
                {
                    label: "18-Aug 22",
                    value: ""
                },
                {
                    label: "19-Aug 22",
                    value: ""
                },
                {
                    label: "20-Aug 22",
                    value: "70"
                },
                {
                    label: "21-Aug 22",
                    value: ""
                },
                {
                    label: "22-Aug 22",
                    value: ""
                },
                {
                    label: "23-Aug 22",
                    value: ""
                },
                {
                    label: "24-Aug 22",
                    value: ""
                },
                {
                    label: "25-Aug 22",
                    value: ""
                },
                {
                    label: "26-Aug 22",
                    value: ""
                },
                {
                    label: "27-Aug 22",
                    value: "70"
                },
                {
                    label: "28-Aug 22",
                    value: ""
                },
                {
                    label: "29-Aug 22",
                    value: ""
                },
                {
                    label: "30-Aug 22",
                    value: ""
                },
                {
                    label: "31-Aug 22",
                    value: ""
                }
                ]
            },
            {
                seriesname: "seriesname 8",
                data: [{
                    label: "1-Aug 22",
                    value: ""
                },
                {
                    label: "2-Aug 22",
                    value: ""
                },
                {
                    label: "3-Aug 22",
                    value: ""
                },
                {
                    label: "4-Aug 22",
                    value: ""
                },
                {
                    label: "5-Aug 22",
                    value: ""
                },
                {
                    label: "6-Aug 22",
                    value: "70"
                },
                {
                    label: "7-Aug 22",
                    value: ""
                },
                {
                    label: "8-Aug 22",
                    value: ""
                },
                {
                    label: "9-Aug 22",
                    value: ""
                },
                {
                    label: "10-Aug 22",
                    value: ""
                },
                {
                    label: "11-Aug 22",
                    value: ""
                },
                {
                    label: "12-Aug 22",
                    value: ""
                },
                {
                    label: "13-Aug 22",
                    value: "70"
                },
                {
                    label: "14-Aug 22",
                    value: ""
                },
                {
                    label: "15-Aug 22",
                    value: ""
                },
                {
                    label: "16-Aug 22",
                    value: ""
                },
                {
                    label: "17-Aug 22",
                    value: ""
                },
                {
                    label: "18-Aug 22",
                    value: ""
                },
                {
                    label: "19-Aug 22",
                    value: ""
                },
                {
                    label: "20-Aug 22",
                    value: "70"
                },
                {
                    label: "21-Aug 22",
                    value: ""
                },
                {
                    label: "22-Aug 22",
                    value: ""
                },
                {
                    label: "23-Aug 22",
                    value: ""
                },
                {
                    label: "24-Aug 22",
                    value: ""
                },
                {
                    label: "25-Aug 22",
                    value: ""
                },
                {
                    label: "26-Aug 22",
                    value: ""
                },
                {
                    label: "27-Aug 22",
                    value: "70"
                },
                {
                    label: "28-Aug 22",
                    value: ""
                },
                {
                    label: "29-Aug 22",
                    value: ""
                },
                {
                    label: "30-Aug 22",
                    value: ""
                },
                {
                    label: "31-Aug 22",
                    value: ""
                }
                ]
            }
            ]
        }
      }
    
    const getIds = async(orgId: number, locationId: number,batteryId:string,vehicleId:string, initial?: boolean)=>{
        if(batteryId == ""){
            setShowSummary(false)
          }
        setReqBody({...reqBody,orgId,locationId})
        setBatteryIdReq({...batteryIdReq,orgId,locationId})  
        setCallBatteryId(!callBatteryId)
      }
  
      useEffect(()=>{
        resize();
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        if(data?.batteryId == ""){
            setShowSummary(false)
          }
        let org = data?.orgId > -1 ? data?.orgId : userId;
        setReqBody({...reqBody, orgId: org,  locationId: data?.locationId ? data?.locationId : -1,buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId})
        setBatteryIdReq({...batteryIdReq, orgId: org,  locationId: data?.locationId ? data?.locationId : -1,buildingId: data?.buildingId ? data?.buildingId : -1,vehicleId: data?.vehicleId})
        setCallBatteryId(!callBatteryId)
      }, [])

      useEffect(() => {
        let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setReqBody({...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId})
            setBatteryIdReq({...batteryIdReq, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId,vehicleId: globalData?.vehicleId})
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])
    const daysFilter = (startDate:string,endDate:string)=>{
        setReqBody({...reqBody,startDate,endDate})
    }

    useEffect(() => {
        if(action !== "") {
          onButtonClick(action)
        }
      }, [action])

    const onButtonClick = useCallback((value:string) => {
        if (ref.current === null) return
        if(value==='png'){
            toPng(ref.current, { cacheBust: true, })
            .then((dataUrl) => {
              const link = document.createElement('a')
              link.download = 'Charge_Current_Over_Time.png'
              link.href = dataUrl
              link.click()
            })
            .catch((err) => {
              console.log(err)
            })
        }else{
            html2canvas(ref.current)
            .then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const link = document.createElement('a')
              link.download = 'my-image-name.png';
              const pdf = new jsPDF();
            //   pdf.addImage(imgData, 'PNG',2,20,0,99);
              pdf.addImage(imgData, 'PNG',5,20,200,100);   
              pdf.save("Charge_Current_Over_Time.pdf");
            })
        }
      }, [ref])

  return(
    <>    
      <LoadingSpinner isShowSpinner={false}></LoadingSpinner>
      {/* <PosinetHeader 
        billing={<HeaderFilter
        getId={getIds}
        vId={true}
        onButtonClick={onButtonClick}
        />}
    /> */}
     <div >
     {
        showSummary && 
        <BatteryDetails 
         batteryData={summaryBattery[0]}
        />
      }

        <div className="container-battery-voltage" ref={ref}>
        <div className="chartTitle p-2 d-flex  align-items-center position-relative">
         <span className="mr-auto ml-auto">Charge Current Over Time</span>
        </div>  
        <span className="export">
        {
                    showDateFilter && <span>
                        <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                    </span>
                }
            <SelectDays daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} />
            {/* <button className='mx-2 exportstyle' type='button' onClick={onButtonClick}>Export as PNG</button> */}
            {/* <img onClick={onButtonClick} className='dow-icon ml-2 mb-2' src={DownloadIcon} alt=""/> */}
          </span>
        <div className='chart-tooltip'>
        <div className='battey-voltage-chart'>
        <ReactFC {...chartConfigs_line}/>
      </div>
      </div>
      </div>
      </div>
      <SessionWarning/>
  </>
  )
}

export default ChargeCurrentOverTime;

