import { useState, useEffect, useRef, useLayoutEffect, FC } from 'react';
import LoadingSpinner from '../spinner/loading-spinner'
import { CaretDownOutlined } from '@ant-design/icons';
import { CaretUpOutlined } from '@ant-design/icons';
import SummaryCards from '../reusableComponent/summaryCards';
import SelectDays from '../reusableComponent/selectDays';
import ShowEntries from '../reusableComponent/showEntries';
import { Utility } from "../../helper/index";
import constantUrl from "../../settings/constants";
import service from '../../service/api/common';
import PaginationComponent from '../reusableComponent/pagination';
import SessionWarning from '../reusableComponent/sessionWarning';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { format, startOfMonth, subDays } from 'date-fns';
import React from 'react';
import { resize , hideSidebar} from '../../settings/resize';

const startOfCurrentMonth: any = format(startOfMonth(new Date(),), 'dd-MMM-yyyy')
const today: any = format(new Date(), 'dd-MMM-yyyy');

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    }
        ,
    action: string
    refresh:number
}

const BillingSummaryReportChart: FC<Props> = ({ globalData, action, refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const [billingSummary, setBillingSummary] = useState<any[]>([])
    const [billable, setBillable] = useState<any[]>([])
    const [energySummary, setEnergySummary] = useState<any[]>([])
    const [summaryBody, setSummaryBody] = useState<any>({ orgId: 0, parentOrgId: 0, divisionId: 0, locationId: 0 })
    const [summaryCounts, setSummaryCounts] = useState<any>({})
    const [logReqBody, setlogReqBody] = useState<any>({ locationId: 0, buildingId: -1, divisionId: -1, pageNumber: 1, pageSize: 10 })
    const [minIndex, setMindex] = useState<number>(0)
    const [maxIndex, setMaxindex] = useState<number>(0)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [sort, setSort] = useState<any>({ batteryId: false, chargeId: false, log: false, port: false })
    const [totalCount, setTotalCount] = useState<number>(1)
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [pagination, setPagination] = useState<any>({ totaPage: 0, current: 1, minIndex: 0, maxIndex: 0, pageSize: 12, showChanger: true })

    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [value, onChange] = useState<any>([subDays(new Date(), 7), new Date()]);
    const [initial, setinitial] = useState<boolean>(true);

    useLayoutEffect(()=>{
        let timer:any
        const debouncedHandleResize = ()=>{
         clearTimeout(timer)
         timer=setTimeout(()=>{
          resize()
         },500)
        }
        window.addEventListener("resize",debouncedHandleResize)
        return()=>{
         window.removeEventListener("resize",debouncedHandleResize)       }
     })
 
     useLayoutEffect(()=>{
       resize();
       hideSidebar();
     },[])

    useEffect(()=>{
       if (refresh === 0) return
       setlogReqBody({...logReqBody})
       getData()
    },[refresh])

    useEffect(() => {
        let date1: any = value && format(value[0], 'dd-MMM-yyyy');
        let date2: any = value && format(value[1], 'dd-MMM-yyyy');
        if (date1 && date2) {
            daysFilter(date1 ?? startOfCurrentMonth, date2 ?? today);
        }
    }, [value])

    async function getData() {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.billingSummaryReport.getBillingSummaryReport,
                method: "GET",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setBillingSummary(response["billable"]);
            setEnergySummary(response["energyUsagesSummary"]);

            let response1 = response["billable"]
            let response2 = response["energyUsagesSummary"]

            setPagination({
                ...pagination,
                totalPage: response1.length / pagination.pageSize,
                minIndex: 0,
                maxIndex: pagination.pageSize,
                showChanger: response1.length > 20 ? true : false
            })

            setPagination({
                ...pagination,
                totalPage: response2.length / pagination.pageSize,
                minIndex: 0,
                maxIndex: pagination.pageSize,
                showChanger: response2.length > 20 ? true : false
            })
            setIsShowSpinner(false)
        } catch (error) {
            console.error("getBillingSummaryReport:", error);
            setIsShowSpinner(false)

        }
    }

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setCurrent(current)
        setMindex((current - 1) * numPageSize)
        setMaxindex(current * numPageSize)
        setPageSize(numPageSize)
        setlogReqBody({ ...logReqBody, pageSize: numPageSize })
    }

    const handlePagination = (pageNumber: number, c: number, maxIndex: number) => {
        setlogReqBody({ ...logReqBody, pageNumber: pageNumber })
        setMaxindex(maxIndex)
        setMindex(minIndex)
        setCurrent(pageNumber);

        let userInfo: any = Utility.getUserInfo();
        let userId: number = userInfo.userId
        logReqBody.parentOrgId === 0 ? setlogReqBody({ ...logReqBody, parentOrgId: userId, pageNumber: pageNumber }) : setlogReqBody({ ...logReqBody, pageNumber: pageNumber })
    }

    const daysFilter = (startDate: string, endDate: string) => {
        setlogReqBody({ ...logReqBody, startDate, endDate })
    }

    const getIds = (dealerId: number, companyId: number, locId: number, initial?: boolean) => {
        if (initial === true) { } else {

            if (haveSameData(summaryBody, { ...summaryBody, parentOrgId: dealerId, orgId: companyId, locationId: locId })) {

            } else {
                setSummaryBody({ ...summaryBody, parentOrgId: dealerId, orgId: companyId, locationId: locId })
            }
            if (haveSameData(logReqBody, { ...logReqBody, parentOrgId: dealerId, orgId: companyId, locationId: locId })) {

            } else {
                setlogReqBody({ ...logReqBody, parentOrgId: dealerId, orgId: companyId, locationId: locId })
            }
        }
    };

    useEffect(() => {
        setinitial(true);
        window.scrollTo(0, 0)
        resize();
        hideSidebar();
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let org = data?.orgId > -1 ? data?.orgId : userId;
        getData()
        setSummaryBody({ ...summaryBody, orgId: org,  locationId: data?.locationId ? data?.locationId : -1,buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
        setlogReqBody({ ...logReqBody, orgId: org,  locationId: data?.locationId ? data?.locationId : -1,buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
        setinitial(false);
    }, [])

    useEffect(() => {
        if(initial == false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setSummaryBody({ ...summaryBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setlogReqBody({ ...logReqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

    const haveSameData = function (obj1: any, obj2: any) {
        const obj1Length = Object.keys(obj1).length;
        const obj2Length = Object.keys(obj2).length;

        if (obj1Length === obj2Length) {
            return Object.keys(obj1).every(
                key => obj2.hasOwnProperty(key)
                    && obj2[key] === obj1[key]);
        }
        return false;
    }

    const hide = useRef<HTMLDivElement>(null)

    // useEffect(() => {
    //     getData()
    // }, [logReqBody])


    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "vechileId":
                if (sort.vechileId) {
                    setBillable(billable.sort((a, b) => (a.vehicle_Id < b.vehicle_Id) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.vehicle_Id < b.vehicle_Id) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'chargerName':
                if (sort.chargerName) {
                    setEnergySummary(energySummary.sort((a, b) => (a.chargerName < b.chargerName) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.chargerName < b.chargerName) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyVehicleId':
                if (sort.energyVehicleId) {
                    setEnergySummary(energySummary.sort((a, b) => (a.vehicleId < b.vehicleId) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.vehicleId < b.vehicleId) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'billableSno':
                if (sort.billableSno) {
                    setBillable(billable.sort((a, b) => { return a.sno - b.sno }))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => { return b.sno - a.sno }))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energySno':
                if (sort.energySno) {
                    setEnergySummary(energySummary.sort((a, b) => { return a.sno - b.sno }))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => { return b.sno - a.sno }))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "billableVEE":
                if (sort.billableVEE) {
                    setBillable(billable.sort((a, b) => (a.vehicle_energy_estimated < b.vehicle_energy_estimated) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.vehicle_energy_estimated < b.vehicle_energy_estimated) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyVEE':
                if (sort.energyVEE) {
                    setEnergySummary(energySummary.sort((a, b) => (a.vehicleEnergyEstimated < b.vehicleEnergyEstimated) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.vehicleEnergyEstimated < b.vehicleEnergyEstimated) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'billableVEU':
                if (sort.billableVEU) {
                    setBillable(billable.sort((a, b) => (a.vehicle_energy_usage < b.vehicle_energy_usage) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.vehicle_energy_usage < b.vehicle_energy_usage) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyVEU':
                if (sort.energyVEU) {
                    setEnergySummary(energySummary.sort((a, b) => (a.vehicleEnergyUsuage < b.vehicleEnergyUsuage) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.vehicleEnergyUsuage < b.vehicleEnergyUsuage) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'billableEU':
                if (sort.billableEU) {
                    setBillable(billable.sort((a, b) => (a.energy_usage < b.energy_usage) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.energy_usage < b.energy_usage) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "billableTB":
                if (sort.billableTB) {
                    setBillable(billable.sort((a, b) => (a.total_bill < b.total_bill) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.total_bill < b.total_bill) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'billableITC':
                if (sort.billableITC) {
                    setBillable(billable.sort((a, b) => (a.idle_time_cost < b.idle_time_cost) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.idle_time_cost < b.idle_time_cost) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'billableClient':
                if (sort.billableClient) {
                    setBillable(billable.sort((a, b) => (a.clients < b.clients) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.clients < b.clients) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'billableTCT':
                if (sort.billableTCT) {
                    setBillable(billable.sort((a, b) => (a.total_connection_time < b.total_connection_time) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.total_connection_time < b.total_connection_time) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyTCT':
                if (sort.energyTCT) {
                    setEnergySummary(energySummary.sort((a, b) => (a.TotalConnectionTime < b.TotalConnectionTime) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.TotalConnectionTime < b.TotalConnectionTime) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "billableCT":
                if (sort.billableCT) {
                    setBillable(billable.sort((a, b) => (a.charge_time < b.charge_time) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.charge_time < b.charge_time) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyCT':
                if (sort.energyCT) {
                    setEnergySummary(energySummary.sort((a, b) => (a.chargeTime < b.chargeTime) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.chargeTime < b.chargeTime) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'billableIdleTime':
                if (sort.billableIdleTime) {
                    setBillable(billable.sort((a, b) => (a.idle_time < b.idle_time) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.idle_time < b.idle_time) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyIdleTime':
                if (sort.energyIdleTime) {
                    setEnergySummary(energySummary.sort((a, b) => (a.IdleTime < b.IdleTime) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.IdleTime < b.IdleTime) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'billableNAT':
                if (sort.billableNAT) {
                    setBillable(billable.sort((a, b) => (a.not_available_time < b.not_available_time) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setBillable(billable.sort((a, b) => (a.not_available_time < b.not_available_time) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "energyNAT":
                if (sort.energyNAT) {
                    setEnergySummary(energySummary.sort((a, b) => (a.NotAvailableTime < b.NotAvailableTime) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.NotAvailableTime < b.NotAvailableTime) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energySessionId':
                if (sort.energySessionId) {
                    setEnergySummary(energySummary.sort((a, b) => (a.sessionId < b.sessionId) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.sessionId < b.sessionId) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyEndDateTime':
                if (sort.energyEndDateTime) {
                    setEnergySummary(energySummary.sort((a, b) => (a.endDateTime < b.endDateTime) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.endDateTime < b.endDateTime) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyStartDateTime':
                if (sort.energyStartDateTime) {
                    setEnergySummary(energySummary.sort((a, b) => (a.startDateTime < b.startDateTime) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.startDateTime < b.startDateTime) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'energyPortId':
                if (sort.energyPortId) {
                    setEnergySummary(energySummary.sort((a, b) => (a.portId < b.portId) ? -1 : 1))
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setEnergySummary(energySummary.sort((a, b) => (a.portId < b.portId) ? 1 : -1))
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };

    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    const getDowFormat = async (format: string) => {

    }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div ref={hide}>
                {/* <PosinetHeader billing={<HeaderFilter getIds={getIds} vId={true}/>}/> */}
                <div className="topTabPanel mb-3 panelShadow">
                    <div id="reporting_chart5" className="container-fluid pr-0 pl-0">
                        <div className="row justify-content-around">
                            <div className="col-md-12 col-lg-12 ">
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="row rounded pr-3 pl-3 pb-0 charge-Log-Top">
                                            {/* <SummaryCards
                                                summaryCounts={summaryCounts}
                                                heading={"Report Summary"}
                                            /> */}
                                            <div className="days__filter" >
                                                {
                                                    showDateFilter && <span>
                                                        <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                                                    </span>
                                                }
                                                <SelectDays daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} />
                                            </div>
                                        </div>
                                        <div className="container-daily-uses mt-2">
                                            <div className="bgWhite" >
                                                <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                                                    <div className='ml-auto mr-auto'>Billable</div>
                                                    <div className='show-entries'>
                                                        <ShowEntries getPageSize={onSelect} />
                                                    </div>
                                                </div>
                                                <div className="billable-tableWrapper overflow-auto customScroll" >
                                                    <table id="" className="billable">
                                                        <thead>
                                                            <tr className="billable-head">
                                                                <th>
                                                                    <div onClick={() => sortBy('billableSno')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >S.No</span>
                                                                        {sort.billableSno ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableClient')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Clients</span>
                                                                        {sort.billableClient ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('vechileId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Vehicle ID</span>
                                                                        {sort.vechileId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableVEE')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Vehicle Energy Estimated (AC)</span>
                                                                        {sort.billableVEE ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableVEU')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Vehicle Energy Usuage (AC)</span>
                                                                        {sort.billableVEU ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableTCT')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Total Connection Time (hh:mm:ss)</span>
                                                                        {sort.billableTCT ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableCT')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Charge Time (hh:mm:ss)</span>
                                                                        {sort.billableCT ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableIdleTime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Idle Time (hh:mm:ss)</span>
                                                                        {sort.billableIdleTime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableNAT')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Not Available Time (hh:mm:ss)</span>
                                                                        {sort.billableNAT ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableITC')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Idle Time Cost</span>
                                                                        {sort.billableITC ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableEU')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Energy Usage $</span>
                                                                        {sort.billableEU ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('billableTB')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Total Bill $ (Energy $ + Idle Time $)</span>
                                                                        {sort.billableTB ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(
                                                                billingSummary?.map((item: any, index: any) => {
                                                                    const { sno, clients, vehicleId, vehicleEnergyEstimated, vehicleEnergyUsage, totalConnectionTime, chargeTime, idleTime, notAvailableTime, idleTimeCost, energyUsage, totalBillAmount } = item;
                                                                    return (
                                                                        index >= pagination.minIndex &&
                                                                        index < pagination.maxIndex &&
                                                                        <React.Fragment key={index + 1}>
                                                                            <tr key={index}>
                                                                                <td>{sno}</td>
                                                                                <td>{clients}</td>
                                                                                <td>{vehicleId} </td>
                                                                                <td>{vehicleEnergyEstimated}KWh</td>
                                                                                <td>{vehicleEnergyUsage}KWh</td>
                                                                                <td>{totalConnectionTime}</td>
                                                                                {/* <td>{chargeTime.slice(0,chargeTime.lastIndexOf(":"))}</td>
                                                                                <td>{idleTime.slice(0,idleTime.lastIndexOf(":"))}</td>
                                                                                <td>{notAvailableTime.slice(0,notAvailableTime.lastIndexOf(":"))}</td> */}
                                                                                <td>{chargeTime}</td>
                                                                                <td>{idleTime}</td>
                                                                                <td>{notAvailableTime}</td>
                                                                                <td>{idleTimeCost}</td>
                                                                                <td>${energyUsage}</td>
                                                                                <td>${totalBillAmount}</td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                            )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <PaginationComponent
                                                    handlePagination={handlePagination}
                                                    totalLength={totalCount}
                                                    pageSize={pageSize}
                                                    current={current}
                                                />
                                            </div>
                                        </div>
                                        <div className="container-daily-uses mt-2">
                                            <div className="bgWhite" >
                                                <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                                                    <div className='ml-auto mr-auto'>Energy Usage Summary</div>
                                                    <div className='show-entries'>
                                                        <ShowEntries getPageSize={onSelect} />
                                                    </div>
                                                </div>
                                                <div className="energy-tableWrapper overflow-auto customScroll">
                                                    <table id="" className="billable">
                                                        <thead>
                                                            <tr className="billable-head">
                                                                <th>
                                                                    <div onClick={() => sortBy('energySno')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >S.No</span>
                                                                        {sort.energySno ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('chargerName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Charger Name</span>
                                                                        {sort.chargerName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyPortId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Port ID</span>
                                                                        {sort.energyPortId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyVehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Vehicle ID</span>
                                                                        {sort.energyVehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyStartDateTime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Start Date/Time</span>
                                                                        {sort.energyStartDateTime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyEndDateTime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >End Date/Time</span>
                                                                        {sort.energyEndDateTime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energySessionId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Session ID</span>
                                                                        {sort.energySessionId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyVEE')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Vehicle Energy Estimated (AC)</span>
                                                                        {sort.energyVEE ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyVEU')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Vehicle Energy Usuage(DC)</span>
                                                                        {sort.energyVEU ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyCT')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Charge Time(hh:mm:ss)</span>
                                                                        {sort.energyCT ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyIdleTime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Idle Time(hh:mm:ss)</span>
                                                                        {sort.energyIdleTime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyNAT')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Not Available Time(hh:mm:ss)</span>
                                                                        {sort.energyNAT ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div onClick={() => sortBy('energyTCT')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                                        <span >Total Connection Time(hh:mm:ss)</span>
                                                                        {sort.energyTCT ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(
                                                                energySummary?.map((item: any, index: any) => {
                                                                    const { sno, chargerName, portId, vehicleId, startDateTime, endDateTime, sessionId, vehicleEnergyEstimated, vehicleEnergyUsage, chargeTime, idleTime, notAvailableTime, totalConnectionTime } = item;
                                                                    return (
                                                                        index >= pagination.minIndex &&
                                                                        index < pagination.maxIndex &&
                                                                        <React.Fragment key={index + 1}>
                                                                            <tr key={index}>
                                                                                <td>{sno}</td>
                                                                                <td>{chargerName}</td>
                                                                                <td>{portId} </td>
                                                                                <td>{vehicleId}</td>
                                                                                <td>{startDateTime.slice(0, startDateTime.lastIndexOf("."))}</td>
                                                                                <td>{endDateTime.replace(".000+00:00", "")}</td>
                                                                                <td>{sessionId}</td>
                                                                                <td>{vehicleEnergyEstimated}KWh</td>
                                                                                <td>{vehicleEnergyUsage}KWh</td>
                                                                                {/* <td>{chargeTime.slice(0,chargeTime.lastIndexOf(":"))}</td>
                                                                                    <td>{idleTime.slice(0,idleTime.lastIndexOf(":"))}</td>
                                                                                    <td>{notAvailableTime.slice(0,notAvailableTime.lastIndexOf(":"))}</td>
                                                                                    <td>{totalConnectionTime.slice(0,totalConnectionTime.lastIndexOf(":"))}</td> */}
                                                                                <td>{chargeTime}</td>
                                                                                <td>{idleTime}</td>
                                                                                <td>{notAvailableTime}</td>
                                                                                <td>{totalConnectionTime}</td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <PaginationComponent
                                                    handlePagination={handlePagination}
                                                    totalLength={totalCount}
                                                    pageSize={pageSize}
                                                    current={current}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    );
}


export default BillingSummaryReportChart;