
import ConvertUtility from './convertUtility'
import constants from './constants'
import Algorithm from './algorithm'
 class Utility {
    static getUserInfo()
    {
        let userInfo = ""
        if(!ConvertUtility.isNullOrEmpty(sessionStorage.getItem(constants.sessionDetails.userInfo)))
        { 
            let user:any =  sessionStorage.getItem(constants.sessionDetails.userInfo);
            userInfo = Algorithm.decryptData(user)
        }
        return userInfo;
    }

    static hasUserEmpty()
    {       
       let result = false;
        if(ConvertUtility.isNullOrEmpty(sessionStorage.getItem(constants.sessionDetails.userInfo)))
        {
            result = true;
        }
       return result;
    }
}

export default Utility