import $ from 'jquery';

export function resize() {
     $(document).ready(function () {
         setTimeout(function () {
             $(".page-body-wrapper").css({ "margin-top": ($(".topnavBar").height() + 15) + "px" });
            }, 500);
     
         if ($(window).width() > 1024) {
             $(".sidebar.vertical-scroll").show();
             $(".sidebar.vertical-scroll").stop().animate({ "left": "0" }, 200);
             $(".topnavBar, .page-body-wrapper").stop().animate({ "margin-left": "270px" }, 300);
             $('#posinet-header').stop().animate({ "margin-left": "270px" }, 300)
         }
         if ($(window).width() <= 1024) {
             $(".sidebar.vertical-scroll").stop().animate({ "left": "-100%" }, 250, function () {
                 $(".topnavBar, .page-body-wrapper").stop().animate({ "margin-left": "0px" }, 250);
                 $(".sidebar.vertical-scroll").hide();
             });
         }
      });
 }

 export function hideSidebar() {
    const nav = document.querySelector(".nav-container");

    if (nav.classList.contains("is-active")) {
      nav.classList.remove("is-active");
    }
}