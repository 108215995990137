import React from "react";

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
  

export default function LoadingSpinner(props) {
  return (
    <div>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.isShowSpinner}
        >
            <CircularProgress color="inherit" />
         </Backdrop>
    </div>
  );
}