import React, { FC, useState, useRef, useEffect, useLayoutEffect } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import LoadingSpinner from '../spinner/loading-spinner'
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import PaginationComponent from '../reusableComponent/pagination';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import ShowEntries from '../reusableComponent/showEntries';
import { format, parseISO, subDays } from 'date-fns';
import SessionWarning from '../reusableComponent/sessionWarning';
import DaysFilter from '../reusableComponent/dateNewFilter';
import { resize, hideSidebar } from '../../settings/resize';
import { Button, ConfigProvider, Input, Select, Space, Modal } from "antd";

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

const reqBody = {
    orgId: 0,
    batteryId: "",
    vehicleId: "",
    batteryRxSim: "",
    locationId: -1,
    buildingId: -1,
    divisionId: -1,
    pageOffset: 1,
    pageLimit: 10,
    startDate: sevenDaysAgo,
    endDate: today,
    sortColumns: "",
    sortDirection: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    // refresh: number
}

const ChargeLogChart: FC<Props> = ({ globalData, action }) => {
    // const ChargeLogChart: FC<Props> = ({ globalData, action, refresh }) => { 
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;

    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [chargeLog, setChargeLog] = useState<any[]>([])
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [logReqBody, setlogReqBody] = useState<any>(reqBody)
    const [exportReqBody, setExportReqBody] = useState<any>(reqBody)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [sort, setSort] = useState<any>({
        batteryId: false, vehicleId: false, batteryRxSim: false, batteryType: false, totalCells: false, batteryCapacity: false, log: false, chargeId: false,
        port: false, startDate: false, endDate: false, chargeAhrs: false, chargeKwh: false, formatStartTemp: false, formatEndTemp: false, startSoc: false, endSoc: false, startVolts: false, endVolts: false,
        marCurr: false, endCurr: false, chargeType: false, startCode: false, termCode: false, startUnbal: false, endUnbal: false, org: false, div: false, loc: false, site: false, startTemp: false, endTemp: false
    })
    const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
    const [selector, setSelector] = useState(false);
    const [count, setCount] = useState<number>(0)
    const [alert, setAlert] = useState<boolean>(false);
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [initial, setInitial] = useState<boolean>(true);
    const [column, setColumn] = useState<any[]>([])
    const [selectorLabel, setSelectorLabel] = useState<any[]>([])

    const tabelRef = useRef<HTMLDivElement>(null)
    const selectorRef = useRef<HTMLDivElement>(null);
    const [emptyError, setemptyError] = useState<string>("");

    const [tableHeight, setTableHeight] = useState<any>(445)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const dateValue = [
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'Last 14 Days', label: 'Last 14 Days' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
        { value: 'Last 60 Days', label: 'Last 60 Days' },
        { value: 'Last 90 Days', label: 'Last 90 Days' },
        { value: 'Custom Date Range', label: 'Custom Date Range' }]

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 80
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
    };

    const [searchObject, setSearchObject] = useState<any>({
        batteryId: '',
        vehicleId: '',
        batteryRxSerialNumber: '',
        batteryRxSim: ''
    })

    const DropList = [
        { label: "Battery ID" },
        { label: "Vehicle ID" },
        { label: "Battery Rx Serial Number" }
    ];

    const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
    const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
    const [inputvalue, setInput] = useState<any>("")

    const onChangedropdown = (value: string) => {
        setDropDownValue(value);
        setemptyError("")
        setPlaceholder(`Search ${value}`)
        setInput("");
        const data = {
            batteryId: '',
            vehicleId: '',
            batteryRxSerialNumber: '',
            batteryRxSim: ''
        }
        setSearchObject(data)
        if (inputvalue) {
            setlogReqBody({ ...logReqBody, ...data, pageOffset: 1 })
            getRecords({ ...logReqBody, ...data, pageOffset: 1 })
            getRecordCount({ ...logReqBody, ...data, pageOffset: 1 })
        }
    };

    const onChangePayload = (value: any) => {
        setemptyError("")
        let obj = { ...searchObject };
        if (dropdownvalue === "Battery ID") {
            setInput(value);
            if (value) {
                obj.batteryId = value.trim();
                setSearchObject({ ...obj })
            } else {
                let globalData: any = sessionStorage.getItem('globalData')
                let parsedData = JSON.parse(globalData)
                obj.batteryId = parsedData['batteryId']
                obj.vehicleId = parsedData['vehicleId']
                callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        } else if (dropdownvalue === "Battery Rx Serial Number") {
            obj.batteryRxSim = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Vehicle ID") {
            setInput(value);
            if (value) {
                obj.vehicleId = value.trim();
                setSearchObject({ ...obj })
            } else {
                let globalData: any = sessionStorage.getItem('globalData')
                let parsedData = JSON.parse(globalData)
                obj.vehicleId = parsedData['vehicleId']
                obj.batteryId = parsedData['batteryId']
                callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        }
    }

    const callCountAndReport = (obj: any) => {
        setlogReqBody({ ...logReqBody, ...obj })
        getRecords({ ...logReqBody, ...obj },true)
        getRecordCount({ ...logReqBody, ...obj })
    };

    // Search Enter Button
    const onPressEnter = (event: any) => {
        let value = event.target.value.trim()
        let obj = { ...searchObject };
        switch (dropdownvalue) {
            case 'Battery ID':
                obj.batteryId = value;
                break;
            case 'Battery Rx Serial Number':
                obj.batteryRxSim = value;
                break;
            case 'Vehicle ID':
                obj.vehicleId = value;
                break;
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        getRecords({ ...logReqBody, ...obj, pageOffset: 1 })
        getRecordCount({ ...logReqBody, ...obj, pageOffset: 1 })
    };

    const handleSearch = () => {
        const { batteryId, vehicleId, batteryRxSim } = searchObject
        let obj = { ...searchObject }
        switch (dropdownvalue) {
            case 'Battery ID':
                if (!batteryId) {
                    setemptyError("Please Enter Battery ID")
                    return
                } else {
                    obj.batteryId = inputvalue.trim();
                }
                break;
            case 'Battery Rx Serial Number':
                if (!batteryRxSim) {
                    setemptyError("Please Enter Battery Rx Serial Number")
                    return
                } else {
                    obj.batteryRxSim = inputvalue.trim();
                }
                break;
            case 'Vehicle ID':
                if (!vehicleId) {
                    setemptyError("Please Enter Vehicle ID")
                    return
                } else {
                    obj.vehicleId = inputvalue.trim();
                }
                break;
            default:
                setemptyError("")
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        getRecords({ ...logReqBody, ...obj, pageOffset: 1 })
        getRecordCount({ ...logReqBody, ...obj, pageOffset: 1 })
    }

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('charge_Log_Report_W')) window.history.back()
        }
        getReportSettings();
        setInitial(true)

    }, []);

    useEffect(() => {
        if (initial == false) {
            let date1: any = value && format(value[0], 'dd-MMM-yyyy');
            let date2: any = value && format(value[1], 'dd-MMM-yyyy');
            if (date1 && date2) {
                daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
            }
        }
    }, [value])

    useEffect(() => {
        if (action !== "") {
            dwnFormat(action)
        }
    }, [action])

    // useEffect(() => {
    //     if (refresh !== 0) {
    //         getRecords({ ...logReqBody })
    //         getRecordCount({ ...logReqBody })
    //     }
    // }, [refresh])

    const handlePagination = (pageNumber: number, c: number, maxIndex: number) => {
        setlogReqBody({ ...logReqBody, pageOffset: pageNumber })
        getRecords({ ...logReqBody, pageOffset: pageNumber })
        setCurrent(pageNumber)
    }

    const getRecords = async (payload: any,resetValidation?:boolean) => {
        if (payload.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        if (payload.batteryId === "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();
        setIsShowSpinner(true);
        try {
            const response = await service({
                url: constantUrl.api.chargeLog.getChargelogReport,
                method: "POST",
                headers: { "Content-type": "application/json", Authorization: "Bearer " + authToken },
                data: payload
            })
            setChargeLog(response)
            setIsShowSpinner(false)
            const { batteryId, vehicleId, batteryRxSim } = searchObject;
            if (
                response.length === 0 &&
                inputvalue && 
                resetValidation===undefined) {
                switch (dropdownvalue) {
                    case "Battery ID":
                        if (batteryId) {
                            setemptyError("Please Enter Valid Battery ID");
                            return;
                        }
                        break;
                    case "Battery Rx Serial Number":
                        if (batteryRxSim) {
                            setemptyError("Please Enter Valid Battery Rx Serial Number");
                            return;
                        }
                        break;
                    case "Vehicle ID":
                        if (vehicleId) {
                            setemptyError("Please Enter Valid Vehicle ID");
                            return;
                        }
                        break;
                    default:
                        setemptyError("");
                }
            }else{
                setemptyError("");
            }

        }
        catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    }

    const getRecordCount = async (payload: any) => {
        if (payload.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();
        try {
            const response = await service({
                url: constantUrl.api.chargeLog.count,
                method: "POST",
                headers: { 'Content-type': 'application/json', Authorization: "Bearer " + token },
                data: payload
            })
            setCount(response)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (initial === false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setInput("");
            const data = {
                batteryId: '',
                vehicleId: '',
                batteryRxSerialNumber: '',
                batteryRxSim: ''
            }
            setSearchObject(data)
            setemptyError("");
            const reqPayload = { ...logReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId }
            const resetValidation = true
            getRecords(reqPayload,resetValidation)
            getRecordCount(reqPayload)
            setExportReqBody({ ...exportReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])


    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "batteryId":
                if (sort.batteryId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "vehicleId":
                if (sort.vehicleId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batteryRxSim":
                if (sort.batteryRxSim) {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryRxSerialNumber", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "batteryRxSerialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryRxSerialNumber", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "batteryRxSerialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batteryType":
                if (sort.batteryType) {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryTypeDesc", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "batteryTypeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryTypeDesc", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "batteryTypeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totalCells":
                if (sort.totalCells) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalCells", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "totalCells", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalCells", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "totalCells", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batteryCapacity":
                if (sort.batteryCapacity) {
                    setlogReqBody({ ...logReqBody, sortColumns: "capacity", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "capacity", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "capacity", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "capacity", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "chargeId":
                if (sort.chargeId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargerId", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargerId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargerId", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargerId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "log":
                if (sort.log) {
                    setlogReqBody({ ...logReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "port":
                if (sort.port) {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargerPort", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargerPort", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargerPort", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargerPort", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startDate":
                if (sort.startDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startDate", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startDate", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startDate", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startDate", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "endDate":
                if (sort.endDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "endDate", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endDate", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "endDate", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endDate", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "chargeAhrs":
                if (sort.chargeAhrs) {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeAh", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargeAh", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeAh", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargeAh", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "chargeKwh":
                if (sort.chargeKwh) {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeKwh", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargeKwh", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeKwh", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargeKwh", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startTemp":
                if (sort.startTemp) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startTemp", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startTemp", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "endTemp":
                if (sort.endTemp) {
                    setlogReqBody({ ...logReqBody, sortColumns: "endTemp", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "endTemp", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startSoc":
                if (sort.startSoc) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startSoc", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startSoc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startSoc", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startSoc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "endSoc":
                if (sort.endSoc) {
                    setlogReqBody({ ...logReqBody, sortColumns: "endSoc", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endSoc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "endSoc", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endSoc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startVolts":
                if (sort.startVolts) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startVolts", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startVolts", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "endVolts":
                if (sort.endVolts) {
                    setlogReqBody({ ...logReqBody, sortColumns: "endVolts", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "endVolts", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "marCurr":
                if (sort.marCurr) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startCurrent", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startCurrent", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startCurrent", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startCurrent", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "endCurr":
                if (sort.endCurr) {
                    setlogReqBody({ ...logReqBody, sortColumns: "endCurrent", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endCurrent", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "endCurrent", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endCurrent", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "chargeType":
                if (sort.chargeType) {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeTypeDesc", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargeTypeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeTypeDesc", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "chargeTypeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startCode":
                if (sort.startCode) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startCodeDesc", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startCodeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startCodeDesc", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startCodeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "termCode":
                if (sort.termCode) {
                    setlogReqBody({ ...logReqBody, sortColumns: "terminationCodeDesc", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "terminationCodeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "terminationCodeDesc", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "terminationCodeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startUnbal":
                if (sort.startUnbal) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startUnbalanceVolts", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startUnbalanceVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startUnbalanceVolts", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "startUnbalanceVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "endUnbal":
                if (sort.endUnbal) {
                    setlogReqBody({ ...logReqBody, sortColumns: "endUnbalanceVolts", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endUnbalanceVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "endUnbalanceVolts", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "endUnbalanceVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "org":
                if (sort.org) {
                    setlogReqBody({ ...logReqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "div":
                if (sort.div) {
                    setlogReqBody({ ...logReqBody, sortColumns: "divisionName", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "divisionName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "divisionName", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "divisionName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "loc":
                if (sort.loc) {
                    setlogReqBody({ ...logReqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "site":
                if (sort.site) {
                    setlogReqBody({ ...logReqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
                    getRecords({ ...logReqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setCurrent(1)
        setPageSize(numPageSize)
        setlogReqBody({ ...logReqBody, pageOffset: 1, pageLimit: numPageSize })
        getRecords({ ...logReqBody, pageOffset: 1, pageLimit: numPageSize })
        setExportReqBody({ ...exportReqBody, pageOffset: 1, pageLimit: numPageSize })
    }

    const isLeapYear = (year: number) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    };

    const daysFilter = (startDate: string, endDate: string) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const startYear = start.getFullYear();
        const endYear = end.getFullYear();
        const isLeapYearAtStart = isLeapYear(startYear);
        const isLeapYearAtEnd = isLeapYear(endYear);
        const Difference_In_Time = end.getTime() - start.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
        if (Difference_In_Days > maxDays) {
            setModalMessage(`The maximum range allowed is 1 year.`);
            setIsModalVisible(true);
            return;
        }
        setlogReqBody({ ...logReqBody, startDate, endDate })
        getRecords({ ...logReqBody, startDate, endDate })
        getRecordCount({ ...logReqBody, startDate, endDate })
        setExportReqBody({ ...exportReqBody, startDate, endDate })
    };

    const handleModalClose = () => {
        onChange([subDays(new Date(), 6), new Date()])
        setIsModalVisible(false);
        setModalMessage('');
    };

    const dwnFormat = async (format: string) => {
        if (selectorLabel.some((item: any) => item.enabled == true)) {
            setIsShowSpinner(true)
            let userInfo: any = Utility.getUserInfo();
            let token = userInfo.authToken
            let payload = {
                orgId: logReqBody?.orgId,
                batteryId: logReqBody?.batteryId,
                vehicleId: logReqBody?.vehicleId,
                locationId: logReqBody?.locationId,
                buildingId: logReqBody?.buildingId,
                divisionId: logReqBody?.divisionId,
                batteryRxSim: logReqBody?.batteryRxSim,
                pageOffset: 1,
                pageLimit: count + 1,
                startDate: logReqBody?.startDate,
                endDate: logReqBody?.endDate,
                sortColumns: logReqBody?.sortColumns,
                sortDirection: logReqBody?.sortDirection,
                timeZone: logReqBody?.timeZone
            }
            try {
                const response = await service({
                    url: constantUrl.api.chargeLog.dwn + '/' + format,
                    method: 'POST',
                    responseType: 'blob',
                    headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                    data: payload
                })
                if (response) {
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'ChargeLog.' + format;
                    a.click();
                    setIsShowSpinner(false)
                }
            } catch (error) {
                console.log(error)
                setIsShowSpinner(false)
            }
        }
        else {
            setAlert(true)
        }
    }

    const formattedDate = (date: string) => {
        const date1 = date;
        const parsedDate = parseISO(date1);
        const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
        return formatDate
    }

    const selectColumn = () => {
        if (selectorRef.current && selector) {
            selectorRef.current.style.right = "-370px";
        } else {
            if (selectorRef.current) { selectorRef.current.style.right = "0px" }
        }
        setSelector(!selector);
    };

    const applyChanges = async () => {
        selectColumn()
        var columnPayload = {
            reportId: 'CHARGE_LOG_REPORT',
            reportName: 'CHARGE_LOG_REPORT',
            columnSettings: selectorLabel,
            sortColumn: logReqBody?.sortColumns,
            sortDirection: logReqBody?.sortDirection
        };
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        setIsShowSpinner(true);
        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}`,
                method: "PUT",
                headers: { Authorization: "Bearer " + authToken },
                data: columnPayload
            })
            setIsShowSpinner(false)
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn([...columnData])
            setSelectorLabel([...selectorData])
        }
        catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const onchangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
        let columndata: any = selectorLabel
        let index: number = parseInt(event.target.name);
        let checked: boolean = event.target.checked;
        columndata[index].enabled = checked
        setSelectorLabel([...columndata])
        setClearAll(false)
        setSelectAll(false)
    };

    const clearall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = false;
        }
        setSelectorLabel([...columnLable])
        setClearAll(true)
        setSelectAll(false)
    };

    const selectall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = true;
        }
        setSelectorLabel(columnLable)
        setClearAll(false)
        setSelectAll(true)
    }

    const cancelSelection = () => {
        let columnData = selectorLabel;
        for (let i = 0; i < columnData.length; i++) {
            columnData[i]['enabled'] = columnData[i].enabled;
        }
        setSelectorLabel(columnData)
        selectColumn()
    };

    const getReportSettings = async () => {
        const reportId = 'CHARGE_LOG_REPORT'
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let org = data?.orgId > -1 ? data?.orgId : userId;

        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            })
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn(columnData)
            setSelectorLabel(selectorData)

            setlogReqBody({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            getRecords({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            getRecordCount({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setExportReqBody({ ...exportReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setInitial(false)
        }
        catch (error) {
            console.log(error)
        }
    };

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner><>
                <div ref={filtersRef} className="posi_search_container">
                    <div className="col-md-6 px-0 drop_search-main">
                        <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                            <div className='ant-design-select'>
                                <Select
                                    value={dropdownvalue}
                                    size={'middle'}
                                    onChange={onChangedropdown}
                                    className='drop-filter'
                                    dropdownStyle={{ zIndex: 900 }}
                                >
                                    {DropList && DropList.map((item: any, index: any) => {
                                        return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                                    })}
                                </Select>
                            </div>
                            <div>
                                <Space direction="vertical" size="middle" className='search-input'>
                                    <Space.Compact className='posi_bmid_search_width'>
                                        <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                                            onPressEnter={onPressEnter} />
                                        <Button type="primary" onClick={handleSearch}>Find</Button>
                                    </Space.Compact>
                                </Space>
                                <div className="errorColor">{emptyError}</div>
                            </div>
                        </ConfigProvider>
                    </div>
                    <div className="column-date-filter position-relative">
                        {
                            showDateFilter && <span className="date-selector">
                                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                            </span>
                        }
                        <button
                            onClick={selectColumn}
                            type="button"
                            className="btn btn-light selector-btn"
                        >
                            Column Selector
                        </button>
                        <span className="date-dropdown">
                            <DaysFilter
                                daysFilter={daysFilter}
                                customFilterStatusChange={setShowDateFilter}
                                dateOptions={dateValue}
                                defaultDateLabel={'Last 7 Days'}
                            />
                        </span>
                        <Modal
                            className="update-users"
                            title="Date Range Error"
                            centered
                            open={isModalVisible}
                            onOk={handleModalClose}
                            footer={[
                                <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                                    OK
                                </Button>,
                            ]}
                            closable={false}
                        >
                            <p>{modalMessage}</p>
                        </Modal>
                    </div>
                </div>
                <div className="pt-3 position-relative">
                    <div className="main-panel">
                        <div className="ChartPanel panelShadow bg-white ">
                            <div className="chartTitle p-2 d-flex  align-items-center ">
                                <div className='table_title'>Charge Log Report</div>
                                <div className='show-entries'>
                                    <ShowEntries getPageSize={onSelect} />
                                </div>
                            </div>
                            <div id="table-container">
                                <div style={{ height: tableHeight + 'px' }} ref={tabelRef} className="charge-log-tableWrappe overflow-auto scrollbar">
                                    <table className="billable width100">
                                        <thead className='tableHeader'>
                                            <tr>
                                                {column?.[0]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[0]?.['columnName']}</span>
                                                        {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th>
                                                    : ""}
                                                {column?.[1]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[1]?.['columnName']}</span>
                                                        {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[2]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('batteryRxSim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[2]?.['columnName']}</span>
                                                        {sort.batteryRxSim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[3]?.['enabled'] ? <th><span>{column?.[3]?.['columnName']}</span></th> : ""}
                                                {column?.[4]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('totalCells')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[4]?.['columnName']}</span>
                                                        {sort.totalCells ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[5]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('batteryCapacity')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[5]?.['columnName']}</span>
                                                        {sort.batteryCapacity ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[6]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('log')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[6]?.['columnName']}</span>
                                                        {sort.log ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[7]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('chargeId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[7]?.['columnName']}</span>
                                                        {sort.chargeId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[8]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('port')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[8]?.['columnName']}</span>
                                                        {sort.port ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[9]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('startDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[9]?.['columnName']}</span>
                                                        {sort.startDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[10]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('endDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[10]?.['columnName']}</span>
                                                        {sort.endDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[11]?.['enabled'] ? <th><span>{column?.[11]?.['columnName']}</span></th> : ""}
                                                {column?.[12]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('chargeAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[12]?.['columnName']}</span>
                                                        {sort.chargeAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[13]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('chargeKwh')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[13]?.['columnName']}</span>
                                                        {sort.chargeKwh ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[14]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('startTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[14]?.['columnName']}</span>
                                                        {sort.startTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[15]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('endTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[15]?.['columnName']}</span>
                                                        {sort.endTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[16]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('startSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[16]?.['columnName']}</span>
                                                        {sort.startSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[17]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('endSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[17]?.['columnName']}</span>
                                                        {sort.endSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[18]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('startVolts')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[18]?.['columnName']}</span>
                                                        {sort.startVolts ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[19]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('endVolts')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[19]?.['columnName']}</span>
                                                        {sort.endVolts ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[20]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('marCurr')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[20]?.['columnName']}</span>
                                                        {sort.marCurr ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[21]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('endCurr')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[21]?.['columnName']}</span>
                                                        {sort.endCurr ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[22]?.['enabled'] ? <th><span>{column?.[22]?.['columnName']}</span></th> : ""}
                                                {column?.[23]?.['enabled'] ? <th><span>{column?.[23]?.['columnName']}</span></th> : ""}
                                                {column?.[24]?.['enabled'] ? <th><span>{column?.[24]?.['columnName']}</span></th> : ""}
                                                {column?.[25]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('startUnbal')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[25]?.['columnName']}</span>
                                                        {sort.startUnbal ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[26]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('endUnbal')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[26]['columnName']}</span>
                                                        {sort.endUnbal ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""}
                                                {column?.[27]?.['enabled'] ? <th><span>{column?.[27]?.['columnName']}</span></th> : ""}
                                                {column?.[28]?.['enabled'] ? <th><span> {column?.[28]?.['columnName']}</span></th> : ""}
                                                {column?.[29]?.['enabled'] ? <th><span>{column?.[29]?.['columnName']}</span></th> : ""}
                                                {column?.[30]?.['enabled'] ? <th><span>{column?.[30]?.['columnName']}</span></th> : ""}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                chargeLog && chargeLog.map((row: any, index: any) => {
                                                    return (
                                                        <tr key={index}>
                                                            {column?.[0]?.['enabled'] ? <td>{row?.batteryId}</td> : ""}
                                                            {column?.[1]?.['enabled'] ? <td>{row?.vehicleId}</td> : ""}
                                                            {column?.[2]?.['enabled'] ? <td>{row?.batteryRxSerialNumber}</td> : ""}
                                                            {column?.[3]?.['enabled'] ? <td>{row?.batteryTypeDesc}</td> : ""}
                                                            {column?.[4]?.['enabled'] ? <td>{row?.totalCells}</td> : ""}
                                                            {column?.[5]?.['enabled'] ? <td>{row?.capacity}</td> : ""}
                                                            {column?.[6]?.['enabled'] ? <td>{row?.logNumber}</td> : ""}
                                                            {column?.[7]?.['enabled'] ? <td>{row?.chargerId}</td> : ""}
                                                            {column?.[8]?.['enabled'] ? <td>{row?.chargerPort}</td> : ""}
                                                            {column?.[9]?.['enabled'] ? <td>{row?.formatStartDate}</td> : ""}
                                                            {column?.[10]?.['enabled'] ? <td>{row?.formatEndDate}</td> : ""}
                                                            {column?.[11]?.['enabled'] ? <td>{row?.duration}</td> : ""}
                                                            {column?.[12]?.['enabled'] ? <td>{row?.chargeAh}</td> : ""}
                                                            {column?.[13]?.['enabled'] ? <td>{row?.chargeKwh}</td> : ""}
                                                            {column?.[14]?.['enabled'] ? <td>{row?.formatStartTemp}</td> : ""}
                                                            {column?.[15]?.['enabled'] ? <td>{row?.formatEndTemp}</td> : ""}
                                                            {column?.[16]?.['enabled'] ? <td>{row?.startSoc}</td> : ""}
                                                            {column?.[17]?.['enabled'] ? <td>{row?.endSoc}</td> : ""}
                                                            {column?.[18]?.['enabled'] ? <td>{row?.startVolts}</td> : ""}
                                                            {column?.[19]?.['enabled'] ? <td>{row?.endVolts}</td> : ""}
                                                            {column?.[20]?.['enabled'] ? <td>{row?.startCurrent}</td> : ""}
                                                            {column?.[21]?.['enabled'] ? <td>{row?.endCurrent}</td> : ""}
                                                            {column?.[22]?.['enabled'] ? <td>{row?.chargeTypeDesc}</td> : ""}
                                                            {column?.[23]?.['enabled'] ? <td>{row?.startCodeDesc}</td> : ""}
                                                            {column?.[24]?.['enabled'] ? <td>{row?.terminationCodeDesc}</td> : ""}
                                                            {column?.[25]?.['enabled'] ? <td>{row?.startUnbalanceVolts}</td> : ""}
                                                            {column?.[26]?.['enabled'] ? <td>{row?.endUnbalanceVolts}</td> : ""}
                                                            {column?.[27]?.['enabled'] ? <td>{row?.orgName}</td> : ""}
                                                            {column?.[28]?.['enabled'] ? <td>{row?.divisionName}</td> : ""}
                                                            {column?.[29]?.['enabled'] ? <td>{row?.locationName}</td> : ""}
                                                            {column?.[30]?.['enabled'] ? <td>{row?.buildingName}</td> : ""}
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {chargeLog?.length === 0 && <tr><td colSpan={column.filter((item: any) => item?.['enabled'] === true)?.length} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div ref={paginationRef}>
                                    <PaginationComponent
                                        handlePagination={handlePagination}
                                        totalLength={count}
                                        pageSize={pageSize}
                                        current={current}
                                    />
                                </div>
                                <div ref={selectorRef} className="column-selector selector">
                                    <div className="column-title">Column Selector</div>
                                    <div className="slector-body">
                                        <label>
                                            <input
                                                checked={clearAll}
                                                value="clearAll"
                                                type="checkbox"
                                                aria-label="checkbox"
                                                onChange={clearall}
                                            />
                                            <span></span>
                                        </label>
                                        <p>Clear All</p>
                                        <label>
                                            <input
                                                checked={selectAll}
                                                value="selectAll"
                                                type="checkbox"
                                                aria-label="checkbox"
                                                onChange={selectall}
                                            />
                                            <span></span>
                                        </label>
                                        <p>Select All</p>
                                    </div>
                                    <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
                                        {selectorLabel?.map((item: any, index: any) => {
                                            return (
                                                <div className="slector-body">
                                                    <label>
                                                        <input
                                                            name={index}
                                                            onChange={onchangeColumn}
                                                            checked={item['enabled']}
                                                            value={item.value}
                                                            type="checkbox"
                                                            aria-label="checkbox"
                                                        />
                                                        <span></span>
                                                    </label>
                                                    <p>{item['columnName']}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="m-flex">
                                        <button onClick={cancelSelection} className="cancel">
                                            Cancel
                                        </button>
                                        <button onClick={applyChanges} className="apply-changes mr-3">
                                            Apply Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <Modal
                open={alert}
                closable={false}
                centered title={"Atleast Select 1 Column"}
                footer={[
                    <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
                ]}
            ></Modal>
            <SessionWarning />
        </>
    )
}

export default ChargeLogChart;