import React,{FC} from 'react'
import { Select, ConfigProvider } from 'antd';
import { format,subDays} from 'date-fns'

interface Props{
  daysFilter:(startdate:string,enddate:string,value:string)=> void
  customFilterStatusChange:(status: boolean) => void
  dateOptions:any
  defaultDateLabel:string
}

const SelectDays:FC<Props> = ({daysFilter, customFilterStatusChange,dateOptions, defaultDateLabel}) => {

    const handleChange = (value:string)=>{
        let today = new Date()
        if(value==="Last 7 Days"){
            const lastSevenDaysAgo = format(subDays(today,6),'dd-MMM-yyyy');
            const currentDate = format(today,'dd-MMM-yyyy')
            customFilterStatusChange(false)
            daysFilter(lastSevenDaysAgo,currentDate,value)
 
            return
         }else  if(value==="Last 14 Days"){
             const lastFourteenDaysAgo = format(subDays(today,13),'dd-MMM-yyyy') 
             const currentDate = format(today,'dd-MMM-yyyy') 
             customFilterStatusChange(false)
             daysFilter(lastFourteenDaysAgo,currentDate,value)
 
             return
         }else if(value ==='Last 30 Days'){
             const lastThirtyDaysAgo = format(subDays(today,29),'dd-MMM-yyyy') 
             const currentDate = format(today,'dd-MMM-yyyy')
             customFilterStatusChange(false) 
             daysFilter(lastThirtyDaysAgo,currentDate,value)
 
             return
         }else if(value ==='Last 60 Days'){
             const lastSixtyDaysAgo = format(subDays(today,59),'dd-MMM-yyyy') 
             const currentDate = format(today,'dd-MMM-yyyy') 
             customFilterStatusChange(false)
             daysFilter(lastSixtyDaysAgo,currentDate,value)
 
             return
         }else if(value === "Last 90 Days"){
             const last90Days = format(subDays(today, 89),'dd-MMM-yyyy');
             customFilterStatusChange(false)
             daysFilter(last90Days,format(today,'dd-MMM-yyyy'),value)
 
             return
        }else if(value === "Custom Date Range"){
            customFilterStatusChange(true)
        }
    }

    return (
        <>
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                <Select
                    defaultValue={defaultDateLabel} 
                    size={'small'}
                    style={{ width: 160 }}
                    dropdownStyle={{ zIndex: 900 }}
                    onChange={handleChange}
                    options={dateOptions}>
                </Select>
            </ConfigProvider>
        </>
    )
}

export default SelectDays;