import React from "react";
import { Link } from "react-router-dom";
import log from "../images/PosiChargeLogo.svg";
import Ampure from "../images/Ampure234x50.jpg";
import searchIcon from "../images/Search_icon.svg";
import shopIcon from "../images/shop_icon.svg";
import posiChargeIcon from "../images/PosiChargeLogo.svg";
import { ConvertUtility, constants, Utility } from "../helper/index";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import constantUrl from "../settings/constants";
import service from "../service/api/common";
import { Popover } from "antd";

interface MyState {
  todayDate: string;
  dashboard: string;
  userName: string;
  isRedirectLogin: boolean;
  age?: number;
  open: boolean;
  popoverOpen: boolean;
  user: any;
  subMenu1: boolean;
  subMenu2: boolean;
  subMenu3: boolean;
  subMenu4: boolean;
  subMenu5: boolean;
  subMenu6: boolean;
  subMenu7: boolean;
  subMenu8: boolean;
  subMenu9: boolean;
  subMenu10: boolean;
  subMenu11: boolean;
  adminDashBoard_W: boolean;
  dealerDashBoard_W: boolean;
  companyDashBoard_W: boolean;
  siteDashBoard_W: boolean;
  locationDashBoard_W: boolean;
  reportController_W: boolean;
  userMaintenanceR_W: boolean;
  organizationMaintenanceR_W: boolean;
  locationMaintenanceR_W: boolean;
  divisionMaintenanceR_W: boolean;
  siteMaintenanceR_W: boolean;
  BbmidInventory_W: boolean;
  accountgeneral_W: boolean;
  Account_Profile_W: boolean;
  Issues_report1_W: boolean;
  eq_log_report_W: boolean;
  OEMeterLog_R: boolean;
  charge_Log_Report_W: boolean;
  battery_history_logs_W: boolean;
  permissions: any;
  NOBMID: string;
  BMID: string;
}

export default class PosinetHeader extends React.Component<any, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      todayDate: format(new Date(), "EEEE, MMMM  d"),
      userName: "",
      dashboard: "admin dashboard",
      isRedirectLogin: false,
      open: false,
      popoverOpen: false,
      user: {},
      subMenu1: false,
      subMenu2: false,
      subMenu3: false,
      subMenu4: false,
      subMenu5: false,
      subMenu6: false,
      subMenu7: false,
      subMenu8: false,
      subMenu9: false,
      subMenu10: false,
      subMenu11: false,
      adminDashBoard_W: false,
      dealerDashBoard_W: false,
      companyDashBoard_W: false,
      siteDashBoard_W: false,
      locationDashBoard_W: false,
      reportController_W: false,
      userMaintenanceR_W: false,
      organizationMaintenanceR_W: false,
      locationMaintenanceR_W: false,
      divisionMaintenanceR_W: false,
      siteMaintenanceR_W: false,
      BbmidInventory_W: false,
      accountgeneral_W: false,
      Account_Profile_W: false,
      Issues_report1_W: false,
      eq_log_report_W: false,
      OEMeterLog_R: false,
      charge_Log_Report_W: false,
      battery_history_logs_W: false,
      permissions: [],
      NOBMID: "NOBMID",
      BMID: "BMID",
    };
    this.showSubMenu = this.showSubMenu.bind(this);
    this.clickSubMenu = this.clickSubMenu.bind(this);
  }

  componentDidMount(): void {
    var boddyrapper: any = document.querySelector(".page-body-wrapper");
    let height: any = document.getElementById("posinet-header")?.offsetHeight;
    localStorage.setItem("navHeight", height);
    if (boddyrapper != null && height) {
      boddyrapper.style.marginTop = height + 15 + "px";
    }
    if (Utility.hasUserEmpty()) {
      this.setState({ isRedirectLogin: true });
    } else {
      let userInfo: any = Utility.getUserInfo();
      var token = userInfo.authToken;
      this.setState({ userName: userInfo.userName });
    }
    (async () => {
      try {
        const response = await service({
          url: constantUrl.api.userRole.getRoleAccess,
          method: "GET",
          headers: { accept: "*/*", Authorization: "Bearer " + token },
        });
        let permissionData = response.roleAccess;
        this.setState({ permissions: permissionData });
        localStorage.setItem("permissions", response?.roleAccess);
        let dealer_permission = permissionData.includes("dealerDashBoard_W");
        let company_permission = permissionData.includes("companyDashBoard_W");
        let location_permission = permissionData.includes(
          "locationDashBoard_W"
        );
        let site_permission = permissionData.includes("siteDashBoard_W");
        let report_permission = permissionData.includes("report-controller_W");
        let admin_permission = permissionData.includes("adminDashBoard_W");
        let userMaintenanceR_W = permissionData.includes("userMaintenanceR_W");
        let organizationMaintenanceR_W = permissionData.includes(
          "organizationMaintenanceR_W"
        );
        let locationMaintenanceR_W = permissionData.includes(
          "locationMaintenanceR_W"
        );
        let divisionMaintenanceR_W = permissionData.includes(
          "divisionMaintenanceR_W"
        );
        let siteMaintenanceR_W = permissionData.includes("siteMaintenanceR_W");
        let BbmidInventory_W = permissionData.includes("BbmidInventory_W");
        let accountgeneral_W = permissionData.includes("accountgeneral_W");
        let Account_Profile_W = permissionData.includes("Account_Profile_W");
        let Issues_report1_W = permissionData.includes("Issues_report1_W");
        let eq_log_report_W = permissionData.includes("eq_log_report_W");
        let OEMeterLog_R = permissionData.includes("OEMeterLog_R");
        let battery_history_logs_W = permissionData.includes(
          "battery_history_logs_W"
        );
        let charge_Log_Report_W = permissionData.includes(
          "charge_Log_Report_W"
        );
        this.setState({
          battery_history_logs_W,
          charge_Log_Report_W,
          dealerDashBoard_W: dealer_permission,
          companyDashBoard_W: company_permission,
          locationDashBoard_W: location_permission,
          siteDashBoard_W: site_permission,
          reportController_W: report_permission,
          adminDashBoard_W: admin_permission,
          userMaintenanceR_W,
          eq_log_report_W,
          OEMeterLog_R,
          organizationMaintenanceR_W,
          locationMaintenanceR_W,
          divisionMaintenanceR_W,
          siteMaintenanceR_W,
          BbmidInventory_W: BbmidInventory_W,
          accountgeneral_W: accountgeneral_W,
          Account_Profile_W: Account_Profile_W,
          Issues_report1_W: Issues_report1_W,
        });
      } catch (error) {
        console.log("getOrg:", error);
      }
    })();
  }

  logOut = () => {
    sessionStorage.removeItem(constants.sessionDetails.userInfo);
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
    window.location.href = "/login";
  };

  hide = () => {
    this.setState({ open: false });
  };

  handleOpenChange = (newOpen: boolean) => {
    this.setState({ open: newOpen });
  };

  handleOpenChangeMoblie = (value: boolean) => {
    this.setState({ popoverOpen: value });
  };

  showSubMenu(event: React.MouseEvent<HTMLInputElement>) {
    let Id = event.currentTarget.id;
    if (Id === "menu1") {
      this.setState({
        subMenu1: !this.state.subMenu1,
        subMenu2: false,
        subMenu3: false,
        subMenu4: false,
        subMenu5: false,
        subMenu6: false,
        subMenu7: false,
        subMenu8: false,
        subMenu9: false,
        subMenu10: false,
        subMenu11: false,
      });
      return;
    } else if (Id === "menu2") {
      this.setState({
        subMenu1: false,
        subMenu2: !this.state.subMenu2,
        subMenu3: false,
        subMenu4: false,
        subMenu5: false,
        subMenu6: false,
        subMenu7: false,
        subMenu8: false,
        subMenu9: false,
        subMenu10: false,
        subMenu11: false,
      });
      return;
    } else if (Id === "menu3") {
      this.setState({
        subMenu1: false,
        subMenu2: false,
        subMenu3: !this.state.subMenu3,
        subMenu4: false,
        subMenu5: false,
        subMenu6: false,
        subMenu7: false,
        subMenu8: false,
        subMenu9: false,
        subMenu10: false,
      });
    } else if (Id === "menu4") {
      this.setState({
        subMenu1: false,
        subMenu2: false,
        subMenu3: false,
        subMenu4: !this.state.subMenu4,
        subMenu5: false,
        subMenu6: false,
        subMenu7: false,
        subMenu8: false,
        subMenu9: false,
        subMenu10: false,
      });
    } else if (Id === "menu5") {
      this.setState({
        subMenu1: false,
        subMenu2: false,
        subMenu3: false,
        subMenu4: false,
        subMenu5: !this.state.subMenu5,
        subMenu6: false,
        subMenu7: false,
        subMenu8: false,
        subMenu9: false,
        subMenu10: false,
      });
    } else if (Id === "menu6") {
      this.setState({
        subMenu1: false,
        subMenu2: false,
        subMenu3: false,
        subMenu4: false,
        subMenu5: false,
        subMenu6: !this.state.subMenu6,
        subMenu7: false,
        subMenu8: false,
        subMenu9: false,
        subMenu10: false,
      });
    } else if (Id === "menu7") {
      this.setState({
        subMenu1: false,
        subMenu2: false,
        subMenu3: false,
        subMenu4: false,
        subMenu5: false,
        subMenu6: false,
        subMenu7: !this.state.subMenu7,
        subMenu8: false,
        subMenu9: false,
        subMenu10: false,
        subMenu11: false,
      });
    } else if (Id === "menu8") {
      this.setState({
        subMenu1: false,
        subMenu2: false,
        subMenu3: false,
        subMenu4: false,
        subMenu5: false,
        subMenu6: false,
        subMenu7: false,
        subMenu8: !this.state.subMenu8,
        subMenu9: false,
        subMenu10: false,
        subMenu11: false,
      });
    } else if (Id === "menu9") {
      this.setState({
        subMenu1: false,
        subMenu2: false,
        subMenu3: false,
        subMenu4: false,
        subMenu5: false,
        subMenu6: false,
        subMenu7: false,
        subMenu8: false,
        subMenu9: !this.state.subMenu9,
        subMenu10: false,
        subMenu11: false,
      });
    } else if (Id === "menu10") {
      this.setState({
        subMenu1: false,
        subMenu2: false,
        subMenu3: false,
        subMenu4: false,
        subMenu5: false,
        subMenu6: false,
        subMenu7: false,
        subMenu8: false,
        subMenu9: false,
        subMenu10: !this.state.subMenu10,
        subMenu11: false,
      });
    }
  }

  hideSidebar = () => {
    const nav: any = document.querySelector(".nav-container");

    if (nav.classList.contains("is-active")) {
      nav.classList.remove("is-active");
    } else {
      nav.classList.add("is-active");
    }
  };

  toggleSidebar = () => {
    const nav: any = document.querySelector(".nav-container");

    if (nav.classList.contains("is-active")) {
      nav.classList.remove("is-active");
    } else {
      nav.classList.add("is-active");
    }
  };

  clickSubMenu = (value: string) => {
    localStorage.setItem("menuId", value);
    this.props.getSideMenu(value);
  };

  render() {
    const {
      subMenu1,
      subMenu4,
      subMenu5,
      subMenu6,
      subMenu7,
      subMenu8,
      subMenu10,
      dealerDashBoard_W,
      NOBMID,
      BMID,
    } = this.state;

    return (
      <nav
        className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row topnavBar border-bottom bg-white"
        id="posinet-header"
        style={{ zIndex: 1000 }}
      >
        <div className="d-flex w-100 align-middle">
          <div className="nav-container">
            <div
              className="nav-toggle layout-sidenav-toggle sidenav-a text-large pl-2 py-4"
              onClick={this.toggleSidebar}
            ></div>

            <nav className="sidebar nav-items">
              <div>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <img src={Ampure} alt="" style={{ marginLeft: "30px" }}></img>
                </a>
              </div>
              <div className="menuItemScroll1 scrollable-container">
                <ul id="sidebar_menu">
                  <li>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={subMenu1 ? "active" : "unactive"}
                      aria-expanded="true"
                    >
                      <span
                        onClick={this.showSubMenu}
                        className="flexJustify"
                        id="menu1"
                      >
                        <span>Dashboard</span>{" "}
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <ul
                      className={subMenu1 ? "sub-menu open" : "sub-menu"}
                      id="sidebar_menu"
                    >
                      {this.state.permissions?.includes("adminDashBoard_W") ? (
                        <li>
                          <Link
                            id="menu1"
                            className="p-0"
                            to={"/admindashboard"}
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Admin Dashboard
                          </Link>{" "}
                        </li>
                      ) : (
                        <></>
                      )}
                      {this.state.permissions?.includes("dealerDashBoard_W") ? (
                        <li>
                          <Link
                            id="menu1"
                            state={{ dealerDashBoard_W }}
                            className="p-0"
                            to={"/dealerdashboard"}
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Dealer Dashboard
                          </Link>{" "}
                        </li>
                      ) : (
                        <></>
                      )}
                      {this.state.permissions?.includes(
                        "companyDashBoard_W"
                      ) ? (
                        <li>
                          <Link
                            id="menu1"
                            className="p-0"
                            to="/companydashboard"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Company Dashboard
                          </Link>{" "}
                        </li>
                      ) : (
                        <></>
                      )}
                      {this.state.permissions?.includes(
                        "locationDashBoard_W"
                      ) ? (
                        <li>
                          <Link
                            id="menu1"
                            className="p-0"
                            to="/locationdashboard"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            {" "}
                            Location Dashboard
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {this.state.permissions?.includes("siteDashBoard_W") ? (
                        <li>
                          <Link
                            id="menu1"
                            className="p-0"
                            to="/sitedashboard"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Site Dashboard
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </li>
                  <li>
                    {this.state.permissions?.includes("BbmidInventory_W") ? (
                      <Link to="/bmidInventory" aria-expanded="false">
                        <span
                          onClick={(e: any) => {
                            this.hideSidebar();
                            this.showSubMenu(e);
                            this.clickSubMenu(BMID);
                          }}
                          className="flexJustify"
                          id="menu2"
                        >
                          <span>Battery Management</span>{" "}
                        </span>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </li>
                  <li>
                    <Link to="/operationStatusHighTemp" aria-expanded="false">
                      <span
                        onClick={(e: any) => {
                          this.hideSidebar();
                          this.showSubMenu(e);
                          this.clickSubMenu(NOBMID);
                        }}
                        className="flexJustify"
                        id="menu3"
                      >
                        Operation Status
                      </span>
                    </Link>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={subMenu4 ? "active" : "unactive"}
                      aria-expanded="false"
                    >
                      <span
                        onClick={this.showSubMenu}
                        className="flexJustify"
                        id="menu4"
                      >
                        {" "}
                        <span>Alerts</span>{" "}
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <ul
                      className={subMenu4 ? "sub-menu open" : "sub-menu"}
                      id="sidebar_menu"
                    >
                      {this.state.permissions?.includes("Issues_report1_W") ? (
                        <li>
                          <Link
                            to="/issues-report"
                            className="p-0"
                            aria-expanded="false"
                            id="menu4"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Weekly Issues
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={subMenu5 ? "active" : "unactive"}
                      aria-expanded="false"
                    >
                      <span
                        onClick={this.showSubMenu}
                        className="flexJustify"
                        id="menu5"
                      >
                        {" "}
                        <span>Location Management</span>{" "}
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <ul
                      className={subMenu5 ? "sub-menu open" : "sub-menu"}
                      id="sidebar_menu"
                    >
                      {this.state.permissions?.includes(
                        "locationMaintenanceR_W"
                      ) ? (
                        <li>
                          <Link
                            className="p-0"
                            to={"/locationmaintenance"}
                            id="menu5"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Locations List
                          </Link>{" "}
                        </li>
                      ) : (
                        <></>
                      )}
                      <li>
                        <Link
                          to="/billing-summary-report"
                          className="p-0"
                          aria-expanded="false"
                          id="menu5"
                          onClick={(e: any) => {
                            this.hideSidebar();
                            this.showSubMenu(e);
                            this.clickSubMenu(NOBMID)
                          }}
                        >
                          Billabale Report
                        </Link>
                      </li>
                      {this.state.permissions?.includes(
                        "siteMaintenanceR_W"
                      ) ? (
                        <li>
                          <Link
                            className="p-0"
                            to={"/sitemaintenance"}
                            id="menu5"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Sites List
                          </Link>{" "}
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </li>
                  {this.state.permissions?.includes(
                    "organizationMaintenanceR_W"
                  ) ? (
                    <li>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className={subMenu8 ? "active" : "unactive"}
                        aria-expanded="false"
                      >
                        <span
                          onClick={this.showSubMenu}
                          className="flexJustify"
                          id="menu8"
                        >
                          {" "}
                          <span>Organization Management</span>{" "}
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </a>
                      <ul
                        className={subMenu8 ? "sub-menu open" : "sub-menu"}
                        id="sidebar_menu"
                      >
                        <li onClick={(e) => e.preventDefault()}>
                          <Link
                            to="/organizationmaintenance"
                            className="p-0"
                            aria-expanded="false"
                            id="menu8"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Organizations List
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <></>
                  )}
                  {this.state.permissions?.includes(
                    "divisionMaintenanceR_W"
                  ) ? (
                    <li>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className={subMenu10 ? "active" : "unactive"}
                        aria-expanded="false"
                      >
                        <span
                          onClick={this.showSubMenu}
                          className="flexJustify"
                          id="menu10"
                        >
                          {" "}
                          <span>Division Management</span>{" "}
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </a>
                      <ul
                        className={subMenu10 ? "sub-menu open" : "sub-menu"}
                        id="sidebar_menu"
                      >
                        <li onClick={(e) => e.preventDefault()}>
                          <Link
                            to="/divisionmaintenance"
                            className="p-0"
                            aria-expanded="false"
                            id="menu10"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Division List
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={subMenu6 ? "active" : "unactive"}
                      aria-expanded="false"
                    >
                      <span
                        onClick={this.showSubMenu}
                        className="flexJustify"
                        id="menu6"
                      >
                        {" "}
                        <span>User Management</span>{" "}
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <ul
                      className={subMenu6 ? "sub-menu open" : "sub-menu"}
                      id="sidebar_menu"
                    >
                      {this.state.permissions?.includes("Account_Profile_W") ? (
                        <li>
                          <Link
                            to="/user-account-management"
                            className="p-0"
                            aria-expanded="false"
                            id="menu6"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Account Management
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {this.state.permissions?.includes(
                        "userMaintenanceR_W"
                      ) ? (
                        <li>
                          <Link
                            to="/usermaintenance"
                            className="p-0"
                            aria-expanded="false"
                            id="menu6"
                            onClick={(e: any) => {
                              this.hideSidebar();
                              this.showSubMenu(e);
                              this.clickSubMenu(NOBMID)
                            }}
                          >
                            Users List
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={subMenu7 ? "active" : "unactive"}
                      aria-expanded="false"
                    >
                      <span
                        onClick={this.showSubMenu}
                        className="flexJustify"
                        id="menu7"
                      >
                        <span>Reporting Management</span>{" "}
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                    <ul
                      id="sidebar_menu"
                      className={subMenu7 ? "sub-menu open" : "sub-menu"}
                    >
                      <li>
                        <Link
                          to="/reports"
                          className="p-0"
                          aria-expanded="false"
                          id="menu7"
                          onClick={(e: any) => {
                            this.hideSidebar();
                            this.showSubMenu(e);
                            this.clickSubMenu(NOBMID);
                          }}
                        >
                          Reports
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/logs"
                          className="p-0"
                          aria-expanded="false"
                          id="menu7"
                          onClick={(e: any) => {
                            this.hideSidebar();
                            this.showSubMenu(e);
                            this.clickSubMenu(NOBMID);
                          }}
                        >
                          Logs
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#" aria-expanded="false">
                      <span
                        className="flexJustify"
                        id="menu9"
                        onClick={(e: any) => {
                          this.hideSidebar();
                          this.showSubMenu(e);
                        }}
                      >
                        <span>Dealer Portal</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <div className="top-nav date_hide_mobile">
            <div className="top-nav-heading">
              <span>{this.state.todayDate}</span>
            </div>
            <div className="ml-auto my-auto">
              <div className="br-header-right">
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item border-0 p-0">
                    <a href="" className="nav-a right-nav-heading pos-relative">
                      <img
                        src={searchIcon}
                        style={{ width: 22, height: 22 }}
                        alt=""
                      />
                    </a>
                  </li>
                  <li className="list-group-item border-0 p-0">
                    <a
                      href="https://store.posicharge.com/bus/login.aspx?Target=storefront.aspx"
                      target="_blank"
                      className="nav-a right-nav-heading pos-relative"
                    >
                      <img
                        src={shopIcon}
                        style={{ width: 22, height: 22 }}
                        alt=""
                      />
                    </a>
                  </li>
                  <li className="list-group-item border-0 p-0">
                    {!ConvertUtility.isNullOrEmpty(this.state.userName) && (
                      <span
                        id="UserLoginDetail"
                        className="nav-a right-nav-heading pos-relative"
                        style={{ color: "#004f9f" }}
                      >
                        Hello, {localStorage?.firstName}{" "}
                        {localStorage?.lastName}
                        <Popover
                          arrow={false}
                          style={{ left: 0, right: 15, top: 90, minWidth: 300 }}
                          placement="bottomRight"
                          content={
                            <div onClick={this.hide}>
                              <div className="user-info">
                                <div className="p-2">
                                  {localStorage?.firstName}&nbsp;
                                  {localStorage?.lastName}
                                </div>
                                <div className="p-2">{localStorage?.email}</div>
                                <NavLink
                                  to="/updatepassword"
                                  className="p-2 change-password d-block"
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                >
                                  Change Password
                                </NavLink>
                                <div
                                  className="p-2 log-out"
                                  onClick={this.logOut}
                                >
                                  Log Out
                                </div>
                              </div>
                            </div>
                          }
                          trigger="click"
                          open={this.state.open}
                          onOpenChange={this.handleOpenChange}
                        >
                          <i
                            className="fa fa-user-circle fontSize22 pl-2"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </Popover>
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="user-avatar-mobile">
            {!ConvertUtility.isNullOrEmpty(this.state.userName) && (
              <span
                id="UserLoginDetail"
                className="nav-a right-nav-heading pos-relative"
                style={{ color: "#004f9f" }}
              >
                <Popover
                  arrow={false}
                  style={{ left: 0, right: 15, top: 90, minWidth: 300 }}
                  placement="bottomRight"
                  content={
                    <div onClick={this.hide}>
                      <div className="user-info">
                        <div className="p-2">
                          {localStorage?.firstName}&nbsp;
                          {localStorage?.lastName}
                        </div>
                        <div className="p-2">{localStorage?.email}</div>
                        <NavLink
                          to="/updatepassword"
                          className="p-2 change-password d-block"
                        >
                          Change Password
                        </NavLink>
                        <div className="p-2 log-out" onClick={this.logOut}>
                          Log Out
                        </div>
                      </div>
                    </div>
                  }
                  trigger="click"
                  open={this.state.popoverOpen}
                  onOpenChange={this.handleOpenChangeMoblie}
                >
                  <span className="top-nav-heading date_display_mobile">
                    {this.state.todayDate}
                  </span>
                  <i className="fa fa-user-circle fontSize22"></i>
                </Popover>
              </span>
            )}
          </div>
        </div>
        <div className="px-2 col-xl-12  col-md-12 nav-bottom">
          {this.props.admin}
          {this.props.dealer}
          {this.props.company}
          {this.props.location2}
          {this.props.site}
          {this.props.billing}
          {this.props.warrentyHeader}
          {this.props.userHeader}
          {this.props.organizationHeader}
          {this.props.locationHeader}
          {this.props.siteHeader}
        </div>
      </nav>
    );
  }
}
