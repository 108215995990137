
class ConvertUtility {
    static convertString = (value :any) => {
        return value === null ||  value === undefined ? "" : String(value);
    }

    static isNullOrEmpty = (value :any) => {
        return value === null ||  value === undefined  || this.convertString(value) === "" ? true : false;
    }

    static trimValue = (value :any) => {
        return value === null ||  value === undefined  || this.convertString(value) === "" ? "" : this.convertString(value).trim();
    }

    static repalceStringWord = (value:any) => {
        return value === null ||  value === undefined  || this.convertString(value) === ""  || this.convertString(value) === 'string' ? "" :this.convertString(value).trim();
    } 
}

export default ConvertUtility