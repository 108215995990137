import React,{useState,useEffect} from 'react'
import { Modal, Button,ConfigProvider,Progress  } from "antd";
import faultsIcon from '../../images/faults2.png'
import {constants} from '../../helper/index'




const SessionWarning = ()=>{
    const [isShow,setIsShow] = useState(false)
    const [seconds,setSeconds] = useState(0)
   

   let inactivityTimeout;

    const resetInactivityTimeout = (click) => {
      if(isShow===false){
        clearTimeout(inactivityTimeout);
        inactivityTimeout = setTimeout(showLogoutWarning, 15 * 60 * 1000); // 15 minutes
      }
      
      if(click){
        setSeconds(0)
        setIsShow(false)
      }   
    };
    const showLogoutWarning = () => {
      setIsShow(true)
      //setTimeout(logout, 1000); // Show warning for 15 seconds before logout
    };

    useEffect(()=>{
      let timer
      if(isShow && seconds<16){
        timer = setInterval(()=>{
        setSeconds(seconds+1)
      },1000)
        seconds ===15 && logout()
      }
      return ()=> clearInterval(timer)
    })


  const logout = () => {
    setIsShow(false)
    sessionStorage.removeItem(constants.sessionDetails.userInfo)
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
    window.location.href = "/login"
  };

  useEffect(() => {
    if(isShow===false)  resetInactivityTimeout();

    const activityListener = () => {
      if(isShow===false)  resetInactivityTimeout();
    };

    window.addEventListener('mousemove', activityListener);
    window.addEventListener('keydown', activityListener);

    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener('mousemove', activityListener);
      window.removeEventListener('keydown', activityListener);
    };
  }, [isShow]);

 


    return(
       <div>
             <ConfigProvider 
                         theme={{ token: {colorPrimary: "#004f9f"}}}>
                            <Modal
                             centered
                             open={isShow}
                             closable={false}
                             footer={null}
                             maskClosable={false}
                             width={300}
                             >
                            <div>
                              <div className='gap align-items-center'>
                                <img src={faultsIcon} alt="" className='img_size2'/>
                                <div className='session-title text-blue'>Session Warning</div>   
                            </div>  
                            <p className='mt6 mb6 color-gray'>Your session will automatically expire due to inactivity.</p>
                            <p className='color-gray mb6'> You will be logged out in 15 seconds. </p>
                            <p  className='color-gray mt6 mb6'>Select “Continue” to extend your session.</p>
                              <Progress percent={seconds===0? seconds :(100/15)*seconds} format={(percent) => `${seconds} sec`} showInfo={true}
                              strokeColor={'#004F9F'}
                              className="custom-progress-bar"
                              />
                              <div className='m-flex mt6'>
                              <Button size='large' onClick={logout}>Logout</Button>  
                              <Button size='large' onClick={()=>resetInactivityTimeout(true)} style={{background: "#004f9f",borderBlockColor: "#004f9f", color: "white"}}>
                                Continue
                              </Button>    
                              </div>
                        </div>    
                            </Modal>                  
                      </ConfigProvider>
       </div>
    )
}

export default SessionWarning;