import React from 'react';
import Routers from './routers/routercomponent';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './styles/searchBox.css'

class App extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <Routers></Routers>
            </BrowserRouter>
        );
    }
}

export default App;
