import React from "react";
import mapBannerIcon from "../../images/mapBanner.svg";
import posiBanneLogo from "../../images/posilinkBannerLogo.svg";

const WelcomeBoard = () => {
  return (
    <div className="row mapBannerDiv">
      <div className="col-md-5 mapBannerImgDiv">
        <img src={mapBannerIcon} className="mapBannerImg" alt="mapBanner" />
      </div>
      <div className="col-md-6">
        <div className="right-side">
          <div className="mapBannerLogoDiv">
            <img
              src={posiBanneLogo}
              alt="posilinkLogo"
              className="mapBannerDivLogo"
            />
          </div>
          <div className="mapBannerDivText">
            Welcome to Posilink, a<br></br> comprehensive battery<br></br>{" "}
            management tool
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBoard;
