import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Utility } from '../../helper/index'
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import World from 'fusioncharts/maps/fusioncharts.world';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// import WorldMap from '../fusionChart/worldMap';
import Donut from '../fusionChart/donutChart';
import {Input } from "antd";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, startOfWeek } from 'date-fns'
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import TaskSummary from '../reusableComponent/TaskSummary';
import Summary from '../reusableComponent/summary';
import OperationStatus from '../reusableComponent/operations-status';
import LocationIcon from '../../images/location@2x.png';
import UserIcon from '../../images/GlobalUser@2x.png';
import Batteries from '../../images/Battery Dashboard.png';
import SiteIcon from '../../images/Companies Icon@2x.png';
import { resize, hideSidebar } from '../../settings/resize';
import WelcomeBoard from '../reusableComponent/welcomeBoard';


ReactFC.fcRoot(FusionCharts, Maps, World, FusionTheme,);

interface MyState {
  toggleOperation: boolean,
  data: [],
  totalPage: number,
  current: number,
  minIndex: number,
  maxIndex: number,
  value: [Date, Date],
  pathName: string,
  calender: boolean,
  pageSize: number,
  showChanger: boolean,
  pageSizeOptions: [number, number, number, number],
  leftHeight: number,
  donutHeight: number,
  dealers: any,
  search: string,
  isRedirectLogin: boolean,
  token: string,
  dropDownsDealers: [],
  dealerId: number,
  companyId: number,
  dropDownCompanies: [],
  systemSummary: {
    dealerCount: number,
    companiesCount: number,
    locationsCount: number,
    usersCount: number
  },
  open: boolean,
  operationStatus: {
    highTempBatteries: number,
    totalAhsCharged: number,
    totalCompletedUnits: number,
    totalOverdueUnits: number,
    totalUpcomingUnits: number,
    totalWateringEvents: number
  },
  taskSummary: {
    severeFaults: number,
    overdueTasks: number,
    upcomingTasks: number
  },
  soc: {
    highSOCUnits: number,
    lowSOCUnits: number,
    totalChargeCount: number,
    totalDisconnectedCount: number,
    totalInstalledUnits: number,
    totalNoChargeUnits: number
  },
  summaryUrl: string,
  accountId: number,
  companyAlert: {
    CoId: number,
    CoName: string
  },
  isShowSpinner: boolean,
  thisWeek: boolean,
  dealerdashboard: {
    dealerDashboarDealerId: number,
    dealerDashboarCompanyId: number,
  }
  companydashboard: {
    companyDashboardCoId: number
  },
  loggedUserId: number | null,
  date: { startDate: string, endDate: string },
  countryResp: any[],
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  initial: boolean,
  sysSummary: boolean
}

interface item {
  Height: {},
  name: string,
  locationsCount: string,
  severeFaults: number,
  overdueTasks: number,
  upcomingTasks: number,
  orderId: number
}

const { Search } = Input;

export default class DashBoard extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      isRedirectLogin: false,
      userInfo: {},
      toggleOperation: false,
      data: [],
      totalPage: 0,
      current: 1,
      minIndex: 0,
      maxIndex: 0,
      value: [new Date(), new Date()],
      pathName: "",
      calender: false,
      pageSize: 10,
      showChanger: false,
      pageSizeOptions: [10, 20, 50, 100],
      leftHeight: 0,
      donutHeight: 200,
      dealers: [],
      search: "",
      token: "",
      dropDownsDealers: [],
      dealerId: 0,
      companyId: 0,
      dropDownCompanies: [],
      open: false,
      systemSummary: {
        dealerCount: 0,
        companiesCount: 0,
        locationsCount: 0,
        usersCount: 0,
        siteCount: 0,
        divicesCount: 0
      },
      operationStatus: {
        highTempBatteries: 0,
        totalAhsCharged: 0,
        totalCompletedUnits: 0,
        totalOverdueUnits: 0,
        totalUpcomingUnits: 0,
        totalWateringEvents: 0
      },
      taskSummary: {
        severeFaults: 0,
        overdueTasks: 0,
        upcomingTasks: 0,
      },
      soc: {
        highSOCUnits: 0,
        lowSOCUnits: 0,
        totalChargeCount: 0,
        totalDisconnectedCount: 0,
        totalInstalledUnits: 0,
        totalNoChargeUnits: 0
      },
      summaryUrl: "",
      accountId: 0,
      companyAlert: {
        CoId: 0,
        CoName: "",
      },
      thisWeek: false,
      dealerdashboard: {
        dealerDashboarDealerId: 0,
        dealerDashboarCompanyId: 0,
      },
      companydashboard: {
        companyDashboardCoId: 0
      },
      loggedUserId: null,
      date: { 
        startDate: format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy'), 
        endDate: format(new Date(), 'dd-MMM-yyyy') 
      },
      countryResp: [],
      initial: true, 
      sysSummary: true,
      loadingCount: 0,
      isShowSpinner: false,
      taskApicall:false,
      summaryApicall:false,
      operatoinApicall:false,
      alertApicall:false
    }

    this.claenderonChange = this.claenderonChange.bind(this);
    this.showCalender = this.showCalender.bind(this);
    this.filterDealer = this.filterDealer.bind(this)
    this.showLoader = this.showLoader.bind(this)
    this.hideLoader = this.hideLoader.bind(this)
  }

  // to manage the loader
  showLoader = () =>{
    
  } 

  hideLoader = () => {
     const{taskApicall,summaryApicall,operatoinApicall,alertApicall} = this.state
     if(taskApicall && summaryApicall && operatoinApicall && alertApicall){
      this.setState({isShowSpinner:false})
     }
  } 


    handleResize = () => {
      let timer:any  
            clearTimeout(timer)
            timer = setTimeout(()=>{
              resize()
            },500)
        //  }
    };

    componentWillUnmount() {
      // Remove event listener when component is unmounted
      window.removeEventListener('resize', this.handleResize);
    }

  componentDidMount() {
    // check the page permission
     window.addEventListener('resize', this.handleResize);
     resize();
     hideSidebar();
     
    let permission: any = localStorage.getItem('permissions')
    if (permission !== null) {
      if (!permission.includes('adminDashBoard_W')) window.history.back()
    }

    if (Utility.hasUserEmpty()) {
      this.setState({ isRedirectLogin: true })
    } else {
      this.setState({ pathName: this.props.path, globalData: this.props.globalData })
      let leftSideHeight = document.getElementById('leftCol')?.offsetHeight
      if (leftSideHeight) {
        this.setState({ leftHeight: leftSideHeight })
      }
      let userInfo: any = Utility.getUserInfo();
      var gettoken = userInfo.authToken;
      let userId = userInfo.userId
      this.setState({ token: gettoken, loggedUserId: userId, accountId: userId })

      const today = new Date()
      today.setDate(today.getDate());
      const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
      const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');
      this.setState({ date: { ...this.state.date, startDate: firstDayOfWeek, endDate: lastDayOfWeek } })
      setTimeout(() => {
        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        this.getTaskSummary(data?.orgId, data?.divId, data?.locationId, data?.buildingId)
        this.getSummary(data?.orgId, data?.divId, data?.locationId, data?.buildingId);
        //this.dealerSoc(firstDayOfWeek, lastDayOfWeek, data?.orgId, data?.divId, data?.locationId, data?.buildingId);
        //this.worldSummary(data?.orgId, data?.divId, data?.locationId, data?.buildingId)
        this.getDealerAlerts(data?.orgId, data?.divId, data?.locationId, data?.buildingId)
        this.setState({ initial: false, orgId: data?.orgId, divId: data?.divId })
      }, 3000);
    }
  }

  getTaskSummary = async (orgId: number, divId: number, location: number, building: number) => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    let url = constantUrl.api.dashboard.getTaskSummary;
    let type = "POST";
    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: location,
      buildingId: building
    }
    this.setState({isShowSpinner:true})
    try {
      const response = await service({
        url: url,
        method: type,
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        data: payload
      });

      const { severeFaults, overdueTasks, upcomingTasks } = response
      this.setState({ taskSummary: { ...this.state.taskSummary, overdueTasks, severeFaults, upcomingTasks } })
    } catch (error) {
      console.log("getTaskSummary", error)
    }finally{
       this.setState({taskApicall:true},()=>{this.hideLoader()})
      
    }
  }

  async getSummary(orgId: number, divId: number, locationId: number, buildingId: number) {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;
    var loginDealerId = userInfo.userId;

    let orgid = orgId > -1 ? orgId : loginDealerId;

    let url = constantUrl.api.dashboard.getCompanySummary;

    let payload = {
      orgId: orgid,
      divisionId: divId,
      locationId: locationId,
      buildingId: buildingId
    }
    this.setState({isShowSpinner:true})
    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });

      const { companiesCount, dealerCount, locationsCount, usersCount, siteCount, divicesCount } = response;

      this.setState({
        systemSummary: {
          ...this.state.systemSummary,
          dealerCount: dealerCount,
          companiesCount: companiesCount,
          usersCount: usersCount,
          locationsCount: locationsCount,
          siteCount: siteCount,
          divicesCount: divicesCount
        }
      })
    } catch (error) {
      console.error("systemSummaryErr:", error);
    }finally{
      this.setState({summaryApicall:true},()=>{this.hideLoader()})
    }
  }

  dealerSoc = async (startDate: string, endDate: string, orgId: number, divId: number, locId: number, buildId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;
    let url = constantUrl.api.dashboard.getOperationSoc;

    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId,
      buildingId: buildId,
      startDate: startDate,
      endDate: endDate
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: "Bearer " + token, },
        data: payload
      });
      if (response) {
        const { highSOCUnits, lowSOCUnits, totalChargeCount, totalDisconnectedCount, totalInstalledUnits, totalNoChargeUnits } = response
        this.setState({
          soc: {
            ...this.state.soc,
            highSOCUnits,
            lowSOCUnits,
            totalChargeCount,
            totalDisconnectedCount,
            totalInstalledUnits,
            totalNoChargeUnits
          }
        })
      }
    } catch (error) {
      console.warn(error);
    }
  }

  getOperationStatus = async (firstDay: any, lastDay: any, orgId: number, divId: number, locId: number, buildId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;
    var url = constantUrl.api.dashboard.getOperationStatus;
    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId,
      buildingId: buildId,
      startDate: firstDay,
      endDate: lastDay,
      weekStartDay: localStorage.getItem("weeklyStartDay")
    }
    this.setState({isShowSpinner:true})
    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        data: payload
      });

      if (response) {
        const { highTempBatteries, totalAhsCharged, totalCompletedUnits, totalOverdueUnits, totalUpcomingUnits, totalWateringEvents } = response
        this.setState({
          operationStatus: {
            ...this.state.operationStatus,
            highTempBatteries: highTempBatteries,
            totalAhsCharged: totalAhsCharged,
            totalCompletedUnits: totalCompletedUnits,
            totalOverdueUnits: totalOverdueUnits,
            totalUpcomingUnits: totalUpcomingUnits,
            totalWateringEvents: totalWateringEvents
          }
        })
      }
    }
    catch (error) {
      console.log("operationStatusError:", error)
    }finally{
      this.setState({operatoinApicall:true},()=>{this.hideLoader()}) 
    }
  }

  worldSummary = async (orgId: any, divId: number, locId: number, buildId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;
    let url = constantUrl.api.dashboard.getWorldSummary;

    let payload = {
      "orgId": orgId,
      "divisionId": divId,
      "locationId": locId,
      "buildingId": buildId
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      this.setState({ countryResp: response })
    }
    catch (error) {
      console.log(error)
    }
  }

  getDealerAlerts = async (orgId: number, divId: number, locationId: number, buildingId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    let url = constantUrl.api.dashboard.getDealerAlerts;
    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locationId,
      buildingId: buildingId
    };

    this.setState({ isShowSpinner: true })
    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: payload
      });

      if (response) {
        this.setState({
          dealers: response,
          totalPage: response.length / this.state.pageSize,
          minIndex: 0,
          maxIndex: this.state.pageSize,
          showChanger: response.length > 10 ? true : false,
          // isShowSpinner: false
        })
      } else {
        // this.setState({ isShowSpinner: false })
      }
    } catch (error) {
      console.log("systemSummaryErr:", error);
      // this.setState({ isShowSpinner: false })
    }finally{
      this.setState({alertApicall:true},()=>{this.hideLoader()}) 
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<MyState>, snapshot?: any): void {
    if (this.state.leftHeight === prevState.leftHeight) {
      let mapHeight = document.getElementById('world_map')?.offsetHeight
      let headerPlusSearch: any = document.getElementById('summaryCardsTitle')?.offsetHeight
      let searchAlertHeight: any = document.getElementById('search-alerts')?.offsetHeight
      let cardTopBottomMargin = 40
      let cardCount = 10
      let paginationHeight: any = document.getElementById('pagination-div')?.offsetHeight
      let cardHeight: any = document.getElementById('card-height')?.offsetHeight
      let rightSideHeight = mapHeight + headerPlusSearch + (cardHeight + cardTopBottomMargin) * cardCount
      let leftHeight: any = document.getElementById('leftCol')?.offsetHeight
      if (leftHeight) { localStorage.setItem('leftsideheight', JSON.stringify(leftHeight)) }

      let remaningHeight = leftHeight - (mapHeight + headerPlusSearch + paginationHeight + searchAlertHeight + cardTopBottomMargin)
      if (rightSideHeight > leftHeight && window.innerWidth < 1025) {
        let getDiv = document.querySelector("[class='alerts-container']") as HTMLElement
        getDiv.style.height = (remaningHeight - paginationHeight) + 'px'
      }
      if (rightSideHeight > leftHeight && window.innerWidth > 1900) {
        let getDiv = document.querySelector("[class='alerts-container']") as HTMLElement
        getDiv.style.height = (remaningHeight) + 'px'
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<any>): void {
    if (this.state.initial == false) {
      const today = new Date()
      today.setDate(today.getDate());
      const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
      const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');

      this.getTaskSummary(nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId)
      this.getSummary(nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId);
      //this.dealerSoc(firstDayOfWeek, lastDayOfWeek, nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId);
      this.getDealerAlerts(nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId)
      //this.worldSummary(nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId)
      this.getOperationStatus(this.state.date.startDate, this.state.date.endDate, nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId)
    }
  }

  handleChange = (page: number, getPageSize: number) => {
    this.setState({
      current: page,
      minIndex: (page - 1) * getPageSize,
      maxIndex: page * getPageSize,
      pageSize: getPageSize
    });
  }

  claenderonChange(value: any) {
    let startDate = format(new Date(value[0]), 'dd-MMM-yyyy')
    let endDate = format(new Date(value[1]), 'dd-MMM-yyyy')
    let label = document.getElementById("dateRangeLabel")
    if (label) label.innerText = `${startDate}-${endDate}`
    this.setState({ open: false, value: [new Date(startDate), new Date(endDate)] })
  }

  showCalender() { this.setState({ calender: !this.state.calender }) }

  onSearch = (value: string) => { this.setState({ search: value }) }

  filterDealer(event: any) {
    let value = event.target.value
    this.setState({ search: value.length < 3 ? "" : value })
  }

  dateFilter = (startDate: string, endDate: string,getGlobalData?:any) => {
    setTimeout(() => {
    var data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    this.setState({date: { ...this.state.date, startDate, endDate } })
    this.getOperationStatus(startDate, endDate, data?.orgId, data?.divId, data?.locationId, data?.buildingId)
    }, 5000);
  }

  render() {
    const { isShowSpinner } = this.state;
    var data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    if (this.state.isRedirectLogin) {
      return (
        <Navigate to={"/login"} />
      );
    }

    return (
      <>
        <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
        <div className="main-panel">
          <div className="ChartPanel">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-7 left-main-col" id='leftCol'>
                  <TaskSummary summaryData={this.state.taskSummary} />
                  <div className="row left-admin-row justify-content-between mt-3  ">
                    <div className="col-md-12 col-lg-12 col-xl-6">
                      {/* SYSTEM SUMMARY   */}
                      <Summary
                        heading='DEALER SUMMARY'
                        summaryData={this.state.systemSummary}
                        orgID={data?.orgId}
                        SiteIcon={SiteIcon}
                        batteryIcon={Batteries}
                        LocationIcon={LocationIcon}
                        UserIcon={UserIcon}
                        siteLable={'SITES'}
                        deviceLabel={'DEVICES'}
                        locationLabel={'LOCATIONS'}
                        userLabel={'USERS'}
                      />

                      {/* STATE OF CHARGE  */}
                      {/* <div className="row state-charge" id='state-charge'>
                        <div className="col-12 px-2 mt-2 mb-2">
                          <div className="col-12  chartTitle">STATE OF CHARGE</div>
                        </div> */}
                        {/* doughnut chart  */}
                        {/* <div className="col-md-12 pb-2 px-2 pie-chart" id="pie-chart">
                          <Donut pieRaidus={70} doughnutRadius={60} soc={this.state.soc} />
                        </div> */}
                        {/* doughnut chart  */}
                      {/* </div> */}
                    </div>
                    {/* OPERATIONS STATUS  */}
                    <div className="col-md-12 col-lg-12 col-xl-6 operation-status-container" id='operation-status-container'>
                      <OperationStatus statusData={this.state.operationStatus} dateFilter={this.dateFilter} />
                    </div>
                  </div>
                </div>
                <div className="col-md-5  pr-0" id="admin-dashboard">
                  <WelcomeBoard/>
                  <div className="row mt-3">
                    <div className="col-12 pl-0">
                      <div className="chartTitle  mb-2">DEALER ALERTS</div>
                      {/* <div className='search-alerts bg-white mb-1' id='search-alerts'>
                        <Search placeholder="Search dealer alerts"
                          onChange={this.filterDealer}
                          onSearch={this.onSearch}
                          allowClear
                          enterButton />
                      </div> */}
                      {/* dealear card start  */}
                      <div id="data-container" className="dealear-card-main">
                        <ul className='alerts-container' >
                          {this.state.dealers && this.state.dealers?.filter((item: any, index: any) => {
                            return this.state.search.toLowerCase() === "" ? item : item.name.toLowerCase().includes(this.state.search.toLowerCase())
                          })
                            .map(
                              (item: item, indexs: any) => {
                                const { name, orderId, overdueTasks, locationsCount, severeFaults, upcomingTasks } = item
                                return indexs >= this.state.minIndex &&
                                  indexs < this.state.maxIndex && (
                                    <div key={indexs} className="row dealer-card" id="card-height">
                                      <div className="col-md-12 dealaer-card-col ">
                                        <div className="dealer-card-body">
                                          <div className="delear-body-right">
                                            <div>
                                              <img src={require("../../images/User.png")} className="dealear-img" alt='img' />
                                            </div>
                                            <div>
                                              <div id="dealaer-no1" className="dealaer-no" onClick={() => {
                                                let data: any = sessionStorage.getItem('globalData');
                                                data = data && JSON.parse(data);
                                                data["dealerId"] = orderId;
                                                sessionStorage.setItem("globalData", JSON.stringify(data))
                                                this.props.setLabelName("dealer", name)
                                                this.props.setGlobalData("dealerName", name);
                                              }}><Link to="/dealerdashboard">{name?.length > 15 ? name?.substring(0, 15) + "..." : name} </Link></div>
                                              <div id="dealear-companies1" className="company-count">{locationsCount}&nbsp; COMPANIES </div>
                                            </div>
                                          </div>
                                          <div className="delear-body-left ">
                                            <div >
                                              <div className="dot-red"></div>
                                              <div className="dot-yellow"></div>
                                              <div className="dot-green"></div>
                                            </div>
                                            <div className="count">
                                              <div>{severeFaults}</div>
                                              <div>{overdueTasks}</div>
                                              <div>{upcomingTasks}</div>
                                            </div>
                                            <div className="delaler-status">
                                              <div>SEVERE FAULTS</div>
                                              <div>OVERDUE TASKS</div>
                                              <div>UPCOMING TASKS</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              })}
                        </ul>
                        {/* <div id='pagination-div' className="mt-2 pagination-div">
                          <Pagination
                            pageSize={this.state.pageSize}
                            current={this.state.current}
                            total={this.state.data.length}
                            onChange={this.handleChange}
                            pageSizeOptions={this.state.pageSizeOptions}
                            showSizeChanger={this.state.showChanger}
                            responsive
                            showLessItems={true}
                            style={{ bottom: "0px", textAlign: "center" }}
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SessionWarning />
      </>
    )
  }
}

