import React,{FC} from 'react'
import {format} from 'date-fns'


interface props {
	// batteryData:{batteryId:string,vehicleId : string,serialNumber : string,manufactureDate :string,inServiceDate :string,daysInOperation :number,batteryType :number,batteryVoltage :number,capacity :number}
	batteryData:any
}

const BatteryDetails:FC<props> = ({batteryData})=>{
    return(
        <>
        <div  className="ChartPanel pl-3 pr-3">
		<div >
			<div className="row mb-2 newRow  justify-content-between">
				<div className="col-md-12 col-lg-3 col-xl-3  p-3 colBackGroundColor bgWhite">
					<div className="m-flex">
						<div className="label-color">Battery ID</div> 
						<div className="value-color">{batteryData?.batteryId}</div>
					</div>
					<div className="m-flex">
						<div className="label-color">Vehicle ID</div>
						<div className="value-color">{batteryData?.vehicleId}</div>
					</div>
					<div className="m-flex">
						<div className="label-color">Serial NO</div>
						<div className="value-color">{batteryData?.serialNumber}</div>
					</div>
				</div>
				<div className="col-md-12 col-lg-3 col-xl-3 p-3 colBackGroundColor bgWhite">
					<div className="m-flex">
						<div className="label-color">Battery Mfp Date</div> 
						<div className="value-color">{batteryData?.manufactureDate?format(new Date(batteryData?.manufactureDate),'dd-MMM-yyyy'):""}</div>
					</div>
					<div className="m-flex">
						<div className="label-color">In Service Date</div>
						<div className="value-color">{batteryData?.inServiceDate?format(new Date(batteryData?.inServiceDate), 'dd-MMM-yyyy'):""}</div>
					</div>
					<div className="m-flex">
						<div className="label-color">Days in Operation</div>
						<div className="value-color">{batteryData?.daysInOperation}</div>
					</div>
				</div>
				<div className="col-md-12 col-lg-5 col-xl-5 p-3 colBackGroundColor bgWhite">
					<div className="m-flex">
						<div className="label-color">Battery Type</div> 
						<div className="value-color">{batteryData?.batteryDescription}</div>
					</div>
					<div className="m-flex">
						<div className="label-color">Battery Voltage</div>
						<div className="value-color">{batteryData?.batteryVoltage}</div>
					</div>
					<div className="m-flex">
						<div className="label-color">Battery Capacity</div>
						<div className="value-color">{batteryData?.capacity}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
        </>
    )
}

export default BatteryDetails