import React,{FC,useState,useEffect} from 'react'
import { Pagination,ConfigProvider } from 'antd'

interface Props{
  handlePagination:(pageNumber:number,minIndex:number,maxIndex:number)=> void,
  totalLength:number,
  pageSize:number,
  current:number  
}

const PaginationComponent:FC<Props> = ({handlePagination,totalLength,pageSize,current})=>{
    const [showSizeChanger,setShowSizeChanger] = useState<boolean>(false)
    const [pageSizeOptions, setPageSizeOptions] = useState<[]>([])

    const handleChange = (pageNumber: number, getPageSize: number) => {
        let minIndex = (pageNumber-1) * getPageSize
        let maxIndex = pageNumber * getPageSize
        handlePagination(pageNumber,minIndex,maxIndex)  
    };

    return(
        <>
        <div className='py-2'>
        <ConfigProvider 
        theme = {{token:{colorPrimary :'#004f9f'}}}> 
          <Pagination
          align = "center"
           pageSize={pageSize}
           current={current}
           total={totalLength}
           onChange={handleChange}
           pageSizeOptions={pageSizeOptions}
           showSizeChanger={showSizeChanger}
           showLessItems={true}
           style={{ bottom: "0px"}}
          /> 
         </ConfigProvider> 
        </div>
        </>
    )
}

export default PaginationComponent;