import React, { FC, useState, useEffect } from "react";
import { format, lastDayOfWeek, subDays, startOfMonth, endOfMonth, startOfWeek, subMonths, addDays } from 'date-fns'
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Popover } from "antd";
import HightempImg from '../../images/High-Temp-Icon.png';
import AhsImg from '../../images/Ahs-Icon.png';
import greenImg from '../../images/Green@2x.png';
import yellowImg from '../../images/Yellow@2x.png';
import RedImg from '../../images/Red@2x.png';
import GroupImg from '../../images/Group-601.png'
import { Link } from "react-router-dom";

interface Props {
  statusData: { highTempBatteries: number, totalAhsCharged: number, totalCompletedUnits: number, totalOverdueUnits: number, totalUpcomingUnits: number, totalWateringEvents: number }
  dateFilter: (startDate: string, endDate: string,getGlobalData?:any) => void
}

const currentD = new Date()
currentD.setDate(currentD.getDate());
const lastDaysWeek = format(currentD, 'dd-MMM-yyyy')
const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');

const OperationStatus: FC<Props> = ({ statusData, dateFilter }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setvalue] = useState<any>([subDays(new Date(), 6), new Date()]);
  const [date, setDate] = useState<any>({ startDate: firstDayOfWeek, endDate: lastDaysWeek });

  useEffect(() => {
    let week_of_day = localStorage.getItem("weeklyStartDay");
    let date_selected = new Date();
    date_selected = new Date(date_selected.getFullYear(), date_selected.getMonth(), date_selected.getDate());

    switch (week_of_day) {
      case "SUN":
          while (date_selected.getDay() !== 0) {
            date_selected.setDate(date_selected.getDate() - 1);
          }
          break;
      case "MON":
          while (date_selected.getDay() !== 1) {
            date_selected.setDate(date_selected.getDate() - 1);
          }
          break;
      case "TUE":
          while (date_selected.getDay() !== 2) {
            date_selected.setDate(date_selected.getDate() - 1);
          }
          break;
      case "WED":
          while (date_selected.getDay() !== 3) {
            date_selected.setDate(date_selected.getDate() - 1);
          }
          break;
      case "THU":
          while (date_selected.getDay() !== 4) {
            date_selected.setDate(date_selected.getDate() - 1);
          }
          break;
      case "FRI":
          while (date_selected.getDay() !== 5) {
            date_selected.setDate(date_selected.getDate() - 1);
          }
          break;
      case "SAT":
          while (date_selected.getDay() !== 6) {
            date_selected.setDate(date_selected.getDate() - 1);
          }
          break;
      default:
          return;
  }

    let date_end = new Date(date_selected.getTime() + (7 * 24 * 60 * 60 * 1000));
    let label = document.getElementById("dateRangeLabel")
    if (label) label.innerText = `${format(date_selected, 'dd-MMM-yyyy')} - ${format(date_end, 'dd-MMM-yyyy')}`;
    var getGlobalData: any = sessionStorage.getItem('globalData');
    getGlobalData = getGlobalData && JSON.parse(getGlobalData);
    dateFilter(format(date_selected, 'dd-MMM-yyyy'), format(date_end, 'dd-MMM-yyyy'),getGlobalData)
    setDate({ startDate: format(date_selected, 'dd-MMM-yyyy'), endDate: format(date_end, 'dd-MMM-yyyy') })
  }, [])


  const thisWeek = () => {
    const today = new Date()
    today.setDate(today.getDate());
    const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
    const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');
    setOpen(false)
    dateFilter(firstDayOfWeek, lastDayOfWeek)
    let label = document.getElementById("dateRangeLabel")
    if (label) label.innerText = "THIS WEEK"
    setDate({ startDate: firstDayOfWeek, endDate: lastDayOfWeek })
  };

  const lastWeek = () => {
    const today = new Date()
    const lastDayOfCurrentWeek = lastDayOfWeek(today)
    const firstDayOfLastWeek = format(subDays(lastDayOfCurrentWeek, 13), 'dd-MMM-yyyy')
    const lastDayOfLastWeek = format(subDays(lastDayOfCurrentWeek, 7), 'dd-MMM-yyyy')
    setDate({ startDate: firstDayOfLastWeek, endDate: lastDayOfLastWeek })
    setOpen(false)
    dateFilter(firstDayOfLastWeek, lastDayOfLastWeek)
    let label = document.getElementById("dateRangeLabel")
    if (label) label.innerText = "LAST WEEK"
  };
  const thisMonth = () => {
    const today = new Date()
    const currentDateOfMonth = format(today, 'dd-MMM-yyyy')
    const firstDateOfMonth = format(startOfMonth(today), 'dd-MMM-yyyy')
    setDate({ startDate: firstDateOfMonth, endDate: currentDateOfMonth })
    setOpen(false)
    dateFilter(firstDateOfMonth, currentDateOfMonth)
    let label = document.getElementById("dateRangeLabel")
    if (label) label.innerText = "THIS MONTH"
  };

  const lastMonth = () => {
    const today = new Date()
    const lastMonthStart = format(startOfMonth(subMonths(today, 1)), 'dd-MMM-yyyy')
    const lastMonthEnd = format(endOfMonth(subMonths(today, 1)), 'dd-MMM-yyyy')
    setDate({ startDate: lastMonthStart, endDate: lastMonthEnd })
    setOpen(false)
    dateFilter(lastMonthStart, lastMonthEnd)
    let label = document.getElementById("dateRangeLabel")
    if (label) label.innerText = "LAST MONTH"
  };
  const showCalender = () => { };

  const claenderonChange = (value: any) => {
    if (value) {
      var startDate = value[0] ? format(new Date(value[0]), 'dd-MMM-yyyy') : ""
      var endDate = value[1] ? format(new Date(value[1]), 'dd-MMM-yyyy') : ""
      setDate({ startDate, endDate })

      let label = document.getElementById("dateRangeLabel")
      if (label) label.innerText = `${startDate} - ${endDate}`
      setOpen(false)
      dateFilter(startDate, endDate)
      setvalue([new Date(startDate), new Date(endDate)])
    } else {
      let label = document.getElementById("dateRangeLabel")
      if (label) label.innerText = `-`
      setOpen(false)
      dateFilter("", "")
      setvalue(["", ""])
    }
  };

  return (
    // <div className="operation-status">
    <div className="operation-status-dashboard">
      <div>
        <div className="operation-status-title">
          <div style={{ textAlign: "center" }} className="col-12">OPERATIONS STATUS</div>
          {/* <Popover
            style={{ top: 160 }}
            className="shadow"
            arrow={false}
            placement="bottomRight"
            trigger="click"
            open={open}
            onOpenChange={()=>setOpen(!open)}
            content={
              <div className="line-toggle-items" id="line-toggle-items">
                 <div>
                  <span onClick={thisWeek}>THIS WEEK</span>{" "}
                </div>
                <div>
                  <span onClick={lastWeek}>LAST WEEK</span>
                </div>
                <div>
                  <span onClick={thisMonth}>THIS MONTH</span>{" "}
                </div>
                <div>
                  <span onClick={lastMonth}>LAST MONTH</span>
                </div>
                <div className="p-rel">
                  <label onClick={showCalender}>CUSTOM DATERANGE</label>
                </div>
                <div className="date-range">
                  <DateRangePicker onChange={claenderonChange} value={value} maxDate={new Date()} />
                </div> 
              </div>
            }
          >
            <div className="line-toggle" id="line-toggle">
              <svg xmlns="http://www.w3.org/2000/svg"
                width="16"height="16"fill="currentColor"className="bi bi-filter"viewBox="0 0 16 16">
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
            </div>  
          </Popover> */}
        </div>
      </div>
      <div className="mt-2 mb-2">
        <span className="range">DATE RANGE</span>
        <span id="dateRangeLabel" className="this-week">
          THIS WEEK
        </span>
      </div>
      <Link state={{ date }} to='/operationStatusHighTemp' style={{ textDecoration: 'none' }}>
        <div className="col-12 operation-card-col">
          <div className="operation-card">
            <div>
              <img
                src={HightempImg}
                className="operation-status-image"
                alt="img"
              />
            </div>
            <div style={{ width: "100%" }}>
              <div id="highTempCount" className="system-count" style={{ font: "normal normal bold 28px/30px Inter" }}>{statusData.highTempBatteries}</div>
              <div className="system-name" style={{ fontSize: "12px", fontWeight: 600 }}>HIGH TEMP BATTERIES</div>
            </div>
          </div>
        </div>
      </Link>
      {/* <Link state={{ date }} to='/operationStatusAhrsCharged' style={{ textDecoration: 'none' }}>
        <div className="col-12 operation-card-col mt-2 ">
          <div className="operation-card">
            <div>
              <img
                src={AhsImg}
                className="operation-status-image"
                alt="img"
              />
            </div>
            <div style={{ width: "100%" }}>
              <div id="ahsCharged" className="system-count">{statusData.totalAhsCharged}</div>
              <div className="system-name" style={{ fontSize: "12px" }}>Ahs CHARGED</div>
            </div>
          </div>
        </div>
      </Link>
      <Link state={{ date }} to='/operationStatusEqOverdue' style={{ textDecoration: 'none' }}>
      <Link state={{ date }} to='/operationStatusEqOverdue' style={{ textDecoration: 'none' }}>
        <div className="col-12 operation-card-col mt-2 ">
          <div className="operation-card-units">
            <div className="operation-eq-image-main">
              <img
                className="operation-eq-image"
                src={greenImg}
                alt=""
              />
              <div className="unit-div">
                <div id="eqCompleted" className="unit-count">{statusData.totalCompletedUnits}</div>
                <div className="unit-lable">Units</div>
              </div>
              <div className="progress-bar"></div>
            </div>
            <div className="operation-eq-image-main">
              <img
                className="operation-eq-image"
                src={yellowImg}
                alt=""
              />
              <div className="unit-div">
                <div id="edUpcoming" className="unit-count">{statusData.totalUpcomingUnits}</div>
                <div className="unit-lable">Units</div>
              </div>
              <div className="progress-bar"></div>
            </div>
            <div className="operation-eq-image-main">
              <img className="operation-eq-image" src={RedImg} alt="" />
              <div className="unit-div">
                <div id="eqOverdue" className="unit-count">{statusData.totalOverdueUnits}</div>
                <div className="unit-lable">Units</div>
              </div>
            </div>
          </div>
        </div>
      </Link> */}
      <Link state={{ date }} to='/operationStatusLowWater' style={{ textDecoration: 'none' }}>
        <div className="col-12 operation-card-col mt-2 ">
          <div className="operation-card">
            <div>
              <img src={GroupImg} className="operation-status-image" alt="img" />
            </div>
            <div style={{ width: "100%" }}>
              <div id="watering" className="system-count" style={{ font: "normal normal bold 28px/30px Inter" }}>{statusData.totalWateringEvents}</div>
              <div className="system-name" style={{ fontSize: "12px", fontWeight: 600 }}>NUMBER OF DAYS WITH LOW WATER</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default OperationStatus;
