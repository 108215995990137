import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import LoadingSpinner from '../spinner/loading-spinner'
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, startOfMonth, subDays } from 'date-fns';
import SelectDays from '../reusableComponent/dateNewFilter';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { resize, hideSidebar } from '../../settings/resize';
import { Button, Modal } from 'antd';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface battery {
  batteryId: string,
  vehicleId: string,
  serialNo: string,
  batteryMfgDate: string,
  inServiceDate: string,
  dayInOperation: string,
  batteryType: string,
  vatteryVoltage: string,
  batteryCapacity: string,
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string
  // refresh:number
}

const startOfCurrentMonth: any = format(startOfMonth(new Date(),), 'dd-MMM-yyyy')
const today: any = format(new Date(), 'dd-MMM-yyyy');

const DailyUsageAndPlugInChart: FC<Props> = ({ globalData, action }) => {
  // const DailyUsageAndPlugInChart: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [reqBody, setReqBody] = useState<any>({ startDate: format(subDays(new Date(), 13), 'dd-MMM-yyyy'), endDate: format(new Date(), 'dd-MMM-yyyy'), orgId: 0, divisionId: -1, locationId: -1 })
  const [chartType, setChartType] = useState<string>("mscombidy2d");
  const [dailyDate, setDailyDate] = useState<any[]>([])
  const [totalAhrsIn, setTotalAhrsIn] = useState<any[]>([])
  const [totalAhrsOut, setTotalAhrsOut] = useState<any[]>([])
  const [totalPlugins, setTotalPlugins] = useState<any[]>([])
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [value, onChange] = useState<any>([subDays(new Date(), 13), new Date()]);
  const [initial, setInitial] = useState<boolean>(true);
  const [value2, setValue] = useState<[Date, Date] | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const dateValue = [
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 14 Days', label: 'Last 14 Days' },
    { value: 'Custom Date Range', label: 'Custom Date Range' }]

  const ref = useRef<HTMLDivElement>(null)

  // useEffect(()=>{
  //   if (refresh === 0) return
  //   setReqBody({...reqBody})
  // },[refresh])

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? startOfCurrentMonth, date2 ?? today);
    }
  }, [value])

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem('permissions')
    if (!permission.includes('daily_usage_plugin_W')) window.history.back()

    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    if (reqBody?.orgId === 0) return
    (async () => {
      setIsShowSpinner(true)
      try {
        const response = await service({
          url: constantUrl.api.dailyUsagePlugin.dailyUsagePlugin,
          method: "POST",
          headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
          data: reqBody
        })
        frameChart(response)
        setIsShowSpinner(false)
      } catch (error) {
        console.error("dailyUsesError:", error)
        setIsShowSpinner(false)
      }
    })();
  }, [reqBody])

  const frameChart = (data: any) => {
    let dateLable: any = data.map((item: any) => {
      return {
        label: item.dailyDate
      }
    })

    let totalHoursIn: any = data.map((item: any) => {
      return {
        value: item.totalAhrsIn
      }
    })

    let totalAhrsOut: any = data.map((item: any) => {
      return {
        value: item.totalAhrsOut
      }
    })

    let totalPlugins: any = data.map((item: any) => {
      return {
        value: item.totalPlugins
      }
    })
    setDailyDate(dateLable)
    setTotalAhrsIn(totalHoursIn)
    setTotalAhrsOut(totalAhrsOut)
    setTotalPlugins(totalPlugins)
    setIsShowSpinner(false)
    let totalHoursInMax = Math.max(...totalHoursIn.map((o: any) => o.value))
    let totalHoursOutMax = Math.max(...totalAhrsOut.map((o: any) => o.value))
    let totaloverMAx = Math.max(...totalPlugins.map((o: any) => o.value))

    let final: any = [totalHoursInMax, totalHoursOutMax, totaloverMAx];
    let maxValue = Math.max(...final.map((o: any) => o));
    maxValue = maxValue + 100;
  }

  let datasetswithscroll = [
    {
      dataset: [{
        seriesname: "Total Ahrs In",
        plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
        data: totalAhrsIn
      }]
    },
    {
      dataset: [{
        seriesname: "Total Ahrs Out",
        plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
        data: totalAhrsOut
      }]
    },
    {
      dataset: [{
        seriesname: "Total Plugins",
        plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
        data: totalPlugins
      }]
    }
  ]

  let datasetswithoutscroll = [
    {
      seriesname: "Total Ahrs In",
      plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
      data: totalAhrsIn
    },
    {
      seriesname: "Total Ahrs Out",
      plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
      data: totalAhrsOut
    },
    {
      seriesname: "Total Plugins",
      plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
      data: totalPlugins
    }
  ]

  const chartConfigs = {
    type: chartType,
    width: '100%',
    height: '92%',
    dataFormat: 'json',
    dataSource: {
      chart: {
        xAxisName: "Date",
        pYAxisName: "Total Ahrs",
        // sYAxisName: "Total Plugins",
        xAxisNameFontColor: "#4f82b6",
        pYAxisNameFontColor: "#4f82b6",
        // sYAxisNameFontColor: "#4f82b6",
        xAxisNameFontBold: "1",
        pYAxisNameFontBold: "1",
        // sYAxisNameFontBold: "1",
        // sNumberSuffix: "%",
        setadaptivepYmin: "1",
        // setadaptivesYmin: "1",
        formatNumberScale: "0",
        formatNumber: "0",
        showDivLineSecondaryValue: "0",
        showSecondaryLimits: "0",
        decimals: "0",
        thickness: "10",
        animation: "1",
        numdivlines: "4",
        legendposition: "top-left",
        legendPadding: "50",
        drawCustomLegendIcon: "2",
        legendIconSides: "0",
        paletteColors: "#e8e368,#4f82b6,#e20a15",
        showvalues: chartType == "mscombidy2d" ? "0" : "0",
        showsum: chartType == "mscombidy2d" ? "0" : "0",
        theme: "fusion"
      },
      categories: [{
        category: dailyDate
      }],
      dataset: chartType == "mscombidy2d" ? datasetswithoutscroll : datasetswithscroll
    }
  };

  const daysFilter = (startDate: string, endDate: string) => {
    var Difference_In_Time = new Date(endDate).getTime() - new Date(startDate).getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days > 59) {
      setModalMessage('The maximum range allowed is 60 days.');
      setIsModalVisible(true);
      return;
    }

    setReqBody({ ...reqBody, startDate: format(new Date(startDate), 'dd-MMM-yyyy'), endDate: format(new Date(endDate), 'dd-MMM-yyyy') })
  }

  const handleModalClose = () => {
    onChange([subDays(new Date(), 6), new Date()])
    setIsModalVisible(false);
    setModalMessage('');
  };

  useEffect(() => {
    setInitial(true);
    resize();
    hideSidebar();
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;
    setReqBody({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setInitial(false);
  }, [])

  useEffect(() => {
    if (initial === false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setReqBody({ ...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  useEffect(() => {
    if (action !== "") {
      onButtonClick(action)
    }
  }, [action])

  const onButtonClick = useCallback((value: string) => {
    if (ref.current === null) {
      return
    }
    setIsShowSpinner(true);
    if (value === 'png') {
      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'dailyUsageAndPlugIns.png'
          link.href = dataUrl
          link.click()
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    } else {
      html2canvas(ref.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a')
          link.download = 'my-image-name.png';
          const pdf = new jsPDF();
          // pdf.addImage(imgData, 'PNG',2,20,0,96);
          pdf.addImage(imgData, 'PNG', 5, 20, 200, 100);
          pdf.save("dailyUsageAndPlugIns.pdf");
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    }


  }, [ref])

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div>
        <div className="container-battery-voltage" ref={ref}>
          <div className="chartTitle p-2 text-center d-flex align-items-center">
            <span className='mr-auto ml-auto '>Daily Usage and Plugin Report</span>
          </div>
          <span className="export">
            {
              showDateFilter && <span>
                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()}  />
              </span>
            }
            <SelectDays
              daysFilter={daysFilter}
              customFilterStatusChange={setShowDateFilter}
              dateOptions={dateValue}
              defaultDateLabel={'Last 14 Days'}
            />
          </span>
          <Modal
            className="update-users"
            title="Date Range Error"
            centered
            open={isModalVisible}
            onOk={handleModalClose}
            footer={[
              <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                OK
              </Button>,
            ]}
            closable={false}
          >
            <p>{modalMessage}</p>
          </Modal>
          <div className='daily-plugin-chart'>
            <ReactFC {...chartConfigs} />
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default DailyUsageAndPlugInChart;