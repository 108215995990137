import React, { FC } from 'react'
import HighTemp from '../../images/High_Temp_Icon.svg'
// import AhrsCharged from '../../images/Ahrs_Icon.svg'
import Eq from '../../images/EQ_icon.svg'
import LowWater from '../../images/Watering_Icon.svg'
import { Link } from 'react-router-dom'

interface Props {
    heading: string,
    operationCounts: { highTempCount: number, ahrsCount: number, eqCount: number, waterCount: number }
    bgColor: { highTempBg: string, ahrsBg: string, eqBg: string, waterBg: string },
    date?: any
}

const OperationSummaryCounts: FC<Props> = ({ heading, operationCounts, bgColor, date }) => {
    return (
        <div className=" report-div p-1">
            <div className=" col-lg-12 col-md-12 chartTitle py-1 pl-2 text-left">{heading}</div>
            <div className="col-12 px-0 py-1">
                <div className="row margin-0">
                    {
                        date ? <div className='col-md-4 px-0'>
                            <Link state={{ date }} to='/operationStatusHighTemp' style={{ textDecoration: 'none' }}>
                                <div className="pr-1 pl-1 width1">
                                    <div className="row border-1-darkgrey" style={{ backgroundColor: bgColor.highTempBg }}>
                                        <div className="col-6 img_container">
                                            <img src={HighTemp} className="img_size_battery" alt='img' />
                                        </div>
                                        <div className="col-6 pr8">
                                            <div className="d-flex img_container_flex">
                                                <span className="fontBlue fontSize20">{operationCounts.highTempCount}</span>
                                                <span className="fontBlue fontSize9">HIGH TEMP</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                            :
                            <div className='col-md-4 px-0'>
                                <Link to='/operationStatusHighTemp' style={{ textDecoration: 'none' }}>
                                    <div className="pr-1 pl-1 width1">
                                        <div className="row border-1-darkgrey" style={{ backgroundColor: bgColor.highTempBg }}>
                                            <div className="col-6 img_container">
                                                <img src={HighTemp} className="img_size_battery" alt='img' />
                                            </div>
                                            <div className="col-6 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationCounts.highTempCount}</span>
                                                    <span className="fontBlue fontSize9">HIGH TEMP</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                    }
                    {/* {
                        date ? <div className='col-md-3 px-0'>
                            <Link state={{ date }} to='/operationStatusAhrsCharged' style={{ textDecoration: 'none' }}>
                                <div className="pr-1 pl-1 width1">
                                    <div className="row border-1-darkgrey" style={{ backgroundColor: bgColor.ahrsBg }}>
                                        <div className="col-6 img_container">
                                            <img src={AhrsCharged} className="img_size_battery" alt='img' />
                                        </div>
                                        <div className="col-6 pr8">
                                            <div className="d-flex img_container_flex">
                                                <span className="fontBlue fontSize20">{operationCounts.ahrsCount}</span>
                                                <span className="fontBlue fontSize9">Ahrs Charged</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                            :
                            <div className='col-md-3 px-0'>
                                <Link to='/operationStatusAhrsCharged' style={{ textDecoration: 'none' }}>
                                    <div className="pr-1 pl-1 width1">
                                        <div className="row border-1-darkgrey" style={{ backgroundColor: bgColor.ahrsBg }}>
                                            <div className="col-6 img_container">
                                                <img src={AhrsCharged} className="img_size_battery" alt='img' />
                                            </div>
                                            <div className="col-6 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationCounts.ahrsCount}</span>
                                                    <span className="fontBlue fontSize9">Ahrs Charged</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                    } */}
                    {
                        date ? <div className='col-md-4 px-0'>
                            <Link state={{ date }} to='/operationStatusEqOverdue' style={{ textDecoration: 'none' }}>
                                <div className="pr-1 pl-1 width1">
                                    <div className="row border-1-darkgrey" style={{ backgroundColor: bgColor.eqBg }}>
                                        <div className="col-6 img_container">
                                            <img src={Eq} className="img_size_battery" alt='img' />
                                        </div>
                                        <div className="col-6 pr8">
                                            <div className="d-flex img_container_flex">
                                                <span className="fontBlue fontSize20">{operationCounts.eqCount}</span>
                                                <span className="fontBlue fontSize9">EQ OVERDUE</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                            :
                            <div className='col-md-4 px-0'>
                                <Link to='/operationStatusEqOverdue' style={{ textDecoration: 'none' }}>
                                    <div className="pr-1 pl-1 width1">
                                        <div className="row border-1-darkgrey" style={{ backgroundColor: bgColor.eqBg }}>
                                            <div className="col-6 img_container">
                                                <img src={Eq} className="img_size_battery" alt='img' />
                                            </div>
                                            <div className="col-6 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationCounts.eqCount}</span>
                                                    <span className="fontBlue fontSize9">EQ OVERDUE</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                    }
                    {
                        date ? <div className='col-md-4 px-0'>
                            <Link state={{ date }} to='/operationStatusLowWater' style={{ textDecoration: 'none' }}>
                                <div className="pr-1 pl-1 width1">
                                    <div className="row border-1-darkgrey" style={{ backgroundColor: bgColor.waterBg }}>
                                        <div className="col-4 img_container">
                                            <img src={LowWater} className="img_size_battery" alt='img' />
                                        </div>
                                        <div className="col-8 pr8">
                                            <div className="d-flex img_container_flex">
                                                <span className="fontBlue fontSize20">{operationCounts.waterCount}</span>
                                                <span className="fontBlue fontSize9 text-end">NUMBER OF DAYS WITH LOW WATER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div> :
                            <div className='col-md-4 px-0'>
                                <Link to='/operationStatusLowWater' style={{ textDecoration: 'none' }}>
                                    <div className="pr-1 pl-1 width1">
                                        <div className="row border-1-darkgrey" style={{ backgroundColor: bgColor.waterBg }}>
                                            <div className="col-4 img_container">
                                                <img src={LowWater} className="img_size_battery" alt='img' />
                                            </div>
                                            <div className="col-8 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationCounts.waterCount}</span>
                                                    <span className="fontBlue fontSize9 text-end">NUMBER OF DAYS WITH LOW WATER</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default OperationSummaryCounts;
