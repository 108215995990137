import React,{FC,useEffect,useState} from 'react'
import { Select, ConfigProvider } from 'antd';

    interface Props{
    placeholder:string,
    dropDownList:any[],
    filterById?:(id:string)=> void,
    allowClear?:boolean,
    sizeType?:any,
    width?:any,
    selectedItem?:any
    }

  const SelectFilter:FC<Props> = ({placeholder,dropDownList,filterById,allowClear,sizeType,width,selectedItem}) => {
    const [selected,setSelected] = useState<any>() 

    // useEffect(()=>{
    //     if(dropDownList.length>0){
    //     setSelected( dropDownList[0])
    //     filterById &&  filterById(dropDownList[0])  
    //     console.log("dropDownList:",dropDownList)  
    //     }   
    // },[dropDownList])

    const changeOrganization = (value:string) => {
    filterById &&  filterById(value)
    //setSelected(value)
    }



    return (
        <>
            <div className='ant-design-select'>
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                <Select
                   // value={selected}
                    allowClear={allowClear===false ? allowClear : true}
                    size={sizeType ? sizeType :'middle'}
                    style={width?{width:width}:{width: 175}}
                    dropdownStyle={{ zIndex: 900 }}
                    placeholder={placeholder}
                    onChange={changeOrganization}>
                    {dropDownList && dropDownList.map((item:any,index:any)=>{
                    return <Select.Option name={item.label?item.label:item} value={item.label?item.label:item} key={index}>{item.label?item.label:item}</Select.Option>
                    })}    
                </Select>
            </ConfigProvider>
            </div>
        </>
    )
}

export default SelectFilter;