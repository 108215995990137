import React, { useState, useEffect, FC, useLayoutEffect, useRef } from 'react';
import LoadingSpinner from '../spinner/loading-spinner';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Modal, Button, Select, Tooltip, ConfigProvider } from 'antd';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import ShowEntries from '../reusableComponent/showEntries';
import PaginationComponent from '../reusableComponent/pagination';
import { format, parseISO } from 'date-fns';
import SessionWarning from '../reusableComponent/sessionWarning';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Shift1 from "./shieft1";
import Shift2 from "./shift2";
import Shift3 from "./shift3";
import UpdateShiftShedules from './updateShiftShedules';
import { resize, hideSidebar } from '../../settings/resize';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import * as roles from '../../settings/roles';

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  // refresh: number
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const req = {
  orgId: "",
  divisionId: -1,
  locationId: -1,
  buildingId: -1,
  pageOffset: 1,
  pageLimit: 10,
  userName: localStorage?.userName,
  sortColumns: "dateUpdated",
  sortDirection: "DESC",
}

const reqBody = {
  "userName": localStorage?.userName,
  "orgId": "",
  "divisionId": -1,
  "locationId": -1,
  "buildingId": -1,
  "shift1Enabled": "N",
  "shiftStartTimer1": null,
  "shiftEndTimer1": null,
  "break1StartTimer1": null,
  "break1EndTimer1": null,
  "break2StartTimer1": null,
  "break2EndTimer1": null,
  "break3StartTimer1": null,
  "break3EndTimer1": null,
  "break4StartTimer1": null,
  "break4EndTimer1": null,
  "break5StartTimer1": null,
  "break5EndTimer1": null,
  "shift2Enabled": 'N',
  "shiftStartTimer2": null,
  "shiftEndTimer2": null,
  "break1StartTimer2": null,
  "break1EndTimer2": null,
  "break2StartTimer2": null,
  "break2EndTimer2": null,
  "break3StartTimer2": null,
  "break3EndTimer2": null,
  "break4StartTimer2": null,
  "break4EndTimer2": null,
  "break5StartTimer2": null,
  "break5EndTimer2": null,
  "shift3Enabled": 'N',
  "shiftStartTimer3": null,
  "shiftEndTimer3": null,
  "break1StartTimer3": null,
  "break1EndTimer3": null,
  "break2StartTimer3": null,
  "break2EndTimer3": null,
  "break3StartTimer3": null,
  "break3EndTimer3": null,
  "break4StartTimer3": null,
  "break4EndTimer3": null,
  "break5StartTimer3": null,
  "break5EndTimer3": null,
  "shift4Enabled": null,
  "shiftStartTimer4": null,
  "shiftEndTimer4": null,
  "break1StartTimer4": null,
  "break1EndTimer4": null,
  "break2StartTimer4": null,
  "break2EndTimer4": null,
  "break3StartTimer4": null,
  "break3EndTimer4": null,
  "break4StartTimer4": null,
  "break4EndTimer4": null,
  "break5StartTimer4": null,
  "break5EndTimer4": null,
  "dateUpdated": "",
  "orgName": "Select Organization",
  "divisionName": "All",
  "locationName": "All",
  "buildingName": "All",
}



const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="mt-3 bg-white"
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-minitab-${index}`,
    "aria-controls": `simple-minitabpanel-${index}`,
  };
}

const ShiftSchedules: FC<Props> = ({ globalData }) => {
  // const ShiftSchedules: FC<Props> = ({ globalData, refresh }) => {
  const [value, setValue] = useState<number>(0);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [shiftReq, setShiftReq] = useState<any>(reqBody)
  const [show, setShow] = useState<boolean>(false)
  const [orgList, setOrgList] = useState<any[]>([])
  const [locationList, setLocationList] = useState<any[]>([])
  const [siteList, setSiteList] = useState<any[]>([])
  const [companyList, setCompanyList] = useState<any[]>([])
  const [shiftSheduleReq, setShiftSheduleReq] = useState<any>(req)
  const [count, setCount] = useState<number>(0)
  const [shiftList, setShiftList] = useState<any[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [shiftId, setShiftId] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [disable1, setDisable1] = useState<boolean>(false)
  const [disable2, setDisable2] = useState<boolean>(false)
  const [disable3, setDisable3] = useState<boolean>(false)
  const [items, setItems] = useState<any>({})
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [titleMsg, setTitleMsg] = useState<string>("")
  const [initial, setInitial] = useState<boolean>(true);
  const [countReq, setCountReq] = useState<any>(req)
  const [sort, setSort] = useState<any>({ shift1Enabled: false, shift2Enabled: false, shift3Enabled: false, dateUpdated: false })
  const [orgDisabled, setOrgDisabled] = useState<boolean>(false)
  const [companyDisabled, setCompanyDisabled] = useState<boolean>(false)
  const [locDisabled, setLocDisabled] = useState<boolean>(false)
  const [siteDisabled, setSiteDisabled] = useState<boolean>(false)

  const [tableHeight, setTableHeight] = useState<any>(425)
  const paginationRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
        callDebounce()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useLayoutEffect(() => {
    resize();
    hideSidebar();
    callDebounce()
  }, []);

  const callDebounce = () => {
    //if(window.innerHeight<=568) return
    let marginAndPaddings: any = 96
    let navHeight = localStorage.getItem('navHeight')
    let paginationHeight
    if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
    let subHeight = marginAndPaddings + Number(navHeight) + paginationHeight
    let tableHeight = window.innerHeight - subHeight
    tableHeight < 425 ? setTableHeight(425) : setTableHeight(tableHeight)
  };

  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;

  const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  async function getOrganization(orgid: any, divid: any, locid: any, siteid: any) {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    let payload = {
      "orgId": orgid,
      "divisionId": divid,
      "locationId": locid,
      "buildingId": siteid
    }

    try {
      const response = await service({
        url: constantUrl.api.dashboard.getOrganizations,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });

      setOrgList(response);

    } catch (error) {
      console.log("getOrg:", error);
    }
  };

  useEffect(() => {
    let userType = localStorage?.UserType
    let userInfo: any = Utility.getUserInfo();
    var loginDealerId = userInfo.userId;

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let org = data?.orgId > -1 ? data?.orgId : userId;

    setInitial(true)

    if (roles.PLATFORM_ADMIN === userType || roles.PLATFORM_MANAGER === userType) {
      getOrganization(0, 0, 0, 0);
    } else if (roles.DEALER_ADMIN === userType || roles.DEALER_MANAGER === userType) {
      getOrganization(loginDealerId, 0, 0, 0);
    } else if (roles.COMPANY_ADMIN === userType || roles.COMPANY_MANAGER === userType) {
      setOrgDisabled(true)
      setCompanyDisabled(true)
      let orgId = Number(localStorage.getItem('accountId'))
      let orgName = localStorage.getItem('accountName')
      let divisionId = Number(localStorage.getItem('divisionId'))
      let divisionName = localStorage.getItem('divisionName')
      setShiftReq({ ...shiftReq, orgId, orgName, divisionId, divisionName })
      getLocations(orgId, divisionId, -1, -1)
    } else if (roles.LOCATION_ADMIN === userType || roles.LOCATION_MANAGER === userType) {
      setOrgDisabled(true)
      setCompanyDisabled(true)
      setLocDisabled(true)
      let orgId = Number(localStorage.getItem('accountId'))
      let orgName = localStorage.getItem('accountName')
      let divisionId = Number(localStorage.getItem('divisionId'))
      let divisionName = localStorage.getItem('divisionName')
      let locationId = Number(localStorage.getItem('locationId'))
      let locationName = localStorage.getItem('locationName')
      setShiftReq({ ...shiftReq, orgId, orgName, divisionId, divisionName, locationId, locationName })
      getSiteList(orgId, divisionId, locationId,)
    } else if (roles.SITE_ADMIN === userType || roles.SITE_MANAGER === userType) {
      setOrgDisabled(true)
      setCompanyDisabled(true)
      setLocDisabled(true)
      setSiteDisabled(true)
      let orgId = Number(localStorage.getItem('accountId'))
      let orgName = localStorage.getItem('accountName')
      let divisionId = Number(localStorage.getItem('divisionId'))
      let divisionName = localStorage.getItem('divisionName')
      let locationId = Number(localStorage.getItem('locationId'))
      let locationName = localStorage.getItem('locationName')
      let buildingId = Number(localStorage.getItem('buildingId'))
      let buildingName = localStorage.getItem('buildingName')
      setShiftReq({ ...shiftReq, orgId, orgName, divisionId, divisionName, locationId, locationName, buildingId, buildingName })
    }

    setShiftSheduleReq({ ...shiftSheduleReq, userName: localStorage?.userName, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
    setCountReq({ ...countReq, userName: localStorage?.userName, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
    setInitial(false)
  }, [])

  useEffect(() => {
    if (initial) return
    let org = globalData?.orgId > -1 ? globalData?.orgId : userId;

    setShiftSheduleReq({ ...shiftSheduleReq, userName: localStorage?.userName, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId })
    setCountReq({ ...countReq, userName: localStorage?.userName, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId })
    setCurrent(1)
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  useEffect(() => {
    existingShiftShedules()
  }, [shiftSheduleReq]);

  // useEffect(() => {
  //   if (refresh === 0) return
  //   setShiftSheduleReq({ ...shiftSheduleReq })
  //   setCountReq({ ...countReq })
  // }, [refresh]);

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]

    for (let sortLabel in sort) {
      switch (id) {
        case sortLabel:
          if (sort[sortLabel]) {
            setShiftSheduleReq({ ...shiftSheduleReq, sortColumns: id, sortDirection: "ASC", pageOffset: 1 })
            setCurrent(1)
            setSort({ ...sort, [id]: getIdboolean })
          } else {
            setShiftSheduleReq({ ...shiftSheduleReq, sortColumns: id, sortDirection: "DESC", pageOffset: 1 })
            setCurrent(1)
            setSort({ ...sort, [id]: getIdboolean })
          }
      }
    }
  };

  const existingShiftShedules = async () => {
    if (!shiftSheduleReq.orgId) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    setIsShowSpinner(true);
    try {
      const response = await service({
        url: constantUrl.api.shiftShedules.getshift,
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + token },
        data: shiftSheduleReq
      });
      setShiftList(response)
      response.length > 0 && setCount(response[0]['recordCount'])
      setIsShowSpinner(false)
    } catch (error) {
      setIsShowSpinner(false)
      console.log("getOrg:", error);
    }
  };

  useEffect(() => {
    getCount()
  }, [countReq])

  const getCount = async () => {
    if (!countReq.orgId) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.shiftShedules.countShift,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        data: countReq
      })
      setCount(response)
    } catch (error) {
      console.log(error)
    }
  };

  const selectOrgId = (orgName: string) => {
    let selectedOrg = orgList.find((item: any) => item.orgName === orgName)
    let orgId = selectedOrg['orgId']
    setShiftReq({
      ...shiftReq, orgId, orgName, divisionId: -1, divisionName: 'All', locationId: -1, locationName: 'All',
      buildingId: -1, buildingName: 'All'
    })

    getCompanyList(orgId)
    getLocations(orgId, -1, -1, -1)
    getSiteList(orgId, -1, -1)
  }

  const toDelete = (shiftId: number) => {
    setOpen(true)
    setShiftId(shiftId)
  }

  const onOk = () => deleteShiftId()

  const deleteShiftId = async () => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.shiftShedules.deleteShift + "/" + shiftId,
        method: "DELETE",
        headers: { Authorization: "Bearer " + token },
      });
      setOpen(false)
      setTitleMsg("Shift schedule deleted Successfully")
      setIsOpen(true)
    } catch (error) {
      console.log(error);
    }
  }

  const shift1Checkbox = (value: boolean) => {
    setDisable1(value)
    value ? setShiftReq({ ...shiftReq, shift1Enabled: 'Y' }) : setShiftReq({ ...shiftReq, shift1Enabled: 'N' })
  };

  const onChangeShift1 = (value1: any, value2: string) => {
    setShiftReq({ ...shiftReq, [value2]: value1 })
  };

  const shift1Break = (value1: string, value2: string) => {
    setShiftReq({ ...shiftReq, [value1]: null, [value2]: null })
  };

  const postShitSchedules = async () => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    const startTime = new Date()
    startTime.setHours(8)
    startTime.setMinutes(0)

    const endTime = new Date()
    endTime.setHours(17)
    endTime.setMinutes(0);
    const {
      shiftStartTimer1,
      shiftEndTimer1,
      shiftStartTimer2,
      shiftEndTimer2,
      shiftStartTimer3,
      shiftEndTimer3,
      shift1Enabled,
      shift2Enabled,
      shift3Enabled
    } = shiftReq;

    const shiftStartTimer1s = shiftStartTimer1 === null && shift1Enabled === "Y" ? startTime : shiftStartTimer1
    const shiftEndTimer1s = shiftEndTimer1 === null && shift1Enabled === "Y" ? endTime : shiftEndTimer1
    const shiftStartTimer2s = shiftStartTimer2 === null && shift2Enabled === "Y" ? startTime : shiftStartTimer2
    const shiftEndTimer2s = shiftEndTimer2 === null && shift2Enabled === "Y" ? endTime : shiftEndTimer2
    const shiftStartTimer3s = shiftStartTimer3 === null && shift3Enabled === "Y" ? startTime : shiftStartTimer3
    const shiftEndTimer3s = shiftEndTimer3 === null && shift3Enabled === "Y" ? endTime : shiftEndTimer3

    const shiftReq1 = {
      ...shiftReq,
      "shiftStartTimer1": shiftStartTimer1s,
      "shiftEndTimer1": shiftEndTimer1s,
      "shiftStartTimer2": shiftStartTimer2s,
      "shiftEndTimer2": shiftEndTimer2s,
      "shiftStartTimer3": shiftStartTimer3s,
      "shiftEndTimer3": shiftEndTimer3s,
      "userName": localStorage?.userName
    }

    try {
      const response = await service({
        url: constantUrl.api.shiftShedules.addshift,
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + token },
        data: shiftReq1
      });

      setTitleMsg("Shift Schedule added Successfully")
      setIsOpen(true)
      setShow(false)
    } catch (error) {
      console.log(error)
    }
  }

  const shift2Checkbox = (value: boolean) => {
    setDisable2(value)
    value ? setShiftReq({ ...shiftReq, shift2Enabled: 'Y' }) : setShiftReq({ ...shiftReq, shift2Enabled: 'N' })
  };

  const onChangeShift2 = (value1: any, value2: string) => {
    setShiftReq({ ...shiftReq, [value2]: value1 })
  };

  const shift2Break = (value1: string, value2: string) => {
    setShiftReq({ ...shiftReq, [value1]: null, [value2]: null })
  };

  const shift3Checkbox = (value: boolean) => {
    setDisable3(value)
    value ? setShiftReq({ ...shiftReq, shift3Enabled: 'Y' }) : setShiftReq({ ...shiftReq, shift3Enabled: 'N' })
  };

  const onChangeShift3 = (value1: any, value2: string) => {
    setShiftReq({ ...shiftReq, [value2]: value1 })
  };

  const shift3Break = (value1: string, value2: string) => {
    setShiftReq({ ...shiftReq, [value1]: null, [value2]: null })
  };

  const formattedDate = (date: string) => {
    const date1 = date;
    const parsedDate = parseISO(date1);
    const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
    return formatDate
  }

  const onSelect = (getPageSize: string) => {
    const numPageSize = Number(getPageSize)
    setCurrent(1)
    setPageSize(numPageSize)
    setShiftSheduleReq({ ...shiftSheduleReq, pageOffset: 1, pageLimit: numPageSize })
  };

  const handlePagination = (pageNumber: number, c: number, maxIndex: number) => {
    setShiftSheduleReq({ ...shiftSheduleReq, pageOffset: pageNumber })
    setCurrent(pageNumber)
  };

  const updateShift = (item: any) => {
    setItems(item)
    update()
  };

  const update = () => {
    setIsModalOpen(!isModalOpen)
  };

  const updatedShedule = () => {
    setTitleMsg("Shift schedule updated Successfully")
    setIsOpen(true)
  };

  const hideModal = () => {
    if (titleMsg === 'Shift Schedule added Successfully' || titleMsg === 'Shift schedule deleted Successfully') {
      setShiftSheduleReq({ ...shiftSheduleReq, pageOffset: 1 })
      setCountReq({ ...countReq, pageOffset: 1 })
      setCurrent(1)
    } else if (titleMsg === 'Shift schedule updated Successfully') {
      setShiftSheduleReq({ ...shiftSheduleReq, pageOffset: 1 })
      setCurrent(1)
    }
    setIsOpen(false)
  };

  const toggleShow = () => {
    let role = localStorage?.UserType
    let orgId
    let orgName
    let divisionId
    let divisionName
    let locationId
    let locationName
    let buildingId
    let buildingName

    if (roles.COMPANY_ADMIN === role || roles.COMPANY_MANAGER === role) {
      orgId = shiftReq.orgId
      orgName = shiftReq.orgName
      divisionId = shiftReq.divisionId
      divisionName = shiftReq.divisionName
      locationId = -1
      locationName = 'All'
      buildingId = -1
      buildingName = 'All'

    } else if (roles.LOCATION_ADMIN === role || roles.LOCATION_MANAGER === role) {
      orgId = shiftReq.orgId
      orgName = shiftReq.orgName
      divisionId = shiftReq.divisionId
      divisionName = shiftReq.divisionName
      locationId = shiftReq.locationId
      locationName = shiftReq.locationName
      buildingId = -1
      buildingName = 'All'
    } else if (roles.SITE_ADMIN === role || roles.SITE_MANAGER === role) {
      orgId = shiftReq.orgId
      orgName = shiftReq.orgName
      divisionId = shiftReq.divisionId
      divisionName = shiftReq.divisionName
      locationId = shiftReq.locationId
      locationName = shiftReq.locationName
      buildingId = shiftReq.buildingId
      buildingName = shiftReq.buildingName
    } else {
      orgId = ""
      orgName = 'Select Organization'
      divisionId = -1
      divisionName = 'All'
      locationId = -1
      locationName = 'All'
      buildingId = -1
      buildingName = 'All'
    }

    setShow(!show)

    setShiftReq({
      ...shiftReq,
      "orgId": orgId,
      "divisionId": divisionId,
      "locationId": locationId,
      "buildingId": buildingId,
      "shift1Enabled": "N",
      "shiftStartTimer1": null,
      "shiftEndTimer1": null,
      "break1StartTimer1": null,
      "break1EndTimer1": null,
      "break2StartTimer1": null,
      "break2EndTimer1": null,
      "break3StartTimer1": null,
      "break3EndTimer1": null,
      "break4StartTimer1": null,
      "break4EndTimer1": null,
      "break5StartTimer1": null,
      "break5EndTimer1": null,
      "shift2Enabled": 'N',
      "shiftStartTimer2": null,
      "shiftEndTimer2": null,
      "break1StartTimer2": null,
      "break1EndTimer2": null,
      "break2StartTimer2": null,
      "break2EndTimer2": null,
      "break3StartTimer2": null,
      "break3EndTimer2": null,
      "break4StartTimer2": null,
      "break4EndTimer2": null,
      "break5StartTimer2": null,
      "break5EndTimer2": null,
      "shift3Enabled": 'N',
      "shiftStartTimer3": null,
      "shiftEndTimer3": null,
      "break1StartTimer3": null,
      "break1EndTimer3": null,
      "break2StartTimer3": null,
      "break2EndTimer3": null,
      "break3StartTimer3": null,
      "break3EndTimer3": null,
      "break4StartTimer3": null,
      "break4EndTimer3": null,
      "break5StartTimer3": null,
      "break5EndTimer3": null,
      "shift4Enabled": null,
      "shiftStartTimer4": null,
      "shiftEndTimer4": null,
      "break1StartTimer4": null,
      "break1EndTimer4": null,
      "break2StartTimer4": null,
      "break2EndTimer4": null,
      "break3StartTimer4": null,
      "break3EndTimer4": null,
      "break4StartTimer4": null,
      "break4EndTimer4": null,
      "break5StartTimer4": null,
      "break5EndTimer4": null,
      "orgName": orgName,
      "divisionName": divisionName,
      "locationName": locationName,
      "buildingName": buildingName,
    })
    setDisable1(false)
    setDisable2(false)
    setDisable3(false)
  };

  const getCompanyList = async (orgId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    try {
      const response = await service({
        url: constantUrl.api.location.getDivisions,
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + token },
        data: { orgId: orgId }
      });
      setCompanyList(response)
    } catch (error) {
      setIsShowSpinner(false)
      console.log("getOrg:", error);
    }
  }

  const handleCompany = (companyName: string) => {
    let company = companyList.find((item: any) => item.divisionName === companyName)
    const { orgId } = shiftReq
    if (companyName === 'All') {
      setShiftReq({
        ...shiftReq, divisionId: -1, divisionName: companyName, locationId: -1,
        locationName: 'All', buildingId: -1, buildingName: 'All'
      })

      getLocations(orgId, -1, -1, -1)
      getSiteList(orgId, -1, -1)
    } else {
      let divisionId = company['divisionId']
      setShiftReq({
        ...shiftReq, divisionId, divisionName: companyName, locationId: -1,
        locationName: 'All', buildingId: -1, buildingName: 'All'
      })

      getLocations(orgId, divisionId, -1, -1)
      getSiteList(orgId, divisionId, -1)
    }
  };

  const getLocations = async (orgId: number, divisionId: number, locationId: number, buildingId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    let payload = { orgId, divisionId, locationId, buildingId }
    try {
      const response = await service({
        url: constantUrl.api.dashboard.getLocations,
        method: "POST",
        headers: { Authorization: 'Bearer ' + token },
        data: payload
      })
      setLocationList(response)
    } catch (error) {
      console.log(error)
    }
  };

  const handleLocation = (locationName: string) => {
    let selectedLoc = locationList.find((item: any) => item.locationName === locationName)
    const { divisionId, orgId } = shiftReq
    if (locationName === 'All') {
      setShiftReq({ ...shiftReq, locationId: -1, locationName, buildingId: -1, buildingName: 'All' })
      getSiteList(orgId, divisionId, -1)
    } else {
      let locationId = selectedLoc['locId']
      setShiftReq({ ...shiftReq, locationId, locationName, buildingId: -1, buildingName: 'All' })
      getSiteList(orgId, divisionId, locationId)
    }
  };

  const getSiteList = async (orgId: number, divisionId: number, locationId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    let payload = { orgId, divisionId, locationId }

    try {
      const response = await service({
        url: constantUrl.api.HeaderFilters.getSites,
        method: "POST",
        headers: { Authorization: 'Bearer ' + token },
        data: payload
      })
      setSiteList(response)
    } catch (error) {
      console.log(error)
    }
  };

  const handleSite = (buildingName: string) => {
    let selectedSite = siteList.find((item: any) => item.buildingName === buildingName)
    if (buildingName === 'All') {
      setShiftReq({ ...shiftReq, buildingId: -1, buildingName })
    } else {
      setShiftReq({ ...shiftReq, buildingId: selectedSite['id'], buildingName })
    }
  };

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className=" row nav-filter align-items-center">
        <div className="col-lg-4 col-md-6 col-sm-12  tab_content-battery">
          <span onClick={toggleShow} className="d-flex align-items-center  ">
            {show ? <MinusCircleOutlined /> : <PlusCircleOutlined />}    <span className="pl-2">Add a New Shift Schedule</span> </span>
        </div>
      </div>

      <div className={show ? "show-user  add-user" : 'hide-user add-user'} >
        <h6 className='bgColor'>Schedule Assignment</h6>
        <div className='drop-list'>
          <ConfigProvider
            theme={{ token: { colorPrimary: '#004f9f' } }}>
            <div className='d-1'>
              <label className="mb-0">Organization: </label>
              <Select
                disabled={orgDisabled}
                size='small'
                style={{ width: 175 }}
                dropdownStyle={{ zIndex: 900 }}
                placeholder="Select Organization"
                onChange={selectOrgId}
                value={shiftReq?.orgName}
              >
                {orgList?.map((item: any, index: any) => {
                  return <Select.Option key={index} value={item.orgName}>{item.orgName}</Select.Option>
                })}
              </Select>
            </div>
            <div className='d-1'>
              <label className="pr-2 mb-0">Division: </label>
              <Select
                disabled={companyDisabled}
                size='small'
                style={{ width: 175 }}
                dropdownStyle={{ zIndex: 900 }}
                placeholder="Select Division"
                onChange={handleCompany}
                value={shiftReq?.divisionName}
              >
                <Select.Option key={'key'} value={'All'}>All</Select.Option>
                {companyList?.map((item: any, index: any) => {
                  return <Select.Option key={index} value={item.divisionName}>{item.divisionName}</Select.Option>
                })}
              </Select>
            </div>
            <div className='d-1'>
              <label className="pr-2 mb-0">Location: </label>
              <Select
                disabled={locDisabled}
                value={shiftReq.locationName}
                size='small'
                style={{ width: 175 }}
                dropdownStyle={{ zIndex: 900 }}
                placeholder="Select Location"
                onChange={handleLocation}
              //onClear={clearLocation}
              >
                <Select.Option key={'key'} value={'All'}>All</Select.Option>
                {locationList?.map((item: any, index: any) => {
                  return <Select.Option key={index + "1"} value={item.locationName}>{item.locationName}</Select.Option>
                })}
              </Select>
            </div>
            <div className='d-1'>
              <label className="pr-2 mb-0">Building: </label>
              <Select
                disabled={siteDisabled}
                value={shiftReq.buildingName}
                size='small'
                style={{ width: 175 }}
                dropdownStyle={{ zIndex: 900 }}
                placeholder="Select Building"
                onChange={handleSite}
              //onClear={clearBuilding}
              >
                <Select.Option key={'buildingKey'} value={'All'}>All</Select.Option>
                {siteList?.map((item: any, index: any) => {
                  return <Select.Option key={index + "-1"} value={item.buildingName}>{item.buildingName}</Select.Option>
                })}
              </Select>
            </div>
          </ConfigProvider>
        </div>
        <div>
          <Box sx={{ backgroundColor: "white" }}>
            <Tabs
              value={value}
              onChange={handleTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="basic tabs example"
            >
              <Tab label="Shift Schedule 1" {...a11yProps(0)} />
              <Tab label="Shift Schedule 2" {...a11yProps(1)} />
              <Tab label="Shift Schedule 3" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Shift1
              shiftReq={shiftReq}
              onChangeShift1={onChangeShift1}
              shift1Checkbox={shift1Checkbox}
              shift1Break={shift1Break}
              disable1={disable1}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Shift2
              shiftReq={shiftReq}
              onChangeShift2={onChangeShift2}
              shift2Checkbox={shift2Checkbox}
              shift2Break={shift2Break}
              disable2={disable2} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Shift3
              shiftReq={shiftReq}
              onChangeShift3={onChangeShift3}
              shift3Checkbox={shift3Checkbox}
              shift3Break={shift3Break}
              disable3={disable3}
            />
          </CustomTabPanel>
        </div>
        <div className="d-flex mt-2">
          <div>
            <Button
              onClick={toggleShow}
              style={{
                background: "#004f9f",
                borderBlockColor: "#004f9f",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="pl-3">
            <Button
              disabled={(disable1 || disable2 || disable3) && (shiftReq.orgId > 0) ? false : true}
              onClick={postShitSchedules}
              style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white" }}
            >
              Add
            </Button>
          </div>
        </div>
      </div>

      <div className="main-panel">
        <div className="ChartPanel panelShadow bg-white ">
          <div className="col-md-12 mt-3  px-0">
            <div className="chartTitle p-2 d-flex  align-items-center ">
              <div className='ml-auto mr-auto'>Existing Shift Schedules</div>
              <div className='show-entries'>
                <ShowEntries getPageSize={onSelect} />
              </div>
            </div>
          </div>
          <div id="table-container">
            <div style={{ height: tableHeight + 'px' }} className="shift-tableWrappe overflow-auto scrollbar">
              <table className="billable width100">
                <thead className='tableHeader'>
                  <tr>
                    <th>Organization</th>
                    <th>Division</th>
                    <th>Location</th>
                    <th>Building</th>
                    <th>
                      <div onClick={() => sortBy('shift1Enabled')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >Shift 1 Enabled</span>
                        {sort.shift1Enabled ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                    <th>
                      <div onClick={() => sortBy('shift2Enabled')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >Shift 2 Enabled</span>
                        {sort.shift2Enabled ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                    <th>
                      <div onClick={() => sortBy('shift3Enabled')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >Shift 3 Enabled</span>
                        {sort.shift3Enabled ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                    <th>
                      <div onClick={() => sortBy('dateUpdated')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >Last Updated</span>
                        {sort.dateUpdated ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {shiftList?.map((item: any, index: any) => {
                    return <tr key={index}>
                      <td>{item?.orgName}</td>
                      <td>{item?.divisionName}</td>
                      <td>{item?.locationName}</td>
                      <td>{item?.buildingName}</td>
                      <td>{item?.shift1Enabled}</td>
                      <td>{item?.shift2Enabled}</td>
                      <td>{item?.shift3Enabled}</td>
                      <td>{item?.formatDateUpdated}</td>
                      <td className='p-0'>
                        <Tooltip title="Update" color='#7a8185'><EditOutlined onClick={() => updateShift(item)} style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px', cursor: 'pointer' }} /></Tooltip>
                        <Tooltip title="Delete" color='#7a8185'> <DeleteOutlined onClick={() => toDelete(item.shiftId)} style={{ fontSize: '15px', color: 'red', paddingRight: '0px', cursor: 'pointer' }} /></Tooltip></td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            <div ref={paginationRef}>
              <PaginationComponent
                handlePagination={handlePagination}
                totalLength={count}
                pageSize={pageSize}
                current={current}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={isOpen}
        closable={false}
        centered
        title={titleMsg}
        footer={[<Button style={{ background: '#004f9f', color: "white" }} onClick={hideModal}>OK</Button>]}
      />

      <Modal
        open={open}
        onOk={onOk}
        onCancel={() => setOpen(false)}
        centered
        title="Are you sure you want to delete Shift Schedule"
      />

      {isModalOpen &&
        <UpdateShiftShedules
          isOpen={isModalOpen}
          update={update}
          items={items}
          updatedShedule={updatedShedule}
        />
      }

      <SessionWarning />
    </>
  )
}

export default ShiftSchedules;