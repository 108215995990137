import React, { useLayoutEffect } from "react";
import { Link } from 'react-router-dom';
import SessionWarning from "../reusableComponent/sessionWarning";
import { resize, hideSidebar } from "../../settings/resize";
import menuData from "../../data/menu.json";

const Reports = () => {

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    })

    useLayoutEffect(() => {
        resize();
        hideSidebar();
    }, [])

    const sortedReports = [...menuData.reports].sort((a, b) => a.reportName.localeCompare(b.reportName));

    return (
        <>
            <div className="container p-2">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="row">
                            <h5>Most popular Reports</h5>
                        </div>
                    </div>
                    <div className="col-12 pt-2 pb-3">
                        <div className="row justify-center">
                            {
                                sortedReports.map((report, index) => (
                                    localStorage.permissions.includes(report.permissions) && (
                                        <Link to={report.linkTo} aria-expanded="false" key={index}>
                                            <div className="flex-item">
                                                <div className="flex-item-head">{report.reportName}</div>
                                                <br />
                                                <div className="flex-item-body">{report.description}</div>
                                            </div>
                                        </Link>
                                    )
                                ))
                            }
                        </div>
                        {/* <div className="row justify-center">                             */}
                        {/* <Link to="/billing-summary-report" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Billing Summary</div>
                                    <br />
                                    <div className="flex-item-body">Billable and Energy Usage Summary by Date Range</div>
                                </div>
                            </Link> */}
                        {/* <Link to="/charge-current-over-time-report" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Charge Current Over Time</div>
                                    <br />
                                    <div className="flex-item-body">Charge Current Over Time Report by Date Range</div>
                                </div>
                            </Link> */}
                        {/* {
                                localStorage.permissions.includes('stateOfCharge_W') &&
                                <Link to="/battery-voltage-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery SOC Report - New</div>
                                        <br />
                                        <div className="flex-item-body">Battery State of Charge by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('battery_temperature_W') &&
                                <Link to="/battery-temperature-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery Temperature Report - New</div>
                                        <br />
                                        <div className="flex-item-body">Battery Temperature by Date Range</div>
                                    </div>
                                </Link>
                            } */
                        }
                        {/* </div> */}
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default Reports;