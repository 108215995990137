import React, { FC } from 'react';
import { Select, ConfigProvider } from 'antd';

interface Props {
    getPageSize: (pageSize: string) => void
}

const ShowEntries: FC<Props> = ({ getPageSize }) => {
    const onSelect = (pageSize: string) => {
        getPageSize(pageSize)
    };
    
    return (
        <>
            <label className='pr-2 mb-0 date_hide_mobile'>Show Entries:</label>
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Select
                    listItemHeight={10}
                    size='small'
                    defaultValue="10"
                    onSelect={onSelect}
                    style={{ width: 60, borderRadius: 20 }}
                    dropdownStyle={{ zIndex: 900 }}
                    options={[
                        { value: '10', label: '10' },
                        { value: '20', label: '20' },
                        { value: '30', label: '30' },
                        { value: '40', label: '40' },
                        { value: '50', label: '50' },
                    ]}
                />
            </ConfigProvider>
        </>
    )
}

export default ShowEntries;