import React,{FC,useRef, useState} from 'react'
import { Select, ConfigProvider } from 'antd';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { format, startOfMonth,subDays } from 'date-fns'


interface Props{
  daysFilter:(startdate:string,enddate:string,value:string) => void
  customFilterStatusChange:(status: boolean) => void,
  Value?: string
}

const DaysFilter:FC<Props> = ({daysFilter, customFilterStatusChange, Value}) => {
    const [show,setShow] = useState<boolean>(false)
    const [dateValue, setDateValue] = useState<any>([startOfMonth(new Date()), new Date()])
    const toggleRef = useRef<HTMLDivElement>(null)

    // const selectedRef1 = useRef<HTMLSpanElement>(null)

    const handleChange = (value:string)=>{
        let today = new Date()
        if(value==="Last 7 Days"){
           const lastSevenDaysAgo = format(subDays(today,6),'dd-MMM-yyyy');
           const currentDate = format(today,'dd-MMM-yyyy')
           customFilterStatusChange(false)
           daysFilter(lastSevenDaysAgo,currentDate,value)

           return
        }else  if(value==="Last 14 Days"){
            const lastFourteenDaysAgo = format(subDays(today,13),'dd-MMM-yyyy') 
            const currentDate = format(today,'dd-MMM-yyyy') 
            customFilterStatusChange(false)
            daysFilter(lastFourteenDaysAgo,currentDate,value)

            return
        }else if(value ==='Last 30 Days'){
            const lastThirtyDaysAgo = format(subDays(today,29),'dd-MMM-yyyy') 
            const currentDate = format(today,'dd-MMM-yyyy')
            customFilterStatusChange(false) 
            daysFilter(lastThirtyDaysAgo,currentDate,value)

            return
        }else if(value ==='Last 60 Days'){
            const lastSixtyDaysAgo = format(subDays(today,59),'dd-MMM-yyyy') 
            const currentDate = format(today,'dd-MMM-yyyy') 
            customFilterStatusChange(false)
            daysFilter(lastSixtyDaysAgo,currentDate,value)

            return
        }else if(value === "Last 90 Days"){
            const last90Days = format(subDays(today, 89),'dd-MMM-yyyy');
            customFilterStatusChange(false)
            daysFilter(last90Days,format(today,'dd-MMM-yyyy'),value)

            return
        }else if(value === "Custom Date Range"){
            customFilterStatusChange(true)
        }
    }

    const claenderonChange = ()=>{

    }

    return (
        <div className='position-relative'> 
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                <Select
                    // onMouseEnter={()=>setShow(false)}
                    // open={true}
                    defaultValue={Value ? Value : 'Last 7 Days'}
                    size={'small'}
                    style={{ width: 160 }}
                    dropdownStyle={{ zIndex: 900 }}
                    onChange={handleChange}
                    options={[
                    { value: 'Last 7 Days', label:'Last 7 Days'},    
                    { value: 'Last 14 Days', label:'Last 14 Days'},
                    { value: 'Last 30 Days', label:'Last 30 Days' },
                    { value: 'Last 60 Days', label:'Last 60 Days' },
                    { value: 'Last 90 Days', label:'Last 90 Days'},
                    { value: 'Custom Date Range', label:'Custom Date Range'},

                      ]}>
                </Select>
            </ConfigProvider>
            {/* <div ref={toggleRef} className={show?' date-show date-range':'date-none'}>
             <div className='to-date'>From Date / To Date</div>   
            <DateRangePicker onChange={claenderonChange} value={dateValue}/>         
           </div> */}
        </div>
    )
}

export default DaysFilter;