import { FC, useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Modal, Input, Button, ConfigProvider, Select, Form } from "antd";
import LoadingSpinner from '../spinner/loading-spinner'
import { Utility } from "../../helper/index";
import service from '../../service/api/common';
import PaginationComponent from '../reusableComponent/pagination';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import ShowEntries from '../reusableComponent/showEntries';
import { format, parseISO, subDays, addDays } from 'date-fns'
import OperationSummaryCounts from './operationSummary';
import SessionWarning from '../reusableComponent/sessionWarning';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DaysFilter from '../reusableComponent/DaysFilter';
import constantUrl from '../../settings/constants';
import { resize, hideSidebar } from '../../settings/resize';
import { useLocation } from 'react-router-dom';
import React from 'react';
import DatePicker from 'react-date-picker';

const today = format(new Date(), 'dd-MMM-yyyy')
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy')

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    email: boolean,
    // refresh: number
    setEmail: () => void
}

const reqBody = {
    "orgId": 0,
    "divisionId": -1,
    "locationId": -1,
    "buildingId": -1,
    "startDate": sevenDaysAgo,
    "endDate": today,
    "sortColumn": "batteryId",
    "sortDirection": "ASC",
    "pageOffset": 1,
    "pageLimit": 10,
    "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "weekStartDay": "MON"
}



const HighTempStatusReport: FC<Props> = ({ globalData, action, email, setEmail }) => {
    // const HighTempStatusReport: FC<Props> = ({ globalData, action, email, setEmail, refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [sort, setSort] = useState<any>({ battery: false, charger: false, datee: false, timee: false, maxTemp: false, maxTemperature: false, highTemp: false, highTemperatureLimit: false })
    const [totalCount, setTotalCount] = useState<number>(1)
    const [operationStatusReport, setOperationStatusReport] = useState<any[]>([])
    const [emailText, setEmailText] = useState<string>("")
    const tabelRef = useRef<HTMLDivElement>(null)
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
    const [ismailsuccess, setIsMailSuccess] = useState<boolean>(false)
    const [validEmail, setValidEmail] = useState<string>("")
    const [ismailfailure, setIsMailFailure] = useState<boolean>(false)
    const [invalidEmail, setInValidEmail] = useState<string>("")
    const [dateLabel, setDateLabel] = useState<string>("Last 7 Days")
    const [dateRange, setDateRange] = useState<any>({ sevenDaysAgo: format(subDays(new Date(), 6), 'dd-MMM-yyyy'), today: format(new Date(), 'dd-MMM-yyyy') })
    const [logReqBody, setlogReqBody] = useState<any>(reqBody)
    const [countReqBody, setCountReqBody] = useState<any>(reqBody)
    const [summaryReq, setSummaryReq] = useState({ orgId: 0, divisionId: 0, locationId: 0, buildingId: 0, startDate: sevenDaysAgo, endDate: today, "weekStartDay": localStorage.getItem("weeklyStartDay") })
    const [operationSummaryCount, setOperationSummaryCount] = useState<any>({ highTempBatteries: 0, totalAhsCharged: 0, totalWateringEvents: 0, totalCompletedUnits: 0, totalOverdueUnits: 0, totalUpcomingUnits: 0 })
    const [initial, setInitial] = useState<boolean>(true);
    const [bgColor, setBgColor] = useState<any>({ highTempBg: "#E5EDF5", ahrsBg: "", eqBg: "", waterBg: "" })
    const [weekDay, setWeekDay] = useState<any>(localStorage.getItem('weeklyStartDay'))

    let activeRequests = 0;

    const location = useLocation()

    // useEffect(() => {
    //     if(initial === false) {
    //         let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    //         let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    //         // To calculate the time difference of two dates
    //         var Difference_In_Time = new Date(date2).getTime() - new Date(date1).getTime();

    //         // To calculate the no. of days between two dates
    //         var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24));
    //         if (date1 && date2) {
    //             daysFilter(date1 ?? sevenDaysAgo, date2 ?? today, `Last ${Difference_In_Days+1} Days`);
    //         }
    //     }
    // }, [value])

    const onChangeWeek = async (val: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        let payload = {
            userName: localStorage?.userName,
            sendOpsReport: localStorage?.sendOpsReport,
            weeklyStartDay: val
        }

        try {
            const response = await service({
                url: constantUrl.api.operationStatus.updateOpsStatus,
                method: "PUT",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });
            if (response == 1) {
                localStorage.setItem("weeklyStartDay", val);
                let date_selected = value[0];
                setWeekDay(val)

                date_selected = new Date(date_selected.getFullYear(), date_selected.getMonth(), date_selected.getDate());
                switch (val) {
                    case "SUN":
                        while (date_selected.getDay() !== 0) {
                            date_selected.setDate(date_selected.getDate() - 1);
                        }
                        break;
                    case "MON":
                        while (date_selected.getDay() !== 1) {
                            date_selected.setDate(date_selected.getDate() - 1);
                        }
                        break;
                    case "TUE":
                        while (date_selected.getDay() !== 2) {
                            date_selected.setDate(date_selected.getDate() - 1);
                        }
                        break;
                    case "WED":
                        while (date_selected.getDay() !== 3) {
                            date_selected.setDate(date_selected.getDate() - 1);
                        }
                        break;
                    case "THU":
                        while (date_selected.getDay() !== 4) {
                            date_selected.setDate(date_selected.getDate() - 1);
                        }
                        break;
                    case "FRI":
                        while (date_selected.getDay() !== 5) {
                            date_selected.setDate(date_selected.getDate() - 1);
                        }
                        break;
                    case "SAT":
                        while (date_selected.getDay() !== 6) {
                            date_selected.setDate(date_selected.getDate() - 1);
                        }
                        break;
                    default:
                        return;
                }
                let date_end = new Date(date_selected.getTime() + (7 * 24 * 60 * 60 * 1000));

                // if (val == "SUN") {
                //     do {
                //         date_selected.setDate(date_selected.getDate() - 1);
                //     } while (date_selected.getDay() !== 0);
                // } else if (val == "MON") {
                //     do {
                //         date_selected.setDate(date_selected.getDate() - 1);
                //     } while (date_selected.getDay() !== 1);
                // } else if (val == "TUE") {
                //     do {
                //         date_selected.setDate(date_selected.getDate() - 1);
                //     } while (date_selected.getDay() !== 2);
                // } else if (val == "WED") {
                //     do {
                //         date_selected.setDate(date_selected.getDate() - 1);
                //     } while (date_selected.getDay() !== 3);
                // } else if (val == "THU") {
                //     do {
                //         date_selected.setDate(date_selected.getDate() - 1);
                //     } while (date_selected.getDay() !== 4);
                // } else if (val == "FRI") {
                //     do {
                //         date_selected.setDate(date_selected.getDate() - 1);
                //     } while (date_selected.getDay() !== 5);
                // } else if (val == "SAT") {
                //     do {
                //         date_selected.setDate(date_selected.getDate() - 1);
                //     } while (date_selected.getDay() !== 6);
                // }

                // let date_end = addDays(new Date(date_selected), 6);

                onChange([new Date(date_selected), new Date(date_end)]);

                let data: any = sessionStorage.getItem('globalData');
                data = data && JSON.parse(data);
                setCurrent(1);
                let org = data?.orgId > -1 ? data?.orgId : userId;

                setDateRange({ sevenDaysAgo: format(date_selected, 'dd-MMM-yyyy'), today: format(date_end, 'dd-MMM-yyyy') })
                setlogReqBody({ ...logReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(date_selected, 'dd-MMM-yyyy'), endDate: format(date_end, 'dd-MMM-yyyy') })
                setCountReqBody({ ...countReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(date_selected, 'dd-MMM-yyyy'), endDate: format(date_end, 'dd-MMM-yyyy') })
                setSummaryReq({ ...summaryReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(date_selected, 'dd-MMM-yyyy'), endDate: format(date_end, 'dd-MMM-yyyy') })
                setTimeout(() => {
                    setInitial(false)
                }, 2000);
            }
        } catch (error) {
            console.error("eqOverdue:", error);
        }
    }

    // useEffect(() => {
    //     if (refresh === 0) return
    //     setlogReqBody({ ...logReqBody })
    //     setCountReqBody({ ...countReqBody})
    //     setSummaryReq({ ...summaryReq })
    // }, [refresh])

    useEffect(() => {
        getData()
    }, [logReqBody]);

    useEffect(() => {
        getCount()
    }, [countReqBody]);

    useEffect(() => {
        if (summaryReq.orgId === 0) return
        getSummary()
    }, [summaryReq])

    async function getSummary() {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        summaryReq.weekStartDay = weekDay;
        setIsShowSpinner(true)
        activeRequests++;

        try {
            const response = await service({
                url: constantUrl.api.dashboard.getOperationStatus,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: summaryReq
            });
            setOperationSummaryCount(response)
        } catch (error) {
            console.error("getOperationStatus:", error);
        } finally {
            activeRequests--;
            if (activeRequests === 0) {
                setIsShowSpinner(false);
            }
        }
    }

    async function getData() {
        if (logReqBody.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        logReqBody.weekStartDay = weekDay;
        setIsShowSpinner(true)
        activeRequests++;

        try {
            const response = await service({
                url: constantUrl.api.operationStatus.highTemp,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: logReqBody
            });
            setOperationStatusReport(response);
        } catch (error) {
            console.error("highTemp:", error);
        } finally {
            activeRequests--;
            if (activeRequests === 0) {
                setIsShowSpinner(false);
            }
        }
    }

    async function getCount() {
        if (countReqBody.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        countReqBody.weekStartDay = weekDay;
        try {
            const response = await service({
                url: constantUrl.api.operationStatus.highTempCount,
                method: "POST",
                data: countReqBody,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTotalCount(response);
        } catch (error) {
            console.error("highTempCount:", error);
        }
    }

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useEffect(() => {
        setInitial(true)
        resize();
        hideSidebar();
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        setCurrent(1);
        let org = data?.orgId > -1 ? data?.orgId : userId;
        let startDate
        let endDate
        let week_day = localStorage.getItem("weeklyStartDay");
        if (location.state) {
            setShowDateFilter(true)
            startDate = new Date(location.state.date.startDate)
            endDate = new Date(location.state.date.endDate)

            startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            switch (week_day) {
                case "SUN":
                    while (startDate.getDay() !== 0) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "MON":
                    while (startDate.getDay() !== 1) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "TUE":
                    while (startDate.getDay() !== 2) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "WED":
                    while (startDate.getDay() !== 3) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "THU":
                    while (startDate.getDay() !== 4) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "FRI":
                    while (startDate.getDay() !== 5) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "SAT":
                    while (startDate.getDay() !== 6) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                default:
                    return;
            }
            endDate = new Date(startDate.getTime() + (7 * 24 * 60 * 60 * 1000));

            onChange([new Date(startDate), new Date(endDate)]);
            setDateLabel(`Selected Week`)
            setDateRange({ sevenDaysAgo: format(startDate, 'dd-MMM-yyyy'), today: format(endDate, 'dd-MMM-yyyy') })
            setlogReqBody({ ...logReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(startDate, 'dd-MMM-yyyy'), endDate: format(endDate, 'dd-MMM-yyyy') })
            setCountReqBody({ ...countReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(startDate, 'dd-MMM-yyyy'), endDate: format(endDate, 'dd-MMM-yyyy') })
            setSummaryReq({ ...summaryReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(startDate, 'dd-MMM-yyyy'), endDate: format(endDate, 'dd-MMM-yyyy') })
            setTimeout(() => {
                setInitial(false)
            }, 2000);
        } else {
            startDate = new Date()
            endDate = new Date()

            startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            switch (week_day) {
                case "SUN":
                    while (startDate.getDay() !== 0) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "MON":
                    while (startDate.getDay() !== 1) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "TUE":
                    while (startDate.getDay() !== 2) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "WED":
                    while (startDate.getDay() !== 3) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "THU":
                    while (startDate.getDay() !== 4) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "FRI":
                    while (startDate.getDay() !== 5) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                case "SAT":
                    while (startDate.getDay() !== 6) {
                        startDate.setDate(startDate.getDate() - 1);
                    }
                    break;
                default:
                    return;
            }
            endDate = new Date(startDate.getTime() + (7 * 24 * 60 * 60 * 1000));

            onChange([new Date(startDate), new Date(endDate)]);
            setDateLabel(`Selected Week`)
            setDateRange({ sevenDaysAgo: format(startDate, 'dd-MMM-yyyy'), today: format(endDate, 'dd-MMM-yyyy') })
            setlogReqBody({ ...logReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(startDate, 'dd-MMM-yyyy'), endDate: format(endDate, 'dd-MMM-yyyy') })
            setCountReqBody({ ...countReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(startDate, 'dd-MMM-yyyy'), endDate: format(endDate, 'dd-MMM-yyyy') })
            setSummaryReq({ ...summaryReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, startDate: format(startDate, 'dd-MMM-yyyy'), endDate: format(endDate, 'dd-MMM-yyyy') })
            setTimeout(() => {
                setInitial(false)
            }, 2000);
        }
    }, [])

    useEffect(() => {
        if (initial === false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setlogReqBody({ ...logReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId })
            setCountReqBody({ ...countReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId })
            setSummaryReq({ ...summaryReq, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId])

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "battery":
                if (sort.battery) {
                    setlogReqBody({ ...logReqBody, sortColumn: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "charger":
                if (sort.charger) {
                    setlogReqBody({ ...logReqBody, sortColumn: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxTemperature":
                if (sort.maxTemperature) {
                    setlogReqBody({ ...logReqBody, sortColumn: "maxTemperature", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "maxTemperature", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "highTemp":
                if (sort.highTemp) {
                    setlogReqBody({ ...logReqBody, sortColumn: "daysHighTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "daysHighTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "highTemperatureLimit":
                if (sort.highTemperatureLimit) {
                    setlogReqBody({ ...logReqBody, sortColumn: "highTemperatureLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "highTemperatureLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };

    const formattedDate = (date: string) => {
        const date1 = date;
        const parsedDate = parseISO(date1);
        const formatDate = format(parsedDate, 'MM/dd/yyyy ');
        return formatDate
    }

    const formattedTime = (date: string) => {
        const date1 = date;
        const parsedDate = parseISO(date1);
        const formatTime = format(parsedDate, 'hh:mm:ss');
        return formatTime
    }

    const claenderonChange = (startDate: string, endDate: string) => {
        setDateRange({ ...dateRange, sevenDaysAgo: startDate, today: endDate })
    }

    useEffect(() => {
        if (action !== "") getDowFormat(action)
    }, [action])

    const getDowFormat = async (format: string) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken
        setIsShowSpinner(true)
        let payload =
        {
            orgId: logReqBody?.orgId,
            divisionId: logReqBody?.divisionId,
            locationId: logReqBody?.locationId,
            buildingId: logReqBody?.buildingId,
            startDate: logReqBody?.startDate,
            endDate: logReqBody?.endDate,
            pageOffset: 1,
            pageLimit: totalCount + 1,
            sortColumn: logReqBody?.sortColumn,
            sortDirection: logReqBody?.sortDirection,
            timeZone: logReqBody?.timeZone,
            weekStartDay: weekDay
        }
        try {
            const response = await service({
                url: constantUrl.api.operationStatus.highTempDownload + '/' + format,
                method: 'POST',
                responseType: 'blob',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            if (response) {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Operation Status Report - High Temp Batteries.' + format;
                a.click();
                setIsShowSpinner(false)
            }
            setIsShowSpinner(false)
        } catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setlogReqBody({ ...logReqBody, pageLimit: numPageSize, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, pageLimit: numPageSize, pageOffset: 1 })
    };

    const handlePagination = (pageNumber: number) => {
        setlogReqBody({ ...logReqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const daysFilter = (startDate: string, endDate: string, value?: string) => {
        if (initial === false) {
            setlogReqBody({ ...logReqBody, startDate, endDate })
            setCountReqBody({ ...countReqBody, startDate, endDate })
            setSummaryReq({ ...summaryReq, startDate, endDate })
            claenderonChange(startDate, endDate)
            value && setDateLabel(value)
            setCurrent(1);
        }
    };

    const toggleEmail = () => {
        setEmailText("")
        setValidEmail("")
        setEmail()
    }

    const sendEmail = async () => {
        if (isShowSpinner) return;
        if (!emailText) {
            setValidEmail("Please Enter Email Id")
            return
        }
        if (validateEmail(emailText) === false) return
        setIsShowSpinner(true)
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken
        let payload =
        {
            orgId: logReqBody?.orgId,
            divisionId: logReqBody?.divisionId,
            locationId: logReqBody?.locationId,
            buildingId: logReqBody?.buildingId,
            startDate: logReqBody?.startDate,
            endDate: logReqBody?.endDate,
            pageOffset: 1,
            pageLimit: totalCount + 1,
            sortColumn: logReqBody?.sortColumn,
            sortDirection: logReqBody?.sortDirection,
            timeZone: logReqBody?.timeZone,
            toEmail: emailText,
            weekStartDay: localStorage.getItem('weeklyStartDay')
        }
        try {
            const response = await service({
                url: constantUrl.api.operationStatus.highTempEmail,
                method: 'POST',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            if (response.status === "FAILURE" && response.errorCode === 1125 && response.data === "Email Sending failed !!!") {
                setValidEmail(response.data)
                setEmail()
            }
            if (response.data === "Email Sent Successfully !!!") {
                setEmailText("")
                setIsMailSuccess(true)
                setEmail()
            }
            if (response.status === "FAILURE" && response.errorCode === 3123) {
                setValidEmail(response.data)
            }
            if (response.status === "FAILURE" && response.errorCode === 3112) {
                setInValidEmail(response.errorMessage)
                setIsMailFailure(true)
                setEmailText("")
                setEmail()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsShowSpinner(false)
        }
    }

    const validateEmail = (email: any) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email)
    };

    const handleChange = (event: any) => {
        if (validateEmail(event.target.value)) {
            setEmailText(event.target.value)
            setValidEmail("")
        } else {
            setEmailText(event.target.value)
            setValidEmail("Please Enter Valid Email ID")
        }
    }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <>
                <div className="row rounded operation-status-top pr-3 pl-3 pb-3">
                    {
                        (location.state) ?

                            <OperationSummaryCounts
                                operationCounts={{
                                    highTempCount: operationSummaryCount.highTempBatteries,
                                    ahrsCount: operationSummaryCount.totalAhsCharged,
                                    eqCount: operationSummaryCount.totalOverdueUnits,
                                    waterCount: operationSummaryCount.totalWateringEvents
                                }}
                                heading={"Operations Summary"}
                                bgColor={bgColor}
                                date={location.state.date}
                            />
                            :
                            <OperationSummaryCounts
                                operationCounts={{
                                    highTempCount: operationSummaryCount.highTempBatteries,
                                    ahrsCount: operationSummaryCount.totalAhsCharged,
                                    eqCount: operationSummaryCount.totalOverdueUnits,
                                    waterCount: operationSummaryCount.totalWateringEvents
                                }}
                                heading={"Operations Summary"}
                                bgColor={bgColor}
                            />
                    }
                    <div>
                        <ConfigProvider>
                            <div className='week-start-end'>
                                <span style={{ color: "rgba(0, 0, 0, 0.88)" }}>Week Start/End: </span>
                                <Select
                                    value={weekDay}
                                    size={'small'}
                                    style={{ width: 200 }}
                                    dropdownStyle={{ zIndex: 900 }}
                                    onChange={onChangeWeek}
                                    options={[
                                        { value: 'SUN', label: 'Sunday to Saturday' },
                                        { value: 'MON', label: 'Monday to Sunday' },
                                        { value: 'TUE', label: 'Tuesday to Monday' },
                                        { value: 'WED', label: 'Wednesday to Tuesday' },
                                        { value: 'THU', label: 'Thursday to Wednesday' },
                                        { value: 'FRI', label: 'Friday to Thursday' }
                                    ]}>
                                </Select>
                            </div>
                        </ConfigProvider>
                        <br />
                        <br />
                        <Form.Item
                            label="Date Of Week"
                            style={{ marginBottom: "5px" }}
                        >
                            <DatePicker
                                className="w-100"
                                onChange={(value: any) => {
                                    if (value) {
                                        let val = new Date(value);

                                        let date_selected = new Date(val.getFullYear(), val.getMonth(), val.getDate());
                                        switch (weekDay) {
                                            case "SUN":
                                                while (date_selected.getDay() !== 0) {
                                                    date_selected.setDate(date_selected.getDate() - 1);
                                                }
                                                break;
                                            case "MON":
                                                while (date_selected.getDay() !== 1) {
                                                    date_selected.setDate(date_selected.getDate() - 1);
                                                }
                                                break;
                                            case "TUE":
                                                while (date_selected.getDay() !== 2) {
                                                    date_selected.setDate(date_selected.getDate() - 1);
                                                }
                                                break;
                                            case "WED":
                                                while (date_selected.getDay() !== 3) {
                                                    date_selected.setDate(date_selected.getDate() - 1);
                                                }
                                                break;
                                            case "THU":
                                                while (date_selected.getDay() !== 4) {
                                                    date_selected.setDate(date_selected.getDate() - 1);
                                                }
                                                break;
                                            case "FRI":
                                                while (date_selected.getDay() !== 5) {
                                                    date_selected.setDate(date_selected.getDate() - 1);
                                                }
                                                break;
                                            case "SAT":
                                                while (date_selected.getDay() !== 6) {
                                                    date_selected.setDate(date_selected.getDate() - 1);
                                                }
                                                break;
                                            default:
                                                return;
                                        }
                                        let date_end = new Date(date_selected.getTime() + (7 * 24 * 60 * 60 * 1000));

                                        // let val1 = addDays(new Date(val), 6);
                                        let date1: any = date_selected && format(date_selected, 'dd-MMM-yyyy');
                                        let date2: any = date_end && format(date_end, 'dd-MMM-yyyy');
                                        // To calculate the time difference of two dates
                                        var Difference_In_Time = new Date(date2).getTime() - new Date(date1).getTime();

                                        // To calculate the no. of days between two dates
                                        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                        if (date1 && date2) {
                                            daysFilter(date1 ?? sevenDaysAgo, date2 ?? today, `Last ${Difference_In_Days} Days`);
                                        }
                                        onChange([date_selected, date_end])
                                    }
                                    else {
                                        onChange(["", ""])
                                    }
                                }}
                                value={value[0]}
                                maxDate={new Date()} />
                        </Form.Item>
                    </div>
                    {/* <div className="days__filter">
                        {
                            showDateFilter && <span>
                                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                            </span>
                        }
                        <span>
                            <DaysFilter daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} Value={location.state?.date?.startDate ? "Custom Date Range" : ""} />
                        </span>
                    </div> */}
                    <ConfigProvider
                        theme={{ token: { colorPrimary: "#004f9f" } }}>
                        <Modal
                            centered
                            open={email}
                            onCancel={toggleEmail}
                            onOk={() => { setEmail(); setEmailText("") }}
                            closable={true}
                            footer={null}
                            maskClosable={false}
                            width={300}
                        >
                            <div>
                                <div className='email-report-title'>Email Report</div>
                                <div className='email-label'>Email Address</div>
                                <Input
                                    placeholder="Enter Email"
                                    type='email'
                                    value={emailText}
                                    onChange={handleChange}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            sendEmail();
                                        }
                                    }}
                                />
                                {validEmail ? <span className='text-danger'>{validEmail}</span> : ""}
                                <Button
                                    block
                                    onClick={sendEmail}
                                    style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", marginBlock: 8 }}>
                                    Send
                                </Button>
                            </div>
                        </Modal>
                    </ConfigProvider>
                </div>
                <div className="main-panel">
                    <div className="ChartPanel panelShadow bg-white">
                        <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                            <div className='ml-auto mr-auto'>Operation Status Report</div>
                            <div className='show-entries'>
                                <ShowEntries getPageSize={onSelect} />
                            </div>
                        </div>
                        <div className='gap marginBlock'>
                            <div className='range pl-3'>{dateLabel.includes('Last') ? 'Last ' + Math.ceil(Number(dateLabel.split(" ")[1])) + ' Days' : dateLabel}</div>
                            <div className='this-week'>{"(" + dateRange.sevenDaysAgo + " to " + dateRange.today + ")"}</div>
                        </div>
                        <div id="table-container">
                            <div ref={tabelRef} className="operationstatus overflow-auto customScroll">
                                <table className="billable" style={{ width: "100%" }}>
                                    <thead className='tableHeader'>
                                        <tr>
                                            <th>
                                                <div onClick={() => sortBy('battery')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Battery ID</span>
                                                    {sort.battery ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('charger')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Vehicle ID</span>
                                                    {sort.charger ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Max Temp Exceeded</span>
                                                    {/* {sort.maxTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />} */}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('maxTemperature')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Max Temp</span>
                                                    {sort.maxTemperature ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('highTemperatureLimit')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Max Temperature Limit</span>
                                                    {sort.highTemperatureLimit ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Days High Temp</span>
                                                    {/* {sort.highTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />} */}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {operationStatusReport?.map((item: any) => {
                                            const { batteryId, vehicleId, maxTemperatureExceeded, daysHighTemp, maxTemperature, formatDatestamp, highTemperatureLimit } = item
                                            return <tr>
                                                <td>{batteryId}</td>
                                                <td>{vehicleId}</td>
                                                <td><span className={maxTemperatureExceeded ? "round dot-red2" : "round dot-green"} ></span></td>
                                                <td>{maxTemperature}</td>
                                                <td>{highTemperatureLimit}</td>
                                                <td>{daysHighTemp}</td>
                                                {/* <td>{formatDatestamp}</td> */}

                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <PaginationComponent
                                handlePagination={handlePagination}
                                totalLength={totalCount}
                                pageSize={pageSize}
                                current={current}
                            />
                        </div>
                    </div>
                </div>
                <SessionWarning />
                <Modal open={ismailsuccess} closable={false} centered title={"Email is sent Successfully"}
                    footer={[
                        <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setIsMailSuccess(false)}>OK</Button>
                    ]}
                ></Modal>
                <Modal open={ismailfailure} closable={false} centered title={invalidEmail}
                    footer={[
                        <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setIsMailFailure(false)}>OK</Button>
                    ]}
                ></Modal>
            </>
        </>
    )
}

export default HighTempStatusReport;