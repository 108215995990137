import React, { FC } from 'react';

interface Props {
    summaryData: { 
        severeFaults: number, 
        overdueTasks: number, 
        upcomingTasks: number 
    }
}

const TaskSummary: FC<Props> = ({ summaryData }) => {

    return (
        <div className="row posicharge-div posi_mobile_tasksummary">
            <div className="col-md-4 server-faults-container admin-posicharge1">
                <div className="server-faults-location posi_mobile_tasksummary_card">
                    <img src={require("../../images/Alerts-Icon.png")} className="img-size-top" alt='img' />
                    <div style={{ width: '100%' }}>
                        <div id="sever-faults-count" className="textAlign position-top">{summaryData?.severeFaults}</div>
                        <div className="text-right position-bottom posi_mobile_tasksummary_card_desc">SEVERE FAULTS</div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 posicharge-overdue-task admin-posicharge2">
                <div className="overdue-task posi_mobile_tasksummary_card">
                    <img src={require("../../images/OverdueTasks.png")} className="img-size-top" alt='img' />
                    <div>
                        <div id="overdue-count" className="textAlign position-top">{summaryData?.overdueTasks}</div>
                        <div className="text-right position-bottom posi_mobile_tasksummary_card_desc">OVERDUE TASKS</div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 posicharge-upcoming-tasks admin-posicharge3">
                <div className="upcoming-tasks posi_mobile_tasksummary_card">
                    <img src={require("../../images/Upcoming-Tasks.png")} className="img-size-top" alt='img' />
                    <div>
                        <div id="upcoming-count" className="textAlign position-top">{summaryData?.upcomingTasks}</div>
                        <div className="text-right position-bottom posi_mobile_tasksummary_card_desc">UPCOMING TASKS</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TaskSummary;

