import React, { useRef, useState, useEffect, FC } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import LoadingSpinner from '../spinner/loading-spinner'
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import DaysFilter from "../reusableComponent/DaysFilter";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, subDays, parseISO } from 'date-fns';
import SessionWarning from '../reusableComponent/sessionWarning';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { resize, hideSidebar } from '../../settings/resize';
import { Button, ConfigProvider, Input, Select, Space } from "antd";

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 7), 'dd-MMM-yyyy');

type reqBody = {
  orgId: number,
  divisionId: number,
  locationId: number,
  buildingId: number,
  startDate: string,
  endDate: string,
  batteryId: string,
  vehicleId: string,
  batteryRxSim: string,
  pageOffset: number,
  pageLimit: number,
  sortColumns: string,
  sortDirection: string,
  timeZone: string
}

let Body = {
  orgId: 0,
  divisionId: -1,
  locationId: 21,
  buildingId: -1,
  startDate: sevenDaysAgo,
  endDate: today,
  batteryId: "",
  vehicleId: "",
  batteryRxSim: "",
  pageOffset: 1,
  pageLimit: 10,
  sortColumns: "batteryId",
  sortDirection: "ASC",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string
  // refresh: number
}

const OperationStatusReport: FC<Props> = ({ globalData, action}) => {
  // const OperationStatusReport: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [selector, setSelector] = useState(false);
  const [sort, setSort] = useState<any>({
    orgName: false, divisionName: false, locationName: false, buildingName: false, batteryId: false, vehicleId: false, batteryRxSimNumber: false, dateLastCompleteSession: false,
    batteryCapacity: false, eqComplete: false, lowSoc: false, totalPlugins: false, cumulativeChargeTimeHours: false, totalChargeAhrs: false, totalDischargeAhrs: false,
    maxTempExceeded: false, avgTemp: false, daysHighTempEvents: false, daysLowWateringEvents: false, maxAhrsDailyThroughput: false, totalCompletedUnits: false,
    totalUpcomingUnits: false, totalOverdueUnits: false
  });
  const [tableReport, setTableReport] = useState<any[]>([])
  const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
  const [exportReqBody, setExportReqBody] = useState<reqBody>(Body)
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [countReq, setCountReq] = useState<reqBody>(Body)
  const [count, setCount] = useState<number>(0)
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);

  const selectorRef = useRef<HTMLDivElement>(null);
  const [emptyError, setemptyError] = useState<string>("");
  const [searchObject, setSearchObject] = useState<any>({
    batteryId: '',
    vehicleId: '',
    batteryRxSim: ''
  })

  const DropList = [
    { label: "Battery Id" },
    { label: "Vehicle ID" },
    { label: "Battery Rx SIM" }

  ];

  const [dropdownvalue, setDropDownValue] = useState<string>("Battery Id");
  const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
  const [inputvalue, setInput] = useState<any>("")
  const [column, setColumn] = useState<any[]>([])
  const [selectorLabel, setSelectorLabel] = useState<any[]>([])

  const onChangedropdown = (value: string) => {
    setemptyError("")
    setDropDownValue(value);
    setPlaceholder(`Search ${value}`)
    setInput("");
    setSearchObject({
      batteryId: '',
      vehicleId: '',
      batteryRxSim: ''
    })

  }

  const onChangePayload = (value: any) => {
    let obj = { ...searchObject };
    if (dropdownvalue === "Battery Id") {
      obj.batteryId = value;
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    } else if (dropdownvalue === "Battery Rx SIM") {
      obj.batteryRxSim = value;
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    } else if (dropdownvalue === "Vehicle ID") {
      obj.vehicleId = value;
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    }
    setemptyError("")
  }

  const callCountAndReport = (obj: any) => {
    setTableReqBody({ ...tableReqBody, ...obj })
    setExportReqBody({ ...exportReqBody, ...obj })
    setCountReq({ ...countReq, ...obj })
  };

  // Search Enter Button
  const onPressEnter = (event: any) => {
    let value = event.target.value.trim()
    let obj = { ...searchObject };
    switch (dropdownvalue) {
      case 'Battery Id':
        obj.batteryId = value;
        break;
      case 'Battery Rx SIM':
        obj.batteryRxSim = value;
        break;
      case 'Vehicle ID':
        obj.vehicleId = value;
        break;
    }
    setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, ...obj, pageOffset: 1 })
    setCountReq({ ...countReq, ...obj, pageOffset: 1 })
  };

  const handleSearch = () => {
    const { batteryId, vehicleId, batteryRxSim } = searchObject
    switch (dropdownvalue) {
      case 'Battery Id':
        if (!batteryId) {
          setemptyError("Please Enter Battery Id")
          return
        }
        break;
      case 'Battery Rx SIM':
        if (!batteryRxSim) {
          setemptyError("Please Enter Battery Rx SIM")
          return
        }
        break;
      case 'Vehicle ID':
        if (!vehicleId) {
          setemptyError("Please Enter Vehicle ID")
          return
        }
        break;
      default:
        setemptyError("")
    }
    setTableReqBody({ ...tableReqBody, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, pageOffset: 1 })
    setCountReq({ ...countReq, pageOffset: 1 })
  }

  const [value, onChange] = useState<any>([subDays(new Date(), 7), new Date()]);

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
    }
  }, [value])

  // useEffect(() => {
  //   getOperationReport()
  //   dataCount()
  // }, [tableReqBody])

  // useEffect(() => {
  //   if (refresh === 0) return
  //   getOperationReport()
  //   dataCount()
  // }, [refresh]);

  const getOperationReport = async () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let payload = tableReqBody;

    if (searchObject?.batteryId !== "") {
      payload.batteryId = searchObject?.batteryId.trim();
    } else {
      payload.batteryId = data?.batteryId;
    }

    if (searchObject?.vehicleId !== "") {
      payload.vehicleId = searchObject?.vehicleId.trim();
    } else {
      payload.vehicleId = data?.vehicleId;
    }

    payload.batteryRxSim = searchObject?.batteryRxSim.trim();

    if (payload.orgId === 0 || payload.startDate === payload.endDate) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    setIsShowSpinner(true)
    try {
      const response = await service({
        // url: constantUrl.api.,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setTableReport(response)
      setIsShowSpinner(false)
    } catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  }
  const dataCount = async () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let payload = tableReqBody;

    if (searchObject?.batteryId !== "") {
      payload.batteryId = searchObject?.batteryId.trim();
    } else {
      payload.batteryId = data?.batteryId;
    }

    if (searchObject?.vehicleId !== "") {
      payload.vehicleId = searchObject?.vehicleId.trim();
    } else {
      payload.vehicleId = data?.vehicleId;
    }

    payload.batteryRxSim = searchObject?.batteryRxSim.trim();

    if (payload.orgId === 0 || payload.startDate === payload.endDate) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        // url: constantUrl.api.,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        data: payload
      })
      setCount(response)
    } catch (error) {
      console.log(error)
    }
  }

  // useEffect(() => {
  //   if (action !== "") {
  //     getDowFormat(action)
  //   }
  // }, [action])

  const getDowFormat = async (format: string) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken
    setIsShowSpinner(true);
    let payload = {
      orgId: tableReqBody?.orgId,
      divisionId: tableReqBody?.divisionId,
      locationId: tableReqBody?.locationId,
      buildingId: tableReqBody?.buildingId,
      startDate: tableReqBody?.startDate,
      endDate: tableReqBody?.endDate,
      batteryId: tableReqBody?.batteryId.trim(),
      vehicleId: tableReqBody?.vehicleId.trim(),
      batteryRxSim: tableReqBody?.batteryRxSim.trim(),
      pageOffset: 1,
      pageLimit: count + 1,
      sortColumns: tableReqBody?.sortColumns,
      sortDirection: tableReqBody?.sortDirection,
      timeZone: tableReqBody?.timeZone
    }
    try {
      const response = await service({
        // url: constantUrl.api..dwn + '/' + format,
        method: 'POST',
        responseType: 'blob',
        headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
        data: payload
      })
      if (response) {
        let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'Battery-History-Log-Report.' + format;
        a.click();
        setIsShowSpinner(false);
      }
    } catch (error) {
      setIsShowSpinner(false);
      console.log(error)
    }
  }

  const selectColumn = () => {
    if (selectorRef.current && selector) {
      selectorRef.current.style.right = "-370px";
    } else {
      if (selectorRef.current) { selectorRef.current.style.right = "0px" }
    }
    setSelector(!selector);
  };

  const applyChanges = async () => {
    selectColumn()
    var columnPayload = { reportId: 'OPERATION_STATUS_REPORT', reportName: 'OPERATION_STATUS_REPORT', columnSettings: selectorLabel };
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;
    setIsShowSpinner(true);
    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}`,
        method: "PUT",
        headers: { Authorization: "Bearer " + authToken },
        data: columnPayload
      })
      setIsShowSpinner(false)
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn([...columnData])
      setSelectorLabel([...selectorData])
    }
    catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  };

  const onchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let columndata: any = selectorLabel
    let index: number = parseInt(event.target.name);
    let checked: boolean = event.target.checked;
    columndata[index].enabled = checked
    setSelectorLabel([...columndata])
    setClearAll(false)
    setSelectAll(false)
  };

  const clearall = () => {
    let columnLable = selectorLabel
    let lengths = columnLable.length
    for (let i = 0; i < lengths; i++) {
      columnLable[i]['enabled'] = false;
    }
    setSelectorLabel([...columnLable])
    setClearAll(true)
    setSelectAll(false)
  }

  const selectall = () => {
    let columnLable = selectorLabel
    let lengths = columnLable.length
    for (let i = 0; i < lengths; i++) {
      columnLable[i]['enabled'] = true;
    }
    setSelectorLabel(columnLable)
    setClearAll(false)
    setSelectAll(true)
  }

  const cancelSelection = () => {
    let columnData = selectorLabel;
    let lengths = columnData.length
    for (let i = 0; i < lengths; i++) {
      columnData[i]['enabled'] = columnData[i].enabled;
    }
    setSelectorLabel(columnData)
    selectColumn()
  };

  const getReportSettings = async () => {
    const reportId = 'OPERATION_STATUS_REPORT'
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;
    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
        method: "GET",
        headers: { Authorization: "Bearer " + authToken },
      })
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn(columnData)
      setSelectorLabel(selectorData)
    }
    catch (error) {
      console.log(error)
    }
  };

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    switch (id) {
      case "orgName":
        if (sort.orgName) {
          setTableReqBody({ ...tableReqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "divisionName":
        if (sort.divisionName) {
          setTableReqBody({ ...tableReqBody, sortColumns: "divisionName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "divisionName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "locationName":
        if (sort.locationName) {
          setTableReqBody({ ...tableReqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "buildingName":
        if (sort.buildingName) {
          setTableReqBody({ ...tableReqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "batteryId":
        if (sort.batteryId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "vehicleId":
        if (sort.vehicleId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "batteryRxSimNumber":
        if (sort.batteryRxSimNumber) {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryRxSimNumber", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryRxSimNumber", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dateLastCompleteSession":
        if (sort.dateLastCompleteSession) {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateLastCompleteSession", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateLastCompleteSession", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "batteryCapacity":
        if (sort.batteryCapacity) {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryCapacity", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryCapacity", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "eqComplete":
        if (sort.eqComplete) {
          setTableReqBody({ ...tableReqBody, sortColumns: "eqComplete", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "eqComplete", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "lowSoc":
        if (sort.lowSoc) {
          setTableReqBody({ ...tableReqBody, sortColumns: "lowSoc", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "lowSoc", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "totalPlugins":
        if (sort.totalPlugins) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalPlugins", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalPlugins", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "cumulativeChargeTimeHours":
        if (sort.cumulativeChargeTimeHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "cumulativeChargeTimeHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "cumulativeChargeTimeHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "totalChargeAhrs":
        if (sort.totalChargeAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeAhrs", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeAhrs", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "totalDischargeAhrs":
        if (sort.totalDischargeAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalDischargeAhrs", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalDischargeAhrs", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "maxTempExceeded":
        if (sort.maxTempExceeded) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxTempExceeded", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxTempExceeded", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "avgTemp":
        if (sort.avgTemp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "avgTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "avgTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "daysHighTempEvents":
        if (sort.daysHighTempEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysHighTempEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysHighTempEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "daysLowWateringEvents":
        if (sort.daysLowWateringEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysLowWateringEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysLowWateringEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "maxAhrsDailyThroughput":
        if (sort.maxAhrsDailyThroughput) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxAhrsDailyThroughput", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxAhrsDailyThroughput", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "totalCompletedUnits":
        if (sort.totalCompletedUnits) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalCompletedUnits", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalCompletedUnits", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "totalUpcomingUnits":
        if (sort.totalUpcomingUnits) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalUpcomingUnits", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalUpcomingUnits", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "totalOverdueUnits":
        if (sort.totalOverdueUnits) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalOverdueUnits", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalOverdueUnits", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
    }
  };

  const handlePagination = (pageOffset: number, c: number, maxIndex: number) => {
    setTableReqBody({ ...tableReqBody, pageOffset })
    setCurrent(pageOffset)
  }

  const onSelect = (pageLimit: any) => {
    const numPageSize = Number(pageLimit)
    setCurrent(1)
    setPageSize(numPageSize)
    setTableReqBody({ ...tableReqBody, pageLimit: numPageSize, pageOffset: 1 })
  };

  const daysFilter = (startDate: string, endDate: string) => setTableReqBody({ ...tableReqBody, startDate, endDate });

  useEffect(() => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;
    setInitial(true)
    resize();
    hideSidebar();
    getReportSettings();
    setTableReqBody({ ...tableReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setExportReqBody({ ...exportReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setCountReq({ ...countReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setInitial(false)
  }, [])

  useEffect(() => {
    if (initial == false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setTableReqBody({ ...tableReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
      setExportReqBody({ ...exportReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
      setCountReq({ ...countReq, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  const formattedDate = (date: string) => {
    const date1 = date;
    const parsedDate = parseISO(date1);
    const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
    return formatDate
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className="d-flex align-items-center">
        <div className="col-md-6 px-0 mr-auto d-flex">
          <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
            <div className='ant-design-select'>
              <Select
                value={dropdownvalue}
                size={'middle'}
                style={{ maxWidth: 180, minWidth: 160 }}
                onChange={onChangedropdown}
              >
                {DropList && DropList.map((item: any, index: any) => {
                  return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                })}
              </Select>
            </div>
            <div>
              <Space direction="vertical" size="middle">
                <Space.Compact style={{ width: '100%' }}>
                  <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                    onPressEnter={onPressEnter}
                  />
                  <Button type="primary" onClick={handleSearch}>Find</Button>
                </Space.Compact>
              </Space>
              <div className="errorColor">{emptyError}</div>
            </div>
          </ConfigProvider>
        </div>
        <div className="justify-end">
          {
            showDateFilter && <span>
              <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
            </span>
          }
          <button
            onClick={selectColumn}
            type="button"
            className="btn btn-light selector-btn"
          >
            Column Selector
          </button>
          <span>
            <DaysFilter daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} />
          </span>
        </div>
      </div>
      <div className="main-panel pt-3 position-relative">
        <div className="ChartPanel panelShadow bg-white">
          <div className="chartTitle p-2 d-flex  align-items-center position-relative">
            <div className="table_title">Operation Status Report</div>
            <div className="show-entries">
              <ShowEntries getPageSize={onSelect} />
            </div>
          </div>
          <div id="table-container">
            <div className="history_report overflow-auto scrollbar">
              <table className="billable width100">
                <thead>
                  <tr className="billable-head">
                    {column?.[0]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('orgName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[0]?.['columnName']}</span>
                        {sort.orgName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                      : ""}
                    {column?.[1]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('divisionName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[1]?.['columnName']}</span>
                        {sort.divisionName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[2]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('locationName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[2]?.['columnName']}</span>
                        {sort.locationName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[3]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('buildingName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[3]?.['columnName']}</span>
                        {sort.buildingName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[4]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[4]?.['columnName']}</span>
                        {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[5]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[5]?.['columnName']}</span>
                        {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[6]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('batteryRxSimNumber')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[6]?.['columnName']}</span>
                        {sort.batteryRxSimNumber ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[7]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dateLastCompleteSession')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[7]?.['columnName']}</span>
                        {sort.dateLastCompleteSession ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[8]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('batteryCapacity')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[8]?.['columnName']}</span>
                        {sort.batteryCapacity ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[9]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('eqComplete')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[9]?.['columnName']}</span>
                        {sort.eqComplete ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[10]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('lowSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[10]?.['columnName']}</span>
                        {sort.lowSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[11]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('totalPlugins')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[11]?.['columnName']}</span>
                        {sort.totalPlugins ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[12]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('cumulativeChargeTimeHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[12]?.['columnName']}</span>
                        {sort.cumulativeChargeTimeHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[13]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('totalChargeAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[13]?.['columnName']}</span>
                        {sort.totalChargeAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[14]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('totalDischargeAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[14]?.['columnName']}</span>
                        {sort.totalDischargeAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[15]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('maxTempExceeded')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[15]?.['columnName']}</span>
                        {sort.maxTempExceeded ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[16]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('avgTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[16]?.['columnName']}</span>
                        {sort.avgTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[17]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('daysHighTempEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[17]?.['columnName']}</span>
                        {sort.daysHighTempEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[18]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('daysLowWateringEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[18]?.['columnName']}</span>
                        {sort.daysLowWateringEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[19]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('maxAhrsDailyThroughput')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[19]?.['columnName']}</span>
                        {sort.maxAhrsDailyThroughput ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[20]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('totalCompletedUnits')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[20]?.['columnName']}</span>
                        {sort.totalCompletedUnits ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[21]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('totalUpcomingUnits')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[21]?.['columnName']}</span>
                        {sort.totalUpcomingUnits ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[22]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('totalOverdueUnits')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[22]?.['columnName']}</span>
                        {sort.totalOverdueUnits ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {tableReport?.map((item: any, index: any) => {
                    const { orgName, divisionName, locationName, buildingName, batteryId, vehicleId, batteryRxSimNumber, dateLastCompleteSession,
                      batteryCapacity, eqComplete, lowSoc, totalPlugins, cumulativeChargeTimeHours, totalChargeAhrs, totalDischargeAhrs,
                      maxTempExceeded, avgTemp, daysHighTempEvents, daysLowWateringEvents, maxAhrsDailyThroughput, totalCompletedUnits,
                      totalUpcomingUnits, totalOverdueUnits } = item
                    return (
                      <React.Fragment>
                        <tr>
                          {column?.[0]?.['enabled'] ? <td>{orgName}</td> : ""}
                          {column?.[1]?.['enabled'] ? <td>{divisionName}</td> : ""}
                          {column?.[2]?.['enabled'] ? <td>{locationName}</td> : ""}
                          {column?.[3]?.['enabled'] ? <td>{buildingName}</td> : ""}
                          {column?.[4]?.['enabled'] ? <td>{batteryId}</td> : ""}
                          {column?.[5]?.['enabled'] ? <td>{vehicleId}</td> : ""}
                          {column?.[6]?.['enabled'] ? <td>{batteryRxSimNumber}</td> : ""}
                          {column?.[7]?.['enabled'] ? <td>{dateLastCompleteSession}</td> : ""}
                          {column?.[8]?.['enabled'] ? <td>{batteryCapacity}</td> : ""}
                          {column?.[9]?.['enabled'] ? <td>{eqComplete}</td> : ""}
                          {column?.[10]?.['enabled'] ? <td>{lowSoc}</td> : ""}
                          {column?.[11]?.['enabled'] ? <td>{totalPlugins}</td> : ""}
                          {column?.[12]?.['enabled'] ? <td>{cumulativeChargeTimeHours}</td> : ""}
                          {column?.[13]?.['enabled'] ? <td>{totalChargeAhrs}</td> : ""}
                          {column?.[14]?.['enabled'] ? <td>{totalDischargeAhrs}</td> : ""}
                          {column?.[15]?.['enabled'] ? <td>{maxTempExceeded}</td> : ""}
                          {column?.[16]?.['enabled'] ? <td>{avgTemp}</td> : ""}
                          {column?.[17]?.['enabled'] ? <td>{daysHighTempEvents}</td> : ""}
                          {column?.[18]?.['enabled'] ? <td>{daysLowWateringEvents}</td> : ""}
                          {column?.[19]?.['enabled'] ? <td>{maxAhrsDailyThroughput}</td> : ""}
                          {column?.[20]?.['enabled'] ? <td>{totalCompletedUnits}</td> : ""}
                          {column?.[21]?.['enabled'] ? <td>{totalUpcomingUnits}</td> : ""}
                          {column?.[22]?.['enabled'] ? <td>{totalOverdueUnits}</td> : ""}
                        </tr>
                      </React.Fragment>
                    )
                  })}
                  {tableReport?.length === 0 && <tr><td colSpan={column.filter((item: any) => item?.['enabled'] === true)?.length} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>}
                </tbody>
              </table>
            </div>
            <PaginationComponent
              handlePagination={handlePagination}
              totalLength={count}
              pageSize={pageSize}
              current={current}
            />
          </div>
        </div>
        <div ref={selectorRef} className="column-selector selector ">
          <div className="column-title">Column Selector</div>
          <div className="slector-body">
            <label>
              <input
                checked={clearAll}
                value="clearAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={clearall}
              />
              <span></span>
            </label>
            <p>Clear All</p>

            <label>
              <input
                checked={selectAll}
                value="selectAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={selectall}
              />
              <span></span>
            </label>
            <p>Select All</p>
          </div>
          <div className="selector-body-height scrollbar">
            {selectorLabel?.map((item: any, index: any) => {
              return (
                <div key={index + 2} className="slector-body">
                  <label>
                    <input
                      key={index}
                      name={index}
                      onChange={onchange}
                      checked={item['enabled']}
                      value={item.value}
                      type="checkbox"
                      aria-label="checkbox"
                    />
                    <span></span>
                  </label>
                  <p>{item['columnName']}</p>
                </div>
              )
            })}
          </div>
          <div className="m-flex">
            <button onClick={cancelSelection} className="cancel">
              Cancel
            </button>
            <button onClick={applyChanges} className="apply-changes mr-3">
              Apply Changes
            </button>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  );
};

export default OperationStatusReport;