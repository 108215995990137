import { CaretDownOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import DownloadIcon from '../../images/Download.png';
import emailICon from '../../images/EmailIcon.svg';
import Apply from '../reusableComponent/apply';

import { Utility } from "../../helper/index";
import service from '../../service/api/common';
import constantUrl from '../../settings/constants';
import * as roles from '../../settings/roles';

import { SearchBox } from './searchBox';

interface props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    setGlobalData: (key: string, value: any) => void,
    setAction: (value: any) => void,
    doRefresh: (value: number) => void,
    sendEmail: () => void,
    dealer: string,
    company: string,
    loc: string,
    site: string,
    currentMenu: string

}

type dropItem = {
    label: string,
    value: number | string
}

const NOBMID = 'NOBMID'
const BMID = 'BMID'

const CommonHeader: FC<props> = ({ setGlobalData, setAction, doRefresh, sendEmail, dealer, company, loc, site, currentMenu }) => {
    var loadCurrentMenu = currentMenu;
    const currentPage = useLocation();
    const{state, pathname}  =currentPage
    if(currentMenu || state?.MenuType===BMID || pathname=== '/bmidInventory'){
        if(state?.MenuType===BMID || pathname=== '/bmidInventory'){
            localStorage.setItem("menuId", BMID);
            loadCurrentMenu= BMID
        }else if(pathname !== '/bmidInventory'){
            localStorage.setItem("menuId", NOBMID);
            loadCurrentMenu= NOBMID
        }else{
            loadCurrentMenu= currentMenu
        }
    }else if((localStorage.getItem("menuId") && !currentMenu) ||  pathname !== '/bmidInventory'){
         if(pathname !== '/bmidInventory'){
            localStorage.setItem("menuId", NOBMID);
            loadCurrentMenu = NOBMID;
          }else{
            const menuId = localStorage.getItem("menuId")
            loadCurrentMenu = menuId !== null ? menuId : NOBMID;
          }
 
        
    }else{
       loadCurrentMenu = currentMenu || NOBMID; 
    }

    const [dowOption, setDowOption] = useState<boolean>(false)
    const dealerFilter = [
        "/admindashboard",
        "/dealerdashboard",
        "/companydashboard",
        "/locationdashboard",
        "/sitedashboard",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/issues-report2",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/shift-schedules",
        "/configurationlogreport",
        "/emeter-log-report",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/usermaintenance",
        "/locationmaintenance",
        "/sitemaintenance",
        "/organizationmaintenance",
        "/divisionmaintenance",
        "/soc-report",
        "/temperature-report",
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ]
    const companyFilter = [
        "/admindashboard",
        "/dealerdashboard",
        "/companydashboard",
        "/locationdashboard",
        "/sitedashboard",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/issues-report2",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/shift-schedules",
        "/configurationlogreport",
        "/emeter-log-report",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/usermaintenance",
        "/locationmaintenance",
        "/sitemaintenance",
        "/soc-report",
        "/temperature-report",
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ]
    const locationFilter = [
        "/admindashboard",
        "/dealerdashboard",
        "/companydashboard",
        "/locationdashboard",
        "/sitedashboard",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/issues-report2",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/shift-schedules",
        "/emeter-log-report",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/sitemaintenance",
        "/usermaintenance",
        "/locationmaintenance",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/soc-report",
        "/temperature-report",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ]
    const siteFilter = [
        "/admindashboard",
        "/dealerdashboard",
        "/companydashboard",
        "/locationdashboard",
        "/sitedashboard",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/issues-report2",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/shift-schedules",
        "/emeter-log-report",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/usermaintenance",
        "/sitemaintenance",
        "/shift-schedules",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/locationmaintenance",
        "/soc-report",
        "/temperature-report",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ]
    const batteryFilter = [
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/temperature-report",
        "/soc-report",
        "/battery-rx-test-page"
    ]
    const vehicleFilter = [
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/temperature-report",
        "/soc-report",
        "/battery-rx-test-page"
    ]
    const serialFilter = [
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/battery-statistics",
        "/dailyBatteryPerformanceReport",
        "/battery-rx-test-page"
    ]
    const apply = [
        "/sitemaintenance",
        "/usermaintenance",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/issues-report2",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/emeter-log-report",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/shift-schedules",
        "/firmhistoryreport",
        "/dailyBatteryPerformanceReport",
        "/locationmaintenance",
        "/battery-statistics",
        "/organizationmaintenance",
        "/divisionmaintenance",
        "/soc-report",
        "/temperature-report",
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ];

    const restrictedPagesSite = [
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/shift-schedules",
        "/usermaintenance",
        "/sitemaintenance",
        "/issues-report2",
        "/issues-report"
    ];

    const restrictedPagesCompany = [
        "/organizationmaintenance",
        "/divisionmaintenance"
    ];

    const restrictedPagesLocation = [
        "/locationmaintenance"
    ];

    const EmailFilter = [
        "/issues-report2",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp"
    ];
    const DownloadTableFilter = [
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/emeter-log-report",
        "/eq-log-report",
        "/issues-report",
        "/billing-summary-report",
        "/issues-report2",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/firmcontrolpanel",
        "/configurationlogreport",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/firmcontrolpanel",
        "/firmware-update-report"
    ];
    const DownloadGraphFilter = [
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/temperature-report",
        "/soc-report"
    ];
    const dealerRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER
    ];
    const companyRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER
    ];
    const locationRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER
    ];
    const siteRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER,
        roles.LOCATION_ADMIN,
        roles.LOCATION_MANAGER
    ]
    const batteryRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER,
        roles.LOCATION_ADMIN,
        roles.LOCATION_MANAGER,
        roles.SITE_ADMIN,
        roles.SITE_MANAGER
    ]
    const vehicleRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER,
        roles.LOCATION_ADMIN,
        roles.LOCATION_MANAGER,
        roles.SITE_ADMIN,
        roles.SITE_MANAGER
    ]
    const serialRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER,
        roles.LOCATION_ADMIN,
        roles.LOCATION_MANAGER,
        roles.SITE_ADMIN,
        roles.SITE_MANAGER
    ]

    //dropdowns list
    const [orgsList, setOrgsList] = useState<any[]>([]);
    const [tOSearchOrgsList, settOSearchOrgsList] = useState<any[]>([]);
    const [divisionsList, setDivisionsList] = useState<any[]>([]);
    const [toSearchDivisionList, setToSearchDivisionList] = useState<any[]>([]);
    const [locationsList, setLocationsList] = useState<any[]>([]);
    const [toSearchLocationsList, setLoSearchLocationsList] = useState<any[]>([]);
    const [sitesList, setSitesList] = useState<any[]>([]);
    const [toSearchSitesList, setToSearchSitesList] = useState<any[]>([]);
    const [batteriesList, setBatteriesList] = useState<any[]>([]);
    const [toSearchBatteriesList, setToSearchBatteriesList] = useState<any[]>([]);
    const [vehiclesList, setVehiclesList] = useState<any[]>([]);
    const [toSearchVehiclesList, setToSearchVehiclesList] = useState<any[]>([]);
    const [serialNumbers, setSerialNumbers] = useState<any[]>([]);
    const [toSearchSerialNumbers, setToSearchSerialNumbers] = useState<any[]>([]);

    //selected dropdown ids
    const [orgId, setOrgId] = useState<number>(-1);
    const [divisionId, setDivisionId] = useState<number>(-1);
    const [locationid, setLocationId] = useState<number>(-1);
    const [buildingid, setBuildingId] = useState<number>(-1);
    const [batteryid, setBatteryId] = useState<string>("");
    const [vehicleid, setVehicleId] = useState<string>("");
    const [serialnumber, setSerialNumber] = useState<string>("");

    //selected dropdown labels
    const [orgname, setOrgName] = useState<string>("All");
    const [divisionname, setDivisionName] = useState<string>("All");
    const [locationname, setLocationName] = useState<string>("All");
    const [buildingname, setBuildingName] = useState<string>("All");
    const [batteryname, setBatteryName] = useState<string>("All");
    const [vehiclename, setVehicleName] = useState<string>("All");
    const [serialname, setSerialName] = useState<string>("All");

    const [dealerLabel,setDelearLable] = useState<any>("")
    const [companyLabel, setCompanyLabel] = useState<string>("")
    const [locationLabel, setLocationLabel] = useState<string>("")
    const [silteLable, setSilteLable] = useState<string>("")
    const [batteryLabel, setBatteryLabel] = useState<string>("")
    const [vehicleLabel, setVehicleLabel] = useState<string>("")
    const [serialNoLabel, setSerialNolable] = useState<string>("")

   

    const [openDropdown, setOpenDropdown] = useState(null);


    //dropdowns list
    useEffect(() => {
        var userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        if (roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) {
            data?.orgId > 0 ? setOrgId(data?.orgId) : setOrgId(-1);
            data?.orgName !== "" ? setOrgName(data?.orgName) : setOrgName("All");
            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > 0 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > 0 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

           // getDealers(0)
        } else if (roles.DEALER_ADMIN === localStorage?.UserType || roles.DEALER_MANAGER === localStorage?.UserType) {
            setOrgId(loginDealerId);
            setOrgName(localStorage?.accountName);

            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > 0 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > 0 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            //getDealers(loginDealerId)
            // getCompanies(loginDealerId);
        } else if (roles.COMPANY_ADMIN === localStorage?.UserType || roles.COMPANY_MANAGER === localStorage?.UserType) {
            setOrgId(loginDealerId);
            setOrgName(localStorage?.accountName);

            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > 0 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > 0 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            getLocations(loginDealerId, data?.divId);
            getSites(loginDealerId, data?.divId, data?.locationId, data?.buildingId);
            getBatteries(loginDealerId, data?.divId, data?.locationId);
            getVehicles(loginDealerId, data?.divId, data?.locationId, data?.batteryId);
            getSerialNumbers(loginDealerId, data?.divId, data?.locationId, data?.batteryId, data?.vehicleId);
        } else if (roles.LOCATION_ADMIN === localStorage?.UserType || roles.LOCATION_MANAGER === localStorage?.UserType) {
            setOrgId(loginDealerId);
            setOrgName(localStorage?.accountName);

            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > -1 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > -1 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            getLocations(loginDealerId, data?.divId);
        } else if (roles.SITE_ADMIN === localStorage?.UserType || roles.SITE_MANAGER === localStorage?.UserType) {
            setOrgId(loginDealerId);
            setOrgName(localStorage?.accountName);

            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > -1 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > -1 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            getSites(loginDealerId, data?.divId, data?.locationId, data?.buildingId);
            getBatteries(loginDealerId, data?.divId, data?.locationId);
            getVehicles(loginDealerId, data?.divId, data?.locationId, data?.batteryId);
            getSerialNumbers(loginDealerId, data?.divId, data?.locationId, data?.batteryId, data?.vehicleId);
        }
    }, [])


    //For Battery Management page adding and removing the All to dealer list
    useEffect(()=>{   
    var userInfo: any = Utility.getUserInfo();
    var loginDealerId = userInfo.userId;

     var orgList = sessionStorage.getItem('orgList')   
     orgList = orgList && JSON.parse(orgList)

     var data: any = sessionStorage.getItem('globalData');
     data = data && JSON.parse(data);  

     let orgItemList: any[] = []
     orgItemList = Array.isArray(orgList) ? [...orgList] : [];
     if(loadCurrentMenu ===BMID || localStorage.getItem('menuId')===BMID){
        // Only unshift "All" if it’s not already there
        if (!orgItemList.some(item => item.value === -1)) {
            orgItemList.unshift({ label: "All", value: -1 });
        }
     }else{
        if((data?.['orgId'] ===-1 && localStorage.getItem('resetParams') !=="true" && sessionStorage.getItem('login_status')==='true')|| (data?.['orgId'] ===-1 && (localStorage.getItem('resetParams')==="true") && sessionStorage.getItem('login_status')==='true')){  
            if (roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) {
                data['orgId']=orgItemList[0]['value']
                data['orgName']=orgItemList[0]['label']
            }else if(roles.DEALER_ADMIN === localStorage?.UserType || roles.DEALER_MANAGER === localStorage?.UserType){
                data['orgId']=loginDealerId
                data['orgName']=localStorage?.accountName
            }
                data["divId"] = -1;
                data["divName"] = "All";
                data["locationId"] = -1;
                data["locationName"] = "All";
                data["buildingId"] = -1;
                data["buildingName"] = "All";
                data["batteryId"] = "";
                data["batteryName"] = "All";
                data["vehicleId"] = "";
                data["vehicleName"] = "All";
                data["serialId"] = "";
                data["serialName"] = "All";
                sessionStorage.setItem('globalData',JSON.stringify(data));

                setDivisionId(-1);
                setDivisionName("All");
                setLocationId(-1);
                setLocationName("All");
                setBuildingId(-1);
                setBuildingName("All");
                setBatteryId("");
                setBatteryName("All");
                setVehicleId("");
                setVehicleName("All");
                setSerialNumber("");
                setSerialName("All");
                if(data?.['orgId'] ===-1){
                    localStorage.setItem('resetParams','true')
                }else{
                    localStorage.setItem('resetParams','false')
                }
        }
        setDelearLable(Math.random())
        }
     setOrgsList(orgItemList);
     settOSearchOrgsList(orgItemList)
     
     if ((roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) && (loadCurrentMenu===NOBMID || !loadCurrentMenu) && 
        (orgList?.length===0 || !orgList)
     ) {getDealers(0)}


     if ((roles.DEALER_ADMIN === localStorage?.UserType || roles.DEALER_MANAGER === localStorage?.UserType) && (loadCurrentMenu===NOBMID || !loadCurrentMenu) && 
        (orgList?.length===0 || !orgList)
     ) {getDealers(loginDealerId)}

     orgList && callAll(orgItemList,false)
     
    },[loadCurrentMenu])

    const callAll = (orgItemList:any,boolValue:boolean)=>{
        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);  
        var userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        if (orgItemList.length > 0) {
            if(boolValue){
                if (roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) {
                    setOrgId(orgItemList[0]['value']);
                    setOrgName(orgItemList[0]['label']);
                    getCompanies(orgItemList[0]['value'])
                    getLocations(orgItemList[0]['value'], -1)
                    getSites(orgItemList[0]['value'], -1, -1, -1)
                    getBatteries(orgItemList[0]['value'], -1, -1)
                    getVehicles(orgItemList[0]['value'], -1, -1, "")
                    getSerialNumbers(orgItemList[0]['value'], -1, -1, "", "")
                    data["orgName"] = orgItemList[0]['label'];
                    sessionStorage.setItem("globalData", JSON.stringify(data));
                    setGlobalData("orgId", orgItemList[0]['value']);
                }else if(roles.DEALER_ADMIN === localStorage?.UserType || roles.DEALER_MANAGER === localStorage?.UserType){
                    setOrgId(loginDealerId);
                    setOrgName(localStorage?.accountName);
                    getCompanies(loginDealerId)
                    getLocations(loginDealerId, -1)
                    getSites(loginDealerId, -1, -1, -1)
                    getBatteries(loginDealerId, -1, -1)
                    getVehicles(loginDealerId, -1, -1, "")
                    getSerialNumbers(loginDealerId, -1, -1, "", "")
                    data["orgId"] = loginDealerId;
                    sessionStorage.setItem("globalData", JSON.stringify(data));
                    setGlobalData("orgId", loginDealerId);
                }

            }else{
                setOrgId(data?.orgId);
                setOrgName(data?.orgName);
                setGlobalData("orgId", data?.orgId);
                getCompanies(data?.orgId)
                getLocations(data?.orgId, data?.divId)
                getSites(data?.orgId, data?.divId, data?.locationId, data?.buildingId)
                getBatteries(data?.orgId, data?.divId, data?.locationId)
                getVehicles(data?.orgId, data?.divId, data?.locationId, data?.batteryId)
                getSerialNumbers(data?.orgId, data?.divId, data?.locationId, data?.batteryId, data?.vehicleId)
            } 
        }
    }

    useEffect(() => {
        if (dealer !== "") { setOrgName(dealer) }
    }, [dealer])

    useEffect(() => {
        if (company !== "") { setDivisionName(company) }
    }, [company])

    useEffect(() => {
        if (loc !== "") { setLocationName(loc) }
    }, [loc])

    useEffect(() => {
        if (site !== "") { setBuildingName(site) }
    }, [site])

    const getDealers = async (orgid: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;
        let payload = {
            "orgId": orgid,
            "divisionId": 0,
            "locationId": 0,
            "buildingId": 0
        }
        try {
            const response = await service({
                url: constantUrl.api.dashboard.getOrganizations,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });
            sessionStorage.setItem('login_status',"true")
            let modifoedRes = []
            for (let orgItem of response) {
                if((orgItem.orgId && orgItem.orgName) !== null){
                modifoedRes.push({ label: orgItem.orgName, value: orgItem.orgId })
                }
            }
            setOrgsList(modifoedRes);
            settOSearchOrgsList(modifoedRes)
            // to give the All in Dealer option in battery management 
            if(localStorage.getItem("menuId")===BMID){
                modifoedRes.unshift({ label: "All", value: -1 });
                setOrgsList(modifoedRes);
                settOSearchOrgsList(modifoedRes)
            }else{
                setOrgsList(modifoedRes);
                settOSearchOrgsList(modifoedRes)
            }
            sessionStorage.setItem('orgList',JSON.stringify(modifoedRes)) 
            callAll(modifoedRes,true) 
        } catch (error) {
            console.log("getCompaniesError:", error)
        }
    }


    const handleDealer = (selectedItem: dropItem) => {
        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        const { label, value } = selectedItem
        const orgId = Number(value)
        const orgName = label
        setOrgId(orgId);
        setOrgName(orgName);
        setDivisionId(-1);
        setDivisionName("All");
        setLocationId(-1);
        setLocationName("All");
        setBuildingId(-1);
        setBuildingName("All");
        setBatteryId("");
        setBatteryName("All");
        setVehicleId("");
        setVehicleName("All");
        setSerialNumber("");
        setSerialName("All");

        getCompanies(orgId)
        if (roles.DEALER_ADMIN === localStorage?.UserType || roles.DEALER_MANAGER === localStorage?.UserType) {
            getLocations(orgId, -1, -1)
            getSites(orgId, -1, -1, -1)
            getBatteries(orgId, -1, -1);
            getVehicles(orgId, -1, -1, "");
            getSerialNumbers(orgId, -1, -1, "", "")
        } else {
            getLocations(orgId, -1, -1)
            getSites(orgId, -1, -1, -1)
            getBatteries(orgId, -1, -1);
            getVehicles(orgId, -1, -1, "");
            getSerialNumbers(orgId, -1, -1, "", "")
        }

        if (currentPage?.pathname === "/admindashboard" ||
            currentPage?.pathname === "/dealerdashboard" ||
            currentPage?.pathname === "/companydashboard" ||
            currentPage?.pathname === "/locationdashboard" ||
            currentPage?.pathname === "/sitedashboard") {
            data["orgName"] = orgName;
            data["divId"] = -1;
            data["divName"] = "All";
            data["locationId"] = -1;
            data["locationName"] = "All";
            data["buildingId"] = -1;
            data["buildingName"] = "All";
            data["batteryId"] = "";
            data["batteryName"] = "All";
            data["vehicleId"] = "";
            data["vehicleName"] = "All";
            data["serialId"] = "";
            data["serialName"] = "All";
            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("orgId", orgId);
        }
    }

    const getCompanies = async (orgid: number) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        var url = constantUrl.api.location.getDivisions;
        let payload = { orgId: orgid };

        if (url) {
            try {
                const response = await service({
                    url: url,
                    method: "POST",
                    headers: { accept: "*/*", Authorization: "Bearer " + token },
                    data: payload
                });

                let modifiedDivision: dropItem[] = []
                if (response?.length > 0) {
                    for (let divisionItem of response) {
                        modifiedDivision.push({ label: divisionItem.divisionName, value: divisionItem.divisionId })
                    }
                    modifiedDivision.unshift({ label: "All", value: -1 })
                    // let companies: any = [{ "divisionId": -1, "divisionName": "All" }, ...response];
                    setDivisionsList(modifiedDivision);
                    setToSearchDivisionList(modifiedDivision)
                } else {
                    modifiedDivision.push({ label: "All", value: -1 })
                    setDivisionsList(modifiedDivision);
                    setToSearchDivisionList(modifiedDivision)
                }
                setCompanyLabel("")
            }
            catch (error) {
                console.log("getDivisionsError:", error)
            }
        }
    }

    const handleCompany = (selectedItem: dropItem) => {
        var userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;
        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        const { label, value } = selectedItem
        const divid = Number(value)
        const divName = label

        let org_id = orgId > -1 ? orgId : data?.orgId > -1 ? data?.orgId : loginDealerId;

        setDivisionId(divid);
        setDivisionName(divName);
        setCompanyLabel(divName)
        setLocationId(-1);
        setLocationName("All");
        setBuildingId(-1);
        setBuildingName("All");
        setBatteryId("");
        setBatteryName("All");
        setVehicleId("");
        setVehicleName("All");
        setSerialNumber("");
        setSerialName("All");

        getLocations(org_id, divid)
        getSites(org_id, divid, -1, -1)
        getBatteries(org_id, divid, -1)
        getVehicles(org_id, divid, -1, "")
        getSerialNumbers(org_id, divid, -1, "", "")

        if (currentPage?.pathname === "/admindashboard" ||
            currentPage?.pathname === "/dealerdashboard" ||
            currentPage?.pathname === "/companydashboard" ||
            currentPage?.pathname === "/locationdashboard" ||
            currentPage?.pathname === "/sitedashboard") {
            data["divName"] = divName;
            data["locationId"] = -1;
            data["locationName"] = "All";
            data["buildingId"] = -1;
            data["buildingName"] = "All";
            data["batteryId"] = "";
            data["batteryName"] = "All";
            data["vehicleId"] = "";
            data["vehicleName"] = "All";
            data["serialId"] = "";
            data["serialName"] = "All";
            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("divId", divid)
        }
    }

    const getLocations = async (orgId: any, divId: any, locId?: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;
        let loginDealerId = userInfo.userId;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let locationId = (locId === -1) ? locId : -1

        let url = constantUrl.api.dashboard.getLocations;
        let payload = {
            "orgId": orgId,
            "divisionId": divId,
            "locationId": locationId,
            "buildingId": -1
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { Authorization: 'Bearer ' + token },
                data: payload
            })
            let locations: dropItem[] = []
            if (response?.length > 0) {
                for (let locItem of response) {
                    locations.push({ label: locItem['locationName'], value: locItem["locId"] })
                }
                locations.unshift({ label: "All", value: -1 })
                // let locations: any = [{ "locId": -1, "locationName": "All" }, ...response];
                setLocationsList(locations);
                setLoSearchLocationsList(locations)

                if (roles.LOCATION_ADMIN === localStorage?.UserType || roles.LOCATION_MANAGER === localStorage?.UserType) {
                    let locid = data?.locationId > 0 ? data?.locationId : locations[1]?.value;
                    let locname = data?.locationName !== "" ? data?.locationName : locations[1]?.label;

                    getSites(loginDealerId, divId, locid, buildingid);
                    getBatteries(loginDealerId, divId, locid);
                    getVehicles(loginDealerId, divId, locid, data?.batteryId);
                    getSerialNumbers(loginDealerId, divId, locid, data?.batteryId, data?.vehicleId);

                    setLocationId(locid);
                    setLocationName(locname);
                    data["locationName"] = locname;
                    sessionStorage.setItem("globalData", JSON.stringify(data));
                    setGlobalData("locationId", locid);
                }
            } else {
                setLocationsList([{ label: "All", value: -1 }])
                setLoSearchLocationsList([{ label: "All", value: -1 }])
            }
            setLocationLabel("")
        } catch (error) {
            console.error('locationList:', error)
        }
    }


    const handleLocation = (selectedItem: dropItem) => {
        var userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        const { label, value } = selectedItem

        const locId = Number(value)
        const locationName = label

        let orgid = orgId > 0 ? orgId : data?.orgId > -1 ? data?.orgId : loginDealerId;

        setLocationId(locId);
        setLocationName(locationName);
        setLocationLabel(locationName)
        setBuildingId(-1);
        setBuildingName("All");
        setBatteryId("");
        setBatteryName("All");
        setVehicleId("");
        setVehicleName("All");
        setSerialNumber("");
        setSerialName("All");

        getSites(orgid, divisionId, locId, buildingid)
        getBatteries(orgid, divisionId, locId)
        getVehicles(orgid, divisionId, locId, "")
        getSerialNumbers(orgid, divisionId, locId, "", "")

        if (currentPage?.pathname === "/admindashboard" ||
            currentPage?.pathname === "/dealerdashboard" ||
            currentPage?.pathname === "/companydashboard" ||
            currentPage?.pathname === "/locationdashboard" ||
            currentPage?.pathname === "/sitedashboard") {
            data["locationName"] = locationName;
            data["buildingId"] = -1;
            data["buildingName"] = "All";
            data["batteryId"] = "";
            data["batteryName"] = "All";
            data["vehicleId"] = "";
            data["vehicleName"] = "All";
            data["serialId"] = "";
            data["serialName"] = "All";

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("locationId", locId)
        }
    }

    const getSites = async (orgId: any, divId: any, locId: any, buildId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let buildingId = (buildId === -1) ? buildId : data?.buildingId

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: buildingId
        }

        let url = constantUrl.api.HeaderFilters.getSites;

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { Authorization: 'Bearer ' + token },
                data: payload
            })
            if (response?.length > 0) {
                let buildings: dropItem[] = [];
                for (let siteItems of response) {
                    buildings.push({ label: siteItems["buildingName"], value: siteItems['id'] })
                }
                if (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) {
                    setLocationId(Number(localStorage?.locationId));
                    setLocationName(localStorage?.locationName);
                    setSitesList(buildings);
                    setToSearchSitesList(buildings)
                    setBuildingName(buildings[0]?.label);
                    setBuildingId(Number(buildings[0]?.value));

                    data["locationId"] = Number(localStorage?.locationId);
                    data["locationName"] = localStorage?.locationName;
                    sessionStorage.setItem("globalData", JSON.stringify(data))
                    setGlobalData("buildingId", buildings[0]?.value)
                } else {
                    buildings.unshift({ label: "All", value: -1 })
                    setSitesList(buildings);
                    setToSearchSitesList(buildings)
                }
            } else {
                setSitesList([{ label: 'All', value: -1 }])
                setToSearchSitesList([{ label: 'All', value: -1 }])
            }
            setSilteLable("")
        } catch (error) {
            console.error('sitesList:', error)
        }
    }


    const handleSite = (selectedItem: dropItem) => {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        const { label, value } = selectedItem
        const id = Number(value)
        const buildingName = label

        setBuildingId(id);
        setBuildingName(buildingName);
        setSilteLable(buildingName)
        setBatteryId("");
        setBatteryName("All");
        setVehicleId("");
        setVehicleName("All");
        setSerialNumber("");
        setSerialName("All");

        if (currentPage?.pathname === "/admindashboard" ||
            currentPage?.pathname === "/dealerdashboard" ||
            currentPage?.pathname === "/companydashboard" ||
            currentPage?.pathname === "/locationdashboard" ||
            currentPage?.pathname === "/sitedashboard") {
            data["buildingName"] = buildingName;
            data["batteryId"] = "";
            data["batteryName"] = "All";
            data["vehicleId"] = "";
            data["vehicleName"] = "All";
            data["serialId"] = "";
            data["serialName"] = "All";
            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("buildingId", id)
        }

        getBatteries(orgId, divisionId, locationid)
        getVehicles(orgId, divisionId, locationid, "")
        getSerialNumbers(orgId, divisionId, locationid, "", "")
    }

    const getBatteries = async (orgid: number, divid: number, locid: number) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;
        let payload = {
            "orgId": orgid,
            "divisionId": divid,
            "locationId": locid,
            "batteryId": "",
            "vehicleId": ""
        }
        try {
            const response = await service({
                url: constantUrl.api.HeaderFilters.getBatteries,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token }
            });
            let batteryList: dropItem[] = []
            for (let batteryItem of response) {
                batteryList.push({ label: batteryItem, value: batteryItem })
            }
            batteryList.unshift({ label: 'All', value: "" })
            setBatteriesList(batteryList)
            setToSearchBatteriesList(batteryList)
            setBatteryLabel("")
        } catch (error) {
            console.log("getBatteriesError:", error)
        }
    }


    const handleBattery = (selectedItem: dropItem) => {
        let userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        const { label, value } = selectedItem
        const batteryId = value.toString()
        const batteryName = label

        let orgid = orgId > 0 ? orgId : data?.orgId > 0 ? data?.orgId : loginDealerId;
        let locid = locationid > 0 ? locationid : data?.locationId;
        let divid = divisionId > 0 ? divisionId : data?.divId;

        batteryId ? setBatteryId(batteryId) : setBatteryId("")
        batteryId ? setBatteryName(batteryName) : setBatteryName("All")
        batteryId ? setBatteryLabel(batteryName) : setBatteryLabel("All")
        getVehicles(orgid, divid, locid, batteryId);
    }

    const getVehicles = async (orgid: number, divid: number, locid: number, bid: string) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let payload = {
            "orgId": orgid,
            "divisionId": divid,
            "locationId": locid,
            "batteryId": bid,
            "vehicleId": ""
        }

        try {
            const response = await service({
                url: constantUrl.api.HeaderFilters.getVehicles,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token }
            });
            const oneIndex = response[0]
            if (bid && response?.length === 1) {
                setVehicleId(oneIndex);
                setVehicleName(oneIndex);
                getSerialNumbers(orgid, divid, locid, bid, oneIndex)
            } else {
                setVehicleId("");
                setVehicleName("All");
                getSerialNumbers(orgid, divid, locid, bid, "")
            }
            setVehicleLabel("")

            let vehicleRes: dropItem[] = []
            for (let vehicleItem of response) {
                vehicleRes.push({ label: vehicleItem, value: vehicleItem })
            }
            vehicleRes.unshift({ label: 'All', value: "" })
            setVehiclesList(vehicleRes);
            setToSearchVehiclesList(vehicleRes)
        } catch (error) {
            console.log("getVehiclesError:", error)
        }
    }

    const handleVehicle = (selectedItem: dropItem) => {
        const { label, value } = selectedItem
        const vehicleId = value.toString()
        const vehicleName = label
        setVehicleId(vehicleId)
        vehicleId ? setVehicleName(vehicleName) : setVehicleName("All")
        vehicleId ? setVehicleLabel(vehicleName) : setVehicleLabel("All")

        let userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let orgid = orgId > 0 ? orgId : data?.orgId > 0 ? data?.orgId : loginDealerId;
        let locid = locationid > 0 ? locationid : data?.locationId;
        let divid = divisionId > 0 ? divisionId : data?.divId;
        let batId = batteryid ? batteryid : data['batteryId']

        getSerialNumbers(orgid, divid, locid, batId, vehicleId)
    }

    const getSerialNumbers = async (orgId: any, divId: any, locationId: any, batteryId: any, vehicleId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            batteryId: batteryId,
            vehicleId: vehicleId,
        }

        try {
            const response = await service({
                url: constantUrl.api.HeaderFilters.getSerialNumbers,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token }
            });
            if (batteryId && response.length === 1) {
                setSerialNumber(response[0]);
                setSerialName(response[0]);
            } else {
                setSerialNumber("");
                setSerialName('All');

            }
            setSerialNolable("")

            let serialNoRes: dropItem[] = []
            for (let serialItem of response) {
                serialNoRes.push({ label: serialItem, value: serialItem })
            }
            serialNoRes.unshift({ label: 'All', value: "" })
            setSerialNumbers(serialNoRes)
            setToSearchSerialNumbers(serialNoRes)
        } catch (error) {
            console.log("getSerialNumbersError:", error)
        }
    }

    const handleSerialNumber = (selectedItem: dropItem) => {
        const { label, value } = selectedItem
        const serialName = label || "All";
        setSerialNumber(value.toString());
        setSerialName(serialName);
        setSerialNolable(serialName)
    }

    const onApply = () => {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        if (serialnumber !== data["serialId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationId"] = locationid;
            data["locationName"] = locationname;
            data["buildingId"] = buildingid;
            data["buildingName"] = buildingname;
            data["batteryId"] = batteryid;
            data["batteryName"] = batteryname;
            data["vehicleId"] = vehicleid;
            data["vehicleName"] = vehiclename;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("serialId", serialnumber)
        } else if (vehicleid !== data["vehicleId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationId"] = locationid;
            data["locationName"] = locationname;
            data["buildingId"] = buildingid;
            data["buildingName"] = buildingname;
            data["batteryId"] = batteryid;
            data["batteryName"] = batteryname;
            data["vehicleName"] = vehiclename;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("vehicleId", vehicleid)
        } else if (batteryid !== data["batteryId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationName"] = locationname;
            data["buildingName"] = buildingname;
            data["vehicleName"] = vehiclename;
            data["locationId"] = locationid;
            data["batteryId"] = batteryid;
            data["vehicleId"] = vehicleid;
            data["buildingId"] = buildingid;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("batteryName", batteryname)
        } else if (buildingid !== data["buildingId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationName"] = locationname;
            data["buildingName"] = buildingname;
            data["batteryName"] = batteryname;
            data["vehicleName"] = vehiclename;
            data["locationId"] = locationid;
            data["batteryId"] = batteryid;
            data["vehicleId"] = vehicleid;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("buildingId", buildingid)
        } else if (locationid !== data["locationId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationName"] = locationname;
            data["buildingName"] = buildingname;
            data["batteryName"] = batteryname;
            data["vehicleName"] = vehiclename;
            data["buildingId"] = buildingid;
            data["batteryId"] = batteryid;
            data["vehicleId"] = vehicleid;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("locationId", locationid);
        } else if (divisionId !== data["divId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["locationId"] = locationid;
            data["divName"] = divisionname;
            data["locationName"] = locationname;
            data["buildingName"] = buildingname;
            data["batteryName"] = batteryname;
            data["vehicleName"] = vehiclename;
            data["buildingId"] = buildingid;
            data["batteryId"] = batteryid;
            data["vehicleId"] = vehicleid;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("divId", divisionId);
        } else if (orgId !== data["orgId"]) {
            data["orgName"] = orgname;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationId"] = locationid;
            data["locationName"] = locationname;
            data["buildingId"] = buildingid;
            data["buildingName"] = buildingname;
            data["batteryId"] = batteryid;
            data["batteryName"] = batteryname;
            data["vehicleId"] = vehicleid;
            data["vehicleName"] = vehiclename;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("orgId", orgId);
        }
    }

    const emailToggle = () => { sendEmail() };

    const handleDropdownClick = (name: any) => {
        setOpenDropdown(openDropdown === name ? null : name);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (!event.target.closest('.selected-label') && !event.target.closest('.filter-input')) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const filterOrgList = (value: string) => {

        const data = tOSearchOrgsList.filter((item: any, index: number) => {
            return item['label']?.toLowerCase().includes(value?.toLowerCase())
        })
        setOrgsList(data)
    }


    const filterCompanyList = (value: string) => {
        const data = toSearchDivisionList.filter((item: any, index: number) => {
            return item['label'].toLowerCase().includes(value.toLowerCase())
        })
        setDivisionsList(data)
    }



    const filterLocationList = (value: string) => {
        const data = toSearchLocationsList.filter((item: any, index: number) => {
            return item['label'].toLowerCase().includes(value.toLowerCase())
        })
        setLocationsList(data)
    }

    const filterSiteList = (value: string) => {
        const data = toSearchSitesList.filter((item: any, index: number) => {
            return item['label'].toLowerCase().includes(value.toLowerCase())
        })
        setSitesList(data)
    }


    const filterBatteryList = (value: string) => {
        const data = toSearchBatteriesList.filter((item: any, index: number) => {
            return item['label'].toLowerCase().includes(value.toLowerCase())
        })
        setBatteriesList(data)
    }


    const filterVehicleList = (value: string) => {
        const data = toSearchVehiclesList.filter((item: any, index: number) => {
            return item['label'].toLowerCase().includes(value.toLowerCase())
        })
        setVehiclesList(data)
    }


    const filterSerialNoList = (value: string) => {
        const data = toSearchSerialNumbers.filter((item: any, index: number) => {
            return item['label'].toLowerCase().includes(value.toLowerCase())
        })
        setSerialNumbers(data)
    }


    return (
        <div className="dropList alignChargeLogHeader col-12 px-0 posi_mobile_header">
            {
                (localStorage?.UserType === roles.COMPANY_ADMIN || localStorage?.UserType === roles.COMPANY_MANAGER) &&
                <>
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Dealer:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.accountName >= 5) ? localStorage?.accountName?.substring(0, 3) + ".." : localStorage?.accountName}</div>
                    </div>

                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Company:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{localStorage?.divisionName}</div>
                    </div>
                </>
            }
            {
                (localStorage?.UserType === roles.LOCATION_ADMIN || localStorage?.UserType === roles.LOCATION_MANAGER) &&
                <>
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Dealer:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.accountName >= 5) ? localStorage?.accountName?.substring(0, 3) + ".." : localStorage?.accountName}</div>
                    </div>

                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Company:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{localStorage?.divisionName !== "" ? localStorage?.divisionName : "All"}</div>
                    </div>

                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Location:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{localStorage?.locationName !== "" ? localStorage?.locationName : "All"}</div>
                    </div>
                </>
            }
            {
                (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) &&
                <>
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Dealer:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.accountName >= 5) ? localStorage?.accountName?.substring(0, 3) + ".." : localStorage?.accountName}</div>
                    </div>

                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Company:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.divisionName !== "" && localStorage?.divisionName >= 5) ? localStorage?.divisionName?.substring(0, 3) + ".." : localStorage?.divisionName !== "" ? localStorage?.divisionName : "All"}</div>
                    </div>

                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Location:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.locationName !== "" && localStorage?.locationName >= 5) ? localStorage?.locationName?.substring(0, 3) + ".." : localStorage?.locationName !== "" ? localStorage?.locationName : "All"}</div>
                    </div>

                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Site:</span>
                        <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.buildingName !== "" && localStorage?.buildingName >= 5) ? localStorage?.buildingName?.substring(0, 3) + ".." : localStorage?.buildingName !== "" ? localStorage?.buildingName : "All"}</div>
                    </div>
                </>
            }
            {
                dealerRoles?.includes(localStorage?.UserType) && dealerFilter?.includes(currentPage?.pathname) &&
                <div className=' label-plus-select'>
                    <div className='label-plus-arrow'>
                        <span className="pageTitle font-weight-bold">Dealer:</span>
                        <div title={orgname} className='selected-label' onClick={() => handleDropdownClick('dealer')}>
                            <span className='label-name'> {(orgname?.length >= 5) ? orgname?.substring(0, 3) + ".." : orgname}</span>
                            <CaretDownOutlined className='select-arrow' />
                        </div>
                    </div>
                    <div className='searchBox-main-div'>
                        <SearchBox
                            placeholder="Search Dealer"
                            listOptions={orgsList}
                            isOpen={openDropdown === 'dealer'}
                            filterList={(value: string) => filterOrgList(value)}
                            getSelectedItem={(selectedItem: dropItem) => handleDealer(selectedItem)}
                            toggleDropdown={(labelName) => handleDropdownClick(labelName)}
                            autoSelectLabel={dealerLabel}
                        />
                    </div>

                </div>
            }
            {
                companyRoles?.includes(localStorage?.UserType) && companyFilter?.includes(currentPage?.pathname) &&
                <div className=' label-plus-select'>
                    <div className='label-plus-arrow'>
                        <span className="pageTitle font-weight-bold">Company:</span>
                        <div title={divisionname} className='selected-label' onClick={() => handleDropdownClick('company')}>
                            <span className='label-name'>{(divisionname?.length >= 5) ? divisionname?.substring(0, 3) + ".." : divisionname}</span>
                            <CaretDownOutlined className='select-arrow' />
                        </div>
                    </div>
                    <div className='searchBox-main-div'>
                        <SearchBox
                            placeholder="Search Company"
                            listOptions={divisionsList}
                            isOpen={openDropdown === 'company'}
                            filterList={(value: string) => filterCompanyList(value)}
                            getSelectedItem={(selectedItem: dropItem) => handleCompany(selectedItem)}
                            toggleDropdown={(labelName) => handleDropdownClick(labelName)}
                            autoSelectLabel={companyLabel}
                        />
                    </div>
                </div>
            }
            {
                locationRoles?.includes(localStorage?.UserType) && locationFilter?.includes(currentPage?.pathname) &&
                <div className=' label-plus-select'>
                    <div className='label-plus-arrow'>
                        <span className="pageTitle font-weight-bold">Location:</span>
                        <div title={locationname} className='selected-label' onClick={() => handleDropdownClick('location')}>
                            <span className='label-name'>{(locationname?.length >= 5) ? locationname?.substring(0, 3) + ".." : locationname}</span>
                            <CaretDownOutlined className='select-arrow' />
                        </div>
                    </div>
                    <div className='searchBox-main-div'>
                        <SearchBox
                            placeholder="Search Location"
                            listOptions={locationsList}
                            isOpen={openDropdown === 'location'}
                            filterList={(value: string) => filterLocationList(value)}
                            getSelectedItem={(selectedItem: dropItem) => handleLocation(selectedItem)}
                            toggleDropdown={(labelName) => handleDropdownClick(labelName)}
                            autoSelectLabel={locationLabel}
                        />
                    </div>
                </div>

            }
            {
                siteRoles?.includes(localStorage?.UserType) && siteFilter?.includes(currentPage?.pathname) && !(
                    (localStorage?.UserType === roles.LOCATION_ADMIN || localStorage?.UserType === roles.LOCATION_MANAGER) &&
                    restrictedPagesLocation.includes(currentPage?.pathname)
                ) &&
                <div className=' label-plus-select'>
                    <div className='label-plus-arrow'>
                        <span className="pageTitle font-weight-bold">Site:</span>
                        <div title={buildingname} className='selected-label' onClick={() => handleDropdownClick('site')}>
                            <span className='label-name'> {(buildingname?.length >= 5) ? buildingname?.substring(0, 3) + ".." : buildingname}</span>
                            <CaretDownOutlined className='select-arrow' />
                        </div>
                    </div>
                    <div className='searchBox-main-div'>
                        <SearchBox
                            placeholder="Search Site"
                            listOptions={sitesList}
                            isOpen={openDropdown === 'site'}
                            filterList={(value: string) => filterSiteList(value)}
                            getSelectedItem={(selectedItem: dropItem) => handleSite(selectedItem)}
                            toggleDropdown={(labelName) => handleDropdownClick(labelName)}
                            autoSelectLabel={silteLable}

                        />
                    </div>

                </div>
            }
            {
                batteryRoles?.includes(localStorage?.UserType) && batteryFilter?.includes(currentPage?.pathname) &&
                <div className=' label-plus-select'>
                    <div className='label-plus-arrow'>
                        <span className="pageTitle font-weight-bold">Battery ID:</span>
                        <div title={batteryname} className='selected-label' onClick={() => handleDropdownClick('batteryId')}>
                            <span className='label-name'>{(batteryname?.length >= 5) ? batteryname?.substring(0, 3) + ".." : batteryname}</span>
                            <CaretDownOutlined className='select-arrow' />
                        </div>
                    </div>
                    <div className='searchBox-main-div'>
                        <SearchBox
                            placeholder="Search Battery"
                            listOptions={batteriesList}
                            isOpen={openDropdown === 'batteryId'}
                            filterList={(value: string) => filterBatteryList(value)}
                            getSelectedItem={(selectedItem: dropItem) => handleBattery(selectedItem)}
                            toggleDropdown={(labelName) => handleDropdownClick(labelName)}
                            autoSelectLabel={batteryLabel}

                        />
                    </div>

                </div>
            }
            {
                vehicleRoles?.includes(localStorage?.UserType) && vehicleFilter?.includes(currentPage?.pathname) &&
                <div className=' label-plus-select'>
                    <div className='label-plus-arrow'>
                        <span className="pageTitle font-weight-bold">Vehicle ID:</span>
                        <div title={vehiclename} className='selected-label' onClick={() => handleDropdownClick('vehicleId')}>
                            <span className='label-name'> {(vehiclename?.length >= 5) ? vehiclename?.substring(0, 3) + ".." : vehiclename}</span>
                            <CaretDownOutlined className='select-arrow' />
                        </div>
                    </div>
                    <div className='searchBox-main-div'>
                        <SearchBox
                            placeholder="Search Vehicle"
                            listOptions={vehiclesList}
                            isOpen={openDropdown === 'vehicleId'}
                            filterList={(value: string) => filterVehicleList(value)}
                            getSelectedItem={(selectedItem: dropItem) => handleVehicle(selectedItem)}
                            toggleDropdown={(labelName) => handleDropdownClick(labelName)}
                            autoSelectLabel={vehicleLabel}

                        />
                    </div>

                </div>
            }
            {
                serialRoles?.includes(localStorage?.UserType) && serialFilter?.includes(currentPage?.pathname) &&
                <div className=' label-plus-select'>
                    <div className='label-plus-arrow'>
                        <span className="pageTitle font-weight-bold">Serial Number:</span>
                        <div title={serialname} className='selected-label' onClick={() => handleDropdownClick('serialNo')}>
                            <span className='label-name'>{(serialname?.length >= 5) ? serialname?.substring(0, 3) + ".." : serialname}</span>
                            <CaretDownOutlined className='select-arrow' />
                        </div>
                    </div>
                    <div className='searchBox-main-div'>
                        <SearchBox
                            placeholder="Search Serial No."
                            listOptions={serialNumbers}
                            isOpen={openDropdown === 'serialNo'}
                            filterList={(value: string) => filterSerialNoList(value)}
                            getSelectedItem={(selectedItem: dropItem) => handleSerialNumber(selectedItem)}
                            toggleDropdown={(labelName) => handleDropdownClick(labelName)}
                            autoSelectLabel={serialNoLabel}

                        />
                    </div>

                </div>
            }
            {
                apply?.includes(currentPage?.pathname) &&
                !(
                    (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) &&
                    restrictedPagesSite.includes(currentPage?.pathname)
                ) && !(
                    (localStorage?.UserType === roles.COMPANY_ADMIN || localStorage?.UserType === roles.COMPANY_MANAGER) &&
                    restrictedPagesCompany.includes(currentPage?.pathname)
                ) && !(
                    (localStorage?.UserType === roles.LOCATION_ADMIN || localStorage?.UserType === roles.LOCATION_MANAGER) &&
                    restrictedPagesLocation.includes(currentPage?.pathname)
                ) &&
                <span className='header_button' onClick={onApply}><Apply /></span>
            }
            {/* {
                    apply?.includes(currentPage?.pathname) &&
                    <span className="refresh header_button" onClick={() => { doRefresh(1) }}>
                        <i className="fa fa-repeat" aria-hidden="true"></i>
                        <div className="modify">Refresh for latest</div>
                    </span>
                } */}
            <span className='position2'>
                {
                    EmailFilter?.includes(currentPage?.pathname) &&
                    <span className='tips'>
                        <img onClick={emailToggle} src={emailICon} className="dow-icon" alt="" />
                        <span className="tooltips emailPosition2">Email Report</span>
                    </span>
                }
                {
                    DownloadGraphFilter?.includes(currentPage?.pathname) &&
                    <span className='tips'>
                        <Popover
                            trigger="click"
                            open={dowOption}
                            onOpenChange={() => setDowOption(!dowOption)}
                            placement="bottomRight"
                            style={{ padding: 8 }}
                            content={<div className='dow-options'>
                                <div onClick={() => setAction('pdf')}>Pdf</div>
                                <div onClick={() => setAction('png')}>Png</div>
                            </div>}
                        >
                            <img onClick={() => setDowOption(!dowOption)} className='dow-icon' src={DownloadIcon} alt="" />
                        </Popover>
                    </span>
                }
                {
                    DownloadTableFilter?.includes(currentPage?.pathname) &&
                    <span className='tips'>
                        <Popover
                            trigger="click"
                            open={dowOption}
                            onOpenChange={() => setDowOption(!dowOption)}
                            placement="bottomRight"
                            style={{ padding: 8 }}
                            content={<div className='dow-options'>
                                {!["/battery-statistics"]?.includes(currentPage?.pathname) && <div onClick={() => setAction('xls')}>Excel</div>}
                                {!["/battery-statistics"]?.includes(currentPage?.pathname) && <div onClick={() => setAction('csv')}>CSV</div>}
                                {["/issues-report2", "/battery-statistics"]?.includes(currentPage?.pathname) && <div onClick={() => setAction('pdf')}>PDF</div>}
                            </div>}
                        >
                            <img onClick={() => setDowOption(!dowOption)} className='dow-icon' src={DownloadIcon} alt="" />
                        </Popover>
                    </span>
                }
            </span>
        </div>
    )
}

export default CommonHeader;