import React from 'react';

class FushionChartSetting {

    static getChartLine = (maxValue:any) => {
        return {        
            pyaxisname: "Total Ahrs In",
            xAxisName: "DATE",
            xAxisNameFontColor: "#4f82b6",
            xAxisNameFontBold : "1",
            syaxisname: "Over / Under Charge",        
            formatNumberScale: "0",  
            formatNumber: "0",  
            decimals: "0",
            yaxisminvalue: "0",
            yaxismaxvalue: maxValue,
            legendposition: "top",
            snumbersuffix: "%",
            syaxismaxvalue: "3",
            syaxisminvalue: "-4", 
            labelStep: "5" ,      
            numdivlines: "4",
            animation: "1",
            lineThickness: "3",
            labelDisplay: "none",
            yAxisNameFontColor: "#4f82b6",
            yAxisNameFontBold : "1", 
            syAxisNameFontColor: "#4f82b6",
            syAxisNameFontBold : "1", 
            useRoundEdges: "1", 
            showvalues: "0",
            plotSpacePercent:"60",
           // legendPadding: "400",
            drawCustomLegend: "1", 
            legendIconSides: "0",  
            legendIconStartAngle: "60", 
            theme: "fusion"
        }
    }
}

export default FushionChartSetting