import React, { useState, useEffect } from 'react'
import PosinetHeader from '../../layout/header';
import LoadingSpinner from '../spinner/loading-spinner';
import Battery1 from '../../images/Device Type-1@2x.png'
import Battery2 from '../../images/ProCore Edge.svg';
import Battery3 from '../../images/PosiCharge Charger Icon.png';
import TotalPlugins from '../../images/Plugins Icon@2x.png'
import LowWater from '../../images/Path 12222@2x.png'
import HighTemp from '../../images/Temp Icon@2x.png'
import DownloadIcon from '../../images/Group_icon.svg'
import DeviceType from '../../images/Device Type.png'
import SessionWarning from '../reusableComponent/sessionWarning';
import { useLocation } from 'react-router-dom';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { Input, Switch, Button, Modal } from 'antd';
import {resize, hideSidebar} from '../../settings/resize';

const Batteries = () => {
    const location = useLocation();
    const propsData = location.state;
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [batteryinfo, setBatteryInfo] = useState<any>({})
    const [tempbatteryinfo, setTempBatteryInfo] = useState<any>({})
    const [serialNumber, setSerialNumber] = useState<any>(propsData?.data?.serialNumber)
    const [edit, setEdit] = useState<boolean>(false);

    useEffect(() => {
        getData();
    }, [serialNumber]);

    useEffect(() => {
        resize();
        hideSidebar();
    },[])

    async function getData() {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constantUrl.api.batteries.getInfo + serialNumber,
                method: "GET",
                headers: { Authorization: 'Bearer ' + token }
            });

            setBatteryInfo(response);
            setTempBatteryInfo(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("getBatteryInfo:", error);
            setIsShowSpinner(false);
        }
    }

    async function updateData() {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constantUrl.api.batteries.updateBattery,
                method: "PUT",
                data: tempbatteryinfo,
                headers: { Authorization: 'Bearer ' + token }
            });

            if(response) {
                window.location.reload();
            }
            setIsShowSpinner(false);
        } catch (error) {
            console.error("getBatteryInfo:", error);
            setIsShowSpinner(false);
        }
    }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <PosinetHeader />
            <div className="container-scroller">
                <div className="container-fluid page-battery-body-wrapper">
                    <div className="battery-batteries">
                        <div className="row justify-content-around pd15">
                            <div className="col-md-6  col-lg-4 pr-1 pl-1">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row battery-details">
                                            <div className=" col-12  ">
                                                <div className="details-body">
                                                    {
                                                        propsData?.data?.batteryTypeDescription === "Douglas Flat Plate Flooded" ?
                                                            <img src={Battery1} className="imgbattery" alt='' /> :
                                                            propsData?.data?.batteryTypeDescription === "PosiCharge ProCore Edge" ?
                                                                <img src={Battery2} className="imgbattery" alt='' /> :
                                                                <img src={Battery3} className="imgbattery" alt='' />
                                                    }

                                                    <div className="batteryb-text">
                                                        <h4>{propsData?.data?.batteryTypeDescription}</h4>
                                                        <table className="battery-table">
                                                            <tbody>
                                                                <tr className="battery-table-row">
                                                                    <td>Battery ID</td>
                                                                    <td>{batteryinfo?.batteryId}</td>
                                                                </tr>
                                                                <tr className="battery-table-row">
                                                                    <td>Vehicle ID</td>
                                                                    <td>{batteryinfo?.vehicleId}</td>
                                                                </tr>
                                                                <tr className="battery-table-row">
                                                                    <td>Last Sync</td>
                                                                    <td>{batteryinfo?.updatedDate}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3 device-settings">
                                            <div className="col-12">
                                                <div className="d-flex justify-content-between">
                                                    <label className="label-device">Device Settings</label>
                                                    <label className="pencil-icon" onClick={() => { setEdit(!edit); setTempBatteryInfo(batteryinfo) }}>
                                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                                        <div className="modify">Modify Details</div>
                                                    </label>
                                                </div>
                                                <div className="device-fonts mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <label>Total Cells</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, totalCells: event.target.value })} size="small" value={tempbatteryinfo.totalCells} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.totalCells}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Capacity</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, capacity: event.target.value })} size="small" value={tempbatteryinfo.capacity} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.capacity}</label>
                                                        }

                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Start Curr Limit</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, startCurrentLimit: event.target.value })} size="small" value={tempbatteryinfo.startCurrentLimit} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.startCurrentLimit}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Internal Resistance</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, batteryInternalOhms: event.target.value })} size="small" value={tempbatteryinfo.batteryInternalOhms} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.batteryInternalOhms}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Targ Volt Limit</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, targetVoltageLimit: event.target.value })} size="small" value={tempbatteryinfo.targetVoltageLimit} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.targetVoltageLimit}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Temp Fbk</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, temperatureFoldBack: event.target.value })} size="small" value={tempbatteryinfo.temperatureFoldBack} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.temperatureFoldBack}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Run Time Curr Thresh</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, runCurrentThreshold: event.target.value })} size="small" value={tempbatteryinfo.runCurrentThreshold} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.runCurrentThreshold}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Key On Det</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, keyOnDetection: event.target.value })} size="small" value={tempbatteryinfo.keyOnDetection} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.keyOnDetection}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Key On Logic</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, keyOnLogic: event.target.value })} size="small" value={tempbatteryinfo.keyOnLogic} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.keyOnLogic}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Key On Curr</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, keyOnThreshold: event.target.value })} size="small" value={tempbatteryinfo.keyOnThreshold} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.keyOnThreshold}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Parasitic Curr</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, parasiticCurrent: event.target.value })} size="small" value={tempbatteryinfo.parasiticCurrent} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.parasiticCurrent}</label>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>GMT Offset</label>
                                                        {
                                                            edit ?
                                                                <label className="count-align">
                                                                    <Input onChange={(event) => setTempBatteryInfo({ ...tempbatteryinfo, timezoneOffset: event.target.value })} size="small" value={tempbatteryinfo.timezoneOffset} />
                                                                </label>
                                                                :
                                                                <label className="count-align">{batteryinfo?.timezoneOffset}</label>
                                                        }
                                                    </div>
                                                    {
                                                        edit &&
                                                        <div className="d-flex justify-content-end">
                                                            <Button
                                                                onClick={() => { setEdit(!edit); setTempBatteryInfo(batteryinfo) }}
                                                                style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", marginRight: "10px" }}>
                                                                CANCEL
                                                            </Button>
                                                            <Button
                                                                onClick={() => { setEdit(!edit); updateData() }}
                                                                style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", }}>
                                                                UPDATE
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 pr-1 pl-1">
                                <div className="row ">
                                    <div className="col-12 ">
                                        <div className="row current-status">
                                            <div className=" col-12 px-3  ">
                                                <div className="d-flex justify-content-between">
                                                    <div className="label-device">Current Status</div>
                                                    <div className="current-date position-relative"> <span className="mr-5">Date as of August 10,2022 </span>
                                                        <label className="refresh-icon" onClick={getData}>
                                                            <i className="fa fa-repeat  mb-4" aria-hidden="true"></i>
                                                            <div className="modify">Refresh for latest</div>
                                                        </label>
                                                    </div>

                                                </div>

                                                <div className="d-flex justify-content-between current-date mt-4 pt-2 pb-2">
                                                    <div>SOC Status</div>
                                                    <div>{propsData?.data?.socStatus ?? 0}%</div>
                                                </div>
                                                <div className="progress-main-red">
                                                    <div className="progress-container-red height radius">
                                                        <div id="progress1" className="progress1 radius"></div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between current-date mt-4 pt-2 pb-2">
                                                    <div>EQ Status</div>
                                                    <div>{propsData?.data?.eqStatus} Days</div>
                                                </div>
                                                <div className="progress-main-green">
                                                    <div className="progress-container-green height radius  ">
                                                        <div id="progress2" className="progress2 height"></div>
                                                    </div>
                                                </div>
                                                <div className="location-footer-img mt-4 pt-4">
                                                    <div className="position-relative">
                                                        <img className='plugins' src={TotalPlugins} alt="" />
                                                        {
                                                            propsData?.data?.totalPlugins && <>
                                                                <span className="red-circle circle-p"></span>
                                                                <span className="count-p">{propsData?.data?.totalPlugins > 99 ? "99+" : propsData?.data?.totalPlugins}</span>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="position-relative">
                                                        <img className='lowater' src={LowWater} alt="" />
                                                        {
                                                            propsData?.data?.daysLowWater && <>
                                                                <span className="red-circle circle-p"></span>
                                                                <span className="count-p">{propsData?.data?.daysLowWater > 99 ? "99+" : propsData?.data?.daysLowWater}</span>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="position-relative">
                                                        <img className='plugins' src={HighTemp} alt="" />
                                                        {
                                                            propsData?.data?.daysHighTemperature && <>
                                                                <span className="red-circle circle-p"></span>
                                                                <span className="count-p">{propsData?.data?.daysHighTemperature > 99 ? "99+" : propsData?.data?.daysHighTemperature}</span>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="location-img-labels ">
                                                    <div>Total<br></br> Plugins</div>
                                                    <div>Days<br></br> Low Water</div>
                                                    <div>Days<br></br> High Temp</div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row bg-white mt-3 device-location">
                                            <div className="col-12">
                                                <div className='label-device'>Device Location</div>
                                                <div className="device-fonts mt-2">
                                                    <div className="d-flex justify-content-between pb-1">
                                                        <label>Organization</label>
                                                        <label className="count-align">{propsData?.data?.orgName}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between pb-1">
                                                        <label>Division</label>
                                                        <label className="count-align">{propsData?.data?.divisionName}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between pb-1">
                                                        <label>Company</label>
                                                        <label className="count-align"></label>
                                                    </div>
                                                    <div className="d-flex justify-content-between pb-1">
                                                        <label>Location</label>
                                                        <label className="count-align">{propsData?.data?.locationName}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Building</label>
                                                        <label className="count-align">{propsData?.data?.buildingName}</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-3 pl-1 pr-1">
                                <div className="row ">
                                    <div className="col-12 ">
                                        <div className="row device-settings mr-top pb-1">
                                            <div className=" col-12 p-0 ">
                                                <div className='label-device'>Charge Details</div>
                                                <div className="device-fonts mt-3">
                                                    <div className="d-flex justify-content-between">
                                                        <label>Daily Call Time</label>
                                                        <label className="count-align">{batteryinfo?.dailyCallTime}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Low SOC Limit</label>
                                                        <label className="count-align">{batteryinfo?.lowSocLimit}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between ">
                                                        <label>High Volt Limit</label>
                                                        <label className="count-align">{batteryinfo?.highVoltageLimit}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between ">
                                                        <label>Low Volt Limit</label>
                                                        <label className="count-align">{batteryinfo?.lowVoltageLimit}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row bg-white mt-3  battery-details pb-2 pt-3">
                                            <div className="col-12">
                                                <div className='label-device'>EQ Settings</div>
                                                <div className="device-fonts mt-3">
                                                    <div className="d-flex justify-content-between">
                                                        <label>Max Ahrs Btw EQs</label>
                                                        <label className="count-align">{batteryinfo?.maxAhBetwenEQ}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label>Max Days Btw EQs</label>
                                                        <label className="count-align">{batteryinfo?.maxDaysBetweenEQS}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between ">
                                                        <label>EQ Start Day</label>
                                                        <label className="count-align">{batteryinfo?.eqStartDay}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between ">
                                                        <label>EQ Start Time</label>
                                                        <label className="count-align">{batteryinfo?.eqStartTime}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between ">
                                                        <label>EQ End Day</label>
                                                        <label className="count-align">{batteryinfo?.eqEndDay}</label>
                                                    </div>
                                                    <div className="d-flex justify-content-between ">
                                                        <label>EQ End Time</label>
                                                        <label className="count-align">{batteryinfo?.eqEndTime}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            propsData?.data?.batteryTypeDescription === "Douglas Flat Plate Flooded" && <div className="row bg-white mt-3  battery-details pb-1">
                                                <div className="col-12">
                                                    <div className="bmid"><img className='devicebm' src={DeviceType} alt="" />
                                                        <span>{batteryinfo?.batteryType}</span>
                                                    </div>
                                                    <div className="device-fonts mt-2">
                                                        <div className="d-flex justify-content-between">
                                                            <label>Status</label>
                                                            <label className="count-align">Connected</label>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <label>Battery ID</label>
                                                            <label className="count-align">{batteryinfo?.batteryId}</label>
                                                        </div>
                                                        <div className="d-flex justify-content-between ">
                                                            <label>SIM ID</label>
                                                            <label className="count-align">{batteryinfo?.batteryRxSimNumber}</label>
                                                        </div>
                                                        <div className="d-flex justify-content-between ">
                                                            <label>Last Sync</label>
                                                            <label className="count-align">{batteryinfo?.updatedDate}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 position-relative dicon">
                        <a href="#" className="tips p-2 d-inline-block float-right">
                            <img src={DownloadIcon} className="dow-icon mr-0" alt="" />
                            <span className="tooltips">Download Report</span>
                        </a>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default Batteries;