import React from 'react';

class CustomValidator {
   static isValidateNumberField = (myNumber : any ) => {
        const numberRegEx = /^[0-9\b]+$/;
        return numberRegEx.test(String(myNumber).toLowerCase());
    };

    static isValidateEmailField = (email : any ) => {
        const numberRegEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return numberRegEx.test(String(email).toLowerCase());
    };

    static setMinDate = () => {
        var now = new Date();
  
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
    
        var today = now.getFullYear() + "-" + (month) + "-" + (day);
        return today;
    }

    static convertPercentage = (currentValue:number,  MAX :number) => {
        let result = 0;
        if(MAX !== 0)
        {
            result =  (currentValue / MAX) *100; 
        }
        return result;
    }
}

export default CustomValidator